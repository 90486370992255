import './style.scss';

import { FC } from 'react';

import classNames from 'classnames';

import { MQTitleProps } from '../types';

const MQTitle: FC<MQTitleProps> = ({
  children,
  type = 'h1',
  className = '',
  bold = false,
  underline = false,
  center = false,
  italic = false,
  nowrap = false,
  dataTestId = '',
  ...props
}) => {
  const HtmlTag = type;

  return (
    <HtmlTag
      data-testid={dataTestId || 'mq-title'}
      className={classNames([
        'mq-title',
        'title',
        type,
        className,
        { nowrap },
        { bold },
        { center },
        { italic },
        { underline },
      ])}
      {...props}
    >
      {children}
    </HtmlTag>
  );
};

export default MQTitle;
