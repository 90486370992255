import { FC, ReactNode } from 'react';

type Props = {
  name: string;
  emoji: ReactNode;
};

const MQEmoji: FC<Props> = ({ name, emoji }) => (
  <span className="mq-emoji" data-unified={name} title={name}>
    {emoji}
  </span>
);

export default MQEmoji;
