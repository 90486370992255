import './style.scss';

import { FC } from 'react';

import classNames from 'classnames';

import { MQContainerProps } from '../../types';

const MQContainer: FC<MQContainerProps> = ({ className, size = 'lg', ...props }) => (
  <div data-testid="mq-container" className={classNames(['mq-container', className, size])} {...props} />
);

MQContainer.displayName = 'MQContainer';

export default MQContainer;
