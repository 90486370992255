export const searchArticles = (search: string) => {
  if (window.zE) {
    try {
      window.zE('webWidget', 'helpCenter:setSuggestions', {
        search,
      });
    } catch (e) {
      console.log(e);
    }
  }
};
