import { ProviderTypesEnum } from 'types/access';
import { LayoutTypesEnum, RouteTypes } from 'types/route';

import { createRoute } from 'helpers/routes';

export default {
  previewMainPage: createRoute({
    params: {
      layoutTypes: [LayoutTypesEnum.Header, LayoutTypesEnum.Sidebar],
      types: [RouteTypes.Preview, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
    },
    data: {
      path: '/preview/',
    },
  }),
  previewDashboard: createRoute({
    params: {
      layoutTypes: [LayoutTypesEnum.Header, LayoutTypesEnum.Sidebar],
      types: [RouteTypes.Preview, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
    },
    data: {
      path: '/preview/user-dashboard',
    },
  }),
  previewAdmin: createRoute({
    params: {
      layoutTypes: [LayoutTypesEnum.Header, LayoutTypesEnum.Sidebar],
      types: [RouteTypes.Preview, RouteTypes.Authenticated],
    },
    data: {
      path: `/preview/user-dashboard/mycliq/admin`,
      exact: true,
    },
  }),
  previewMyCliQ: createRoute({
    params: {
      layoutTypes: [LayoutTypesEnum.Header],
      types: [RouteTypes.Preview, RouteTypes.Authenticated],
    },
    data: {
      path: `/preview/user-dashboard/mycliq`,
      exact: true,
    },
  }),
  previewPrograms: createRoute({
    params: {
      layoutTypes: [LayoutTypesEnum.Header],
      types: [RouteTypes.Preview, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
    },
    data: {
      path: `/preview/user-dashboard/programs`,
      exact: true,
    },
  }),
  previewTimeline: createRoute({
    params: {
      layoutTypes: [LayoutTypesEnum.Header],
      types: [RouteTypes.Preview, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
    },
    data: {
      path: '/preview/user-dashboard/timeline',
      exact: true,
    },
  }),
  previewDevelopmentReports: createRoute({
    params: {
      layoutTypes: [LayoutTypesEnum.Header],
      types: [RouteTypes.Preview, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
    },
    data: {
      path: `/preview/user-dashboard/development-reports`,
    },
  }),
  previewProfile: createRoute({
    params: {
      layoutTypes: [LayoutTypesEnum.Header],
      types: [RouteTypes.Preview, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
    },
    data: {
      path: `/preview/user-dashboard/profile`,
    },
  }),
  previewProfileVps: createRoute({
    params: {
      layoutTypes: [],
      types: [RouteTypes.Preview, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        providers: {
          value: [ProviderTypesEnum.VpsEnabled],
        },
      },
    },
    data: {
      path: `/preview/user-dashboard/profile/vps`,
      exact: true,
    },
  }),
  previewProfileVpsQuestions: createRoute({
    params: {
      layoutTypes: [],
      types: [RouteTypes.Preview, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        providers: {
          value: [ProviderTypesEnum.VpsEnabled],
        },
      },
    },
    data: {
      path: `/preview/user-dashboard/profile/vps/questions`,
      exact: true,
    },
  }),
  previewProfileVpsComplete: createRoute({
    params: {
      layoutTypes: [],
      types: [RouteTypes.Preview, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        providers: {
          value: [ProviderTypesEnum.VpsEnabled],
        },
      },
    },
    data: {
      path: `/preview/user-dashboard/profile/vps/complete`,
      exact: true,
    },
  }),
};
