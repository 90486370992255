import { useMemo } from 'react';

import { matchPath, useLocation } from 'react-router-dom';

import ROUTES from 'routes';

import { RouteTypes } from 'types/route';

export const useAppRouter = () => {
  const location = useLocation();

  const route = useMemo(
    () =>
      Object.values(ROUTES).find(
        (item) =>
          !!matchPath(location.pathname, {
            path: item.data.path,
            exact: true,
          }),
      ) || ROUTES.statusPage404,
    [location.pathname],
  );

  return useMemo(
    () => ({
      route,
      location,
      requirements: {
        gdpr: route.oneOfType([RouteTypes.GdprAccepted]),
        personal: route.oneOfType([RouteTypes.Verified]),
        profile: route.oneOfType([RouteTypes.Verified]),
        enrolled: route.oneOfType([RouteTypes.Enrolled]),
        preview: route.oneOfType([RouteTypes.Preview]),
        admin: route.oneOfType([RouteTypes.Admin]),
      },
    }),
    [route, location],
  );
};
