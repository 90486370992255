import './style.scss';

import { FC, ChangeEvent, useEffect, useState } from 'react';

import classesNames from 'classnames';

import { MQFormRadioCardProps } from '../../types';
import MQFormRadio from '../MQFormRadio';

const MQFormRadioCard: FC<MQFormRadioCardProps> = ({
  name,
  dataTestId = '',
  className = '',
  header,
  icon,
  children,
  checked = false,
  disabled = false,
  value,
  onChange,
}) => {
  const [selected, setSelected] = useState(checked);

  useEffect(() => {
    setSelected(checked);
  }, [checked]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = !checked;
    setSelected(newValue);
    onChange(e);
  };

  return (
    <label
      data-testid={`mq-form-radio-card-${dataTestId || name}`}
      className={classesNames(['mq-form-radio-card', className, { disabled }, { checked }])}
    >
      {header && <div className="mq-form-radio-card__header">{header}</div>}

      {icon && <div className="mq-form-radio-card__icon">{icon}</div>}

      <div className="mq-form-radio-card__content">{children}</div>

      <div className="mq-form-radio-card__toggle" />

      <MQFormRadio
        className="mq-form-radio-card__input"
        dataTestId={`mq-form-radio-card-input-${dataTestId || name}`}
        name={name}
        value={JSON.stringify(value)}
        checked={selected}
        onChange={handleChange}
        disabled={disabled}
      />
    </label>
  );
};

export default MQFormRadioCard;
