import { AppLocaleStorageItemDto, AppLocaleStorageItemDtoOf, AppLocaleStorageKeysEnum } from 'types/global';

export const AppLocalStorage = {
  getItem: <T extends AppLocaleStorageKeysEnum = AppLocaleStorageKeysEnum>(
    key: T,
  ): AppLocaleStorageItemDtoOf<T> | null => {
    let value = null;

    if (localStorage.getItem(key)) {
      value = localStorage.getItem(key);
    }

    if (value) {
      return JSON.parse(value);
    }

    return null;
  },
  setItem: (item: AppLocaleStorageItemDto) => {
    localStorage.setItem(item.type, JSON.stringify(item.data));
  },
  removeItem: (key: AppLocaleStorageKeysEnum) => {
    localStorage.removeItem(key);
  },
  clear: () => localStorage.clear(),
};

const caches = {
  locale: AppLocalStorage,
};

export default caches;
