import './style.scss';

import { FC, useMemo } from 'react';

import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import ROUTES from 'routes';

import { useAppLayout } from 'hooks/useAppLayout';
import { useAppRouter } from 'hooks/useAppRouter';

import MQButton from 'modules/MQButton';
import MQIcon from 'modules/MQIcon';
import MQSidebar from 'modules/MQSidebar';
import MQTooltip from 'modules/MQTooltip';

import DashboardSidebar from './Dashboard';
import PlatformSidebar from './Platform';
import PreviewSidebar from './Preview';
import ProgramSidebar from './Program';
import ReportsSidebar from './Reports';

const Contents = [
  {
    component: <PlatformSidebar />,
    paths: [ROUTES.configure.data.path],
  },
  {
    component: <ReportsSidebar />,
    paths: [ROUTES.reports.data.path],
  },
  {
    component: <ProgramSidebar />,
    paths: [ROUTES.program.data.path, ROUTES.programWizardDraftEdit.data.path],
  },
  {
    component: <PreviewSidebar />,
    paths: [ROUTES.previewMainPage.data.path],
  },
  {
    component: <DashboardSidebar />,
    paths: [ROUTES.mainPage.data.path],
  },
];

interface Props {
  expanded: boolean;
}

const AppSidebar: FC<Props> = ({ expanded }) => {
  const { route } = useAppRouter();
  const { setSidebarExpanded } = useAppLayout();

  const Content = useMemo(
    () => Contents.find((item) => item.paths.some((path) => route.data.path.includes(path))),
    [route],
  );

  return (
    <nav className={classNames(['app-sidebar', { expanded }])} aria-label="app-sidebar">
      <div className="app-sidebar__toggle">
        <MQTooltip
          placement="right"
          overlay={
            expanded ? (
              <FormattedMessage defaultMessage="Collapse" description="[Collapse] Tooltip" id="app.sidebar.collapse" />
            ) : (
              <FormattedMessage defaultMessage="Expand" description="[Expand] Tooltip" id="app.sidebar.expand" />
            )
          }
          triggers={['hover']}
        >
          <MQButton
            dataTestId="sidebar-toggle"
            className="app-sidebar__toggle-button"
            variant="tertiary"
            shape="circle"
            onClick={() => {
              setSidebarExpanded?.(!expanded);
            }}
            startIcon={expanded ? <MQIcon.Svg icon="minus" /> : <MQIcon.Svg icon="plus" />}
          />
        </MQTooltip>
      </div>
      <MQSidebar className="app-sidebar__nav">{Content?.component}</MQSidebar>
    </nav>
  );
};

export default AppSidebar;
