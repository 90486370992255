import './style.scss';

import { FC } from 'react';

import classNames from 'classnames';

import { MQLayoutHeadingProps } from '../../types';
import MQDivider from '../MQLayoutDivider';

const MQLayoutHeading: FC<MQLayoutHeadingProps> = ({
  id,
  className = '',
  divider = false,
  compact = false,
  center = false,
  startIcon,
  endIcon,
  bold = false,
  nowrap = false,
  description,
  info,
  children,
  dataTestId = 'mq-layout-heading',
  variant = 'indigo',
  ...props
}) => (
  <div
    {...props}
    id={id}
    data-testid={dataTestId}
    className={classNames(['mq-layout-heading', { compact, center, bold, nowrap }, variant, className])}
  >
    <div className="mq-layout-heading__content">
      <div className="mq-layout-heading__title">
        {startIcon && <span className="mq-layout-heading__start-icon">{startIcon}</span>}
        {children}
        {endIcon && <span className="mq-layout-heading__end-icon">{endIcon}</span>}
      </div>
      {info && <div className="mq-layout-heading__info">{info}</div>}
    </div>

    {description && <span className="mq-layout-heading__description">{description}</span>}

    {divider && <MQDivider className="mq-layout-heading__divider" bold />}
  </div>
);

export default MQLayoutHeading;
