import { FC, ReactNode, useMemo } from 'react';

import ROUTES from 'routes';

import { RouteTypes } from 'types/route';

import { checkIsAuthorized } from 'helpers/access';

import { useAppAuthState } from 'hooks/useAppAuthState';
import { useAppConfigs } from 'hooks/useAppConfigs';
import { useAppParams } from 'hooks/useAppParams';
import { useAppQuery } from 'hooks/useAppQuery';
import { useAppRouter } from 'hooks/useAppRouter';

import AppRedirect from 'router/AppRedirect';

interface PageProviderProps {
  children?: ReactNode;
}

const PageProvider: FC<PageProviderProps> = ({ children }) => {
  const { programId } = useAppParams();
  const { returnTo } = useAppQuery();
  const { route, requirements, location } = useAppRouter();
  const { privateConfigs, publicConfigs } = useAppConfigs();
  const { mode, authenticated, permissions, adminProgramIds } = useAppAuthState();

  const isAuthorized = useMemo(() => {
    const access = route.params.access ?? {};

    if (requirements.admin) {
      access.programs = {
        value: programId ? [+programId] : [],
        strict: !!route.params.access?.programs?.strict,
        exist: true,
      };
    }

    return checkIsAuthorized(access, {
      permissions: permissions ?? {},
      features: publicConfigs.features ?? [],
      adminMode: mode.admin,
      vpsEnabled: privateConfigs.vps.enabled,
      profilePictureEnabled: privateConfigs.profilePictureEnabled,
      labsEnabled: privateConfigs.learningLab.enabled,
      accountEditEnabled: publicConfigs.accountEditEnabled,
      programs: adminProgramIds ?? [],
    });
  }, [
    adminProgramIds,
    mode.admin,
    permissions,
    privateConfigs.learningLab.enabled,
    privateConfigs.profilePictureEnabled,
    privateConfigs.vps.enabled,
    programId,
    publicConfigs.accountEditEnabled,
    publicConfigs.features,
    requirements.admin,
    route.params.access,
  ]);

  const routeState = useMemo(
    () => ({
      from: `${location.pathname}${location.search}`,
    }),
    [location.pathname, location.search],
  );

  if (returnTo && authenticated) {
    return <AppRedirect path="*" to={returnTo} />;
  }

  if (route.oneOfType([RouteTypes.UnAuthenticated, RouteTypes.Base]) && authenticated) {
    if (mode.admin) {
      return (
        <AppRedirect
          path="*"
          to={{
            pathname: ROUTES.adminDashboard.data.path,
            state: routeState,
          }}
        />
      );
    } else {
      return (
        <AppRedirect
          path="*"
          to={{
            pathname: ROUTES.myCliQ.data.path,
            state: routeState,
          }}
        />
      );
    }
  }

  if (route.oneOfType([RouteTypes.Authenticated, RouteTypes.Base]) && !authenticated) {
    return (
      <AppRedirect
        path="*"
        to={{
          pathname: ROUTES.login.data.path,
          state: routeState,
        }}
      />
    );
  }

  if (route.oneOfType([RouteTypes.Authenticated]) && !isAuthorized) {
    return (
      <AppRedirect
        path="*"
        to={{
          pathname: ROUTES.statusPage403.data.path,
          state: routeState,
        }}
      />
    );
  }

  return children;
};

export default PageProvider;
