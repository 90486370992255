import './style.scss';

import { FC, HTMLAttributes } from 'react';

import classNames from 'classnames';

const MQFormInputGroupPrepend: FC<HTMLAttributes<HTMLDivElement>> = ({ className = '', ...props }) => (
  <div {...props} className={classNames(['mq-form-input-group-prepend', className])} />
);

export default MQFormInputGroupPrepend;
