import { default as Badge } from './components/MQSidebarBadge';
import { default as Heading } from './components/MQSidebarHeading';
import { default as List } from './components/MQSidebarList';
import { default as ListItem } from './components/MQSidebarListItem';
import { default as ListToggle } from './components/MQSidebarListToggle';
import { default as Provider } from './components/MQSiderbarProvider';
import './components/MQSiderbarProvider/style.scss';

const MQSidebar = Object.assign(Provider, {
  List,
  Heading,
  Badge,
  ListItem,
  ListToggle,
});

export default MQSidebar;
