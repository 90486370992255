import MQDropdown from './components/MQDropdown';
import MQDropdownItem from './components/MQDropdownItem';
import MQDropdownMenu from './components/MQDropdownMenu';
import MQDropdownToggle from './components/MQDropdownToggle';
import MQLink from './components/MQLink';
import MQNavAccordionItem from './components/MQNavAccordionItem';
import MQNavbar from './components/MQNavbar';
import MQNavbarBrand from './components/MQNavbarBrand';
import MQNavbarCollapse from './components/MQNavbarCollapse';
import MQNavbarToggle from './components/MQNavbarToggle';
import MQNavDivider from './components/MQNavDivider';
import MQNavGroup from './components/MQNavGroup';
import MQNavProvider from './components/MQNavProvider';

const MQNav = Object.assign(MQNavProvider, {
  Brand: MQNavbarBrand,
  Toggle: MQNavbarToggle,
  Collapse: MQNavbarCollapse,
  Navbar: MQNavbar,
  Dropdown: MQDropdown,
  DropdownMenu: MQDropdownMenu,
  DropdownItem: MQDropdownItem,
  DropdownToggle: MQDropdownToggle,
  Divider: MQNavDivider,
  Group: MQNavGroup,
  Link: MQLink,
  AccordionItem: MQNavAccordionItem,
});

export default MQNav;
