import './style.scss';

import { createContext, FC, useCallback, useEffect, useState } from 'react';

import classNames from 'classnames';

import { MQNavbarProps } from '../../types';

const expandSizes = {
  sm: {
    width: 576,
  },
  md: {
    width: 768,
  },
  lg: {
    width: 991,
  },
  xl: {
    width: 1200,
  },
};

export interface MQNavbarContextProps {
  expanded?: boolean;
  setExpanded?: (expanded: boolean) => void;
  collapsed?: boolean;
  setCollapsed?: (expanded: boolean) => void;
}

export const MQNavbarContext = createContext<MQNavbarContextProps>({});

const MQNavbar: FC<MQNavbarProps> = ({
  className = '',
  children,
  expand,
  ariaLabel = 'Nav Bar',
  onExpended,
  ...props
}) => {
  const size = expand && expandSizes[expand];
  const [expanded, setExpanded] = useState(!!(size && size.width >= global.window.innerWidth));
  const [collapsed, setCollapsed] = useState(false);

  const handleResize = useCallback(() => {
    if (expand) {
      const size = expandSizes[expand];
      if (size.width >= global.window.innerWidth) {
        setExpanded(true);
      } else {
        setExpanded(false);
      }
    }
  }, [expand]);

  useEffect(() => {
    onExpended?.(expanded);
  }, [expanded, onExpended]);

  useEffect(() => {
    global.window.addEventListener('resize', handleResize);

    return () => {
      global.window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return (
    <MQNavbarContext.Provider
      value={{
        expanded,
        setExpanded,
        setCollapsed,
        collapsed,
      }}
    >
      <nav
        data-testid="mq-navbar"
        aria-label={ariaLabel}
        {...props}
        className={classNames(['mq-navbar navbar', `navbar-expand-${expand}`, className])}
      >
        {children}
      </nav>
    </MQNavbarContext.Provider>
  );
};

export default MQNavbar;
