import { FC, ReactNode } from 'react';

import { Switch, SwitchProps } from 'react-router-dom';

interface AppSwitchProps extends SwitchProps {
  children: ReactNode;
}

const AppSwitch: FC<AppSwitchProps> = ({ ...props }) => <Switch {...props} />;

export default AppSwitch;
