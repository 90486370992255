import './style.scss';

import { FC } from 'react';

import classesNames from 'classnames';

import { MQLayoutDividerProps } from '../../types';

const MQLayoutDivider: FC<MQLayoutDividerProps> = ({
  className = '',
  axis = 'horizontal',
  compact = false,
  variant,
  bold = false,
}) => (
  <hr className={classesNames(['mq-layout-divider', className, axis, { ['compact']: compact }, variant, { bold }])} />
);

export default MQLayoutDivider;
