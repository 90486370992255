import { lazy } from 'react';

import ROUTES from 'routes';

const AcceptGdpr = lazy(() => import('./AcceptGdpr'));
const AdminDashboard = lazy(() => import('./AdminDashboard'));
const Cliqipedia = lazy(() => import('./Cliqipedia'));
const Configure = lazy(() => import('./Configure'));
const ContentHelpdesk = lazy(() => import('./ContentHelpdesk'));
const Enrollment = lazy(() => import('./Enrollment'));
const LearningLab = lazy(() => import('./LearningLab'));
const LinkedIn = lazy(() => import('./LinkedIn'));
const Matches = lazy(() => import('./Matches'));
const MentorFlix = lazy(() => import('./MentorFlix'));
const MicrosoftAuth = lazy(() => import('./MicrosoftAuth'));
const GoogleAuth = lazy(() => import('./GoogleAuth'));
const People = lazy(() => import('./People'));
const Profilette = lazy(() => import('./Profilette'));
const Program = lazy(() => import('./Program'));
const ProgramWizard = lazy(() => import('./ProgramWizard'));
const Reports = lazy(() => import('./Reports'));
const ResourceLibrary = lazy(() => import('./ResourceLibrary'));
const SlackRedirect = lazy(() => import('./SlackRedirect'));
const UserDashboard = lazy(() => import('./UserDashboard'));
const VerifyWizard = lazy(() => import('./VerifyWizard'));

export const PrivatePages = [
  {
    route: ROUTES.microsoftAuth.data,
    component: <MicrosoftAuth />,
  },
  {
    route: ROUTES.googleAuth.data,
    component: <GoogleAuth />,
  },
  {
    route: ROUTES.verify.data,
    component: <VerifyWizard />,
  },
  {
    route: ROUTES.linkedInConnect.data,
    component: <LinkedIn />,
  },
  {
    route: ROUTES.enrollment.data,
    component: <Enrollment />,
  },
  {
    route: ROUTES.userDashboard.data,
    component: <UserDashboard />,
  },
  {
    route: ROUTES.adminDashboard.data,
    component: <AdminDashboard />,
  },
  {
    route: ROUTES.profilette.data,
    component: <Profilette />,
  },
  {
    route: ROUTES.configure.data,
    component: <Configure />,
  },
  {
    route: ROUTES.program.data,
    component: <Program />,
  },
  {
    route: ROUTES.programWizard.data,
    component: <ProgramWizard />,
  },
  {
    route: ROUTES.resourceLibrary.data,
    component: <ResourceLibrary />,
  },
  {
    route: ROUTES.learningLab.data,
    component: <LearningLab />,
  },
  {
    route: ROUTES.matches.data,
    component: <Matches />,
  },
  {
    route: ROUTES.reports.data,
    component: <Reports />,
  },
  {
    route: ROUTES.mentorFlix.data,
    component: <MentorFlix />,
  },
  {
    route: ROUTES.people.data,
    component: <People />,
  },
  {
    route: ROUTES.helpdesk.data,
    component: <ContentHelpdesk />,
  },
  {
    route: ROUTES.acceptGdpr.data,
    component: <AcceptGdpr />,
  },
  {
    route: ROUTES.cliqipedia.data,
    component: <Cliqipedia />,
  },
  {
    route: ROUTES.slack.data,
    component: <SlackRedirect />,
  },
];
