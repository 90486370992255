import './style.scss';

import { FC, ReactNode } from 'react';

import classNames from 'classnames';

export interface MQCardLinkProps {
  link?: string;
  icon?: ReactNode;
  className?: string;
  children?: ReactNode;
  dataTestId?: string;
  onNavigate?: (to: string) => void;
}

const MQCardLink: FC<MQCardLinkProps> = ({
  className = '',
  link = '',
  onNavigate,
  dataTestId = 'mq-card-link',
  children,
  icon,
}) => (
  <a
    data-testid={dataTestId}
    className={classNames(['mq-card-link', className])}
    href={link}
    onClick={(e) => {
      if (onNavigate) {
        e.preventDefault();
        onNavigate(link);
      }
    }}
  >
    {icon && <span className="mq-card-link__icon">{icon}</span>}
    {children && <span className="mq-card-link__label">{children}</span>}
  </a>
);

export default MQCardLink;
