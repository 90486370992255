import { MQ_LOADER_MAX_WIDTH_PERCENT, MQ_LOADER_MIN_WIDTH_PERCENT } from '../definitions';

export const getRandomWidth = (width?: number, widthInPercents?: boolean) => {
  const max = width || MQ_LOADER_MAX_WIDTH_PERCENT,
    min = (MQ_LOADER_MIN_WIDTH_PERCENT / 100) * (width || MQ_LOADER_MAX_WIDTH_PERCENT);

  const random = Math.random();
  const result = Math.floor(random * (max - min) + min);

  return widthInPercents ? `${result}%` : result;
};
