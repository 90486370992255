import './style.scss';

import { cloneElement, createRef, FC, useCallback } from 'react';

import { MQUploadProps } from './types';

const MQUpload: FC<MQUploadProps> = ({
  name,
  triggers = ['click', 'drop'],
  children,
  disabled = false,
  extensions,
  onUpload,
  onReject,
  ...props
}) => {
  const hiddenFileInput = createRef<HTMLInputElement>();

  const handleUpload = useCallback(
    (files: FileList) => {
      let allowUpload = true;

      for (const file of files) {
        const fileName = file.name.toLowerCase();
        const extension = fileName.split('.').pop();
        const fileType = file.type;

        if ((fileType && !extensions.includes(fileType)) || (!fileType && !extensions.includes(`.${extension}`))) {
          allowUpload = false;
          break;
        }
      }

      if (allowUpload) {
        onUpload(files);
      } else if (onReject) {
        onReject(files);
      }
    },
    [extensions, onReject, onUpload],
  );

  return (
    <>
      <input
        data-testid={`mq-file-input-${name}-hidden`}
        ref={hiddenFileInput}
        className="mq-file-hidden"
        type="file"
        onChange={(e) => {
          e.preventDefault();
          if (e.target.files?.length) {
            handleUpload(e.target.files);
          }
        }}
        accept={extensions.join(', ')}
        disabled={disabled}
      />
      {!!children &&
        cloneElement(children, {
          ...props,
          ...children.props,
          onClick: () => {
            if (triggers.includes('click')) {
              children.props.onClick?.();
              if (hiddenFileInput.current) {
                hiddenFileInput.current.value = '';
                hiddenFileInput.current.click();
              }
            }
          },
          onDrop: (e: DragEvent) => {
            if (triggers.includes('drop')) {
              if (e.dataTransfer?.files.length) {
                e.preventDefault();
                handleUpload(e.dataTransfer.files);
              }
            }
          },
        })}
    </>
  );
};

export default MQUpload;
