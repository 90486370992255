import { useMemo } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector } from 'react-redux';

import { RootState } from 'types/global';

export const useAppSelector: TypedUseSelectorHook<RootState> = (p) => {
  const selector = useMemo(() => createSelector((s: RootState) => s, p), [p]);
  return useSelector(selector);
};
