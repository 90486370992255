import { FC, ReactNode, useMemo, useState } from 'react';

import { GUIDE_STEPS } from 'assets/mocks/guide';

import { useAppIntl } from 'hooks/useAppIntl';

import MQTour from 'modules/MQTour';

import AppDynamicMessage from 'formatters/AppDynamicMessage';

interface GuideWrapperProps {
  children: ReactNode;
}

const GuideWrapper: FC<GuideWrapperProps> = ({ children }) => {
  const intl = useAppIntl();
  const [activeStep, setActiveStep] = useState<string | null>(null);

  const steps = useMemo(
    () =>
      Object.values(GUIDE_STEPS)
        .find((values) => values.some((step) => step.name === activeStep))
        ?.map((item) => ({
          ...item,
          content: (
            <div
              dangerouslySetInnerHTML={{
                __html: intl.dynamicMessage({
                  defaultMessage: item.content,
                }),
              }}
            />
          ),
          title: <AppDynamicMessage defaultMessage={item.title} />,
        })),
    [activeStep, intl],
  );

  return (
    <>
      {!!activeStep && !!steps?.length && (
        <MQTour
          steps={steps}
          active={activeStep}
          setActiveStep={setActiveStep}
          onHide={() => {
            setActiveStep(null);
          }}
        />
      )}
      {children}
    </>
  );
};

export default GuideWrapper;
