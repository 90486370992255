import './style.scss';

import { FC, useState, useEffect, useRef, useCallback } from 'react';

import classNames from 'classnames';

import { MQDropdownProps } from '../../types';

const MQDropdown: FC<MQDropdownProps> = ({
  children,
  className = '',
  variant = 'primary',
  show,
  triggers,
  uncontrolled = false,
  ...props
}) => {
  const [isShow, setIsShow] = useState(show || false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const onClickOutside = useCallback(() => {
    !uncontrolled && setIsShow(false);
  }, [uncontrolled]);

  useEffect(() => {
    if (triggers?.includes('click')) {
      const handleOutsideClick = () => {
        onClickOutside();
      };

      document.addEventListener('mousedown', handleOutsideClick);

      return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
      };
    }
  }, [onClickOutside, variant, triggers]);

  const mouseEnter = () => {
    if (triggers?.includes('hover')) {
      setIsShow(true);
    }
  };

  const mouseLeve = () => {
    if (triggers?.includes('hover')) {
      setIsShow(false);
    }
  };

  return (
    <div
      data-testid="mq-dropdown"
      {...props}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeve}
      ref={containerRef}
      onMouseDown={(event) => {
        event.stopPropagation();
      }}
      onClick={() => {
        if (triggers?.includes('click') && !uncontrolled) {
          setIsShow((prev) => !prev);
        }
      }}
      className={classNames(['mq-nav-dropdown', className, variant], { show: isShow })}
    >
      {children}
    </div>
  );
};

export default MQDropdown;
