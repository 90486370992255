import './style.scss';

import { FC, ReactNode } from 'react';

import classNames from 'classnames';

export interface MQCardSubTitleProps {
  className?: string;
  children?: ReactNode;
  justify?: 'center' | 'start' | 'end';
}

const MQCardSubTitle: FC<MQCardSubTitleProps> = ({ className = '', children, justify = 'center' }) => (
  <div data-testid="mq-card-subtitle" className={classNames(['mq-card__subtitle', className, justify])}>
    {children}
  </div>
);

export default MQCardSubTitle;
