import { FC, useMemo } from 'react';

import { parseISO } from 'date-fns';
import { FormattedDate, FormattedDateTimeRange, FormatDateOptions } from 'react-intl';

import { DateValueType } from 'types/global';

import { mapDateFormatToOptions, toUTCDate } from 'helpers/date';

interface AppFormattedDateProps {
  date?: DateValueType;
  dateFrom?: DateValueType;
  dateTo?: DateValueType;
  standard?: 'iso' | 'utc';
  options?: FormatDateOptions;
}

const AppFormattedDate: FC<AppFormattedDateProps> = ({ date, dateTo, dateFrom, standard, options = {} }) => {
  const configs = useMemo(() => mapDateFormatToOptions(options), [options]);

  const jsDate = useMemo(() => {
    if (date) {
      if (standard === 'iso') {
        return parseISO(new Date(date).toISOString());
      }

      if (standard === 'utc') {
        return toUTCDate(date);
      }

      return date;
    }
  }, [standard, date]);

  const jsDateFrom = useMemo(() => dateFrom && new Date(dateFrom), [dateFrom]);
  const jsDateTo = useMemo(() => dateTo && new Date(dateTo), [dateTo]);

  if (jsDateTo && jsDateFrom) {
    return <FormattedDateTimeRange from={jsDateFrom} to={jsDateTo} {...configs} />;
  } else if (jsDate) {
    return <FormattedDate value={jsDate} {...configs} />;
  }

  return null;
};

export default AppFormattedDate;
