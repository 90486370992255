import './style.scss';

import { FC, useEffect, useState } from 'react';

import alerts from 'helpers/alerts';
import history from 'helpers/history';

import { useAppRouter } from 'hooks/useAppRouter';

import MQAlert from 'modules/MQAlert';
import MQScrollable from 'modules/MQScrollable';

type AppAlertsProps = {
  containerId?: string;
};

const AppAlerts: FC<AppAlertsProps> = ({ containerId }) => {
  const [data, setData] = useState([...alerts.items]);
  const { location } = useAppRouter();

  useEffect(() => {
    if (containerId) {
      alerts.reload();
    }

    return () => {
      alerts.reload();
    };
  }, [containerId]);

  useEffect(() => {
    const unListen = history.mq.listen((newLocation) => {
      if (location.pathname !== newLocation.pathname) {
        alerts.reload();
      }
    });

    return () => {
      unListen();
    };
  }, [location.pathname]);

  useEffect(() => {
    alerts.subscribe((items) => {
      setData(items.reverse());

      if (items.length > data.length && containerId) {
        const container = document.getElementById(containerId);

        if (container) {
          container.scrollTop = 0;
        }
      }
    });
  }, [setData, data, containerId]);

  useEffect(
    () => () => {
      alerts.conclude();
    },
    [],
  );

  const handleClose = (id: string) => {
    alerts.remove(id);
  };

  return (
    <MQScrollable.Pointer id="app-alerts" dependencies={data}>
      {!!data.length && (
        <div className="app-alerts">
          {data.map(({ id, ...item }) => (
            <MQAlert
              key={id}
              onClose={() => {
                handleClose(id);
              }}
              message={item.message}
              variant={item.variant}
              button={item.button}
              dismissible
            >
              {item.body}
            </MQAlert>
          ))}
        </div>
      )}
    </MQScrollable.Pointer>
  );
};

export default AppAlerts;
