import { CountResultsDiff } from '../types';

export const calculateAnimationResult = (
  calculationResult: number,
  calculationResultDiff: CountResultsDiff,
): {
  result: number;
  finalStep: boolean;
} => {
  const result = calculationResult + calculationResultDiff.step;

  const finalStep =
    Math.abs(calculationResultDiff.currentResult - calculationResult) < Math.abs(calculationResultDiff.step * 2);

  return {
    result: finalStep ? calculationResultDiff.currentResult : result,
    finalStep,
  };
};

export const calculateCountDifference = (
  oldValue: number,
  newValue: number,
  steps: number,
  minStepDiff: number,
): CountResultsDiff | null => {
  const step = Math.round((newValue - oldValue) / steps);
  if (Math.abs(step) > minStepDiff) {
    return {
      step: step,
      currentResult: newValue,
    };
  } else {
    return null;
  }
};
