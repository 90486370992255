import './style.scss';

import { FC } from 'react';

import classNames from 'classnames';

import { MQFormRatingProps } from '../../types';

const MQFormRating: FC<MQFormRatingProps> = ({
  className = '',
  label,
  count,
  value,
  defaultIcon,
  activeIcon,
  onChange,
  isValid = true,
  isInvalid = false,
  dataTestId = 'mq-form-rating',
}) => {
  const range = [...Array(count).keys()];

  return (
    <div className={classNames('mq-form-rating', className)} data-testid={dataTestId}>
      <div className="mq-form-rating__filled">
        {range.map((i) => (
          <div
            key={i + 1}
            onClick={() => onChange?.(i + 1)}
            className={classNames(`${value >= i + 1 ? 'star-full' : 'star-empty'}`, {
              ['is-valid']: isValid,
              ['is-invalid']: isInvalid,
            })}
            data-testid="mq-form-rating"
          >
            {value >= i + 1 ? activeIcon : defaultIcon}
          </div>
        ))}
      </div>

      <div className="badge" data-testid={`badge-${value}`}>
        {label}
      </div>
    </div>
  );
};

export default MQFormRating;
