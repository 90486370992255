import './style.scss';

import { FC } from 'react';

import classNames from 'classnames';

import { MQNavbarBrandProps } from '../../types';

const MQNavbarBrand: FC<MQNavbarBrandProps> = ({ className = '', children, isBrand, ...props }) => (
  <div
    data-testid="mq-nav-brand"
    {...props}
    className={classNames(['mq-navbar-brand', className, { branded: isBrand }])}
  >
    {children}
  </div>
);

export default MQNavbarBrand;
