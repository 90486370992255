import './style.scss';

import { FC, ReactNode } from 'react';

import classNames from 'classnames';

export interface MQCardBadgeProps {
  className?: string;
  justify?: 'left' | 'center' | 'right';
  dataTestId?: string;
  children?: ReactNode;
  sticky?: boolean;
}

const MQCardBadge: FC<MQCardBadgeProps> = ({
  className = '',
  dataTestId = 'mq-card-badge',
  justify = 'center',
  children,
  sticky = false,
}) => (
  <div data-testid={dataTestId} className={classNames(['mq-card-badge', className, justify, { sticky }])}>
    {children}
  </div>
);

export default MQCardBadge;
