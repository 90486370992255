import './style.scss';

import { FC } from 'react';

import classNames from 'classnames';

import MQIcon from 'modules/MQIcon';
import MQTooltip from 'modules/MQTooltip';

import { MQFormLabelProps } from '../../types';

const MQFormLabel: FC<MQFormLabelProps> = ({
  children,
  nowrap = false,
  inline = false,
  className = '',
  helpText,
  required,
  description,
  dataTestId = 'mq-form-label',
  infoIcon = false,
  ...props
}) => (
  <label
    {...props}
    className={classNames(['mq-form-label', className, { nowrap }, { inline }])}
    data-testid={dataTestId}
  >
    <span className="mq-form-label__title" >
      {children}
      {required && (
        <span className="mq-form-label__required-icon" data-testid={`${dataTestId}-required`}>
          *
        </span>
      )}
      {helpText && (
        <MQTooltip overlay={helpText}>
          <MQIcon.Svg className="mq-form-label__help-icon" icon="info-circle" />
        </MQTooltip>
      )}
    </span>
    {description && (
      <span className="mq-form-label__description">
        {infoIcon && <MQIcon.Svg icon="info-circle-d" variant="indigo" size="lg" />}
        {description}
      </span>
    )}
  </label>
);

export default MQFormLabel;
