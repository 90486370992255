import './style.scss';

import { FC } from 'react';

import classNames from 'classnames';

import { MQSidebarBadgeProps } from '../../types';

const MQSidebarBadge: FC<MQSidebarBadgeProps> = ({ className = '', children, ...props }) => (
  <span data-testid="mq-sidebar-badge" className={classNames(['mq-sidebar__badge', className])} {...props}>
    {children}
  </span>
);

export default MQSidebarBadge;
