import './style.scss';

import { FC, useCallback, useContext, useMemo } from 'react';

import classNames from 'classnames';

import MQIcon from 'modules/MQIcon';

import { MQAccordionContext } from '../../definitions';
import { MQAccordionItemProps } from '../../types';

const MQAccordionItem: FC<MQAccordionItemProps> = ({
  className,
  eventKey,
  arrow = 'right',
  header,
  children,
  centered = false,
  disabled = false,
  compact = false,
  onSelect,
  toggleIconUp,
  toggleIconDown,
  ariaLabel = 'Accordion item',
  ...props
}) => {
  const accordion = useContext(MQAccordionContext);
  const collapsed = useMemo(() => accordion.activeKey === eventKey, [accordion.activeKey, eventKey]);

  const handleSelect = useCallback(() => {
    if (!disabled) {
      if (children) {
        accordion.onSelect?.(eventKey);
      }

      onSelect?.();
    }
  }, [disabled, children, eventKey, onSelect, accordion]);

  const toggleIcon = useMemo(
    () => (
      <div className="mq-accordion-toggle__arrow">
        {accordion.activeKey === eventKey
          ? toggleIconUp || <MQIcon.Svg icon="chevron-up" />
          : toggleIconDown || <MQIcon.Svg icon="chevron-down" />}
      </div>
    ),
    [accordion.activeKey, eventKey, toggleIconUp, toggleIconDown],
  );

  return (
    <div className={classNames('mq-accordion-item', className, { disabled, compact })}>
      <div
        role={accordion.onSelect && 'button'}
        tabIndex={!disabled && accordion.onSelect ? 0 : -1}
        aria-label={ariaLabel}
        data-testid={`accordion-toggle-${eventKey}`}
        className={classNames('mq-accordion-toggle', arrow, { collapsed: collapsed && !!children, centered, compact })}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            handleSelect?.();
          }
        }}
        onClick={() => {
          handleSelect();
        }}
        {...props}
      >
        {arrow === 'left' && toggleIcon}
        <div className="mq-accordion-toggle__content">{header}</div>
        {arrow === 'right' && toggleIcon}
      </div>
      {children && (
        <div
          data-testid={`accordion-collapse-${eventKey}`}
          className={classNames(['mq-accordion-collapse', { collapsed, compact }])}
          {...props}
        >
          <div className="mq-accordion-collapse__content">{children}</div>
        </div>
      )}
    </div>
  );
};

export default MQAccordionItem;
