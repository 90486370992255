import './style.scss';

import { FC, ReactNode } from 'react';

import classNames from 'classnames';

export interface MQCardOverlayProps {
  className?: string;
  children?: ReactNode;
  dataTestId?: string;
  compact?: boolean;
}

const MQCardOverlay: FC<MQCardOverlayProps> = ({
  className = '',
  compact = false,
  dataTestId = 'mq-card-overlay',
  children,
}) => (
  <div data-testid={dataTestId} className={classNames(['mq-card__overlay', { compact }, className])}>
    {children}
  </div>
);

export default MQCardOverlay;
