import { FC, ReactNode, useMemo } from 'react';

import {
  authorizeThunk,
  FeatureTypeEnum,
  fetchShowCookiesBannerThunk,
  getAllChatRoomsThunk,
  getAuthGdprSelector,
  getAuthUserSelector,
  getAuthViewerSelector,
  getIsGdprIgnoredSelector,
  getEnrollmentCurrentStateThunk,
  getGdprAcceptanceThunk,
  getIntlInfoThunk,
  getPlatformBrandingConfigsThunk,
  getPlatformInformationPrivateThunk,
  getPlatformInformationPublicThunk,
  getProfileCurrentStateThunk,
} from '@mentorcliq/storage';

import { AppLocaleStorageKeysEnum } from 'types/global';

import caches from 'helpers/caches';

import { useAppConfigs } from 'hooks/useAppConfigs';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppProvider } from 'hooks/useAppProvider';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAppUpdate } from 'hooks/useAppUpdate';

import MQLoader from 'modules/MQLoader';

interface AppProviderProps {
  children?: ReactNode;
}

const AppProvider: FC<AppProviderProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { publicConfigs } = useAppConfigs();
  const authUser = useAppSelector(({ auth }) => getAuthUserSelector(auth));
  const authViewer = useAppSelector(({ auth }) => getAuthViewerSelector(auth));

  const gdpr = useAppSelector(({ auth }) => getAuthGdprSelector(auth));

  const isGdprIgnored = useAppSelector(({ auth }) => getIsGdprIgnoredSelector(auth));

  const isGdprEnabled = useMemo(
    () => publicConfigs.features?.find((feature) => feature.name === FeatureTypeEnum.Gdpr)?.enabled || null,
    [publicConfigs.features],
  );

  const isChatEnabled = useMemo(
    () => publicConfigs.features?.find((feature) => feature.name === FeatureTypeEnum.Chat)?.enabled || null,
    [publicConfigs.features],
  );

  const isGdprAllowed = useMemo(
    () => isGdprIgnored || !isGdprEnabled || (isGdprEnabled && gdpr?.accepted === true),
    [gdpr?.accepted, isGdprEnabled, isGdprIgnored],
  );

  useAppUpdate(() => {
    if (authUser) {
      caches.locale.setItem({
        type: AppLocaleStorageKeysEnum.User,
        data: authUser,
      });
    } else {
      caches.locale.removeItem(AppLocaleStorageKeysEnum.User);
      caches.locale.removeItem(AppLocaleStorageKeysEnum.Locale);
    }
  }, [authUser]);

  useAppUpdate(() => {
    if (authViewer) {
      caches.locale.setItem({
        type: AppLocaleStorageKeysEnum.Viewer,
        data: authViewer,
      });
    } else {
      caches.locale.removeItem(AppLocaleStorageKeysEnum.Viewer);
    }
  }, [authViewer]);

  const providers = useMemo(
    () => [
      {
        id: getPlatformInformationPublicThunk.typePrefix,
        fc: async () => {
          await dispatch(getPlatformInformationPublicThunk());
          await dispatch(fetchShowCookiesBannerThunk());
        },
        active: true,
        deps: [],
      },
      {
        id: getIntlInfoThunk.typePrefix,
        fc: async () => {
          await dispatch(getIntlInfoThunk());
        },
        active: true,
        deps: [],
      },
      {
        id: getPlatformBrandingConfigsThunk.typePrefix,
        fc: async () => {
          await dispatch(getPlatformBrandingConfigsThunk());
        },
        active: true,
        deps: [],
      },
      {
        id: authorizeThunk.typePrefix,
        fc: async () => {
          if (authUser?.id) {
            await dispatch(
              authorizeThunk({
                userId: authUser.id,
              }),
            );
          }
        },
        active: !!authUser?.id,
        deps: [authUser?.id],
      },
      {
        id: getGdprAcceptanceThunk.typePrefix,
        fc: async () => {
          if (!!authUser?.id && !!isGdprEnabled && !isGdprIgnored) {
            await dispatch(getGdprAcceptanceThunk());
          }
        },
        active: !!authUser?.id && !!isGdprEnabled && !isGdprIgnored,
        deps: [authUser?.id, isGdprEnabled, isGdprIgnored],
      },
      {
        id: getPlatformInformationPrivateThunk.typePrefix,
        fc: async () => {
          if (!!authUser?.id && isGdprAllowed) {
            await dispatch(getPlatformInformationPrivateThunk());
          }
        },
        active: !!authUser?.id && isGdprAllowed,
        deps: [authUser?.id, isGdprAllowed],
      },
      {
        id: getProfileCurrentStateThunk.typePrefix,
        fc: async () => {
          if (!!authUser?.id && isGdprAllowed) {
            await dispatch(
              getProfileCurrentStateThunk({
                userId: authUser.id,
              }),
            );
          }
        },
        active: !!authUser?.id && isGdprAllowed,
        deps: [authUser?.id, isGdprAllowed],
      },
      {
        id: getEnrollmentCurrentStateThunk.typePrefix,
        fc: async () => {
          if (!!authUser?.id && isGdprAllowed) {
            await dispatch(
              getEnrollmentCurrentStateThunk({
                userId: authUser.id,
              }),
            );
          }
        },
        active: !!authUser?.id && isGdprAllowed,
        deps: [authUser?.id, isGdprAllowed],
      },
      {
        id: getAllChatRoomsThunk.typePrefix,
        fc: async () => {
          if (!!authUser?.id && isGdprAllowed && !!isChatEnabled) {
            await dispatch(
              getAllChatRoomsThunk({
                userId: authUser.id,
              }),
            );
          }
        },
        active: !!authUser?.id && isGdprAllowed && !!isChatEnabled,
        deps: [authUser?.id, isGdprAllowed, isChatEnabled],
      },
    ],
    [dispatch, isGdprEnabled, isGdprIgnored, authUser?.id, isGdprAllowed, isChatEnabled],
  );

  const provider = useAppProvider(providers);

  if (!provider.ready) {
    return <MQLoader.Spinner />;
  }

  return <>{children}</>;
};

export default AppProvider;
