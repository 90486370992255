import './style.scss';

import { FC } from 'react';

import classNames from 'classnames';

import { MQModalFooterProps } from '../../types';

const MQModalFooter: FC<MQModalFooterProps> = ({ className = '', align = 'space-between', ...props }) => (
  <div data-testid="mq-modal-footer" className={classNames('mq-modal__footer', className, align)} {...props} />
);

export default MQModalFooter;
