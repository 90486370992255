import './style.scss';

import { FC, useRef } from 'react';

import classNames from 'classnames';

import { MQFormColorProps } from '../../types';

const MQFormColor: FC<MQFormColorProps> = ({
  value,
  isValid = false,
  isInvalid = false,
  className = '',
  onChange,
  id,
  ...props
}) => {
  const ref = useRef<HTMLInputElement>(null);
  return (
    <div data-testid="mq-form-color" className="mq-form-color">
      <input
        {...props}
        id={id}
        ref={ref}
        data-testid="mq-form-color-swatch"
        className="mq-form-color__swatch"
        color={value}
        value={value}
        inputMode="text"
        onChange={onChange}
        type="color"
      />
      <input
        id={id}
        data-testid="mq-form-color-input"
        className={classNames('mq-form-color__input', className, {
          ['is-valid']: isValid,
          ['is-invalid']: isInvalid,
        })}
        color={value}
        value={value}
        inputMode="text"
        onChange={onChange}
        type="text"
        onFocus={() => {
          ref.current?.click();
          ref.current?.focus();
        }}
      />
    </div>
  );
};

export default MQFormColor;
