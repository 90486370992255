import './style.scss';

import { FC, MouseEvent, useMemo } from 'react';

import classNames from 'classnames';

import { MQLinkComponentProps } from '../../types';

const MQLink: FC<MQLinkComponentProps> = ({
  className = '',
  dataTestId = '',
  variant = 'neutral',
  truncate,
  startIcon,
  endIcon,
  children,
  disabled = false,
  bolder = false,
  eventKey,
  to = '',
  onClick,
  as: Component,
  inline,
  compact,
  underline = false,
  href = '',
  instance,
  ...props
}) => {
  const content = useMemo(
    () => (
      <>
        {startIcon && <span className="start-icon">{startIcon}</span>}
        {children && <span className="mq-nav__link-text">{children}</span>}
        {endIcon && <span className="start-icon">{endIcon}</span>}
      </>
    ),
    [startIcon, endIcon, children],
  );

  return Component ? (
    <Component
      data-testid={dataTestId || 'mq-nav-link'}
      {...props}
      onClick={(e: MouseEvent<HTMLAnchorElement>) => {
        onClick?.(e);
        if (disabled || !to) {
          e.preventDefault();
          return false;
        }
      }}
      to={to}
      className={classNames('mq-link', variant, { inline, compact, bolder, underline, disabled }, className, {
        truncate: !!startIcon || !!endIcon || truncate,
      })}
      ref={instance}
    >
      {content}
    </Component>
  ) : (
    <a
      data-testid={dataTestId || 'mq-nav-link'}
      {...props}
      href={href}
      onClick={(e) => {
        onClick?.(e);
        if (disabled || !href) {
          e.preventDefault();
          return false;
        }
      }}
      className={classNames('mq-link', { inline, compact, bolder, underline, disabled }, variant, className, {
        truncate: !!startIcon || !!endIcon || truncate,
      })}
      ref={instance}
      data-event-key={eventKey}
    >
      {content}
    </a>
  );
};

export default MQLink;
