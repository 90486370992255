import './style.scss';

import { FC } from 'react';

import classNames from 'classnames';

import { MQLoaderSpinnerProps } from '../../types';

const MQLoaderSpinner: FC<MQLoaderSpinnerProps> = ({
  size = 'md',
  className = '',
  variant = 'primary',
  compact = false,
}) => (
  <span data-testid="mq-loader-spinner" className={classNames('mq-loader-spinner', { compact }, className)}>
    <span className={classNames('mq-loader-spinner__wrapper', variant, size)} />
  </span>
);

export default MQLoaderSpinner;
