import { ProviderTypesEnum } from 'types/access';
import { LayoutTypesEnum, RouteTypes } from 'types/route';

import { createRoute } from 'helpers/routes';

export default {
  userDashboard: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
    },
    data: {
      path: '/user-dashboard',
    },
  }),
  myCliQ: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
    },
    data: {
      path: '/user-dashboard/mycliq',
      exact: true,
    },
  }),
  timeline: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
    },
    data: {
      path: '/user-dashboard/timeline',
      exact: true,
    },
  }),
  programs: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
    },
    data: {
      path: `/user-dashboard/programs`,
      exact: true,
    },
  }),
  developmentReports: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
    },
    data: {
      path: `/user-dashboard/development-reports`,
    },
  }),
  developmentReportsOthers: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
    },
    data: {
      path: `/user-dashboard/development-reports/others`,
      exact: true,
    },
  }),
  developmentReportsPersonal: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
    },
    data: {
      path: `/user-dashboard/development-reports/personal`,
      exact: true,
    },
  }),
  profile: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
    },
    data: {
      path: `/user-dashboard/profile`,
    },
  }),
  profileEdit: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
    },
    data: {
      path: `/user-dashboard/profile`,
      exact: true,
    },
  }),
  profilePicture: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
      access: {
        providers: {
          value: [ProviderTypesEnum.ProfilePictureEnabled],
        },
      },
    },
    data: {
      path: `/user-dashboard/profile/picture`,
      exact: true,
    },
  }),
  profileVps: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
      access: {
        providers: {
          value: [ProviderTypesEnum.VpsEnabled],
        },
      },
    },
    data: {
      path: `/user-dashboard/profile/vps`,
      exact: true,
    },
  }),
  profileVpsQuestions: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
      access: {
        providers: {
          value: [ProviderTypesEnum.VpsEnabled],
        },
      },
    },
    data: {
      path: `/user-dashboard/profile/vps/questions`,
      exact: true,
    },
  }),
  profileVpsComplete: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
      access: {
        providers: {
          value: [ProviderTypesEnum.VpsEnabled],
        },
      },
    },
    data: {
      path: `/user-dashboard/profile/vps/complete`,
      exact: true,
    },
  }),
  profileAccount: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
    },
    data: {
      path: `/user-dashboard/profile/account`,
      exact: true,
    },
  }),
};
