import { FileDestinationEnum } from '@mentorcliq/storage';

import { STATIC_ASSETS } from 'definitions/assets';

export const DOCUMENT_FILE_UPLOAD_EXTENSIONS: Record<string, string> = {
  pdf: 'application/pdf',
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  txt: 'text/plain',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  csv: 'text/csv',
  dot: 'application/msword',
  dotx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  xml: 'application/xml',
  xps: 'application/oxps',
  ods: 'application/vnd.oasis.opendocument.spreadsheet',
  pot: 'application/vnd.ms-powerpoint',
  potx: 'application/vnd.openxmlformats-officedocument.presentationml.template',
  pps: 'application/vnd.ms-powerpoint',
  ppsx: 'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  json: 'application/json',
  pgp: 'application/pgp-encrypted',
  pgp_accept: '.pgp',
};

export const IMAGE_FILE_UPLOAD_EXTENSIONS: Record<string, string> = {
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  gif: 'image/gif',
  svg: 'image/svg+xml',
};

export const VIDEO_FILE_UPLOAD_EXTENSIONS: Record<string, string> = {
  mp4: 'video/mp4',
  ogg: 'video/ogg',
  webm: 'video/webm',
  mov: 'video/quicktime',
};

export const AUDIO_FILE_UPLOAD_EXTENSIONS: Record<string, string> = {
  mp3: 'audio/mpeg',
};

export const FILE_STORAGE_CONFIGS = {
  [FileDestinationEnum.MilestoneFile]: {
    extensions: [...Object.values(DOCUMENT_FILE_UPLOAD_EXTENSIONS), ...Object.values(IMAGE_FILE_UPLOAD_EXTENSIONS)],
    maxSize: 15000000,
  },
  [FileDestinationEnum.ProgramLogo]: {
    extensions: [...Object.values(IMAGE_FILE_UPLOAD_EXTENSIONS)],
    maxSize: 15000000,
  },
  [FileDestinationEnum.ProfilePicture]: {
    extensions: [...Object.values(IMAGE_FILE_UPLOAD_EXTENSIONS)],
    maxSize: 15000000,
  },
  [FileDestinationEnum.EmailConfigLogo]: {
    extensions: [...Object.values(IMAGE_FILE_UPLOAD_EXTENSIONS)],
    maxSize: 102400,
  },
  [FileDestinationEnum.PlatformBrandingLogo]: {
    extensions: [...Object.values(IMAGE_FILE_UPLOAD_EXTENSIONS)],
    maxSize: 102400,
  },
  [FileDestinationEnum.Email]: {
    extensions: [
      ...Object.values(IMAGE_FILE_UPLOAD_EXTENSIONS),
      ...Object.values(DOCUMENT_FILE_UPLOAD_EXTENSIONS),
      ...Object.values(VIDEO_FILE_UPLOAD_EXTENSIONS),
    ],
    maxSize: 15000000,
  },
  [FileDestinationEnum.Chat]: {
    extensions: [
      ...Object.values(DOCUMENT_FILE_UPLOAD_EXTENSIONS),
      ...Object.values(IMAGE_FILE_UPLOAD_EXTENSIONS),
      ...Object.values(VIDEO_FILE_UPLOAD_EXTENSIONS),
      ...Object.values(AUDIO_FILE_UPLOAD_EXTENSIONS),
    ],
    maxSize: 15000000,
  },
  [FileDestinationEnum.ResourceLibrary]: {
    extensions: [...Object.values(IMAGE_FILE_UPLOAD_EXTENSIONS), ...Object.values(DOCUMENT_FILE_UPLOAD_EXTENSIONS)],
    maxSize: 100000000,
  },
  [FileDestinationEnum.Translations]: {
    extensions: [DOCUMENT_FILE_UPLOAD_EXTENSIONS.csv, DOCUMENT_FILE_UPLOAD_EXTENSIONS.json],
    maxSize: 15000000,
  },
  [FileDestinationEnum.GoogleApi]: {
    extensions: [DOCUMENT_FILE_UPLOAD_EXTENSIONS.json],
    maxSize: 15000000,
  },
  resourceThumbnail: {
    extensions: [...Object.values(IMAGE_FILE_UPLOAD_EXTENSIONS)],
    maxSize: 2000000,
  },
  [FileDestinationEnum.HRIS]: {
    extensions: [
      DOCUMENT_FILE_UPLOAD_EXTENSIONS.csv,
      DOCUMENT_FILE_UPLOAD_EXTENSIONS.xls,
      DOCUMENT_FILE_UPLOAD_EXTENSIONS.xlsx,
      DOCUMENT_FILE_UPLOAD_EXTENSIONS.zip,
      DOCUMENT_FILE_UPLOAD_EXTENSIONS.txt,
      DOCUMENT_FILE_UPLOAD_EXTENSIONS.pgp,
      DOCUMENT_FILE_UPLOAD_EXTENSIONS.pgp_accept,
    ],
    maxSize: 100000000,
  },
  [FileDestinationEnum.ProfileField]: {
    extensions: [
      ...Object.values(DOCUMENT_FILE_UPLOAD_EXTENSIONS),
      ...Object.values(IMAGE_FILE_UPLOAD_EXTENSIONS),
      ...Object.values(VIDEO_FILE_UPLOAD_EXTENSIONS),
      ...Object.values(AUDIO_FILE_UPLOAD_EXTENSIONS),
    ],
    maxSize: 100000000,
  },
};

export const RESOURCE_FORMATS = {
  video: 'VIDEO',
  ppt: 'PPT',
  pptx: 'PPTX',
  doc: 'DOC',
  docx: 'DOCX',
  png: 'PNG',
  pdf: 'PDF',
  other: 'OTHER',
};

export const YOUTUBE_VALIDATOR_REGEXP =
  /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;

export const VIMEO_VALIDATOR_REGEXP =
  /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|video\/|)(\d+)/;

export const VALID_VIDEO_FORMATS_LIST = ['mp4', 'ogg', 'webm'];

export const UNICODE_FONT_FACES = [
  {
    name: 'unicode',
    family: 'unicode, sans-serif',
    locales: ['ru'],
    variants: [
      {
        style: 'normal',
        bold: false,
        url: STATIC_ASSETS.fonts.robotoRegular,
      },
      {
        style: 'normal',
        bold: true,
        url: STATIC_ASSETS.fonts.robotoBold,
      },
    ],
  },
  {
    name: 'unicode',
    family: 'unicode, sans-serif',
    locales: ['zh-hans', 'zh-hant', 'ja'],
    variants: [
      {
        style: 'normal',
        bold: false,
        url: STATIC_ASSETS.fonts.notoRegular,
      },
      {
        style: 'normal',
        bold: true,
        url: STATIC_ASSETS.fonts.notoBold,
      },
    ],
  },
  {
    name: 'unicode',
    family: 'unicode, sans-serif',
    locales: ['th'],
    variants: [
      {
        style: 'normal',
        bold: false,
        url: STATIC_ASSETS.fonts.thaiRegular,
      },
      {
        style: 'normal',
        bold: true,
        url: STATIC_ASSETS.fonts.thaiBold,
      },
    ],
  },
];
