import { useLocation } from 'react-router-dom';

export const useAppQuery = (): Record<string, string> => {
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const queryParams = Object.create(null);
  query.forEach((value, key) => {
    if (value) {
      queryParams[key] = value;
    }
  });

  return queryParams;
};
