import { default as Body } from './components/MQModalBody';
import { default as Footer } from './components/MQModalFooter';
import { default as Header } from './components/MQModalHeader';
import { default as Provider } from './components/MQModalProvider';
import { default as Title } from './components/MQModalTitle';

const MQModal = Object.assign(Provider, {
  Header,
  Footer,
  Body,
  Title,
});

export default MQModal;
