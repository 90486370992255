import './style.scss';

import { FC, ReactNode } from 'react';

import classNames from 'classnames';

export interface MQCardFooterProps {
  justify?: 'space-between' | 'center' | 'start' | 'end';
  className?: string;
  inline?: boolean;
  children?: ReactNode;
  description?: ReactNode;
  compact?: boolean;
  alt?: boolean;
}

const MQCardFooter: FC<MQCardFooterProps> = ({ className = '', alt = true, justify = 'space-between', children }) => (
  <div data-testid="mq-card-footer" className={classNames(['mq-card__footer', className, justify, { alt }])}>
    {children}
  </div>
);

export default MQCardFooter;
