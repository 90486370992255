import './style.scss';

import { FC } from 'react';

import classNames from 'classnames';

import { MQSidebarListProps } from '../../types';

const MQSidebarList: FC<MQSidebarListProps> = ({ className = '', children, ...props }) => (
  <ul data-testid="mq-sidebar-list" className={classNames(['mq-sidebar__list', className])} {...props}>
    {children}
  </ul>
);

export default MQSidebarList;
