import './style.scss';

import { FC, useState } from 'react';

import { FormattedMessage } from 'react-intl';

import { useAppIntl } from 'hooks/useAppIntl';

import MQAnimate from 'modules/MQAnimate';
import MQIcon from 'modules/MQIcon';
import MQSidebar from 'modules/MQSidebar';

import AppLink from 'router/AppLink';

const DashboardSidebar: FC = () => {
  const intl = useAppIntl();
  const [selectedKeys, setSelectedKey] = useState<string[]>([]);

  return (
    <MQAnimate.Styles animation="slide-right">
      <MQSidebar.List className="app-sidebar__list" data-testid="sidebar-dashboard">
        <MQSidebar.ListItem
          as={AppLink}
          className="app-sidebar__list-item"
          data-testid="sidebar-dashboard-link"
          to="#"
          prefix={<MQIcon.Svg icon="house" />}
        >
          <FormattedMessage defaultMessage="Current Menu Item" description="[Sidebar] Menu Item Name" />
        </MQSidebar.ListItem>
        <MQSidebar.ListItem
          as={AppLink}
          data-testid="sidebar-configure-link"
          to="#"
          prefix={<MQIcon.Svg icon="sliders-h" />}
          overlay={<MQIcon.Svg icon="arrow-circle-right" />}
        >
          <FormattedMessage defaultMessage="Other Menu Item" description="[Sidebar] Menu Item Name" />
        </MQSidebar.ListItem>
        <MQSidebar.ListItem as={AppLink} className="app-sidebar__list">
          <MQSidebar.List className="app-sidebar__list">
            <MQSidebar.ListItem as={AppLink}>
              <MQSidebar.List className="app-sidebar__list">
                <MQSidebar.ListItem as={AppLink}>
                  <MQSidebar.Heading className="app-sidebar__heading">
                    <FormattedMessage defaultMessage="Programs" description="[Sidebar] Menu Item Name" />
                    <MQSidebar.Badge className="mq-ml-scale-3">2</MQSidebar.Badge>
                  </MQSidebar.Heading>
                </MQSidebar.ListItem>

                <MQSidebar.ListToggle
                  eventKey={`category-1`}
                  badge={1}
                  title="Program Category"
                  className="app-sidebar__list-toggle"
                  activeEventKeys={selectedKeys}
                  setActiveEventKeys={(keys) => setSelectedKey(keys)}
                >
                  <MQSidebar.List className="app-sidebar__list">
                    <MQSidebar.ListItem
                      as={AppLink}
                      data-testid={`sidebar-program-1-link`}
                      to="#"
                      overlay={<MQIcon.Svg icon="arrow-circle-right" />}
                      truncate
                    >
                      <FormattedMessage defaultMessage="My Program Link" description="[Programs] My Program Link" />
                    </MQSidebar.ListItem>
                  </MQSidebar.List>
                </MQSidebar.ListToggle>

                <MQSidebar.ListToggle
                  key="draft-programs"
                  dataTestId="program-draft-programs"
                  eventKey="draft-programs"
                  isDraft
                  title={intl.formatMessage({
                    defaultMessage: 'Draft Programs',
                    description: '[Admin.Sidebar] label',
                  })}
                  badge={1}
                  activeEventKeys={selectedKeys}
                  setActiveEventKeys={(keys) => setSelectedKey(keys)}
                  className="app-sidebar__list-toggle"
                >
                  <MQSidebar.List className="app-sidebar__list">
                    <MQSidebar.ListItem
                      as={AppLink}
                      data-testid={`sidebar-draft-1-link`}
                      key={`draft-1`}
                      to="#"
                      overlay={<MQIcon.Svg icon="arrow-circle-right" />}
                      truncate
                    >
                      <FormattedMessage
                        defaultMessage="My Program Draft Link"
                        description="[Dashboard] My Program Draft Link"
                      />
                    </MQSidebar.ListItem>
                  </MQSidebar.List>
                </MQSidebar.ListToggle>
              </MQSidebar.List>
            </MQSidebar.ListItem>
          </MQSidebar.List>
        </MQSidebar.ListItem>
      </MQSidebar.List>
    </MQAnimate.Styles>
  );
};

export default DashboardSidebar;
