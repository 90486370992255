import './style.scss';

import { FC, ReactNode } from 'react';

import classNames from 'classnames';

export interface MQCardTextProps {
  className?: string;
  children?: ReactNode;
  description?: ReactNode;
  dataTestId?: string;
  variant?: 'dark' | 'indigo';
  size?: 'sm' | 'md';
}

const MQCardTitle: FC<MQCardTextProps> = ({
  className = '',
  dataTestId = 'mq-card-title',
  variant = 'dark',
  description,
  children,
  size = 'sm',
}) => (
  <div data-testid={dataTestId} className={classNames(['mq-card__title', className, variant])}>
    {children && (
      <span className={classNames('mq-card-title__label', [size])} data-testid="mq-card-title-label">
        {children}
      </span>
    )}
    {description && <small className="mq-card-title__description">{description}</small>}
  </div>
);

export default MQCardTitle;
