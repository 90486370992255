import { createBrowserHistory } from 'history';

const instance = createBrowserHistory({
  basename: '',
});

const history = {
  mq: instance,
};

export default history;
