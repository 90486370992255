import './style.scss';

import { FC, ReactNode, useState } from 'react';

type MQSliderSwipeDataProps = {
  x: number;
  y: number;
};

type MQSliderSwiperProps = {
  direction?: 'x' | 'y' | 'xy';
  children: ReactNode;
  onSwipe: (data: MQSliderSwipeDataProps) => void;
  onSwipeEnd: (data: MQSliderSwipeDataProps) => void;
};

const MQSliderSwiper: FC<MQSliderSwiperProps> = ({ direction, children, onSwipe, onSwipeEnd }) => {
  const [drag, setDrag] = useState(false);
  return (
    <div
      style={{
        pointerEvents: drag ? 'none' : 'all',
      }}
      className="mq-swiper"
      onMouseDown={(ev) => {
        ev.stopPropagation();
        ev.preventDefault();

        const startPos = {
          x: ev.clientX,
          y: ev.clientY,
        };

        const handleMouseMove = (e: MouseEvent) => {
          e.stopPropagation();
          setDrag(true);
          const data = {
            x: e.x - startPos.x,
            y: e.y - startPos.y,
          };

          if (direction !== 'x') {
            data.y = e.y - (startPos?.y || 0);
          }

          if (direction !== 'y') {
            data.x = e.x - (startPos?.x || 0);
          }
          onSwipe(data);
        };

        const handleMouseUp = (e: MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();
          const data = {
            x: e.x - startPos.x,
            y: e.y - startPos.y,
          };
          onSwipeEnd(data);
          setDrag(false);
          window.removeEventListener('mousemove', handleMouseMove, true);
        };

        window.addEventListener('mousemove', handleMouseMove, true);
        window.addEventListener('mouseup', handleMouseUp, {
          once: true,
          capture: true,
        });
      }}
    >
      {children}
    </div>
  );
};
export default MQSliderSwiper;
