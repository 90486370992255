import './style.scss';

import { FC } from 'react';

import classNames from 'classnames';

import { MQTableProps } from './types';

const MQTable: FC<MQTableProps> = ({
  className = '',
  striped = false,
  responsive = true,
  borderless = false,
  centered = false,
  stretched = false,
  inline = false,
  align = 'middle',
  alignHeader = 'start',
  layout = 'vertical',
  variant = 'primary',
  dataTestId = 'mq-table',
  ...props
}) => (
  <div
    className={classNames([
      'mq-table',
      `align-${align}`,
      `header-${alignHeader}`,
      layout,
      variant,
      { responsive },
      { centered },
      { striped },
      { inline },
      { stretched },
      { borderless },
      className,
    ])}
    {...props}
  >
    <table data-testid={dataTestId} tabIndex={0} aria-label="Table" {...props} />
  </div>
);

export default MQTable;
