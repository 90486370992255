import { createRoute } from 'helpers/routes';

export default {
  redirectToFilenameSystemFiles: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/system/files/resources/:resourceId/:filename`,
    },
    redirectTo: {
      path: 'https://storage.googleapis.com/',
      bucket: true,
    },
  }),
  redirectToFilename: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/sites/all/modules/mentorcliq/features/mentorcliq_your_resource_library/resources/:filename`,
    },
    redirectTo: {
      path: 'https://storage.googleapis.com/',
      bucket: true,
    },
  }),
  redirectFromLegacyYRLToFilename: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/sites/all/modules/mentorcliq/mc_resource_library/files/:filename`,
    },
    redirectTo: {
      path: 'https://storage.googleapis.com/',
      bucket: true,
    },
  }),
};
