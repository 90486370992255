import { useEffect, useState, useDebugValue, useCallback } from 'react';

import isMobile from 'ismobilejs';

const MOBILE_WIDTH = 768;

export const useAppViewport = ({ width = MOBILE_WIDTH, agent = navigator.userAgent } = {}) => {
  const [isMobileState, setIsMobileState] = useState(window.innerWidth <= width || isMobile(agent).phone);
  const [isTabletState, setIsTabletState] = useState(window.innerWidth <= width || isMobile(agent).tablet);

  const handleResize = useCallback(() => {
    setIsMobileState(global.window.innerWidth <= width || isMobile(agent).phone);
    setIsTabletState(global.window.innerWidth <= width || isMobile(agent).tablet);
  }, [agent, width]);

  useEffect(() => {
    global.window.addEventListener('resize', handleResize);

    return () => {
      global.window.removeEventListener('resize', handleResize);
    };
  }, [agent, handleResize, width]);

  useDebugValue('useAppViewport');

  return {
    isMobileState,
    isTabletState,
  };
};
