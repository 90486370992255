import { MQFormDateInputMinMax, MQFormDateInputType } from '../types';

export const generateMinMaxValues = (
  type: MQFormDateInputType,
  minValues: MQFormDateInputMinMax,
  maxValues: MQFormDateInputMinMax,
): { min: string; max: string } => {
  switch (type) {
    case 'date':
      return {
        min: `${minValues.year}-${minValues.month}-${minValues.day}`,
        max: `${maxValues.year}-${maxValues.month}-${maxValues.day}`,
      };
    case 'month':
      return {
        min: `${minValues.year}-${minValues.month}`,
        max: `${maxValues.year}-${maxValues.month}`,
      };
    default:
      return {
        min: `${minValues.hours}:${minValues.minutes}`,
        max: `${maxValues.hours}:${maxValues.minutes}`,
      };
  }
};

export const generateValidDateValue = (type: MQFormDateInputType, value: string) => {
  switch (type) {
    case 'month':
      return value?.split('-').slice(0, 2).join('-');
    case 'year':
      return value?.split('-').slice(0, 1).join('-');
    default:
      return value;
  }
};

export const validateDateInput = (
  inputValue: string,
  minDate: Date | undefined,
  maxDate: Date | undefined,
): boolean => {
  const inputDate = new Date(inputValue);
  return !isNaN(inputDate.getTime()) && (!minDate || inputDate >= minDate) && (!maxDate || inputDate <= maxDate);
};

export const toUTCDate = (d: Date | number | string): Date => {
  const date = new Date(d).getTime();
  const offset = new Date().getTimezoneOffset();
  return new Date(date.valueOf() + offset * 60 * 1000);
};
