import './style.scss';

import { FC, ReactNode } from 'react';

import classNames from 'classnames';

export interface MQCardHeaderProps {
  justify?: 'space-between' | 'center' | 'start' | 'end';
  className?: string;
  inline?: boolean;
  children?: ReactNode;
  description?: ReactNode;
  compact?: boolean;
  alt?: boolean;
}

const MQCardHeader: FC<MQCardHeaderProps> = ({
  className = '',
  justify = 'start',
  alt = true,
  inline = false,
  children,
}) => (
  <div data-testid="mq-card-header" className={classNames(['mq-card-header', className, justify, { inline }, { alt }])}>
    {children}
  </div>
);

export default MQCardHeader;
