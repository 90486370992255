import { FC, ReactNode, useEffect, useLayoutEffect, useMemo, useState } from 'react';

import {
  changeIntlLocaleThunk,
  changeIntlTempLocaleThunk,
  getAuthUserSelector,
  getIntlBaseMessagesSelector,
  getIntlDynamicMessagesSelector,
  getIntlInfoSelector,
  getIntlTempLocaleSelector,
  loadIntlBasePrivateMessagesThunk,
  loadIntlBasePublicMessagesThunk,
  loadIntlDynamicMessagesThunk,
} from '@mentorcliq/storage';

import { AppLocaleStorageKeysEnum } from 'types/global';

import { APP_ENV_CONFIGS } from 'definitions/configs';

import caches from 'helpers/caches';

import { useAppConfigs } from 'hooks/useAppConfigs';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppLocation } from 'hooks/useAppLocation';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAppUpdate } from 'hooks/useAppUpdate';

import AppLocale from 'router/AppLocale';

import LocaleProvider from 'providers/LocaleProvider';

interface LocaleWrapperProps {
  children?: ReactNode;
}

const LocaleWrapper: FC<LocaleWrapperProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const location = useAppLocation();
  const { publicConfigs } = useAppConfigs();
  const tempLocale = useAppSelector(({ intl }) => getIntlTempLocaleSelector(intl));
  const intlInfo = useAppSelector(({ intl }) => getIntlInfoSelector(intl));
  const intlBaseMessages = useAppSelector(({ intl }) => getIntlBaseMessagesSelector(intl));
  const intlDynamicMessages = useAppSelector(({ intl }) => getIntlDynamicMessagesSelector(intl));
  const authUser = useAppSelector(({ auth }) => getAuthUserSelector(auth));

  const [basename, setBasename] = useState(
    publicConfigs.languages.list?.find(({ languageCode }) => location.pathname.startsWith(`${languageCode}/`, 1))
      ?.prefix,
  );

  const messages = useMemo(
    () =>
      Object.entries(intlDynamicMessages).reduce(
        (acc, [key, messages]) => ({
          ...acc,
          [key]: {
            ...acc[key],
            ...messages,
          },
        }),
        intlBaseMessages,
      ),
    [intlBaseMessages, intlDynamicMessages],
  );

  const requestParams = useMemo(() => {
    if (intlInfo) {
      if (APP_ENV_CONFIGS.version === intlInfo.version) {
        return {
          version: APP_ENV_CONFIGS.defaultVersion || null,
        };
      } else {
        return {
          version: APP_ENV_CONFIGS.version ?? (APP_ENV_CONFIGS.defaultVersion || null),
        };
      }
    }
  }, [intlInfo]);

  const currentLocale = useMemo(() => {
    const locale = basename ?? tempLocale ?? authUser?.language ?? window.navigator.language.split('-')[0];
    const founded = publicConfigs.languages.list.find(({ languageCode }) => languageCode === locale)?.languageCode;

    if (!founded) {
      return publicConfigs.languages.locale;
    }

    return locale;
  }, [basename, tempLocale, authUser?.language, publicConfigs.languages.list, publicConfigs.languages.locale]);

  useAppUpdate(() => {
    if (tempLocale) {
      caches.locale.setItem({
        type: AppLocaleStorageKeysEnum.Locale,
        data: tempLocale,
      });
    } else {
      caches.locale.removeItem(AppLocaleStorageKeysEnum.Locale);
    }
  }, [tempLocale]);

  useLayoutEffect(() => {
    if (requestParams?.version) {
      const promise = dispatch(
        loadIntlBasePublicMessagesThunk({
          language: currentLocale,
          params: {
            version: requestParams.version,
            fallback: APP_ENV_CONFIGS.defaultVersion,
          },
        }),
      );

      return () => {
        promise.abort();
      };
    }
  }, [dispatch, currentLocale, requestParams]);

  useLayoutEffect(() => {
    const promise = dispatch(
      loadIntlDynamicMessagesThunk({
        language: currentLocale,
      }),
    );

    return () => {
      promise.abort();
    };
  }, [dispatch, currentLocale]);

  useEffect(() => {
    if (authUser?.id) {
      if (requestParams?.version) {
        dispatch(
          loadIntlBasePrivateMessagesThunk({
            language: currentLocale,
            params: {
              version: requestParams.version,
              fallback: APP_ENV_CONFIGS.defaultVersion,
            },
          }),
        );
      }
    }
  }, [dispatch, currentLocale, authUser?.id, requestParams]);

  useEffect(() => {
    const promise = dispatch(
      changeIntlLocaleThunk({
        language: currentLocale,
      }),
    );

    return () => {
      promise.abort();
    };
  }, [dispatch, currentLocale]);

  useEffect(() => {
    if (!authUser?.id) {
      const promise = dispatch(
        changeIntlTempLocaleThunk({
          language: null,
        }),
      );

      return () => {
        promise.abort();
      };
    }
  }, [dispatch, authUser?.id]);

  const data = useMemo(
    () => ({
      messages: messages[currentLocale],
      locale: currentLocale,
    }),
    [currentLocale, messages],
  );

  useEffect(() => {
    if (!authUser?.id) {
      const promise = dispatch(
        changeIntlTempLocaleThunk({
          language: null,
        }),
      );

      return () => {
        promise.abort();
      };
    }
  }, [dispatch, authUser?.id]);

  useLayoutEffect(() => {
    if (basename) {
      dispatch(
        changeIntlTempLocaleThunk({
          language: basename,
        }),
      );
    }
  }, [dispatch, basename]);

  useAppUpdate(() => {
    if (basename && tempLocale && basename !== tempLocale) {
      setBasename(tempLocale);
    }
  }, [basename, tempLocale]);

  return (
    <AppLocale basename={tempLocale ?? data.locale}>
      <LocaleProvider defaultLocale={publicConfigs.languages.native} locale={data.locale} messages={data.messages}>
        {children}
      </LocaleProvider>
    </AppLocale>
  );
};

export default LocaleWrapper;
