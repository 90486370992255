import './style.scss';

import { FC } from 'react';

import classNames from 'classnames';

import { MQDropdownMenuProps } from '../../types';

const MQDropdownMenu: FC<MQDropdownMenuProps> = ({ children, className = '', ...props }) => (
  <div data-testid="mq-dropdown-menu" {...props} className={classNames(['mq-nav-dropdown__menu', className])}>
    {children}
  </div>
);

export default MQDropdownMenu;
