import './style.scss';

import { FC } from 'react';

import classNames from 'classnames';

import { MQFormSelectProps } from '../../types';

const MQFormSelect: FC<MQFormSelectProps> = ({
  className = '',
  dataTestId = '',
  count = 1,
  compact = false,
  isValid = false,
  isInvalid = false,
  children,
  maxCount = 15,
  ariaLabel = 'Select Field',
  ...props
}) => (
  <select
    {...props}
    data-testid={dataTestId || `mq-form-select-${props.name}`}
    size={Math.min(count, maxCount)}
    id={props.name}
    className={classNames(
      'mq-form-select',
      { ['is-valid']: isValid, ['is-invalid']: isInvalid, compact },
      { countable: !!count },
      className,
    )}
    aria-label={ariaLabel}
  >
    {children}
  </select>
);

export default MQFormSelect;
