import { RouteBaseDefinition, RouteDefinition, RoutePathParams, RouteQueryParams } from 'types/route';

export const generateSearchPath = (path: string, params: RouteQueryParams): string => {
  const queryString = Object.entries(params)
    .filter(([key, value]) => {
      const parsed = value ?? null;
      return key && parsed !== null;
    })
    .map(([key, value]) => (value !== null ? `${key}=${params[key]}` : null))
    .filter((item) => item !== null)
    .join('&');

  if (queryString) {
    path = `${path}?${queryString}`;
  }

  return path;
};

export const generateQueryPath = (path: string, params: RoutePathParams): string =>
  Object.entries(params).reduce((path, [key, value]) => {
    if (!value) {
      return path.replace(`:${key}?/`, '').replace(`:${key}?`, '').replace(`:${key}/`, '').replace(`:${key}`, '');
    }

    return path.replace(`:${key}?`, `${value}`).replace(`:${key}`, `${value}`);
  }, path);

export const createRoute = (routeData: RouteBaseDefinition): RouteDefinition => ({
  ...routeData,
  oneOfType: (types) => types.some((t) => routeData.params.types.includes(t)),
  convert: (params?: { path?: RoutePathParams; search?: RouteQueryParams }): string => {
    let path = routeData.data.path;

    if (params?.path) {
      path = generateQueryPath(path, params.path);
    }

    if (params?.search) {
      path = generateSearchPath(path, params.search);
    }

    return path;
  },
});
