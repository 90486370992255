import './style.scss';

import { FC, useMemo } from 'react';

import classNames from 'classnames';

import { MQColProps } from '../../types';

const MQCol: FC<MQColProps> = ({
  className = '',
  compact = false,
  dataTestId = '',
  prefix = 'mq-col',
  min = 'xs',
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
  ...props
}) => {
  const sizes = useMemo(
    () => ({
      xs,
      sm,
      md,
      lg,
      xl,
      xxl,
    }),
    [xs, sm, md, lg, xl, xxl],
  );

  const data = useMemo(() => {
    const classes = [prefix];

    Object.entries(sizes).forEach(([size, value]) => {
      let span;
      let offset = null;
      let order = null;

      if (typeof value === 'object') {
        ({ span, offset, order } = value);
      } else {
        span = value;
      }

      const infix = size !== min ? `-${size}` : '';

      if (!!span || span === 0) {
        classes.push(span === true ? `${prefix}${infix}` : `${prefix}${infix}-${span}`);
      }

      if (order) {
        classes.push(`order${infix}-${order}`);
      }

      if (offset) {
        classes.push(`offset${infix}-${offset}`);
      }
    });

    return {
      classes,
    };
  }, [sizes, min, prefix]);

  return (
    <div
      data-testid={`mq-grid-col${dataTestId}`}
      className={classNames(['mq-grid-col', className, { compact }, ...data.classes])}
      {...props}
    />
  );
};
export default MQCol;
