import { LayoutSizeTypes, LayoutTypesEnum, RouteTypes } from 'types/route';

import { createRoute } from 'helpers/routes';

export default {
  learningLab: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
      layoutSize: LayoutSizeTypes.lg,
    },
    data: {
      path: `/learning-lab`,
    },
  }),
  labsList: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
      layoutSize: LayoutSizeTypes.lg,
    },
    data: {
      path: `/learning-lab/labs-list`,
      exact: true,
    },
  }),
  mentorLab: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Banner,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
      layoutSize: LayoutSizeTypes.lg,
    },
    data: {
      path: `/learning-lab/mentor-lab`,
      exact: true,
    },
  }),
  mentorLabQuickStart: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
      layoutSize: LayoutSizeTypes.lg,
    },
    data: {
      path: `/learning-lab/mentor-lab/quick-start`,
      exact: true,
    },
  }),
  mentorLabMasterClass: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Banner,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
      layoutSize: LayoutSizeTypes.lg,
    },
    data: {
      path: `/learning-lab/mentor-lab/master-class`,
      exact: true,
    },
  }),
  inclusionLab: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Banner,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
      layoutSize: LayoutSizeTypes.lg,
    },
    data: {
      path: `/learning-lab/inclusion-lab`,
      exact: true,
    },
  }),
  inclusionLabQuickStart: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
      layoutSize: LayoutSizeTypes.lg,
    },
    data: {
      path: `/learning-lab/inclusion-lab/quick-start`,
      exact: true,
    },
  }),
  inclusionLabMasterClass: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Banner,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
      layoutSize: LayoutSizeTypes.lg,
    },
    data: {
      path: `/learning-lab/inclusion-lab/master-class`,
      exact: true,
    },
  }),
  leadershipLab: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Banner,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
    },
    data: {
      path: `/learning-lab/leadership-lab`,
      exact: true,
    },
  }),
  leadershipLabQuickStart: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
      layoutSize: LayoutSizeTypes.lg,
    },
    data: {
      path: `/learning-lab/leadership-lab/quick-start`,
      exact: true,
    },
  }),
  leadershipLabMasterClass: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Banner,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
      layoutSize: LayoutSizeTypes.lg,
    },
    data: {
      path: `/learning-lab/leadership-lab/master-class`,
      exact: true,
    },
  }),
  learningLabLive: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
      layoutSize: LayoutSizeTypes.lg,
    },
    data: {
      path: `/learning-lab/:lab/:type/live`,
      exact: true,
    },
  }),
};
