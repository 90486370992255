import { MQOverlayDataProps, MQOverlayPlacementTypes } from '../types';

const generateOverlayTranslate = (size: number, distance: number, maxSize: number) => {
  if (distance + size / 2 > maxSize) {
    return -(size / 2 + (distance + size / 2) - maxSize);
  } else if (distance - size / 2 < 0) {
    return -(size / 2 + (distance - size / 2));
  } else {
    return -(size / 2);
  }
};

export const generateOverlayPlacement = (
  rect: DOMRect,
  placement: MQOverlayPlacementTypes,
): MQOverlayPlacementTypes => {
  const dimensions = {
    left: rect.left,
    right: window.innerWidth - rect.right,
    top: rect.top,
    bottom: window.innerHeight - rect.bottom,
  };

  if (['auto', 'right'].includes(placement) && dimensions.left >= dimensions.right) {
    placement = 'left';
  } else if (['auto', 'left'].includes(placement) && dimensions.right >= dimensions.left) {
    placement = 'right';
  } else if (['auto', 'bottom'].includes(placement) && dimensions.top >= dimensions.bottom) {
    placement = 'top';
  } else if (['auto', 'top'].includes(placement) && dimensions.bottom >= dimensions.top) {
    placement = 'bottom';
  } else if (['auto', 'top-right'].includes(placement) && dimensions.bottom >= dimensions.top) {
    if (dimensions.right > dimensions.left) {
      placement = 'bottom-left';
    } else {
      placement = 'bottom-right';
    }
  } else if (['auto', 'top-left'].includes(placement) && dimensions.bottom >= dimensions.top) {
    if (dimensions.right > dimensions.left) {
      placement = 'bottom-left';
    } else {
      placement = 'bottom-right';
    }
  } else if (['auto', 'bottom-right'].includes(placement) && dimensions.top >= dimensions.bottom) {
    if (dimensions.right > dimensions.left) {
      placement = 'top-left';
    } else {
      placement = 'top-right';
    }
  } else if (['auto', 'bottom-left'].includes(placement) && dimensions.top >= dimensions.bottom) {
    if (dimensions.right > dimensions.left) {
      placement = 'top-left';
    } else {
      placement = 'top-right';
    }
  }

  return placement;
};

export const generateOverlayRelativeData = (rect: DOMRect, data: MQOverlayDataProps): MQOverlayDataProps | null => {
  if (!data.width) {
    data.width = 0;
  }

  if (!data.height) {
    data.height = 0;
  }

  if (rect) {
    data.placement = generateOverlayPlacement(rect, data.placement);

    switch (data.placement) {
      case 'bottom':
        data.top = rect.bottom;
        data.left = rect.left + rect.width / 2;
        data.translateX = generateOverlayTranslate(data.width, data.left, window.innerWidth);
        return data;
      case 'top':
        data.bottom = window.innerHeight - rect.top;
        data.left = rect.left + rect.width / 2;
        data.translateX = generateOverlayTranslate(data.width, data.left, window.innerWidth);
        return data;
      case 'left':
        data.right = window.innerWidth - rect.left;
        data.top = rect.top + rect.height / 2;
        data.translateY = generateOverlayTranslate(data.height, data.top, window.innerHeight);
        return data;
      case 'right':
        data.left = rect.right;
        data.top = rect.top + rect.height / 2;
        data.translateY = generateOverlayTranslate(data.height, data.top, window.innerHeight);
        return data;
    }
  }

  return null;
};

export const generateOverlayAbsoluteData = (rect: DOMRect, data: MQOverlayDataProps): MQOverlayDataProps | null => {
  if (!data.width) {
    data.width = 0;
  }

  if (!data.height) {
    data.height = 0;
  }

  if (rect) {
    data.placement = generateOverlayPlacement(rect, data.placement);
    switch (data.placement) {
      case 'bottom':
        data.top = rect.bottom;
        if (window.innerWidth - rect.right > rect.width) {
          data.left = rect.left;
        } else {
          data.right = window.innerWidth - rect.right;
        }
        break;
      case 'bottom-left':
        data.top = rect.bottom;
        data.left = rect.left;
        break;
      case 'bottom-right':
        data.top = rect.bottom;
        data.right = window.innerWidth - rect.right;
        break;
      case 'top':
        data.bottom = window.innerHeight - rect.top;
        if (window.innerWidth - rect.right > rect.width) {
          data.left = rect.left;
        } else {
          data.right = window.innerWidth - rect.right;
        }
        break;
      case 'right':
        data.left = rect.right;
        data.top = rect.top;
        break;
      case 'top-left':
        data.bottom = window.innerHeight - rect.top;
        data.left = rect.left;
        break;
      case 'top-right':
        data.bottom = window.innerHeight - rect.top;
        data.right = window.innerWidth - rect.right;
        break;
      case 'left':
      case 'auto':
      default:
        data.right = window.innerWidth - rect.left;
        data.top = rect.top;
        break;
    }

    if (data.left && data.left + data.width > window.innerWidth) {
      data.translateX = window.innerWidth - data.width - data.left;
    }

    return data;
  }

  return null;
};

export const getEventTarget = (target: EventTarget) => {
  if (target instanceof HTMLElement) {
    return target;
  }
};
