import './style.scss';

import { FC, useEffect, useRef } from 'react';

import classNames from 'classnames';

import MQOverlay from 'modules/MQOverlay';

import { MQTooltipProps } from './types';

const MQTooltip: FC<MQTooltipProps> = ({
  active = true,
  arrow = true,
  hideOnScroll = false,
  delay,
  size = 'md',
  overlay,
  children,
  className = '',
  triggers = ['visibility', 'hover', 'focus', 'blur'],
  placement = 'auto',
  rootId = 'root',
  ...props
}) => {
  const delayId = useRef(0);

  useEffect(() => {
    window.clearTimeout(delayId.current);
  }, [delay]);

  if (active) {
    return (
      <MQOverlay>
        {(state, setState) => (
          <>
            <MQOverlay.Trigger
              {...props}
              state={state}
              setState={(data) => {
                const duration = data ? delay?.show || 0 : delay?.hide || 0;
                delayId.current = window.setTimeout(() => {
                  setState(data);
                }, duration);
              }}
              placement={placement}
              triggers={triggers}
            >
              {children}
            </MQOverlay.Trigger>
            {state && (
              <MQOverlay.Wrapper
                className="mq-tooltip-wrapper"
                state={state}
                setState={(data) => {
                  const duration = data ? delay?.show || 0 : delay?.hide || 0;
                  delayId.current = window.setTimeout(() => {
                    setState(data);
                  }, duration);
                }}
                placement={placement}
                hideOnScroll={hideOnScroll}
                triggers={triggers}
                rootId={rootId}
                centralize
              >
                {({ placement, translateX, translateY }) => (
                  <div
                    data-testid="mq-tooltip"
                    data-placement={placement}
                    className={classNames(['mq-tooltip', className, size])}
                  >
                    {arrow && (
                      <div
                        className="mq-tooltip__arrow"
                        style={{
                          marginLeft: -(translateX || 0),
                          marginTop: -(translateY || 0),
                        }}
                      />
                    )}
                    <div className="mq-tooltip-inner" data-testid="mq-tooltip-inner">
                      {overlay || children.props?.children || children}
                    </div>
                  </div>
                )}
              </MQOverlay.Wrapper>
            )}
          </>
        )}
      </MQOverlay>
    );
  }

  return <>{children}</>;
};

export default MQTooltip;
