import { FC } from 'react';

import { FeatureTypeEnum, PermissionsEnum } from '@mentorcliq/storage';
import { FormattedMessage } from 'react-intl';

import ROUTES from 'routes';

import { ProviderTypesEnum } from 'types/access';

import MQAnimate from 'modules/MQAnimate';
import MQIcon from 'modules/MQIcon';
import MQSidebar from 'modules/MQSidebar';

import AppLink from 'router/AppLink';

import PermissionWrapper from 'components/PermissionWrapper';

const ReportsSidebar: FC = () => (
  <MQAnimate.Styles animation="slide-left">
    <MQSidebar.List className="app-sidebar__list" data-testid="sidebar-program">
      <MQSidebar.ListItem
        as={AppLink}
        data-testid="reports-sidebar-dashboard"
        className="app-sidebar__list-item"
        to={ROUTES.adminDashboard.data.path}
        prefix={<MQIcon.Svg icon="long-arrow-alt-left" />}
      >
        <FormattedMessage defaultMessage="Admin Dashboard" description="[Admin Dashboard] Menu Item Name" />
      </MQSidebar.ListItem>

      <MQSidebar.ListItem>
        <MQSidebar.List className="app-sidebar__list">
          <MQSidebar.ListItem className="app-sidebar__list-item" data-testid="sidebar-reports">
            <MQSidebar.Heading className="app-sidebar__heading">
              <FormattedMessage defaultMessage="REPORTS" description="[Sidebar] Menu Item Name" />
            </MQSidebar.Heading>
          </MQSidebar.ListItem>
          <PermissionWrapper
            features={{ value: [FeatureTypeEnum.RoiReport] }}
            permissions={{ value: [PermissionsEnum.RoiCalculator] }}
            strict
          >
            <MQSidebar.ListItem
              as={AppLink}
              className="app-sidebar__list-item"
              data-testid="sidebar-roi-calculator-link"
              to={ROUTES.reportsROICalculator.data.path}
              prefix={<MQIcon.Svg icon="chart-pie" />}
            >
              <FormattedMessage defaultMessage="ROI Calculator" description="[Sidebar] Menu Item Name" />
            </MQSidebar.ListItem>
          </PermissionWrapper>
          <PermissionWrapper
            permissions={{ value: [PermissionsEnum.ViewRoiTrackingResults] }}
            features={{ value: [FeatureTypeEnum.RoiReport] }}
            strict
          >
            <MQSidebar.ListItem
              as={AppLink}
              className="app-sidebar__list-item"
              data-testid="sidebar-roi-tracking-report-link"
              to={ROUTES.roiTrackingReport.data.path}
              prefix={<MQIcon.Svg icon="file-chart-column" />}
            >
              <FormattedMessage defaultMessage="ROI Tracking Report" description="[Sidebar] Menu Item Name" />
            </MQSidebar.ListItem>
          </PermissionWrapper>
          <PermissionWrapper
            permissions={{ value: [PermissionsEnum.ViewLearningLabReport] }}
            providers={{ value: [ProviderTypesEnum.LabsEnabled] }}
            strict
          >
            <MQSidebar.ListItem
              as={AppLink}
              className="app-sidebar__list-item"
              data-testid="sidebar-mentor-lab-report-link"
              to={ROUTES.learningLabReport.data.path}
              prefix={<MQIcon.Svg icon="screen-users" />}
            >
              <FormattedMessage defaultMessage="LearningLab Report" description="[Sidebar] Menu Item Name" />
            </MQSidebar.ListItem>
          </PermissionWrapper>
          <PermissionWrapper permissions={{ value: [PermissionsEnum.ExportPlatformData] }}>
            <MQSidebar.ListItem
              as={AppLink}
              className="app-sidebar__list-item"
              data-testid="sidebar-platform-report-link"
              to={ROUTES.platformReports.data.path}
              prefix={<MQIcon.Svg icon="file-export" />}
            >
              <FormattedMessage defaultMessage="Export Data" description="[Sidebar] Menu Item Name" />
            </MQSidebar.ListItem>
          </PermissionWrapper>
          <PermissionWrapper>
            <MQSidebar.ListItem
              as={AppLink}
              className="app-sidebar__list-item"
              data-testid="sidebar-mentor-relationship-exception-report-link"
              to={ROUTES.platformRelationshipExceptionReport.data.path}
              prefix={<MQIcon.Svg icon="user-xmark-alt" />}
            >
              <FormattedMessage
                defaultMessage="Relationship Exception Report"
                description="[Sidebar] Menu Item Name"
                id="sidebar.reports.relationship.exception.link"
              />
            </MQSidebar.ListItem>
          </PermissionWrapper>
          <PermissionWrapper
            features={{ value: [FeatureTypeEnum.ResourceLibrary] }}
            permissions={{ value: [PermissionsEnum.ManagePlatformResources] }}
            strict
          >
            <MQSidebar.ListItem
              as={AppLink}
              className="app-sidebar__list-item"
              data-testid="sidebar-resource-library-report-link"
              to={ROUTES.resourceLibraryReport.data.path}
              prefix={<MQIcon.Svg icon="photo-film" />}
            >
              <FormattedMessage defaultMessage="Resource Library Report" description="[Sidebar] Menu Item Name" />
            </MQSidebar.ListItem>
          </PermissionWrapper>
          <PermissionWrapper
            features={{ value: [FeatureTypeEnum.MentorFlix] }}
            permissions={{ value: [PermissionsEnum.ViewMentorFlixReport] }}
            strict
          >
            <MQSidebar.ListItem
              as={AppLink}
              className="app-sidebar__list-item"
              data-testid="sidebar-mentor-flix-report-link"
              to={ROUTES.mentorFlixReport.data.path}
              prefix={<MQIcon.Svg icon="film" />}
            >
              <FormattedMessage defaultMessage="MentorFlix Report" description="[Sidebar] Menu Item Name" />
            </MQSidebar.ListItem>
          </PermissionWrapper>
          <PermissionWrapper
            permissions={{
              value: [PermissionsEnum.ViewContentHelpdeskReport],
            }}
            features={{ value: [FeatureTypeEnum.ContentHelpdesk] }}
            strict
          >
            <MQSidebar.ListItem
              as={AppLink}
              className="app-sidebar__list-item"
              data-testid="sidebar-content-helpdesk-report-link"
              to={ROUTES.helpdeskReport.data.path}
              prefix={<MQIcon.Svg icon="cabinet-filing" />}
            >
              <FormattedMessage defaultMessage="Content Helpdesk Report" description="[Sidebar] Menu Item Name" />
            </MQSidebar.ListItem>
          </PermissionWrapper>
        </MQSidebar.List>
      </MQSidebar.ListItem>
    </MQSidebar.List>
  </MQAnimate.Styles>
);
export default ReportsSidebar;
