import './style.scss';

import { FC } from 'react';

import classNames from 'classnames';

import { MQFormNavProps } from '../../types';

const MQFormNav: FC<MQFormNavProps> = ({ dataTestId = 'mq-form-nav', nowrap = true, alt = true, ...props }) => (
  <div {...props} data-testid={dataTestId} className={classNames(['mq-form-nav', { alt }, { nowrap }])} />
);

export default MQFormNav;
