import { LayoutTypesEnum, RouteTypes } from 'types/route';

import { createRoute } from 'helpers/routes';

export default {
  linkedInConnect: createRoute({
    params: {
      layoutTypes: [LayoutTypesEnum.Footer, LayoutTypesEnum.Header, LayoutTypesEnum.Sidebar, LayoutTypesEnum.Alerts],
      types: [RouteTypes.GdprAccepted, RouteTypes.Authenticated],
    },
    data: {
      path: `/external/profile/connect`,
      exact: true,
    },
  }),
};
