import approval from './approval';
import cookiesPolicy from './cookiesPolicy';
import enrollments from './enrollments';
import main from './main';
import match from './match';
import privacy from './privacy';
import quickcliqs from './quickcliqs';
import status from './status';
import support from './support';
import survey from './survey';

const commonRoutes = {
  ...main,
  ...status,
  ...quickcliqs,
  ...match,
  ...privacy,
  ...support,
  ...enrollments,
  ...survey,
  ...approval,
  ...cookiesPolicy,
};

export default commonRoutes;
