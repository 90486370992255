import { FC } from 'react';

import { FormatNumberOptions, FormattedMessage, FormattedNumber } from 'react-intl';

interface AppFormattedNumberProps {
  number: number;
  ordinal?: boolean;
  options?: FormatNumberOptions;
}

const AppFormattedNumber: FC<AppFormattedNumberProps> = ({ number, ordinal = false, options }) => {
  if (ordinal) {
    const lastDigit = number % 10;

    switch (lastDigit) {
      case 1:
        return (
          <FormattedMessage
            defaultMessage="{number}st"
            description="[st] label"
            values={{
              number,
            }}
          />
        );
      case 2:
        return (
          <FormattedMessage
            defaultMessage="{number}nd"
            description="[st] label"
            values={{
              number,
            }}
          />
        );
      case 3:
        return (
          <FormattedMessage
            defaultMessage="{number}rd"
            description="[st] label"
            values={{
              number,
            }}
          />
        );
      default:
        return (
          <FormattedMessage
            defaultMessage="{number}th"
            description="[st] label"
            values={{
              number,
            }}
          />
        );
    }
  }

  return <FormattedNumber value={number} {...options} />;
};

export default AppFormattedNumber;
