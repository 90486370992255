import './style.scss';

import { FC } from 'react';

import classNames from 'classnames';

import { MQDropdownToggleProps } from '../../types';

const MQDropdownToggle: FC<MQDropdownToggleProps> = ({ children, className = '', toggleIcon, ...props }) => (
  <div data-testid="mq-dropdown-toggle" {...props} className={classNames(['mq-dropdown__toggle', className])}>
    {children}
    {toggleIcon}
  </div>
);
export default MQDropdownToggle;
