import * as React from 'react';
import { ElementType, ReactElement, ReactNode, useLayoutEffect } from 'react';

import { noop } from 'lodash';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

type AppFormatXMLElementFn<T, R = string | T | (string | T)[]> = (parts: Array<string | T>) => R;
type AppFormattedMessageValue = Record<
  string,
  string | number | boolean | null | Date | ReactNode | AppFormatXMLElementFn<ReactNode, ReactNode>
>;

interface AppFormattedMessageProps<V extends AppFormattedMessageValue = AppFormattedMessageValue>
  extends MessageDescriptor {
  values?: V;
  tagName?: ElementType;

  children?(nodes: ReactNode[]): ReactElement | null;
}

const APP_LOADED_MESSAGES: string[] = [];

const AppFormattedMessage = <V extends AppFormattedMessageValue = AppFormattedMessageValue>(
  props: AppFormattedMessageProps<V>,
) => {
  useLayoutEffect(() => {
    if (props.id) {
      if (!APP_LOADED_MESSAGES.includes(props.id)) {
        APP_LOADED_MESSAGES.push(props.id);
      }
    } else {
      console.error(props.defaultMessage);
    }
  }, [props.defaultMessage, props.id]);

  noop(APP_LOADED_MESSAGES);

  return <FormattedMessage {...props} />;
};

export default AppFormattedMessage;
