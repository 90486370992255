import { default as Col } from './components/MQCol';
import { default as Container } from './components/MQContainer';
import { default as FlexBox } from './components/MQFlexBox';
import { default as Row } from './components/MQRow';

const MQGrid = {
  Col,
  Row,
  FlexBox,
  Container,
};

export default MQGrid;
