import { default as Item } from './components/MQSliderItem';
import { default as Provider } from './components/MQSliderProvider';
import { default as Swiper } from './components/MQSliderSwiper';

const MQSlider = Object.assign(Provider, {
  Item,
  Swiper,
});

export default MQSlider;
