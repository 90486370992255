import './style.scss';

import { FC, HTMLAttributes, useContext, useEffect, useState } from 'react';

import classNames from 'classnames';

import { MQAccordionContext } from '../../definitions';

export interface MQAccordionProps extends HTMLAttributes<HTMLElement> {
  activeKey?: string;
  defaultActiveKey?: string;
  onToggle?: (eventKey?: string) => void;
  bordered?: boolean;
  stripped?: boolean;
}

const MQAccordionProvider: FC<MQAccordionProps> = ({
  className,
  onToggle,
  defaultActiveKey,
  activeKey,
  bordered,
  stripped,
  children,
}) => {
  const accordionContext = useContext(MQAccordionContext);

  const [accordionActiveKey, setAccordionActiveKey] = useState(
    defaultActiveKey || activeKey || accordionContext.activeKey,
  );

  useEffect(() => {
    if (activeKey) {
      setAccordionActiveKey(activeKey);
    }
  }, [activeKey, accordionContext.activeKey]);

  const handleSelect = (eventKey?: string) => {
    onToggle?.(eventKey);
    setAccordionActiveKey((prev) => (prev === eventKey ? '' : eventKey));
  };

  return (
    <div className={classNames(['mq-accordion', className, { bordered, stripped }])}>
      <MQAccordionContext.Provider value={{ onSelect: handleSelect, activeKey: accordionActiveKey }}>
        {children}
      </MQAccordionContext.Provider>
    </div>
  );
};

export default MQAccordionProvider;
