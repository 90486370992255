import './style.scss';

import { FC } from 'react';

const MQLoaderDots: FC = () => (
  <span className="mq-loader-dots">
    <span className="mq-loader-dots__dot" />
    <span className="mq-loader-dots__dot" />
    <span className="mq-loader-dots__dot" />
  </span>
);

export default MQLoaderDots;
