import { ChatMessageDto } from '@mentorcliq/storage';
import { format } from 'date-fns';

import { LOCALE_DATE_FORMAT_WITH_MONTH_WEEK_DAY, LOCALE_DATE_FORMAT_WITH_MONTH } from 'definitions/configs';

import { getPureDate, getTodayTime, getYesterdayTime } from 'helpers/date';

import { CategorizeChatMessagesProps } from '../types';

export const categorizeMessagesByDate = (
  messages: ChatMessageDto[],
  todayDateName: string,
  yesterdayDateName: string,
) => {
  const current = new Date();
  const todayTime = getTodayTime();
  const yesterdayTime = getYesterdayTime();

  const initial = {
    [todayTime]: {
      time: todayTime,
      date: todayDateName,
      messages: [],
    },
    [yesterdayTime]: {
      time: yesterdayTime,
      date: yesterdayDateName,
      messages: [],
    },
  };

  const formatted = messages.reduce<Record<string, CategorizeChatMessagesProps>>((categorized, msg) => {
    const messageDate = getPureDate(msg.created);
    const msgDateTime = messageDate.getTime();

    if (!categorized[msgDateTime]) {
      const messageYear = messageDate.getFullYear();
      const currentYear = current.getFullYear();

      const options = {
        date: messageDate,
        format: LOCALE_DATE_FORMAT_WITH_MONTH_WEEK_DAY,
      };

      if (messageYear !== currentYear) {
        options.format = LOCALE_DATE_FORMAT_WITH_MONTH;
      }

      categorized[msgDateTime] = {
        time: msgDateTime,
        date: format(options.date, options.format),
        messages: [msg],
      };
    } else {
      categorized[msgDateTime].messages.push(msg);
    }

    return categorized;
  }, initial);

  return Object.values(formatted)
    .sort((prev, next) => prev.time - next.time)
    .filter(({ messages }) => !!messages.length);
};
