import './style.scss';

import { FC, useMemo } from 'react';

import classNames from 'classnames';

import MQNav from 'modules/MQNav';

import { MQSidebarListToggleProps } from '../../types';

const MQSidebarListToggle: FC<MQSidebarListToggleProps> = ({
  title,
  badge,
  eventKey,
  activeEventKeys,
  setActiveEventKeys,
  children,
  icon,
  className = '',
  isDraft,
  dataTestId,
}) => {
  const handleSelect = () => {
    if (activeEventKeys.includes(eventKey)) {
      setActiveEventKeys(activeEventKeys.filter((activeEventKey) => activeEventKey !== eventKey));
    } else {
      setActiveEventKeys(activeEventKeys.concat(eventKey));
    }
  };

  const active = useMemo(() => activeEventKeys.includes(eventKey), [eventKey, activeEventKeys]);

  return (
    <MQNav.AccordionItem
      className={classNames(['mq-sidebar__list-toggle', className, { active }])}
      startIcon={icon}
      badge={
        badge && (
          <span
            className={classNames('mq-sidebar__list-badge-container-badge', {
              drafts: isDraft,
            })}
          >
            {badge}
          </span>
        )
      }
      onClick={handleSelect}
      dataTestId={dataTestId}
      title={title}
      content={<div data-testid={`mq-sidebar-accordion-collapse-${eventKey}`}>{children}</div>}
      showIcon
    />
  );
};

export default MQSidebarListToggle;
