import './style.scss';

import { FC, ReactNode } from 'react';

import classNames from 'classnames';

export interface MQCardTextProps {
  className?: string;
  children?: ReactNode;
  dataTestId?: string;
}

const MQCardText: FC<MQCardTextProps> = ({ className = '', dataTestId = 'mq-card-text', children }) => (
  <p data-testid={dataTestId} className={classNames(['mq-card__text', className])}>
    {children}
  </p>
);

export default MQCardText;
