import { useRef, useEffect, FC, ReactNode } from 'react';

interface MQIsVisible {
  onVisible: () => void;
  children?: ReactNode;
  threshold?: number;
}

const MQIsVisible: FC<MQIsVisible> = ({ onVisible, children, threshold = 1 }) => {
  const targetRef = useRef(null);

  useEffect(() => {
    const target = targetRef.current;
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: threshold,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          onVisible();
        }
      });
    }, options);

    if (target) {
      observer.observe(target);
    }

    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, [onVisible, threshold]);

  return (
    <div className="mq-is-vsible" ref={targetRef}>
      {children}
    </div>
  );
};

export default MQIsVisible;
