import { LayoutTypesEnum, RouteTypes } from 'types/route';

import { createRoute } from 'helpers/routes';

export default {
  mainPage: createRoute({
    params: {
      layoutTypes: [LayoutTypesEnum.Footer, LayoutTypesEnum.Header, LayoutTypesEnum.Sidebar, LayoutTypesEnum.Alerts],
      types: [RouteTypes.Common, RouteTypes.Base],
    },
    data: {
      path: '/',
    },
  }),
};
