import './style.scss';

import { FC } from 'react';

import classNames from 'classnames';

import { MQFormFeedbackProps } from '../../types';

const MQFormFeedback: FC<MQFormFeedbackProps> = ({
  touched = true,
  name = 'mq-form-feedback',
  type,
  className,
  children,
}) =>
  touched && !!children ? (
    <div
      data-testid="mq-form-feedback"
      data-feedback={type}
      data-name={name}
      className={classNames('mq-form-feedback', type, className)}
    >
      {children}
    </div>
  ) : null;

export default MQFormFeedback;
