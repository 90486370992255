import './style.scss';

import { FC, useContext } from 'react';

import classNames from 'classnames';

import { MQNavbarToggleProps } from '../../types';
import { MQNavbarContext } from '../MQNavbar';

const MQNavbarToggle: FC<MQNavbarToggleProps> = ({ children, className = '', ...props }) => {
  const { collapsed, setCollapsed } = useContext(MQNavbarContext);

  return (
    <button
      data-testid="mq-navbar-toggle"
      {...props}
      onClick={(e) => {
        e.stopPropagation();
        setCollapsed?.(!collapsed);
      }}
      className={classNames(['mq-navbar__toggle', className], { collapsed })}
    >
      {children}
    </button>
  );
};

export default MQNavbarToggle;
