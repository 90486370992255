import { RouteTypes } from 'types/route';

import { createRoute } from 'helpers/routes';

export default {
  slack: createRoute({
    params: {
      layoutTypes: [],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted],
    },
    data: {
      path: `/platform-addons/slack_auth`,
      exact: true,
    },
  }),
};
