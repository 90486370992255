import './style.scss';

import { FC } from 'react';

import { PermissionsEnum } from '@mentorcliq/storage';
import { FormattedMessage } from 'react-intl';

import ROUTES from 'routes';

import { APP_GLOBAL_MESSAGES } from 'definitions/messages';

import MQAnimate from 'modules/MQAnimate';
import MQIcon from 'modules/MQIcon';
import MQSidebar from 'modules/MQSidebar';

import AppLink from 'router/AppLink';

import PermissionWrapper from 'components/PermissionWrapper';

import Programs from '../Programs';
import Resources from '../Resources';

const DashboardSidebar: FC = () => (
  <MQAnimate.Styles animation="slide-right">
    <MQSidebar.List className="app-sidebar__list" data-testid="sidebar-dashboard">
      <MQSidebar.ListItem
        as={AppLink}
        className="app-sidebar__list-item"
        data-testid="sidebar-dashboard-link"
        to={ROUTES.adminDashboard.data.path}
        prefix={<MQIcon.Svg icon="house" />}
      >
        <FormattedMessage defaultMessage="Admin Dashboard" description="[Sidebar] Menu Item Name" />
      </MQSidebar.ListItem>
      <PermissionWrapper permissions={{ value: [PermissionsEnum.AddAProgram] }}>
        <MQSidebar.ListItem
          as={AppLink}
          className="app-sidebar__list-item"
          data-testid="sidebar-program-wizard-link"
          to={ROUTES.programWizardWelcome.data.path}
          prefix={<MQIcon.Svg icon="draw-circle" />}
        >
          <FormattedMessage defaultMessage="Design New Program" description="[Sidebar] Menu Item Name" />
        </MQSidebar.ListItem>
      </PermissionWrapper>
      <PermissionWrapper permissions={{ value: [PermissionsEnum.ViewRhr] }}>
        <MQSidebar.ListItem
          as={AppLink}
          data-testid="sidebar-reports-link"
          to={ROUTES.reports.data.path}
          prefix={<MQIcon.Svg icon="chart-line-up" />}
          overlay={<MQIcon.Svg icon="arrow-circle-right" />}
        >
          <FormattedMessage defaultMessage="Reports" description="[Sidebar] Menu Item Name" />
        </MQSidebar.ListItem>
      </PermissionWrapper>
      <PermissionWrapper permissions={{ value: [PermissionsEnum.ManageUsers] }}>
        <MQSidebar.ListItem
          as={AppLink}
          data-testid="sidebar-people-link"
          to={ROUTES.people.data.path}
          prefix={<MQIcon.Svg icon="user-group" />}
          overlay={<MQIcon.Svg icon="arrow-circle-right" />}
        >
          <FormattedMessage defaultMessage="People" description="[Sidebar] Menu Item Name" />
        </MQSidebar.ListItem>
      </PermissionWrapper>
      <PermissionWrapper
        permissions={{
          value: [
            PermissionsEnum.ManagePlatformInfo,
            PermissionsEnum.ViewAddOns,
            PermissionsEnum.UpdateAddOns,
            PermissionsEnum.MentorcliqBranding,
            PermissionsEnum.ManagePlatformResources,
            PermissionsEnum.ManagePlatformConfiguration,
            PermissionsEnum.ManageFeatures,
            PermissionsEnum.ManageDynamicTranslations,
            PermissionsEnum.ManageBaseTranslations,
            PermissionsEnum.ManageProfileSettings,
            PermissionsEnum.ManageProfile,
            PermissionsEnum.ManageHRIS,
            PermissionsEnum.ManageSSOSettings,
            PermissionsEnum.ManageCalendarConfiguration,
            PermissionsEnum.ManageZoomConfiguration,
            PermissionsEnum.ManageCollaborationTools,
          ],
        }}
      >
        <MQSidebar.ListItem
          as={AppLink}
          data-testid="sidebar-configure-link"
          to={ROUTES.configure.data.path}
          prefix={<MQIcon.Svg icon="sliders-h" />}
          overlay={<MQIcon.Svg icon="arrow-circle-right" />}
        >
          <FormattedMessage {...APP_GLOBAL_MESSAGES.platformSettingsTitle} />
        </MQSidebar.ListItem>
      </PermissionWrapper>
      <Programs />
      <PermissionWrapper
        permissions={{ value: [PermissionsEnum.ManageContentHelpdeskData, PermissionsEnum.ManageMentorFlixContent] }}
      >
        <MQSidebar.ListItem>
          <Resources />
        </MQSidebar.ListItem>
      </PermissionWrapper>
    </MQSidebar.List>
  </MQAnimate.Styles>
);

export default DashboardSidebar;
