import { useMemo } from 'react';

import {
  EnrollmentStateEnum,
  ProfileStateEnum,
  enrollmentStateLoadingStateSelector,
  getEnrollmentStateSelector,
  getIsAdminModeSelector,
  getIsAuthenticatedSelector,
  getProfileStateSelector,
  profileStateLoadingStateSelector,
  getEnrollmentMetadataSelector,
  getQhrPrivacy,
  getAuthGdprSelector,
  getAuthSelector,
  getIsAdministratorSelector,
  getIsGdprIgnoredSelector,
} from '@mentorcliq/storage';

import { useAppSelector } from 'hooks/useAppSelector';
import { useAppViewAs } from 'hooks/useAppViewAs';

export const useAppAuthState = () => {
  const { viewingUserId } = useAppViewAs();

  const auth = useAppSelector(({ auth }) => getAuthSelector(auth));
  const isInAdminMode = useAppSelector(({ auth }) => getIsAdminModeSelector(auth));
  const isAuthenticated = useAppSelector(({ auth }) => getIsAuthenticatedSelector(auth));
  const isAdministrator = useAppSelector(({ auth }) => getIsAdministratorSelector(auth));
  const isGdprIgnored = useAppSelector(({ auth }) => getIsGdprIgnoredSelector(auth));
  const enrollmentState = useAppSelector(({ enrollmentState }) => getEnrollmentStateSelector(enrollmentState));
  const enrollmentMeta = useAppSelector(({ enrollmentState }) => getEnrollmentMetadataSelector(enrollmentState));
  const enrollmentStateLoading = useAppSelector(({ enrollmentState }) =>
    enrollmentStateLoadingStateSelector(enrollmentState),
  );
  const profileState = useAppSelector(({ profileState }) => getProfileStateSelector(profileState));
  const profileStateLoading = useAppSelector(({ profileState }) => profileStateLoadingStateSelector(profileState));

  const gdpr = useAppSelector(({ auth }) => getAuthGdprSelector(auth));
  const qhrPrivacy = useAppSelector(({ auth }) => getQhrPrivacy(auth));

  return useMemo(() => {
    const viewMode = !!auth.user?.id && !!viewingUserId && viewingUserId !== auth.user.id;
    const adminMode = isAdministrator && isInAdminMode;

    return {
      authenticated: isAuthenticated,
      administrator: isAdministrator,
      gdpr: {
        ignored: isGdprIgnored,
        accepted: gdpr?.accepted === true,
        required: !isGdprIgnored && gdpr?.accepted === false,
      },
      enrollment: {
        state: enrollmentState,
        meta: enrollmentMeta,
        loading: enrollmentStateLoading.isPending,
        enrolling:
          !!enrollmentState &&
          enrollmentState !== EnrollmentStateEnum.Initial &&
          enrollmentState !== EnrollmentStateEnum.PendingInvitations &&
          enrollmentState !== EnrollmentStateEnum.EnrollmentCompleted,
        invited: enrollmentState === EnrollmentStateEnum.PendingInvitations,
      },
      registration: {
        state: profileState?.state,
        loading: profileStateLoading.isPending,
        verifying: !!profileState?.state && profileState.state !== ProfileStateEnum.ProfileCompleted && !viewMode,
      },
      user: auth.user,
      profile: auth.profile,
      viewer: auth.viewer,
      permissions: auth.permissions,
      adminProgramIds: auth.adminProgramIds,
      qhrPrivacy: qhrPrivacy,
      mode: {
        view: viewMode,
        admin: adminMode,
        userId: viewingUserId,
      },
    };
  }, [
    auth.user,
    auth.profile,
    auth.viewer,
    auth.permissions,
    auth.adminProgramIds,
    viewingUserId,
    isAdministrator,
    isInAdminMode,
    isAuthenticated,
    isGdprIgnored,
    gdpr?.accepted,
    enrollmentState,
    enrollmentMeta,
    enrollmentStateLoading.isPending,
    profileState?.state,
    profileStateLoading.isPending,
    qhrPrivacy,
  ]);
};
