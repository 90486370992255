import { FC, Ref } from 'react';

import { Router, RouterProps } from 'react-router-dom';

interface AppRouterProps extends RouterProps {
  loader?: Ref<Router>;
}

const AppRouter: FC<AppRouterProps> = ({ loader, ...props }) => <Router ref={loader} {...props} />;

export default AppRouter;
