import { createRoute } from 'helpers/routes';

export default {
  redirectLogin: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/logout`,
    },
    redirectTo: {
      path: `/login`,
      exact: true,
    },
  }),
  redirectEnrollmentsRequestsEncrypted: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/preferences/:encryptedOldEnrollmentId`,
    },
    redirectTo: {
      path: '/enroll/edit/encrypted/:token',
    },
  }),
  redirectMainPage: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: '/user',
    },
    redirectTo: {
      path: '/user-dashboard/mycliq',
    },
  }),
  redirectResetPassword: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: '/user/reset/:oldUserId/:timestamp/:token',
      exact: true,
    },
    redirectTo: {
      path: `/create-password`,
      exact: true,
    },
  }),
  redirectGroupComparison: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: '/group-comparison/:oldProgramId/:oldGroupId/-1',
      exact: true,
    },
    redirectTo: {
      path: `/match/:token/compatibility`,
      exact: true,
    },
  }),
  redirectMatchComparison: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: '/match-comparison/:oldProgramId/:oldEnrollmentId1/:oldEnrollmentId2',
      exact: true,
    },
    redirectTo: {
      path: `/match/:token/compatibility`,
      exact: true,
    },
  }),
  redirectQuickcliQRequestsEncrypted: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/quickcliq/:encryptedOldQQRequestId`,
      exact: true,
    },
    redirectTo: {
      path: `/quickcliq-requests/encrypted/:token`,
      exact: true,
    },
  }),
  redirectNudgeLeaveRelationship: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/group-leave/:encryptedOldLeaveData`,
      exact: true,
    },
    redirectTo: {
      path: `/match/leave/:token`,
      exact: true,
    },
  }),
  redirectTimeline: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/timeline/:oldUserId`,
      exact: true,
    },
    redirectTo: {
      path: '/user-dashboard/timeline',
      exact: true,
    },
  }),
  redirectSurveyForm: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/custom-survey/:encryptedOldSurveyRequestId`,
      exact: true,
    },
    redirectTo: {
      path: '/survey-requests/:token',
      exact: true,
    },
  }),
  redirectSatisfactionSurveyForm: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/satisfaction-survey/:encryptedOldSatisfactionSurveyRequestId`,
      exact: true,
    },
    redirectTo: {
      path: '/survey-requests/:token',
      exact: true,
    },
  }),
  redirectAcceptMatchRequest: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/match-request/:encryptedOldGroupId/accept`,
      exact: true,
    },
    redirectTo: {
      path: '/match/:token/accept',
      exact: true,
    },
  }),
  redirectDeclineMatchRequest: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/match-request/:encryptedOldGroupId/decline`,
      exact: true,
    },
    redirectTo: {
      path: '/match/:token/decline',
      exact: true,
    },
  }),
  redirectEnrollmentApprovalRequest: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/enrollment_approval/:encryptedOldEnrollmentId`,
      exact: true,
    },
    redirectTo: {
      path: '/enroll/approval/:token',
      exact: true,
    },
  }),
  redirectPrograms: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/open-programs/:oldUserId`,
      exact: true,
    },
    redirectTo: {
      path: '/user-dashboard/programs',
      exact: true,
    },
  }),
  redirectEnrollPreferences: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/welcome/preferences/:enrollmentId`,
      exact: true,
    },
    redirectTo: {
      path: '/user-dashboard/mycliq',
    },
  }),
  redirectEnrollWelcome: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/enroll/:programAlias`,
      exact: true,
    },
    redirectTo: {
      path: '/enroll/wizard/welcome/:programId',
      exact: true,
    },
  }),
  redirectWelcome: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/welcome`,
      exact: true,
    },
    redirectTo: {
      path: '/',
      exact: true,
    },
  }),
  redirectEnrollSRWelcome: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/enroll/welcome/:programId`,
      exact: true,
    },
    redirectTo: {
      path: '/enroll/wizard/welcome/:programId',
      exact: true,
    },
  }),
  redirectDevelopmentReportsPersonal: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/report/:oldUserId/development/personal`,
      exact: true,
    },
    redirectTo: {
      path: '/user-dashboard/development-reports/personal',
      exact: true,
    },
  }),
  redirectDevelopmentReportsOthers: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/report/:oldUserId/development`,
      exact: true,
    },
    redirectTo: {
      path: '/user-dashboard/development-reports/others',
      exact: true,
    },
  }),
  redirectMilestones: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/user/:oldUserId/programs`,
      exact: true,
    },
    redirectTo: {
      path: '/user-dashboard/timeline',
      exact: true,
    },
  }),
  redirectLearningLabs: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/:oldLabType/:oldSessionType/live`,
      exact: true,
    },
    redirectTo: {
      path: '/learning-lab/:lab/:type/live',
      exact: true,
    },
  }),
  redirectMentorLab: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/mentorlab`,
      exact: true,
    },
    redirectTo: {
      path: '/learning-lab/mentor-lab',
      exact: true,
    },
  }),
  redirectInclusionLab: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/inclusionlab`,
      exact: true,
    },
    redirectTo: {
      path: '/learning-lab/inclusion-lab',
      exact: true,
    },
  }),
  redirectLeadershipLab: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/leadershiplab`,
      exact: true,
    },
    redirectTo: {
      path: '/learning-lab/leadership-lab',
      exact: true,
    },
  }),
  redirectLearningLabList: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/learninglab`,
      exact: true,
    },
    redirectTo: {
      path: '/learning-lab/labs-list',
      exact: true,
    },
  }),
  redirectProgramResources: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/node/:oldProgramId/program-resources-dashboard`,
      exact: true,
    },
    redirectTo: {
      path: '/resource-library/program',
      exact: true,
    },
  }),
  redirectGlobalResourcesModal: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/resource-library`,
      exact: true,
    },
    redirectTo: {
      path: '/resource-library/global',
      exact: true,
    },
  }),
  redirectProgramResourcesModal: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/program-resource-library`,
      exact: true,
    },
    redirectTo: {
      path: '/resource-library/program',
      exact: true,
    },
  }),
};
