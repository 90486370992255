import './style.scss';

import { FC, ReactNode } from 'react';

import classNames from 'classnames';

export interface MQCardSectionProps {
  className?: string;
  children?: ReactNode;
}

const MQCardSection: FC<MQCardSectionProps> = ({ className = '', ...props }) => (
  <div data-testid="mq-card-section" className={classNames(['mq-card__section', className])} {...props} />
);

export default MQCardSection;
