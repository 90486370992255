import './style.scss';

import { FC, HTMLAttributes } from 'react';

import classNames from 'classnames';

const MQFormInputGroupText: FC<HTMLAttributes<HTMLSpanElement>> = ({ className = '', ...props }) => (
  <span {...props} className={classNames(['mq-form-input-group-text', className])} />
);

export default MQFormInputGroupText;
