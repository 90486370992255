import './style.scss';

import { FC } from 'react';

import classNames from 'classnames';

import { MQNavGroupProps } from '../../types';

const MQNavGroup: FC<MQNavGroupProps> = ({ children, direction = 'horizontal', divided = false }) => (
  <div className={classNames('mq-nav-group', { divided }, direction)}>{children}</div>
);

export default MQNavGroup;
