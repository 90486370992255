import { default as Check } from './components/MQFormCheck';
import { default as Color } from './components/MQFormColor';
import { default as DateInput } from './components/MQFormDateInput';
import { default as Feedback } from './components/MQFormFeedback';
import { default as Group } from './components/MQFormGroup';
import { default as Input } from './components/MQFormInput';
import { default as InputGroup } from './components/MQFormInputGroup';
import { default as Label } from './components/MQFormLabel';
import { default as Multiselect } from './components/MQFormMultiselect';
import { default as Nav } from './components/MQFormNav';
import { default as NavItem } from './components/MQFormNavItem';
import { default as Provider } from './components/MQFormProvider';
import { default as Radio } from './components/MQFormRadio';
import { default as RadioCard } from './components/MQFormRadioCard';
import { default as Range } from './components/MQFormRange';
import { default as Rating } from './components/MQFormRating';
import { default as Section } from './components/MQFormSection';
import { default as Select } from './components/MQFormSelect';
import { default as Switch } from './components/MQFormSwitch';
import { default as Text } from './components/MQFormText';
import { default as Textarea } from './components/MQFormTextarea';

const MQForm = Object.assign(Provider, {
  Input,
  Textarea,
  Select,
  Check,
  Radio,
  RadioCard,
  Switch,
  Label,
  Group,
  Section,
  Feedback,
  Nav,
  NavItem,
  Text,
  InputGroup,
  Color,
  Rating,
  DateInput,
  Range,
  Multiselect,
});

export default MQForm;
