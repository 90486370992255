import './style.scss';

import { FC, ReactNode, useMemo } from 'react';

import ROUTES from 'routes';

import NoResultFoundImage from 'assets/dynamic/noResultsFound';

import { APP_SUPPORT_EMAIL } from 'definitions/configs';
import { APP_GLOBAL_MESSAGES } from 'definitions/messages';

import MQButton from 'modules/MQButton';
import MQGrid from 'modules/MQGrid';
import MQIcon from 'modules/MQIcon';
import MQLayout from 'modules/MQLayout';
import MQTypo from 'modules/MQTypo';

import AppFormattedMessage from 'formatters/AppFormattedMessage';

interface Props {
  label?: ReactNode;
  onClearFilter?: () => void;
  content?: ReactNode;
  title?: ReactNode;
  dirty?: boolean;
  fallback?: ReactNode;
  article?: string;
  support?: boolean;
  centered?: boolean;
  children?: ReactNode;
}

const EmptyList: FC<Props> = ({
  content,
  label,
  title,
  dirty = false,
  onClearFilter,
  fallback,
  article,
  support = false,
  centered = false,
  children,
}) => {
  const dataLabel = useMemo(
    () =>
      label || (
        <AppFormattedMessage
          defaultMessage="data"
          description="[Global] Empty list label"
          id="global.empty.list.label"
        />
      ),
    [label],
  );

  if (fallback && !dirty) {
    return <>{fallback}</>;
  }

  return (
    <div className="empty-list">
      <MQGrid.Row centered={centered}>
        <MQGrid.Col xl="auto" lg="auto" md="auto" sm={12}>
          <MQIcon.Custom svg={NoResultFoundImage} />
        </MQGrid.Col>
        <MQGrid.Col xl="auto" lg="auto" md="auto" sm={12}>
          <div className="empty-list__content">
            <MQGrid.FlexBox direction="column">
              <MQLayout.Heading dataTestId="empty-list-no-items" center={centered}>
                <MQTypo.Text size="5x" bold nowrap>
                  <MQTypo.Formatter prefix={<MQIcon.Svg icon="info-circle-alt" />}>
                    {title || (
                      <AppFormattedMessage
                        defaultMessage="No {label} found"
                        description="[EmptyList] Heading"
                        id="empty.list.heading"
                        values={{
                          label: dataLabel,
                        }}
                      />
                    )}
                  </MQTypo.Formatter>
                </MQTypo.Text>
              </MQLayout.Heading>
              {content || (
                <MQGrid.FlexBox direction="column">
                  <MQTypo.Paragraph>
                    <AppFormattedMessage
                      defaultMessage="You have no {label} to show based on your filter criteria."
                      description="[EmptyList] Description"
                      id="empty.list.description"
                      values={{
                        label: dataLabel,
                      }}
                    />
                  </MQTypo.Paragraph>
                  {!!onClearFilter && (
                    <MQTypo.Paragraph>
                      <AppFormattedMessage
                        defaultMessage="You may <button>clear your filters</button> to view all entries."
                        description="[EmptyList] Clear filters button"
                        id="empty.list.clear.filters.button"
                        values={{
                          button: (...chunks) => (
                            <MQButton
                              dataTestId="empty-list-clear-filters"
                              onClick={onClearFilter}
                              variant="sub-action--tertiary"
                            >
                              {chunks}
                            </MQButton>
                          ),
                        }}
                      />
                    </MQTypo.Paragraph>
                  )}
                </MQGrid.FlexBox>
              )}
              <MQGrid.FlexBox>
                {children}
                {support && (
                  <MQButton
                    dataTestId="roi-tracking-reports-contact-support"
                    onClick={() => window.open(`mailto:${APP_SUPPORT_EMAIL}`)}
                    startIcon={<MQIcon.Svg icon="user-headset" />}
                  >
                    <AppFormattedMessage
                      defaultMessage="Contact Support"
                      description="[EmptyList] Contact support button label"
                      id="empty.list.contact.button"
                    />
                  </MQButton>
                )}
                {article && (
                  <MQButton
                    dataTestId="learn-more"
                    variant="tertiary"
                    startIcon={<MQIcon.Svg icon="info-circle-alt" />}
                    onClick={() =>
                      window.open(
                        ROUTES.cliqipedia.convert({
                          search: {
                            article,
                          },
                        }),
                        '_blank',
                      )
                    }
                  >
                    <AppFormattedMessage {...APP_GLOBAL_MESSAGES.learnMore} />
                  </MQButton>
                )}
              </MQGrid.FlexBox>
            </MQGrid.FlexBox>
          </div>
        </MQGrid.Col>
      </MQGrid.Row>
    </div>
  );
};

export default EmptyList;
