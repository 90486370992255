import ChatFilters from './Addons/components/ChatFilters';
import ChatMenu from './Addons/components/ChatMenu';
import ChatMessages from './Addons/components/ChatMessages';
import ChatProvider from './Addons/components/ChatProvider';
import ChatRoom from './Addons/components/ChatRoom';
import ChatRooms from './Addons/components/ChatRooms';
import ChatToolbar from './Addons/components/ChatToolbar';

const AppChat = Object.assign(ChatProvider, {
  Filters: ChatFilters,
  Room: ChatRoom,
  Menu: ChatMenu,
  Rooms: ChatRooms,
  Messages: ChatMessages,
  Toolbar: ChatToolbar,
});

export default AppChat;
