import './style.scss';

import { FC } from 'react';

import classesNames from 'classnames';

import { MQFormCheckProps } from '../../types';

const MQFormSwitch: FC<MQFormCheckProps> = ({
  dataTestId,
  disabled,
  checked,
  label = '',
  isValid,
  isInvalid,
  className,
  id,
  required,
  onChange,
  ariaLabel = 'Switch Field',
  ...props
}) => (
  <div {...props} className={classesNames('mq-form-switch', { disabled }, { checked }, className)}>
    <input
      {...props}
      data-testid={dataTestId || `mq-form-switch-${props.name}-${id}`}
      type="checkbox"
      onClick={(e) => {
        if (disabled || !onChange) {
          e.stopPropagation();
        }
      }}
      checked={checked}
      disabled={disabled}
      aria-label={ariaLabel}
      onChange={(e) => (!disabled && onChange ? onChange(e) : e.stopPropagation())}
      className={classesNames('mq-form-switch__input', { ['is-valid']: isValid }, { ['is-invalid']: isInvalid })}
      id={id}
    />
    <label className="mq-form-switch__label" data-testid={`mq-form-switch-label-${dataTestId || id}`} htmlFor={id}>
      {label}
    </label>
    {required && <span className="mq-form-switch__required">*</span>}
  </div>
);

export default MQFormSwitch;
