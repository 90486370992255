import { CSSVariable } from '@mentorcliq/storage';
import { kebabCase } from 'lodash';

export function getCSSVariables(list: CSSVariable[]) {
  return list.map((item) => `\n--${item.name}: ${item.value};`).join('');
}

export const transformToCSSVariables = (
  variables: Record<string, string>,
  options: { prefix?: string; suffix?: string } = {},
): CSSVariable[] => {
  const cssVariables: CSSVariable[] = [];
  for (const [key, value] of Object.entries(variables)) {
    const { prefix, suffix } = options;
    const kebabCaseKey = kebabCase(key);
    let name = prefix ? `${prefix}-${kebabCaseKey}` : kebabCaseKey;
    name = suffix ? `${kebabCaseKey}-${suffix}` : name;
    cssVariables.push({
      name,
      value,
    });
  }
  return cssVariables;
};

export const generateCSSVariableStyles = (cssVariables: CSSVariable[], classNameList?: string[] | string) => {
  const styles = getCSSVariables(cssVariables);
  const selector = !classNameList
    ? ':root'
    : Array.isArray(classNameList)
    ? classNameList.map((v) => `.${v}`).join(' ,')
    : `.${classNameList}`;
  return `${selector} {${styles}\n}`;
};
