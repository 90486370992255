import { COOKIES_DEFINITIONS } from 'definitions/cookies';

export const getCookie = (name: string) => {
  const cookies = document.cookie.split('; ');
  for (const cookie of cookies) {
    const [key, value] = cookie.split('=');
    if (key === name) {
      return value;
    }
  }
  return null;
};

export const setCookie = (name: string, value: any, days: number) => {
  const milliseconds = 24 * 60 * 60 * 1000 * days;

  window.cookieStore.set({
    name,
    value,
    expires: Date.now() + milliseconds,
  });
};

export const checkUnansweredCookies = () =>
  Object.values(COOKIES_DEFINITIONS).find((definition) => getCookie(definition) === null);
