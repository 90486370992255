import { FC, ReactNode, useMemo } from 'react';

import { createBrowserHistory } from 'history';

import history from 'helpers/history';

import { useAppConfigs } from 'hooks/useAppConfigs';
import { useAppRouter } from 'hooks/useAppRouter';

import AppRedirect from 'router/AppRedirect';
import AppRouter from 'router/AppRouter';

type AppLocaleProps = {
  basename?: string | null;
  children?: ReactNode;
};

const AppLocale: FC<AppLocaleProps> = ({ basename, children }) => {
  const { publicConfigs } = useAppConfigs();
  const { location } = useAppRouter();

  const lang = useMemo(
    () =>
      publicConfigs.languages.list?.find(({ languageCode }) => location.pathname.startsWith(`${languageCode}/`, 1))
        ?.prefix,
    [location.pathname, publicConfigs.languages.list],
  );

  const redirect = useMemo(() => {
    if (lang && basename && lang !== basename) {
      return location.pathname.replace(lang, basename);
    }
  }, [basename, location.pathname, lang]);

  const router = useMemo(() => {
    if (lang && basename) {
      return createBrowserHistory({
        basename: basename,
      });
    }
  }, [basename, lang]);

  if (redirect) {
    return (
      <AppRedirect
        to={{
          pathname: redirect,
          search: location.search,
        }}
      />
    );
  }

  if (router) {
    return (
      <AppRouter
        history={router}
        loader={(router) => {
          if (router?.props?.history) {
            history.mq = router.props.history;
          }
        }}
      >
        {children}
      </AppRouter>
    );
  }

  return children;
};

export default AppLocale;
