import { FC } from 'react';

import classNames from 'classnames';

import { MQFormProviderProps } from '../../types';

const MQFormProvider: FC<MQFormProviderProps> = ({ className = '', ...props }) => (
  <form {...props} className={classNames(['mq-form', className])} />
);

export default MQFormProvider;
