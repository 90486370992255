import { cloneElement, FC, MouseEvent, FocusEvent, KeyboardEvent } from 'react';

import classNames from 'classnames';

import { KEY_ENTER } from '../../definitions';
import { MQOverlayTriggerProps } from '../../types';

const MQOverlayTrigger: FC<MQOverlayTriggerProps> = ({
  active = true,
  toggle = false,
  state,
  setState,
  children,
  className,
  triggers = ['hover', 'focus'],
  ...props
}) => {
  if (active) {
    return cloneElement(children, {
      ...props,
      ...children.props,
      className: classNames(className, children.props.className),
      onMouseDown: (e: MouseEvent<HTMLElement>) => {
        if (triggers.includes('click')) {
          if (toggle && state) {
            setState(null);
          } else {
            setState({
              target: e.currentTarget,
            });
          }
        }
        children.props?.onMouseDown?.(e);
      },
      onKeyDown: (e: KeyboardEvent<HTMLElement>) => {
        if (e.key === KEY_ENTER) {
          if (toggle && state) {
            setState(null);
          } else {
            setState({
              target: e.currentTarget,
            });
          }
        }
      },
      onMouseOver: (e: MouseEvent<HTMLElement>) => {
        if (triggers.includes('hover')) {
          setState({
            target: e.currentTarget,
          });
        }

        if (triggers.includes('visibility')) {
          if (e.currentTarget.scrollWidth > e.currentTarget.clientWidth) {
            setState({
              target: e.currentTarget,
            });
          } else {
            const childNodes = e.currentTarget.children;
            const largeChild = [...childNodes].some((item) => item.scrollWidth > item.clientWidth);

            if (largeChild) {
              setState({
                target: e.currentTarget,
              });
            }
          }
        }

        children.props?.onMouseOver?.(e);
      },
      onMouseOut: (e: MouseEvent) => {
        if (triggers.includes('hover') || triggers.includes('visibility')) {
          setState(null);
        }

        children.props?.onMouseOut?.(e);
      },
      onFocus: (e: FocusEvent<HTMLElement>) => {
        if (triggers.includes('focus')) {
          setState({
            target: e.currentTarget,
          });
        }

        if (triggers.includes('visibility')) {
          if (e.currentTarget.scrollWidth >= e.currentTarget.clientWidth) {
            setState({
              target: e.currentTarget,
            });
          }
        }

        children.props?.onFocus?.(e);
      },
      onBlur: (e: FocusEvent) => {
        if (triggers.includes('blur') || triggers?.includes('visibility')) {
          setState(null);
        }

        children.props?.onBlur?.(e);
      },
    });
  }

  return children;
};

export default MQOverlayTrigger;
