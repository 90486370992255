import Bugsnag from '@bugsnag/js';
import { ApiErrorCodes, AuthDto, BaseTokenDto, MQApiError } from '@mentorcliq/storage';

import ROUTES from 'routes';

import { AppLocaleStorageKeysEnum } from 'types/global';

import { APP_ENV_CONFIGS } from 'definitions/configs';

import caches from 'helpers/caches';
import history from 'helpers/history';

export const processError = (payload: MQApiError) => {
  if (APP_ENV_CONFIGS.environments.production) {
    if (payload.apiError) {
      Bugsnag.notify(payload.apiError);
    }
  }

  if (payload.subErrors) {
    for (const e of payload.subErrors) {
      switch (e.code) {
        case ApiErrorCodes.GdprNotAccepted:
          history.mq.push({
            pathname: ROUTES.acceptGdpr.data.path,
            search: history.mq.location.search,
          });
          break;
        case ApiErrorCodes.ViewAsNotAllowed:
        case ApiErrorCodes.LearningLabDisabled:
          history.mq.push(
            ROUTES.statusPage403.convert({
              search: {
                reason: e.code,
              },
            }),
          );
          break;
      }
    }
  }

  const status = payload.code ?? 0;

  if (status === 403) {
    history.mq.push(ROUTES.statusPage403.data.path);
  } else if (status === 404) {
    history.mq.push(ROUTES.statusPage404.data.path);
  } else if (status === 429) {
    history.mq.push(ROUTES.statusPage429.data.path);
  } else if (status >= 500) {
    history.mq.push(ROUTES.statusPage500.data.path);
  }

  return payload;
};

export const refreshToken = (user: AuthDto, payload: BaseTokenDto) => {
  user.accessToken = payload.accessToken;
  user.expiresAt = payload.expiresAt;

  caches.locale.setItem({
    type: AppLocaleStorageKeysEnum.User,
    data: user,
  });

  return user;
};

export const createAuthHeaders = (token: string) => ({
  Authorization: `Bearer ${token}`,
});
