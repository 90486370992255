export const prepareKeys = <T = unknown>(items: T[]) => {
  let count = 0;

  return items.map((item) => ({
    item,
    key: String(++count),
  }));
};

export const generateUrl = (link: string) => {
  try {
    return new URL(link).href;
  } catch {
    return window.location.origin;
  }
};

export const intersect = (first: unknown[], second: unknown[]) => first.some((v) => second.includes(v));
