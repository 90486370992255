import './style.scss';

import { FC } from 'react';

import classNames from 'classnames';

import { MQFormRangeProps } from '../../types';

const MQFormRange: FC<MQFormRangeProps> = ({
  className,
  dataTestId = 'mq-form-range-container',
  minLabel,
  maxLabel,
  ariaLabel = 'Range field',
  ...props
}) => (
  <div className="mq-form-range__container" data-testid={dataTestId}>
    <input {...props} className={classNames('mq-form-range', className)} type="range" aria-label={ariaLabel} />
    {(minLabel || maxLabel) && (
      <div className="mq-form-range__labels-container">
        {minLabel && (
          <span className="mq-form-range__label" data-testid={`${dataTestId}-min`}>
            {minLabel}
          </span>
        )}
        {maxLabel && (
          <span className="mq-form-range__label" data-testid={`${dataTestId}-max`}>
            {maxLabel}
          </span>
        )}
      </div>
    )}
  </div>
);

export default MQFormRange;
