import './style.scss';

import { FC } from 'react';

import classNames from 'classnames';

import { MQSidebarHeadingProps } from '../../types';

const MQSidebarHeading: FC<MQSidebarHeadingProps> = ({ className = '', children, ...props }) => (
  <span data-testid="mq-sidebar-heading" className={classNames(['mq-sidebar__heading', className])} {...props}>
    {children}
  </span>
);

export default MQSidebarHeading;
