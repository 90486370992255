import { ReactNode, useEffect, useMemo, useState } from 'react';

import { FormikConfig, FormikValues, useFormik } from 'formik';

import { useAppIntl } from 'hooks/useAppIntl';

interface AppFormikConfig<T> extends FormikConfig<T> {
  feedbackSelector?: string;
}

export const useAppFormik = <T extends FormikValues>({
  feedbackSelector = '[data-feedback="invalid"]',
  ...config
}: AppFormikConfig<T>) => {
  const formik = useFormik<T>(config);
  const intl = useAppIntl();
  const { errors, isSubmitting, isValid } = formik;

  useEffect(() => {
    if (isSubmitting && !isValid && feedbackSelector) {
      const feedback = document.querySelector(feedbackSelector);

      feedback?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [isSubmitting, isValid, feedbackSelector]);

  const translatedErrors = useMemo(() => {
    const translateObject = (errors: any) => {
      if (typeof errors === 'object') {
        const clonedObj = { ...errors };
        const entries = Object.entries(clonedObj);

        entries.forEach(([key, value]) => {
          if (value && typeof value === 'object' && !Object.keys(value).includes('definition')) {
            clonedObj[key] = translateObject(value);
          } else if (
            value &&
            typeof value === 'object' &&
            Object.keys(value).includes('definition') &&
            'definition' in value &&
            value.definition
          ) {
            if ('val' in value && value.val) {
              clonedObj[key] = intl.formatMessage(value.definition, value.val);
            } else {
              clonedObj[key] = intl.formatMessage(value.definition);
            }
          } else {
            clonedObj[key] = value;
          }
        });
        return clonedObj;
      }
      return errors;
    };
    return translateObject(errors);
  }, [errors, intl]);

  return useMemo(
    () => ({
      ...formik,
      errors: translatedErrors,
    }),
    [formik, translatedErrors],
  );
};

export const useAppErrors = (initial?: Record<string, ReactNode | null>) => {
  const initialized = useMemo(() => initial || {}, [initial]);
  return useState<typeof initialized>(initialized);
};
