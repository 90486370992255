import { ReactNode } from 'react';

import { AuthDto } from '@mentorcliq/storage';

import storage from 'storage';

import { APP_FULL_EMOJIS_LIST } from 'assets/mocks/emojis';

export type RootState = ReturnType<typeof storage.reducer>;

export enum ReminderPeriods {
  Day = 'DAY',
  Week = 'WEEK',
  Month = 'MONTH',
  Hour = 'HOUR',
}

export enum VerificationTypesEnum {
  ResetPassword = 'RESET_PASSWORD',
  CreateAccount = 'CREATE_ACCOUNT',
}

export enum MessageTemplatesActionTypeEnum {
  Empty = '',
  Save = 'SAVE',
  Import = 'IMPORT',
  Manage = 'MANAGE',
}

export enum ViewTypeEnum {
  List = 'list',
  Grid = 'grid',
}

export enum SortOrderEnum {
  Asc = 'asc',
  Desc = 'desc',
}

export interface SortDto {
  field: string;
  order: SortOrderEnum;
}

export interface SelectableDto<T = unknown> {
  selectionItems: T[];
  selectedItems: T[];
  onSelect: (selectionItem: T) => void;
  onSelectAll?: (selectionItems: T[]) => void;
  indicator?: ReactNode;
}

export interface SortableDetailsDto {
  field: string;
  order: SortOrderEnum;
}

export interface SortableDto {
  sortDetails: SortableDetailsDto[];
  onSort: (data?: SortableDetailsDto) => void;
}

export interface ColumnStylesDto {
  width?: string | number;
  maxWidth?: string | number;
}

export interface ColumnDto {
  field: string;
  name: ReactNode;
  sortable?: boolean;
  resizable?: boolean;
  show?: boolean;
  inline?: boolean;
  colspan?: number;
  style?: ColumnStylesDto;
}

export interface PageableDto {
  pageSize: number;
  currentPage: number;
  totalElements: number;
  onPaginate: (pageNumber: number) => void;
}

export interface ProcessedFile {
  src: string;
  fileName: string;
}

export interface BlobFile {
  src: Blob;
  fileName: string;
}

export interface TimeRangeValue {
  timeWithPMAM: string;
  timeInMS: number;
}

export enum PreviewMessageTypesEnum {
  SetVPSPreviewData = 'SET_VPS_PREVIEW_DATA',
  SyncConfigs = 'SYNC_CONFIGS',
}

export enum PreviewSourceTypesEnum {
  MQ = 'MQ',
}

export enum AppLocaleStorageKeysEnum {
  Viewer = 'mq-viewer',
  User = 'mq-current-user',
  Emojis = 'mq-emoji',
  Locale = 'mq-locale',
  Cookies = 'mq-cookies',
}

interface AppLocaleStorageViewerDto {
  type: AppLocaleStorageKeysEnum.Viewer;
  data: AuthDto | null;
}

interface AppLocaleStorageUserDto {
  type: AppLocaleStorageKeysEnum.User;
  data: AuthDto | null;
}

interface AppLocaleStorageEmojisDto {
  type: AppLocaleStorageKeysEnum.Emojis;
  data: typeof APP_FULL_EMOJIS_LIST.activities.emojis;
}

interface AppLocaleStorageLocaleDto {
  type: AppLocaleStorageKeysEnum.Locale;
  data: string | null;
}

interface AppLocaleStorageCookiesDto {
  type: AppLocaleStorageKeysEnum.Cookies;
  data: any;
}

export type AppLocaleStorageItemDto =
  | AppLocaleStorageUserDto
  | AppLocaleStorageViewerDto
  | AppLocaleStorageEmojisDto
  | AppLocaleStorageLocaleDto
  | AppLocaleStorageCookiesDto;

export type DateValueType = Date | number | string;

type FindAppLocaleStorageItemByType<T, U extends AppLocaleStorageItemDto = AppLocaleStorageItemDto> = U extends {
  type: T;
}
  ? U['data']
  : never;

export type AppLocaleStorageItemDtoOf<T extends AppLocaleStorageItemDto['type']> = FindAppLocaleStorageItemByType<T>;
