import { LayoutTypesEnum, RouteTypes } from 'types/route';

import { createRoute } from 'helpers/routes';

export default {
  verify: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.GdprAccepted, RouteTypes.Authenticated],
    },
    data: {
      path: `/verify`,
    },
  }),
  verifyWelcome: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.GdprAccepted, RouteTypes.Authenticated],
    },
    data: {
      path: `/verify/welcome`,
      exact: true,
    },
  }),
  verifyPerson: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.GdprAccepted, RouteTypes.Authenticated],
    },
    data: {
      path: `/verify/person`,
      exact: true,
    },
  }),
  verifyProfile: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.GdprAccepted, RouteTypes.Authenticated],
    },
    data: {
      path: `/verify/profile`,
      exact: true,
    },
  }),
  verifyProfilePicture: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.GdprAccepted, RouteTypes.Authenticated],
    },
    data: {
      path: `/verify/profile-picture`,
      exact: true,
    },
  }),
  verifyVps: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.GdprAccepted, RouteTypes.Authenticated],
    },
    data: {
      path: `/verify/vps`,
      exact: true,
    },
  }),
  verifyComplete: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified],
    },
    data: {
      path: `/verify/complete`,
      exact: true,
    },
  }),
};
