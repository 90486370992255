import './style.scss';

import { FC } from 'react';

import classNames from 'classnames';

import { MQFormGroupProps } from '../../types';

const MQFormGroup: FC<MQFormGroupProps> = ({
  className = '',
  direction = 'column',
  align = 'start',
  justify = 'start',
  fill = false,
  controlId,
  dataTestId = 'mq-form-group',
  ...props
}) => (
  <div
    {...props}
    data-testid={dataTestId}
    id={controlId}
    className={classNames('mq-form-group', direction, `align-${align}`, `justify-${justify}`, { fill }, className)}
  />
);

MQFormGroup.displayName = 'MQFormGroup';

export default MQFormGroup;
