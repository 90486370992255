import { PermissionsEnum } from '@mentorcliq/storage';

import { LayoutSizeTypes, LayoutTypesEnum, RouteTypes } from 'types/route';

import { createRoute } from 'helpers/routes';

const CUSTOM_ROUTES = {
  customQuickcliQsReports: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageQuickcliQsReport],
        },
      },
    },
    data: {
      path: `/program/:programId/measure/detailed-reports/custom-quickcliqs`,
    },
  }),
};

export default CUSTOM_ROUTES;
