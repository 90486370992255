import { FC, ReactNode, useEffect } from 'react';

import { useAppConfigs } from 'hooks/useAppConfigs';

interface PlatformWrapperProps {
  children?: ReactNode;
}

const PlatformWrapper: FC<PlatformWrapperProps> = ({ children }) => {
  const { publicConfigs } = useAppConfigs();

  useEffect(() => {
    if (publicConfigs.platformInfo?.siteName) {
      document.title = publicConfigs.platformInfo.siteName;
    }
  }, [publicConfigs.platformInfo]);

  return <>{children}</>;
};

export default PlatformWrapper;
