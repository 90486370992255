import { default as Badge } from './components/MQAvatarBadge';
import { default as Group } from './components/MQAvatarGroup';
import { default as Image } from './components/MQAvatarImage';
import { default as Info } from './components/MQAvatarInfo';

const MQAvatar = {
  Group,
  Badge,
  Image,
  Info,
};
export default MQAvatar;
