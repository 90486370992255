import './style.scss';

import { FC, HTMLAttributes } from 'react';

import classNames from 'classnames';

const MQFormInputGroupAppend: FC<HTMLAttributes<HTMLDivElement>> = ({ className = '', ...props }) => (
  <div {...props} className={classNames('mq-form-input-group-append', className)} />
);

export default MQFormInputGroupAppend;
