export const APP_STATIC_EMOJIS_LIST = {
  handWave: {
    name: 'mq:hand-wave',
    icon: '👋',
  },
  faceFantastic: {
    name: 'mq:face-fantastic',
    icon: '😀',
  },
  faceGood: {
    name: 'mq:face-good',
    icon: '🙂',
  },
  faceOkay: {
    name: 'mq:face-okay',
    icon: '😐',
  },
  faceYikes: {
    name: 'mq:face-yikes',
    icon: '😣',
  },
  faceNotGood: {
    name: 'mq:face-not-good',
    icon: '😞',
  },
};

export const APP_FULL_EMOJIS_LIST = {
  people: {
    label: 'Smileys & People',
    emojis: [
      { code: 'U+1F600', emoji: '😀', keywords: ['face', 'grin'] },
      { code: 'U+1F601', emoji: '😁', keywords: ['eye', 'face', 'grin', 'smile'] },
      { code: 'U+1F602', emoji: '😂', keywords: ['face', 'joy', 'laugh', 'tear'] },
      { code: 'U+1F923', emoji: '🤣', keywords: ['face', 'floor', 'laugh', 'lol', 'rofl', 'rolling'] },
      { code: 'U+1F603', emoji: '😃', keywords: ['face', 'mouth', 'open', 'smile'] },
      { code: 'U+1F604', emoji: '😄', keywords: ['eye', 'face', 'mouth', 'open', 'smile'] },
      { code: 'U+1F605', emoji: '😅', keywords: ['cold', 'face', 'open', 'smile', 'sweat'] },
      { code: 'U+1F606', emoji: '😆', keywords: ['face', 'laugh', 'mouth', 'open', 'satisfied', 'smile'] },
      { code: 'U+1F609', emoji: '😉', keywords: ['face', 'wink'] },
      { code: 'U+1F60A', emoji: '😊', keywords: ['blush', 'eye', 'face', 'smile'] },
      { code: 'U+1F60B', emoji: '😋', keywords: ['delicious', 'face', 'savouring', 'smile', 'um', 'yum'] },
      {
        code: 'U+1F60E',
        emoji: '😎',
        keywords: ['bright', 'cool', 'eye', 'eyewear', 'face', 'glasses', 'smile', 'sun', 'sunglasses', 'weather'],
      },
      { code: 'U+1F60D', emoji: '😍', keywords: ['eye', 'face', 'heart', 'love', 'smile'] },
      { code: 'U+1F618', emoji: '😘', keywords: ['face', 'heart', 'kiss'] },
      { code: 'U+1F617', emoji: '😗', keywords: ['face', 'kiss'] },
      { code: 'U+1F619', emoji: '😙', keywords: ['eye', 'face', 'kiss', 'smile'] },
      { code: 'U+1F61A', emoji: '😚', keywords: ['closed', 'eye', 'face', 'kiss'] },
      { code: 'U+1F642', emoji: '🙂', keywords: ['face', 'smile'] },
      { code: 'U+1F917', emoji: '🤗', keywords: ['face', 'hug', 'hugging'] },
      { code: 'U+1F914', emoji: '🤔', keywords: ['face', 'thinking'] },
      { code: 'U+1F610', emoji: '😐', keywords: ['deadpan', 'face', 'neutral'] },
      { code: 'U+1F611', emoji: '😑', keywords: ['expressionless', 'face', 'inexpressive', 'unexpressive'] },
      { code: 'U+1F636', emoji: '😶', keywords: ['face', 'mouth', 'quiet', 'silent'] },
      { code: 'U+1F644', emoji: '🙄', keywords: ['eyes', 'face', 'rolling'] },
      { code: 'U+1F60F', emoji: '😏', keywords: ['face', 'smirk'] },
      { code: 'U+1F623', emoji: '😣', keywords: ['face', 'persevere'] },
      { code: 'U+1F625', emoji: '😥', keywords: ['disappointed', 'face', 'relieved', 'whew'] },
      { code: 'U+1F62E', emoji: '😮', keywords: ['face', 'mouth', 'open', 'sympathy'] },
      { code: 'U+1F910', emoji: '🤐', keywords: ['face', 'mouth', 'zipper'] },
      { code: 'U+1F62F', emoji: '😯', keywords: ['face', 'hushed', 'stunned', 'surprised'] },
      { code: 'U+1F62A', emoji: '😪', keywords: ['face', 'sleep'] },
      { code: 'U+1F62B', emoji: '😫', keywords: ['face', 'tired'] },
      { code: 'U+1F634', emoji: '😴', keywords: ['face', 'sleep', 'zzz'] },
      { code: 'U+1F60C', emoji: '😌', keywords: ['face', 'relieved'] },
      { code: 'U+1F913', emoji: '🤓', keywords: ['face', 'geek', 'nerd'] },
      { code: 'U+1F61B', emoji: '😛', keywords: ['face', 'tongue'] },
      { code: 'U+1F61C', emoji: '😜', keywords: ['eye', 'face', 'joke', 'tongue', 'wink'] },
      { code: 'U+1F61D', emoji: '😝', keywords: ['eye', 'face', 'horrible', 'taste', 'tongue'] },
      { code: 'U+1F924', emoji: '🤤', keywords: ['drooling', 'face'] },
      { code: 'U+1F612', emoji: '😒', keywords: ['face', 'unamused', 'unhappy'] },
      { code: 'U+1F613', emoji: '😓', keywords: ['cold', 'face', 'sweat'] },
      { code: 'U+1F614', emoji: '😔', keywords: ['dejected', 'face', 'pensive'] },
      { code: 'U+1F615', emoji: '😕', keywords: ['confused', 'face'] },
      { code: 'U+1F643', emoji: '🙃', keywords: ['face', 'upside-down'] },
      { code: 'U+1F911', emoji: '🤑', keywords: ['face', 'money', 'mouth'] },
      { code: 'U+1F632', emoji: '😲', keywords: ['astonished', 'face', 'shocked', 'totally'] },
      { code: 'U+1F641', emoji: '🙁', keywords: ['face', 'frown'] },
      { code: 'U+1F616', emoji: '😖', keywords: ['confounded', 'face'] },
      { code: 'U+1F61E', emoji: '😞', keywords: ['disappointed', 'face'] },
      { code: 'U+1F61F', emoji: '😟', keywords: ['face', 'worried'] },
      { code: 'U+1F624', emoji: '😤', keywords: ['face', 'triumph', 'won'] },
      { code: 'U+1F622', emoji: '😢', keywords: ['cry', 'face', 'sad', 'tear'] },
      { code: 'U+1F62D', emoji: '😭', keywords: ['cry', 'face', 'sad', 'sob', 'tear'] },
      { code: 'U+1F626', emoji: '😦', keywords: ['face', 'frown', 'mouth', 'open'] },
      { code: 'U+1F627', emoji: '😧', keywords: ['anguished', 'face'] },
      { code: 'U+1F628', emoji: '😨', keywords: ['face', 'fear', 'fearful', 'scared'] },
      { code: 'U+1F629', emoji: '😩', keywords: ['face', 'tired', 'weary'] },
      { code: 'U+1F62C', emoji: '😬', keywords: ['face', 'grimace'] },
      { code: 'U+1F630', emoji: '😰', keywords: ['blue', 'cold', 'face', 'mouth', 'open', 'rushed', 'sweat'] },
      { code: 'U+1F631', emoji: '😱', keywords: ['face', 'fear', 'fearful', 'munch', 'scared', 'scream'] },
      { code: 'U+1F633', emoji: '😳', keywords: ['dazed', 'face', 'flushed'] },
      { code: 'U+1F635', emoji: '😵', keywords: ['dizzy', 'face'] },
      { code: 'U+1F621', emoji: '😡', keywords: ['angry', 'face', 'mad', 'pouting', 'rage', 'red'] },
      { code: 'U+1F620', emoji: '😠', keywords: ['angry', 'face', 'mad'] },
      {
        code: 'U+1F607',
        emoji: '😇',
        keywords: ['angel', 'face', 'fairy tale', 'fantasy', 'halo', 'innocent', 'smile'],
      },
      { code: 'U+1F920', emoji: '🤠', keywords: ['cowboy', 'cowgirl', 'face', 'hat'] },
      { code: 'U+1F921', emoji: '🤡', keywords: ['clown', 'face'] },
      { code: 'U+1F925', emoji: '🤥', keywords: ['face', 'lie', 'pinocchio'] },
      { code: 'U+1F637', emoji: '😷', keywords: ['cold', 'doctor', 'face', 'mask', 'medicine', 'sick'] },
      { code: 'U+1F912', emoji: '🤒', keywords: ['face', 'ill', 'sick', 'thermometer'] },
      { code: 'U+1F915', emoji: '🤕', keywords: ['bandage', 'face', 'hurt', 'injury'] },
      { code: 'U+1F922', emoji: '🤢', keywords: ['face', 'nauseated', 'vomit'] },
      { code: 'U+1F927', emoji: '🤧', keywords: ['face', 'gesundheit', 'sneeze'] },
      { code: 'U+1F608', emoji: '😈', keywords: ['face', 'fairy tale', 'fantasy', 'horns', 'smile'] },
      { code: 'U+1F47F', emoji: '👿', keywords: ['demon', 'devil', 'face', 'fairy tale', 'fantasy', 'imp'] },
      {
        code: 'U+1F479',
        emoji: '👹',
        keywords: ['creature', 'face', 'fairy tale', 'fantasy', 'japanese', 'monster', 'ogre'],
      },
      {
        code: 'U+1F47A',
        emoji: '👺',
        keywords: ['creature', 'face', 'fairy tale', 'fantasy', 'goblin', 'japanese', 'monster'],
      },
      { code: 'U+1F480', emoji: '💀', keywords: ['body', 'death', 'face', 'fairy tale', 'monster', 'skull'] },
      { code: 'U+2620', emoji: '☠', keywords: ['body', 'crossbones', 'death', 'face', 'monster', 'skull'] },
      { code: 'U+1F47B', emoji: '👻', keywords: ['creature', 'face', 'fairy tale', 'fantasy', 'ghost', 'monster'] },
      {
        code: 'U+1F47D',
        emoji: '👽',
        keywords: ['alien', 'creature', 'extraterrestrial', 'face', 'fairy tale', 'fantasy', 'monster', 'space', 'ufo'],
      },
      {
        code: 'U+1F47E',
        emoji: '👾',
        keywords: ['alien', 'creature', 'extraterrestrial', 'face', 'fairy tale', 'fantasy', 'monster', 'space', 'ufo'],
      },
      { code: 'U+1F916', emoji: '🤖', keywords: ['face', 'monster', 'robot'] },
      { code: 'U+1F4A9', emoji: '💩', keywords: ['comic', 'dung', 'face', 'monster', 'poo', 'poop'] },
      { code: 'U+1F63A', emoji: '😺', keywords: ['cat', 'face', 'mouth', 'open', 'smile'] },
      { code: 'U+1F638', emoji: '😸', keywords: ['cat', 'eye', 'face', 'grin', 'smile'] },
      { code: 'U+1F639', emoji: '😹', keywords: ['cat', 'face', 'joy', 'tear'] },
      { code: 'U+1F63B', emoji: '😻', keywords: ['cat', 'eye', 'face', 'heart', 'love', 'smile'] },
      { code: 'U+1F63C', emoji: '😼', keywords: ['cat', 'face', 'ironic', 'smile', 'wry'] },
      { code: 'U+1F63D', emoji: '😽', keywords: ['cat', 'eye', 'face', 'kiss'] },
      { code: 'U+1F640', emoji: '🙀', keywords: ['cat', 'face', 'oh', 'surprised', 'weary'] },
      { code: 'U+1F63F', emoji: '😿', keywords: ['cat', 'cry', 'face', 'sad', 'tear'] },
      { code: 'U+1F63E', emoji: '😾', keywords: ['cat', 'face', 'pouting'] },
      {
        code: 'U+1F648',
        emoji: '🙈',
        keywords: ['evil', 'face', 'forbidden', 'gesture', 'monkey', 'no', 'not', 'prohibited', 'see'],
      },
      {
        code: 'U+1F649',
        emoji: '🙉',
        keywords: ['evil', 'face', 'forbidden', 'gesture', 'hear', 'monkey', 'no', 'not', 'prohibited'],
      },
      {
        code: 'U+1F64A',
        emoji: '🙊',
        keywords: ['evil', 'face', 'forbidden', 'gesture', 'monkey', 'no', 'not', 'prohibited', 'speak'],
      },
      { code: 'U+1F466', emoji: '👦', keywords: ['boy'] },
      { code: 'U+1F467', emoji: '👧', keywords: ['girl', 'maiden', 'virgin', 'virgo', 'zodiac'] },
      { code: 'U+1F468', emoji: '👨', keywords: ['man'] },
      { code: 'U+1F469', emoji: '👩', keywords: ['woman'] },
      { code: 'U+1F474', emoji: '👴', keywords: ['man', 'old'] },
      { code: 'U+1F475', emoji: '👵', keywords: ['old', 'woman'] },
      { code: 'U+1F476', emoji: '👶', keywords: ['baby'] },
      { code: 'U+1F47C', emoji: '👼', keywords: ['angel', 'baby', 'face', 'fairy tale', 'fantasy'] },
      { code: 'U+1F471', emoji: '👱', keywords: ['blond'] },
      { code: 'U+1F46E', emoji: '👮', keywords: ['cop', 'officer', 'police'] },
      { code: 'U+1F472', emoji: '👲', keywords: ['gua pi mao', 'hat', 'man'] },
      { code: 'U+1F473', emoji: '👳', keywords: ['man', 'turban'] },
      { code: 'U+1F477', emoji: '👷', keywords: ['construction', 'hat', 'worker'] },
      { code: 'U+1F478', emoji: '👸', keywords: ['fairy tale', 'fantasy', 'princess'] },
      { code: 'U+1F934', emoji: '🤴', keywords: ['prince'] },
      { code: 'U+1F482', emoji: '💂', keywords: ['guard', 'guardsman'] },
      { code: 'U+1F575', emoji: '🕵', keywords: ['detective', 'sleuth', 'spy'] },
      {
        code: 'U+1F385',
        emoji: '🎅',
        keywords: ['activity', 'celebration', 'christmas', 'fairy tale', 'fantasy', 'father', 'santa'],
      },
      { code: 'U+1F936', emoji: '🤶', keywords: ['christmas', 'mother', 'mrs. claus'] },
      { code: 'U+1F470', emoji: '👰', keywords: ['bride', 'veil', 'wedding'] },
      { code: 'U+1F935', emoji: '🤵', keywords: ['groom', 'man', 'tuxedo'] },
      { code: 'U+1F486', emoji: '💆', keywords: ['massage', 'salon'] },
      { code: 'U+1F487', emoji: '💇', keywords: ['barber', 'beauty', 'haircut', 'parlor'] },
      { code: 'U+1F64D', emoji: '🙍', keywords: ['frown', 'gesture'] },
      { code: 'U+1F64E', emoji: '🙎', keywords: ['gesture', 'pouting'] },
      { code: 'U+1F645', emoji: '🙅', keywords: ['forbidden', 'gesture', 'hand', 'no', 'not', 'prohibited'] },
      { code: 'U+1F646', emoji: '🙆', keywords: ['gesture', 'hand', 'ok'] },
      { code: 'U+1F481', emoji: '💁', keywords: ['hand', 'help', 'information', 'sassy'] },
      { code: 'U+1F937', emoji: '🤷', keywords: ['doubt', 'ignorance', 'indifference', 'shrug'] },
      { code: 'U+1F64B', emoji: '🙋', keywords: ['gesture', 'hand', 'happy', 'raised'] },
      { code: 'U+1F926', emoji: '🤦', keywords: ['disbelief', 'exasperation', 'face', 'palm'] },
      { code: 'U+1F647', emoji: '🙇', keywords: ['apology', 'bow', 'gesture', 'sorry'] },
      { code: 'U+1F6B6', emoji: '🚶', keywords: ['hike', 'pedestrian', 'walk', 'walking'] },
      { code: 'U+1F3C3', emoji: '🏃', keywords: ['marathon', 'runner', 'running'] },
      { code: 'U+1F483', emoji: '💃', keywords: ['dancer'] },
      { code: 'U+1F57A', emoji: '🕺', keywords: ['dance', 'man'] },
      { code: 'U+1F930', emoji: '🤰', keywords: ['pregnant', 'woman'] },
      { code: 'U+1F46F', emoji: '👯', keywords: ['bunny', 'dancer', 'ear', 'girl', 'woman'] },
      { code: 'U+1F574', emoji: '🕴', keywords: ['business', 'man', 'suit'] },
      { code: 'U+1F5E3', emoji: '🗣', keywords: ['face', 'head', 'silhouette', 'speak', 'speaking'] },
      { code: 'U+1F464', emoji: '👤', keywords: ['bust', 'silhouette'] },
      { code: 'U+1F465', emoji: '👥', keywords: ['bust', 'silhouette'] },
      { code: 'U+1F46B', emoji: '👫', keywords: ['couple', 'hand', 'hold', 'man', 'woman'] },
      { code: 'U+1F46C', emoji: '👬', keywords: ['couple', 'gemini', 'hand', 'hold', 'man', 'twins', 'zodiac'] },
      { code: 'U+1F46D', emoji: '👭', keywords: ['couple', 'hand', 'hold', 'woman'] },
      { code: 'U+1F48F', emoji: '💏', keywords: ['couple', 'kiss', 'romance'] },
      {
        code: 'U+1F469 U+200D U+2764 U+FE0F U+200D U+1F48B U+200D U+1F468',
        emoji: '👩‍❤️‍💋‍👨',
        keywords: ['kiss', 'man', 'woman'],
      },
      {
        code: 'U+1F468 U+200D U+2764 U+FE0F U+200D U+1F48B U+200D U+1F468',
        emoji: '👨‍❤️‍💋‍👨',
        keywords: ['kiss', 'man'],
      },
      {
        code: 'U+1F469 U+200D U+2764 U+FE0F U+200D U+1F48B U+200D U+1F469',
        emoji: '👩‍❤️‍💋‍👩',
        keywords: ['kiss', 'woman'],
      },
      { code: 'U+1F491', emoji: '💑', keywords: ['couple', 'heart', 'love', 'romance'] },
      { code: 'U+1F469 U+200D U+2764 U+FE0F U+200D U+1F468', emoji: '👩‍❤️‍👨', keywords: ['couple', 'man', 'woman'] },
      { code: 'U+1F468 U+200D U+2764 U+FE0F U+200D U+1F468', emoji: '👨‍❤️‍👨', keywords: ['couple', 'man'] },
      { code: 'U+1F469 U+200D U+2764 U+FE0F U+200D U+1F469', emoji: '👩‍❤️‍👩', keywords: ['couple', 'woman'] },
      { code: 'U+1F46A', emoji: '👪', keywords: ['child', 'family', 'father', 'mother'] },
      { code: 'U+1F468 U+200D U+1F469 U+200D U+1F466', emoji: '👨‍👩‍👦', keywords: ['boy', 'family', 'man', 'woman'] },
      { code: 'U+1F468 U+200D U+1F469 U+200D U+1F467', emoji: '👨‍👩‍👧', keywords: ['family', 'girl', 'man', 'woman'] },
      {
        code: 'U+1F468 U+200D U+1F469 U+200D U+1F467 U+200D U+1F466',
        emoji: '👨‍👩‍👧‍👦',
        keywords: ['boy', 'family', 'girl', 'man', 'woman'],
      },
      {
        code: 'U+1F468 U+200D U+1F469 U+200D U+1F466 U+200D U+1F466',
        emoji: '👨‍👩‍👦‍👦',
        keywords: ['boy', 'family', 'man', 'woman'],
      },
      {
        code: 'U+1F468 U+200D U+1F469 U+200D U+1F467 U+200D U+1F467',
        emoji: '👨‍👩‍👧‍👧',
        keywords: ['family', 'girl', 'man', 'woman'],
      },
      { code: 'U+1F468 U+200D U+1F468 U+200D U+1F466', emoji: '👨‍👨‍👦', keywords: ['boy', 'family', 'man'] },
      { code: 'U+1F468 U+200D U+1F468 U+200D U+1F467', emoji: '👨‍👨‍👧', keywords: ['family', 'girl', 'man'] },
      {
        code: 'U+1F468 U+200D U+1F468 U+200D U+1F467 U+200D U+1F466',
        emoji: '👨‍👨‍👧‍👦',
        keywords: ['boy', 'family', 'girl', 'man'],
      },
      {
        code: 'U+1F468 U+200D U+1F468 U+200D U+1F466 U+200D U+1F466',
        emoji: '👨‍👨‍👦‍👦',
        keywords: ['boy', 'family', 'man'],
      },
      {
        code: 'U+1F468 U+200D U+1F468 U+200D U+1F467 U+200D U+1F467',
        emoji: '👨‍👨‍👧‍👧',
        keywords: ['family', 'girl', 'man'],
      },
      { code: 'U+1F469 U+200D U+1F469 U+200D U+1F466', emoji: '👩‍👩‍👦', keywords: ['boy', 'family', 'woman'] },
      { code: 'U+1F469 U+200D U+1F469 U+200D U+1F467', emoji: '👩‍👩‍👧', keywords: ['family', 'girl', 'woman'] },
      {
        code: 'U+1F469 U+200D U+1F469 U+200D U+1F467 U+200D U+1F466',
        emoji: '👩‍👩‍👧‍👦',
        keywords: ['boy', 'family', 'girl', 'woman'],
      },
      {
        code: 'U+1F469 U+200D U+1F469 U+200D U+1F466 U+200D U+1F466',
        emoji: '👩‍👩‍👦‍👦',
        keywords: ['boy', 'family', 'woman'],
      },
      {
        code: 'U+1F469 U+200D U+1F469 U+200D U+1F467 U+200D U+1F467',
        emoji: '👩‍👩‍👧‍👧',
        keywords: ['family', 'girl', 'woman'],
      },
      { code: 'U+1F3FB', emoji: '🏻', keywords: ['emoji modifier', 'fitzpatrick', 'skin', 'tone'] },
      { code: 'U+1F3FC', emoji: '🏼', keywords: ['emoji modifier', 'fitzpatrick', 'skin', 'tone'] },
      { code: 'U+1F3FD', emoji: '🏽', keywords: ['emoji modifier', 'fitzpatrick', 'skin', 'tone'] },
      { code: 'U+1F3FE', emoji: '🏾', keywords: ['emoji modifier', 'fitzpatrick', 'skin', 'tone'] },
      { code: 'U+1F3FF', emoji: '🏿', keywords: ['emoji modifier', 'fitzpatrick', 'skin', 'tone'] },
      { code: 'U+1F4AA', emoji: '💪', keywords: ['biceps', 'body', 'comic', 'flex', 'muscle'] },
      { code: 'U+1F933', emoji: '🤳', keywords: ['camera', 'phone', 'selfie'] },
      { code: 'U+1F448', emoji: '👈', keywords: ['backhand', 'body', 'finger', 'hand', 'index', 'point'] },
      { code: 'U+1F449', emoji: '👉', keywords: ['backhand', 'body', 'finger', 'hand', 'index', 'point'] },
      { code: 'U+261D', emoji: '☝', keywords: ['body', 'finger', 'hand', 'index', 'point', 'up'] },
      { code: 'U+1F446', emoji: '👆', keywords: ['backhand', 'body', 'finger', 'hand', 'index', 'point', 'up'] },
      { code: 'U+1F595', emoji: '🖕', keywords: ['body', 'finger', 'hand', 'middle finger'] },
      { code: 'U+1F447', emoji: '👇', keywords: ['backhand', 'body', 'down', 'finger', 'hand', 'index', 'point'] },
      { code: 'U+270C', emoji: '✌', keywords: ['body', 'hand', 'v', 'victory'] },
      { code: 'U+1F91E', emoji: '🤞', keywords: ['cross', 'finger', 'hand', 'luck'] },
      { code: 'U+1F596', emoji: '🖖', keywords: ['body', 'finger', 'hand', 'spock', 'vulcan'] },
      { code: 'U+1F918', emoji: '🤘', keywords: ['body', 'finger', 'hand', 'horns', 'rock-on'] },
      { code: 'U+1F919', emoji: '🤙', keywords: ['call', 'hand'] },
      { code: 'U+270B', emoji: '✋', keywords: ['body', 'hand'] },
      { code: 'U+1F44C', emoji: '👌', keywords: ['body', 'hand', 'ok'] },
      { code: 'U+1F44D', emoji: '👍', keywords: ['+1', 'body', 'hand', 'thumb', 'thumbs up', 'up'] },
      { code: 'U+1F44E', emoji: '👎', keywords: ['-1', 'body', 'down', 'hand', 'thumb', 'thumbs down'] },
      { code: 'U+270A', emoji: '✊', keywords: ['body', 'clenched', 'fist', 'hand', 'punch'] },
      { code: 'U+1F44A', emoji: '👊', keywords: ['body', 'clenched', 'fist', 'hand', 'punch'] },
      { code: 'U+1F91B', emoji: '🤛', keywords: ['fist', 'leftwards'] },
      { code: 'U+1F91C', emoji: '🤜', keywords: ['fist', 'rightwards'] },
      { code: 'U+1F91A', emoji: '🤚', keywords: ['backhand', 'raised'] },
      { code: 'U+1F44B', emoji: '👋', keywords: ['body', 'hand', 'wave', 'waving'] },
      { code: 'U+1F44F', emoji: '👏', keywords: ['body', 'clap', 'hand'] },
      { code: 'U+1F450', emoji: '👐', keywords: ['body', 'hand', 'open'] },
      { code: 'U+1F64C', emoji: '🙌', keywords: ['body', 'celebration', 'gesture', 'hand', 'hooray', 'raised'] },
      {
        code: 'U+1F64F',
        emoji: '🙏',
        keywords: ['ask', 'body', 'bow', 'folded', 'gesture', 'hand', 'please', 'pray', 'thanks'],
      },
      { code: 'U+1F91D', emoji: '🤝', keywords: ['agreement', 'hand', 'handshake', 'meeting', 'shake'] },
      { code: 'U+1F485', emoji: '💅', keywords: ['body', 'care', 'cosmetics', 'manicure', 'nail', 'polish'] },
      { code: 'U+1F442', emoji: '👂', keywords: ['body', 'ear'] },
      { code: 'U+1F443', emoji: '👃', keywords: ['body', 'nose'] },
      { code: 'U+1F463', emoji: '👣', keywords: ['body', 'clothing', 'footprint', 'print'] },
      { code: 'U+1F440', emoji: '👀', keywords: ['body', 'eye', 'face'] },
      { code: 'U+1F441 U+200D U+1F5E8', emoji: '👁‍🗨', keywords: ['bubble', 'eye', 'speech', 'witness'] },
      { code: 'U+1F445', emoji: '👅', keywords: ['body', 'tongue'] },
      { code: 'U+1F444', emoji: '👄', keywords: ['body', 'lips', 'mouth'] },
      { code: 'U+1F48B', emoji: '💋', keywords: ['heart', 'kiss', 'lips', 'mark', 'romance'] },
      { code: 'U+1F498', emoji: '💘', keywords: ['arrow', 'cupid', 'heart', 'romance'] },
      { code: 'U+2764', emoji: '❤', keywords: ['heart'] },
      { code: 'U+1F493', emoji: '💓', keywords: ['beating', 'heart', 'heartbeat', 'pulsating'] },
      { code: 'U+1F494', emoji: '💔', keywords: ['break', 'broken', 'heart'] },
      { code: 'U+1F495', emoji: '💕', keywords: ['heart', 'love'] },
      { code: 'U+1F496', emoji: '💖', keywords: ['excited', 'heart', 'sparkle'] },
      { code: 'U+1F497', emoji: '💗', keywords: ['excited', 'growing', 'heart', 'heartpulse', 'nervous'] },
      { code: 'U+1F499', emoji: '💙', keywords: ['blue', 'heart'] },
      { code: 'U+1F49A', emoji: '💚', keywords: ['green', 'heart'] },
      { code: 'U+1F49B', emoji: '💛', keywords: ['heart', 'yellow'] },
      { code: 'U+1F49C', emoji: '💜', keywords: ['heart', 'purple'] },
      { code: 'U+1F5A4', emoji: '🖤', keywords: ['black', 'evil', 'heart', 'wicked'] },
      { code: 'U+1F49D', emoji: '💝', keywords: ['heart', 'ribbon', 'valentine'] },
      { code: 'U+1F49E', emoji: '💞', keywords: ['heart', 'revolving'] },
      { code: 'U+1F49F', emoji: '💟', keywords: ['heart'] },
      { code: 'U+2763', emoji: '❣', keywords: ['exclamation', 'heart', 'mark', 'punctuation'] },
      { code: 'U+1F48C', emoji: '💌', keywords: ['heart', 'letter', 'love', 'mail', 'romance'] },
      { code: 'U+1F4A4', emoji: '💤', keywords: ['comic', 'sleep', 'zzz'] },
      { code: 'U+1F4A2', emoji: '💢', keywords: ['angry', 'comic', 'mad'] },
      { code: 'U+1F4A3', emoji: '💣', keywords: ['bomb', 'comic'] },
      { code: 'U+1F4A5', emoji: '💥', keywords: ['boom', 'collision', 'comic'] },
      { code: 'U+1F4A6', emoji: '💦', keywords: ['comic', 'splashing', 'sweat'] },
      { code: 'U+1F4A8', emoji: '💨', keywords: ['comic', 'dash', 'running'] },
      { code: 'U+1F4AB', emoji: '💫', keywords: ['comic', 'dizzy', 'star'] },
      { code: 'U+1F4AC', emoji: '💬', keywords: ['balloon', 'bubble', 'comic', 'dialog', 'speech'] },
      { code: 'U+1F5E8', emoji: '🗨', keywords: ['dialog', 'speech'] },
      { code: 'U+1F5EF', emoji: '🗯', keywords: ['angry', 'balloon', 'bubble', 'mad'] },
      { code: 'U+1F4AD', emoji: '💭', keywords: ['balloon', 'bubble', 'comic', 'thought'] },
      { code: 'U+1F573', emoji: '🕳', keywords: ['hole'] },
      { code: 'U+1F453', emoji: '👓', keywords: ['clothing', 'eye', 'eyeglasses', 'eyewear', 'glasses'] },
      { code: 'U+1F576', emoji: '🕶', keywords: ['dark', 'eye', 'eyewear', 'glasses', 'sunglasses'] },
      { code: 'U+1F454', emoji: '👔', keywords: ['clothing', 'necktie'] },
      { code: 'U+1F455', emoji: '👕', keywords: ['clothing', 'shirt', 'tshirt'] },
      { code: 'U+1F456', emoji: '👖', keywords: ['clothing', 'jeans', 'pants', 'trousers'] },
      { code: 'U+1F457', emoji: '👗', keywords: ['clothing', 'dress'] },
      { code: 'U+1F458', emoji: '👘', keywords: ['clothing', 'kimono'] },
      { code: 'U+1F459', emoji: '👙', keywords: ['bikini', 'clothing', 'swim'] },
      { code: 'U+1F45A', emoji: '👚', keywords: ['clothing', 'woman'] },
      { code: 'U+1F45B', emoji: '👛', keywords: ['clothing', 'coin', 'purse'] },
      { code: 'U+1F45C', emoji: '👜', keywords: ['bag', 'clothing', 'handbag'] },
      { code: 'U+1F45D', emoji: '👝', keywords: ['bag', 'clothing', 'pouch'] },
      { code: 'U+1F6CD', emoji: '🛍', keywords: ['bag', 'hotel', 'shopping'] },
      { code: 'U+1F392', emoji: '🎒', keywords: ['activity', 'bag', 'satchel', 'school'] },
      { code: 'U+1F45E', emoji: '👞', keywords: ['clothing', 'man', 'shoe'] },
      { code: 'U+1F45F', emoji: '👟', keywords: ['athletic', 'clothing', 'shoe', 'sneaker'] },
      { code: 'U+1F460', emoji: '👠', keywords: ['clothing', 'heel', 'shoe', 'woman'] },
      { code: 'U+1F461', emoji: '👡', keywords: ['clothing', 'sandal', 'shoe', 'woman'] },
      { code: 'U+1F462', emoji: '👢', keywords: ['boot', 'clothing', 'shoe', 'woman'] },
      { code: 'U+1F451', emoji: '👑', keywords: ['clothing', 'crown', 'king', 'queen'] },
      { code: 'U+1F452', emoji: '👒', keywords: ['clothing', 'hat', 'woman'] },
      { code: 'U+1F3A9', emoji: '🎩', keywords: ['activity', 'clothing', 'entertainment', 'hat', 'top', 'tophat'] },
      { code: 'U+1F393', emoji: '🎓', keywords: ['activity', 'cap', 'celebration', 'clothing', 'graduation', 'hat'] },
      { code: 'U+26D1', emoji: '⛑', keywords: ['aid', 'cross', 'face', 'hat', 'helmet'] },
      { code: 'U+1F4FF', emoji: '📿', keywords: ['beads', 'clothing', 'necklace', 'prayer', 'religion'] },
      { code: 'U+1F484', emoji: '💄', keywords: ['cosmetics', 'lipstick', 'makeup'] },
      { code: 'U+1F48D', emoji: '💍', keywords: ['diamond', 'ring', 'romance'] },
      { code: 'U+1F48E', emoji: '💎', keywords: ['diamond', 'gem', 'jewel', 'romance'] },
    ],
  },
  nature: {
    label: 'Animals & Nature',
    emojis: [
      { code: 'U+1F435', emoji: '🐵', keywords: ['face', 'monkey'] },
      { code: 'U+1F412', emoji: '🐒', keywords: ['monkey'] },
      { code: 'U+1F98D', emoji: '🦍', keywords: ['gorilla'] },
      { code: 'U+1F436', emoji: '🐶', keywords: ['dog', 'face', 'pet'] },
      { code: 'U+1F415', emoji: '🐕', keywords: ['dog', 'pet'] },
      { code: 'U+1F429', emoji: '🐩', keywords: ['dog', 'poodle'] },
      { code: 'U+1F43A', emoji: '🐺', keywords: ['face', 'wolf'] },
      { code: 'U+1F98A', emoji: '🦊', keywords: ['face', 'fox'] },
      { code: 'U+1F431', emoji: '🐱', keywords: ['cat', 'face', 'pet'] },
      { code: 'U+1F408', emoji: '🐈', keywords: ['cat', 'pet'] },
      { code: 'U+1F981', emoji: '🦁', keywords: ['face', 'leo', 'lion', 'zodiac'] },
      { code: 'U+1F42F', emoji: '🐯', keywords: ['face', 'tiger'] },
      { code: 'U+1F405', emoji: '🐅', keywords: ['tiger'] },
      { code: 'U+1F406', emoji: '🐆', keywords: ['leopard'] },
      { code: 'U+1F434', emoji: '🐴', keywords: ['face', 'horse'] },
      { code: 'U+1F40E', emoji: '🐎', keywords: ['horse', 'racehorse', 'racing'] },
      { code: 'U+1F98C', emoji: '🦌', keywords: ['deer'] },
      { code: 'U+1F984', emoji: '🦄', keywords: ['face', 'unicorn'] },
      { code: 'U+1F42E', emoji: '🐮', keywords: ['cow', 'face'] },
      { code: 'U+1F402', emoji: '🐂', keywords: ['bull', 'ox', 'taurus', 'zodiac'] },
      { code: 'U+1F403', emoji: '🐃', keywords: ['buffalo', 'water'] },
      { code: 'U+1F404', emoji: '🐄', keywords: ['cow'] },
      { code: 'U+1F437', emoji: '🐷', keywords: ['face', 'pig'] },
      { code: 'U+1F416', emoji: '🐖', keywords: ['pig', 'sow'] },
      { code: 'U+1F417', emoji: '🐗', keywords: ['boar', 'pig'] },
      { code: 'U+1F43D', emoji: '🐽', keywords: ['face', 'nose', 'pig'] },
      { code: 'U+1F40F', emoji: '🐏', keywords: ['aries', 'ram', 'sheep', 'zodiac'] },
      { code: 'U+1F411', emoji: '🐑', keywords: ['ewe', 'sheep'] },
      { code: 'U+1F410', emoji: '🐐', keywords: ['capricorn', 'goat', 'zodiac'] },
      { code: 'U+1F42A', emoji: '🐪', keywords: ['camel', 'dromedary', 'hump'] },
      { code: 'U+1F42B', emoji: '🐫', keywords: ['bactrian', 'camel', 'hump'] },
      { code: 'U+1F418', emoji: '🐘', keywords: ['elephant'] },
      { code: 'U+1F98F', emoji: '🦏', keywords: ['rhinoceros'] },
      { code: 'U+1F42D', emoji: '🐭', keywords: ['face', 'mouse'] },
      { code: 'U+1F401', emoji: '🐁', keywords: ['mouse'] },
      { code: 'U+1F400', emoji: '🐀', keywords: ['rat'] },
      { code: 'U+1F439', emoji: '🐹', keywords: ['face', 'hamster', 'pet'] },
      { code: 'U+1F430', emoji: '🐰', keywords: ['bunny', 'face', 'pet', 'rabbit'] },
      { code: 'U+1F407', emoji: '🐇', keywords: ['bunny', 'pet', 'rabbit'] },
      { code: 'U+1F43F', emoji: '🐿', keywords: ['chipmunk'] },
      { code: 'U+1F987', emoji: '🦇', keywords: ['bat', 'vampire'] },
      { code: 'U+1F43B', emoji: '🐻', keywords: ['bear', 'face'] },
      { code: 'U+1F428', emoji: '🐨', keywords: ['bear', 'koala'] },
      { code: 'U+1F43C', emoji: '🐼', keywords: ['face', 'panda'] },
      { code: 'U+1F43E', emoji: '🐾', keywords: ['feet', 'paw', 'print'] },
      { code: 'U+1F983', emoji: '🦃', keywords: ['turkey'] },
      { code: 'U+1F414', emoji: '🐔', keywords: ['chicken'] },
      { code: 'U+1F413', emoji: '🐓', keywords: ['rooster'] },
      { code: 'U+1F423', emoji: '🐣', keywords: ['baby', 'chick', 'hatching'] },
      { code: 'U+1F424', emoji: '🐤', keywords: ['baby', 'chick'] },
      { code: 'U+1F425', emoji: '🐥', keywords: ['baby', 'chick'] },
      { code: 'U+1F426', emoji: '🐦', keywords: ['bird'] },
      { code: 'U+1F427', emoji: '🐧', keywords: ['penguin'] },
      { code: 'U+1F54A', emoji: '🕊', keywords: ['bird', 'dove', 'fly', 'peace'] },
      { code: 'U+1F985', emoji: '🦅', keywords: ['bird', 'eagle'] },
      { code: 'U+1F986', emoji: '🦆', keywords: ['bird', 'duck'] },
      { code: 'U+1F989', emoji: '🦉', keywords: ['bird', 'owl', 'wise'] },
      { code: 'U+1F438', emoji: '🐸', keywords: ['face', 'frog'] },
      { code: 'U+1F40A', emoji: '🐊', keywords: ['crocodile'] },
      { code: 'U+1F422', emoji: '🐢', keywords: ['turtle'] },
      { code: 'U+1F98E', emoji: '🦎', keywords: ['lizard', 'reptile'] },
      { code: 'U+1F40D', emoji: '🐍', keywords: ['bearer', 'ophiuchus', 'serpent', 'snake', 'zodiac'] },
      { code: 'U+1F432', emoji: '🐲', keywords: ['dragon', 'face', 'fairy tale'] },
      { code: 'U+1F409', emoji: '🐉', keywords: ['dragon', 'fairy tale'] },
      { code: 'U+1F433', emoji: '🐳', keywords: ['face', 'spouting', 'whale'] },
      { code: 'U+1F40B', emoji: '🐋', keywords: ['whale'] },
      { code: 'U+1F42C', emoji: '🐬', keywords: ['dolphin', 'flipper'] },
      { code: 'U+1F41F', emoji: '🐟', keywords: ['fish', 'pisces', 'zodiac'] },
      { code: 'U+1F420', emoji: '🐠', keywords: ['fish', 'tropical'] },
      { code: 'U+1F421', emoji: '🐡', keywords: ['blowfish', 'fish'] },
      { code: 'U+1F988', emoji: '🦈', keywords: ['fish', 'shark'] },
      { code: 'U+1F419', emoji: '🐙', keywords: ['octopus'] },
      { code: 'U+1F41A', emoji: '🐚', keywords: ['shell', 'spiral'] },
      { code: 'U+1F980', emoji: '🦀', keywords: ['cancer', 'crab', 'zodiac'] },
      { code: 'U+1F990', emoji: '🦐', keywords: ['shellfish', 'shrimp', 'small'] },
      { code: 'U+1F991', emoji: '🦑', keywords: ['molusc', 'squid'] },
      { code: 'U+1F98B', emoji: '🦋', keywords: ['butterfly', 'insect', 'pretty'] },
      { code: 'U+1F40C', emoji: '🐌', keywords: ['snail'] },
      { code: 'U+1F41B', emoji: '🐛', keywords: ['bug', 'insect'] },
      { code: 'U+1F41C', emoji: '🐜', keywords: ['ant', 'insect'] },
      { code: 'U+1F41D', emoji: '🐝', keywords: ['bee', 'honeybee', 'insect'] },
      { code: 'U+1F41E', emoji: '🐞', keywords: ['beetle', 'insect', 'lady beetle', 'ladybird', 'ladybug'] },
      { code: 'U+1F577', emoji: '🕷', keywords: ['insect', 'spider'] },
      { code: 'U+1F578', emoji: '🕸', keywords: ['spider', 'web'] },
      { code: 'U+1F982', emoji: '🦂', keywords: ['scorpio', 'scorpion', 'scorpius', 'zodiac'] },
      { code: 'U+1F490', emoji: '💐', keywords: ['bouquet', 'flower', 'plant', 'romance'] },
      { code: 'U+1F338', emoji: '🌸', keywords: ['blossom', 'cherry', 'flower', 'plant'] },
      { code: 'U+1F4AE', emoji: '💮', keywords: ['flower'] },
      { code: 'U+1F3F5', emoji: '🏵', keywords: ['plant', 'rosette'] },
      { code: 'U+1F339', emoji: '🌹', keywords: ['flower', 'plant', 'rose'] },
      { code: 'U+1F940', emoji: '🥀', keywords: ['flower', 'wilted'] },
      { code: 'U+1F33A', emoji: '🌺', keywords: ['flower', 'hibiscus', 'plant'] },
      { code: 'U+1F33B', emoji: '🌻', keywords: ['flower', 'plant', 'sun', 'sunflower'] },
      { code: 'U+1F33C', emoji: '🌼', keywords: ['blossom', 'flower', 'plant'] },
      { code: 'U+1F337', emoji: '🌷', keywords: ['flower', 'plant', 'tulip'] },
      { code: 'U+1F331', emoji: '🌱', keywords: ['plant', 'seedling', 'young'] },
      { code: 'U+1F332', emoji: '🌲', keywords: ['evergreen', 'plant', 'tree'] },
      { code: 'U+1F333', emoji: '🌳', keywords: ['deciduous', 'plant', 'shedding', 'tree'] },
      { code: 'U+1F334', emoji: '🌴', keywords: ['palm', 'plant', 'tree'] },
      { code: 'U+1F335', emoji: '🌵', keywords: ['cactus', 'plant'] },
      { code: 'U+1F33E', emoji: '🌾', keywords: ['ear', 'plant', 'rice'] },
      { code: 'U+1F33F', emoji: '🌿', keywords: ['herb', 'leaf', 'plant'] },
      { code: 'U+2618', emoji: '☘', keywords: ['plant', 'shamrock'] },
      { code: 'U+1F340', emoji: '🍀', keywords: ['4', 'clover', 'four', 'leaf', 'plant'] },
      { code: 'U+1F341', emoji: '🍁', keywords: ['falling', 'leaf', 'maple', 'plant'] },
      { code: 'U+1F342', emoji: '🍂', keywords: ['falling', 'leaf', 'plant'] },
      { code: 'U+1F343', emoji: '🍃', keywords: ['blow', 'flutter', 'leaf', 'plant', 'wind'] },
      { code: 'U+1F347', emoji: '🍇', keywords: ['fruit', 'grape', 'plant'] },
      { code: 'U+1F348', emoji: '🍈', keywords: ['fruit', 'melon', 'plant'] },
      { code: 'U+1F349', emoji: '🍉', keywords: ['fruit', 'plant', 'watermelon'] },
      { code: 'U+1F34A', emoji: '🍊', keywords: ['fruit', 'orange', 'plant', 'tangerine'] },
      { code: 'U+1F34B', emoji: '🍋', keywords: ['citrus', 'fruit', 'lemon', 'plant'] },
      { code: 'U+1F34C', emoji: '🍌', keywords: ['banana', 'fruit', 'plant'] },
      { code: 'U+1F34D', emoji: '🍍', keywords: ['fruit', 'pineapple', 'plant'] },
      { code: 'U+1F34E', emoji: '🍎', keywords: ['apple', 'fruit', 'plant', 'red'] },
      { code: 'U+1F34F', emoji: '🍏', keywords: ['apple', 'fruit', 'green', 'plant'] },
      { code: 'U+1F350', emoji: '🍐', keywords: ['fruit', 'pear', 'plant'] },
      { code: 'U+1F351', emoji: '🍑', keywords: ['fruit', 'peach', 'plant'] },
      { code: 'U+1F352', emoji: '🍒', keywords: ['cherry', 'fruit', 'plant'] },
      { code: 'U+1F353', emoji: '🍓', keywords: ['berry', 'fruit', 'plant', 'strawberry'] },
      { code: 'U+1F345', emoji: '🍅', keywords: ['plant', 'tomato', 'vegetable'] },
      { code: 'U+1F95D', emoji: '🥝', keywords: ['fruit', 'kiwi'] },
      { code: 'U+1F951', emoji: '🥑', keywords: ['avocado', 'fruit'] },
      { code: 'U+1F346', emoji: '🍆', keywords: ['aubergine', 'eggplant', 'plant', 'vegetable'] },
      { code: 'U+1F954', emoji: '🥔', keywords: ['potato', 'vegetable'] },
      { code: 'U+1F955', emoji: '🥕', keywords: ['carrot', 'vegetable'] },
      { code: 'U+1F33D', emoji: '🌽', keywords: ['corn', 'ear', 'maize', 'maze', 'plant'] },
      { code: 'U+1F336', emoji: '🌶', keywords: ['hot', 'pepper', 'plant'] },
      { code: 'U+1F952', emoji: '🥒', keywords: ['cucumber', 'pickle', 'vegetable'] },
      { code: 'U+1F344', emoji: '🍄', keywords: ['mushroom', 'plant'] },
      { code: 'U+1F95C', emoji: '🥜', keywords: ['nut', 'peanut', 'vegetable'] },
      { code: 'U+1F330', emoji: '🌰', keywords: ['chestnut', 'plant'] },
      { code: 'U+1F35E', emoji: '🍞', keywords: ['bread', 'loaf'] },
      { code: 'U+1F950', emoji: '🥐', keywords: ['bread', 'crescent roll', 'croissant', 'french'] },
      { code: 'U+1F956', emoji: '🥖', keywords: ['baguette', 'bread', 'french'] },
      { code: 'U+1F95E', emoji: '🥞', keywords: ['crêpe', 'hotcake', 'pancake'] },
      { code: 'U+1F9C0', emoji: '🧀', keywords: ['cheese'] },
      { code: 'U+1F356', emoji: '🍖', keywords: ['bone', 'meat'] },
      { code: 'U+1F357', emoji: '🍗', keywords: ['bone', 'chicken', 'leg', 'poultry'] },
      { code: 'U+1F953', emoji: '🥓', keywords: ['bacon', 'meat'] },
      { code: 'U+1F354', emoji: '🍔', keywords: ['burger', 'hamburger'] },
      { code: 'U+1F35F', emoji: '🍟', keywords: ['french', 'fries'] },
      { code: 'U+1F355', emoji: '🍕', keywords: ['cheese', 'pizza', 'slice'] },
      { code: 'U+1F32D', emoji: '🌭', keywords: ['frankfurter', 'hot dog', 'hotdog', 'sausage'] },
      { code: 'U+1F32E', emoji: '🌮', keywords: ['mexican', 'taco'] },
      { code: 'U+1F32F', emoji: '🌯', keywords: ['burrito', 'mexican'] },
      { code: 'U+1F959', emoji: '🥙', keywords: ['falafel', 'flatbread', 'gyro', 'kebab', 'stuffed'] },
      { code: 'U+1F95A', emoji: '🥚', keywords: ['egg'] },
      { code: 'U+1F373', emoji: '🍳', keywords: ['cooking', 'egg', 'frying', 'pan'] },
      { code: 'U+1F958', emoji: '🥘', keywords: ['casserole', 'paella', 'pan', 'shallow'] },
      { code: 'U+1F372', emoji: '🍲', keywords: ['pot', 'stew'] },
      { code: 'U+1F957', emoji: '🥗', keywords: ['green', 'salad'] },
      { code: 'U+1F37F', emoji: '🍿', keywords: ['popcorn'] },
      { code: 'U+1F371', emoji: '🍱', keywords: ['bento', 'box'] },
      { code: 'U+1F358', emoji: '🍘', keywords: ['cracker', 'rice'] },
      { code: 'U+1F359', emoji: '🍙', keywords: ['ball', 'japanese', 'rice'] },
      { code: 'U+1F35A', emoji: '🍚', keywords: ['cooked', 'rice'] },
      { code: 'U+1F35B', emoji: '🍛', keywords: ['curry', 'rice'] },
      { code: 'U+1F35C', emoji: '🍜', keywords: ['bowl', 'noodle', 'ramen', 'steaming'] },
      { code: 'U+1F35D', emoji: '🍝', keywords: ['pasta', 'spaghetti'] },
      { code: 'U+1F360', emoji: '🍠', keywords: ['potato', 'roasted', 'sweet'] },
      { code: 'U+1F362', emoji: '🍢', keywords: ['kebab', 'oden', 'seafood', 'skewer', 'stick'] },
      { code: 'U+1F363', emoji: '🍣', keywords: ['sushi'] },
      { code: 'U+1F364', emoji: '🍤', keywords: ['fried', 'prawn', 'shrimp', 'tempura'] },
      { code: 'U+1F365', emoji: '🍥', keywords: ['cake', 'fish', 'pastry', 'swirl'] },
      { code: 'U+1F361', emoji: '🍡', keywords: ['dango', 'dessert', 'japanese', 'skewer', 'stick', 'sweet'] },
      { code: 'U+1F366', emoji: '🍦', keywords: ['cream', 'dessert', 'ice', 'icecream', 'soft', 'sweet'] },
      { code: 'U+1F367', emoji: '🍧', keywords: ['dessert', 'ice', 'shaved', 'sweet'] },
      { code: 'U+1F368', emoji: '🍨', keywords: ['cream', 'dessert', 'ice', 'sweet'] },
      { code: 'U+1F369', emoji: '🍩', keywords: ['dessert', 'donut', 'doughnut', 'sweet'] },
      { code: 'U+1F36A', emoji: '🍪', keywords: ['cookie', 'dessert', 'sweet'] },
      { code: 'U+1F382', emoji: '🎂', keywords: ['birthday', 'cake', 'celebration', 'dessert', 'pastry', 'sweet'] },
      { code: 'U+1F370', emoji: '🍰', keywords: ['cake', 'dessert', 'pastry', 'shortcake', 'slice', 'sweet'] },
      { code: 'U+1F36B', emoji: '🍫', keywords: ['bar', 'chocolate', 'dessert', 'sweet'] },
      { code: 'U+1F36C', emoji: '🍬', keywords: ['candy', 'dessert', 'sweet'] },
      { code: 'U+1F36D', emoji: '🍭', keywords: ['candy', 'dessert', 'lollipop', 'sweet'] },
      { code: 'U+1F36E', emoji: '🍮', keywords: ['custard', 'dessert', 'pudding', 'sweet'] },
      { code: 'U+1F36F', emoji: '🍯', keywords: ['honey', 'honeypot', 'pot', 'sweet'] },
      { code: 'U+1F37C', emoji: '🍼', keywords: ['baby', 'bottle', 'drink', 'milk'] },
      { code: 'U+1F95B', emoji: '🥛', keywords: ['drink', 'glass', 'milk'] },
      { code: 'U+2615', emoji: '☕', keywords: ['beverage', 'coffee', 'drink', 'hot', 'steaming', 'tea'] },
      { code: 'U+1F375', emoji: '🍵', keywords: ['beverage', 'cup', 'drink', 'tea', 'teacup'] },
      { code: 'U+1F376', emoji: '🍶', keywords: ['bar', 'beverage', 'bottle', 'cup', 'drink', 'sake'] },
      { code: 'U+1F37E', emoji: '🍾', keywords: ['bar', 'bottle', 'cork', 'drink', 'popping'] },
      { code: 'U+1F377', emoji: '🍷', keywords: ['bar', 'beverage', 'drink', 'glass', 'wine'] },
      { code: 'U+1F378', emoji: '🍸', keywords: ['bar', 'cocktail', 'drink', 'glass'] },
      { code: 'U+1F379', emoji: '🍹', keywords: ['bar', 'drink', 'tropical'] },
      { code: 'U+1F37A', emoji: '🍺', keywords: ['bar', 'beer', 'drink', 'mug'] },
      { code: 'U+1F37B', emoji: '🍻', keywords: ['bar', 'beer', 'clink', 'drink', 'mug'] },
      { code: 'U+1F942', emoji: '🥂', keywords: ['celebrate', 'clink', 'drink', 'glass'] },
      { code: 'U+1F943', emoji: '🥃', keywords: ['glass', 'liquor', 'shot', 'tumbler', 'whisky'] },
      { code: 'U+1F37D', emoji: '🍽', keywords: ['cooking', 'fork', 'knife', 'plate'] },
      { code: 'U+1F374', emoji: '🍴', keywords: ['cooking', 'fork', 'knife'] },
      { code: 'U+1F944', emoji: '🥄', keywords: ['spoon', 'tableware'] },
      { code: 'U+1F52A', emoji: '🔪', keywords: ['cooking', 'hocho', 'knife', 'tool', 'weapon'] },
      {
        code: 'U+1F3FA',
        emoji: '🏺',
        keywords: ['amphora', 'aquarius', 'cooking', 'drink', 'jug', 'tool', 'weapon', 'zodiac'],
      },
    ],
  },
  travel: {
    label: 'Travel & Places',
    emojis: [
      { code: 'U+1F30D', emoji: '🌍', keywords: ['africa', 'earth', 'europe', 'globe', 'world'] },
      { code: 'U+1F30E', emoji: '🌎', keywords: ['americas', 'earth', 'globe', 'world'] },
      { code: 'U+1F30F', emoji: '🌏', keywords: ['asia', 'australia', 'earth', 'globe', 'world'] },
      { code: 'U+1F310', emoji: '🌐', keywords: ['earth', 'globe', 'meridians', 'world'] },
      { code: 'U+1F5FA', emoji: '🗺', keywords: ['map', 'world'] },
      { code: 'U+1F5FE', emoji: '🗾', keywords: ['japan', 'map'] },
      { code: 'U+1F3D4', emoji: '🏔', keywords: ['cold', 'mountain', 'snow'] },
      { code: 'U+26F0', emoji: '⛰', keywords: ['mountain'] },
      { code: 'U+1F30B', emoji: '🌋', keywords: ['eruption', 'mountain', 'volcano', 'weather'] },
      { code: 'U+1F5FB', emoji: '🗻', keywords: ['fuji', 'mountain'] },
      { code: 'U+1F3D5', emoji: '🏕', keywords: ['camping'] },
      { code: 'U+1F3D6', emoji: '🏖', keywords: ['beach', 'umbrella'] },
      { code: 'U+1F3DC', emoji: '🏜', keywords: ['desert'] },
      { code: 'U+1F3DD', emoji: '🏝', keywords: ['desert', 'island'] },
      { code: 'U+1F3DE', emoji: '🏞', keywords: ['national park', 'park'] },
      { code: 'U+1F3DF', emoji: '🏟', keywords: ['stadium'] },
      { code: 'U+1F3DB', emoji: '🏛', keywords: ['building', 'classical'] },
      { code: 'U+1F3D7', emoji: '🏗', keywords: ['building', 'construction'] },
      { code: 'U+1F3D8', emoji: '🏘', keywords: ['building', 'house'] },
      { code: 'U+1F3D9', emoji: '🏙', keywords: ['building', 'city'] },
      { code: 'U+1F3DA', emoji: '🏚', keywords: ['building', 'derelict', 'house'] },
      { code: 'U+1F3E0', emoji: '🏠', keywords: ['building', 'home', 'house'] },
      { code: 'U+1F3E1', emoji: '🏡', keywords: ['building', 'garden', 'home', 'house'] },
      { code: 'U+1F3E2', emoji: '🏢', keywords: ['building'] },
      { code: 'U+1F3E3', emoji: '🏣', keywords: ['building', 'japanese', 'post'] },
      { code: 'U+1F3E4', emoji: '🏤', keywords: ['building', 'european', 'post'] },
      { code: 'U+1F3E5', emoji: '🏥', keywords: ['building', 'doctor', 'hospital', 'medicine'] },
      { code: 'U+1F3E6', emoji: '🏦', keywords: ['bank', 'building'] },
      { code: 'U+1F3E8', emoji: '🏨', keywords: ['building', 'hotel'] },
      { code: 'U+1F3E9', emoji: '🏩', keywords: ['building', 'hotel', 'love'] },
      { code: 'U+1F3EA', emoji: '🏪', keywords: ['building', 'convenience', 'store'] },
      { code: 'U+1F3EB', emoji: '🏫', keywords: ['building', 'school'] },
      { code: 'U+1F3EC', emoji: '🏬', keywords: ['building', 'department', 'store'] },
      { code: 'U+1F3ED', emoji: '🏭', keywords: ['building', 'factory'] },
      { code: 'U+1F3EF', emoji: '🏯', keywords: ['building', 'castle', 'japanese'] },
      { code: 'U+1F3F0', emoji: '🏰', keywords: ['building', 'castle', 'european'] },
      { code: 'U+1F492', emoji: '💒', keywords: ['activity', 'chapel', 'romance', 'wedding'] },
      { code: 'U+1F5FC', emoji: '🗼', keywords: ['tokyo', 'tower'] },
      { code: 'U+1F5FD', emoji: '🗽', keywords: ['liberty', 'statue'] },
      { code: 'U+26EA', emoji: '⛪', keywords: ['building', 'christian', 'church', 'cross', 'religion'] },
      { code: 'U+1F54C', emoji: '🕌', keywords: ['islam', 'mosque', 'muslim', 'religion'] },
      { code: 'U+1F54D', emoji: '🕍', keywords: ['jew', 'jewish', 'religion', 'synagogue', 'temple'] },
      { code: 'U+26E9', emoji: '⛩', keywords: ['religion', 'shinto', 'shrine'] },
      { code: 'U+1F54B', emoji: '🕋', keywords: ['islam', 'kaaba', 'muslim', 'religion'] },
      { code: 'U+26F2', emoji: '⛲', keywords: ['fountain'] },
      { code: 'U+26FA', emoji: '⛺', keywords: ['camping', 'tent'] },
      { code: 'U+1F301', emoji: '🌁', keywords: ['fog', 'weather'] },
      { code: 'U+1F303', emoji: '🌃', keywords: ['night', 'star', 'weather'] },
      { code: 'U+1F304', emoji: '🌄', keywords: ['morning', 'mountain', 'sun', 'sunrise', 'weather'] },
      { code: 'U+1F305', emoji: '🌅', keywords: ['morning', 'sun', 'sunrise', 'weather'] },
      {
        code: 'U+1F306',
        emoji: '🌆',
        keywords: ['building', 'city', 'dusk', 'evening', 'landscape', 'sun', 'sunset', 'weather'],
      },
      { code: 'U+1F307', emoji: '🌇', keywords: ['building', 'dusk', 'sun', 'sunset', 'weather'] },
      { code: 'U+1F309', emoji: '🌉', keywords: ['bridge', 'night', 'weather'] },
      { code: 'U+2668', emoji: '♨', keywords: ['hot', 'hotsprings', 'springs', 'steaming'] },
      { code: 'U+1F30C', emoji: '🌌', keywords: ['milky way', 'space', 'weather'] },
      { code: 'U+1F3A0', emoji: '🎠', keywords: ['activity', 'carousel', 'entertainment', 'horse'] },
      { code: 'U+1F3A1', emoji: '🎡', keywords: ['activity', 'amusement park', 'entertainment', 'ferris', 'wheel'] },
      { code: 'U+1F3A2', emoji: '🎢', keywords: ['activity', 'amusement park', 'coaster', 'entertainment', 'roller'] },
      { code: 'U+1F488', emoji: '💈', keywords: ['barber', 'haircut', 'pole'] },
      { code: 'U+1F3AA', emoji: '🎪', keywords: ['activity', 'circus', 'entertainment', 'tent'] },
      {
        code: 'U+1F3AD',
        emoji: '🎭',
        keywords: ['activity', 'art', 'entertainment', 'mask', 'performing', 'theater', 'theatre'],
      },
      { code: 'U+1F5BC', emoji: '🖼', keywords: ['art', 'frame', 'museum', 'painting', 'picture'] },
      { code: 'U+1F3A8', emoji: '🎨', keywords: ['activity', 'art', 'entertainment', 'museum', 'painting', 'palette'] },
      { code: 'U+1F3B0', emoji: '🎰', keywords: ['activity', 'game', 'slot'] },
      { code: 'U+1F682', emoji: '🚂', keywords: ['engine', 'locomotive', 'railway', 'steam', 'train', 'vehicle'] },
      {
        code: 'U+1F683',
        emoji: '🚃',
        keywords: ['car', 'electric', 'railway', 'train', 'tram', 'trolleybus', 'vehicle'],
      },
      { code: 'U+1F684', emoji: '🚄', keywords: ['railway', 'shinkansen', 'speed', 'train', 'vehicle'] },
      { code: 'U+1F685', emoji: '🚅', keywords: ['bullet', 'railway', 'shinkansen', 'speed', 'train', 'vehicle'] },
      { code: 'U+1F686', emoji: '🚆', keywords: ['railway', 'train', 'vehicle'] },
      { code: 'U+1F687', emoji: '🚇', keywords: ['metro', 'subway', 'vehicle'] },
      { code: 'U+1F688', emoji: '🚈', keywords: ['railway', 'vehicle'] },
      { code: 'U+1F689', emoji: '🚉', keywords: ['railway', 'station', 'train', 'vehicle'] },
      { code: 'U+1F68A', emoji: '🚊', keywords: ['tram', 'trolleybus', 'vehicle'] },
      { code: 'U+1F69D', emoji: '🚝', keywords: ['monorail', 'vehicle'] },
      { code: 'U+1F69E', emoji: '🚞', keywords: ['car', 'mountain', 'railway', 'vehicle'] },
      { code: 'U+1F68B', emoji: '🚋', keywords: ['car', 'tram', 'trolleybus', 'vehicle'] },
      { code: 'U+1F68C', emoji: '🚌', keywords: ['bus', 'vehicle'] },
      { code: 'U+1F68D', emoji: '🚍', keywords: ['bus', 'oncoming', 'vehicle'] },
      { code: 'U+1F68E', emoji: '🚎', keywords: ['bus', 'tram', 'trolley', 'trolleybus', 'vehicle'] },
      { code: 'U+1F68F', emoji: '🚏', keywords: ['bus', 'busstop', 'stop'] },
      { code: 'U+1F690', emoji: '🚐', keywords: ['bus', 'minibus', 'vehicle'] },
      { code: 'U+1F691', emoji: '🚑', keywords: ['ambulance', 'vehicle'] },
      { code: 'U+1F692', emoji: '🚒', keywords: ['engine', 'fire', 'truck', 'vehicle'] },
      { code: 'U+1F693', emoji: '🚓', keywords: ['car', 'patrol', 'police', 'vehicle'] },
      { code: 'U+1F694', emoji: '🚔', keywords: ['car', 'oncoming', 'police', 'vehicle'] },
      { code: 'U+1F695', emoji: '🚕', keywords: ['taxi', 'vehicle'] },
      { code: 'U+1F696', emoji: '🚖', keywords: ['oncoming', 'taxi', 'vehicle'] },
      { code: 'U+1F697', emoji: '🚗', keywords: ['automobile', 'car', 'vehicle'] },
      { code: 'U+1F698', emoji: '🚘', keywords: ['automobile', 'car', 'oncoming', 'vehicle'] },
      { code: 'U+1F699', emoji: '🚙', keywords: ['recreational', 'rv', 'vehicle'] },
      { code: 'U+1F69A', emoji: '🚚', keywords: ['delivery', 'truck', 'vehicle'] },
      { code: 'U+1F69B', emoji: '🚛', keywords: ['lorry', 'semi', 'truck', 'vehicle'] },
      { code: 'U+1F69C', emoji: '🚜', keywords: ['tractor', 'vehicle'] },
      { code: 'U+1F6B2', emoji: '🚲', keywords: ['bicycle', 'bike', 'vehicle'] },
      { code: 'U+26FD', emoji: '⛽', keywords: ['fuel', 'fuelpump', 'gas', 'pump', 'station'] },
      { code: 'U+1F6E3', emoji: '🛣', keywords: ['highway', 'motorway', 'road'] },
      { code: 'U+1F6E4', emoji: '🛤', keywords: ['railway', 'train'] },
      { code: 'U+1F6A8', emoji: '🚨', keywords: ['beacon', 'car', 'light', 'police', 'revolving', 'vehicle'] },
      { code: 'U+1F6A5', emoji: '🚥', keywords: ['light', 'signal', 'traffic'] },
      { code: 'U+1F6A6', emoji: '🚦', keywords: ['light', 'signal', 'traffic'] },
      { code: 'U+1F6A7', emoji: '🚧', keywords: ['barrier', 'construction'] },
      { code: 'U+1F6D1', emoji: '🛑', keywords: ['octagonal', 'stop'] },
      { code: 'U+1F6F4', emoji: '🛴', keywords: ['kick', 'scooter'] },
      { code: 'U+1F6F5', emoji: '🛵', keywords: ['motor', 'scooter'] },
      { code: 'U+2693', emoji: '⚓', keywords: ['anchor', 'ship', 'tool'] },
      { code: 'U+26F5', emoji: '⛵', keywords: ['boat', 'resort', 'sailboat', 'sea', 'vehicle', 'yacht'] },
      { code: 'U+1F6A3', emoji: '🚣', keywords: ['boat', 'rowboat', 'vehicle'] },
      { code: 'U+1F6F6', emoji: '🛶', keywords: ['boat', 'canoe'] },
      { code: 'U+1F6A4', emoji: '🚤', keywords: ['boat', 'speedboat', 'vehicle'] },
      { code: 'U+1F6F3', emoji: '🛳', keywords: ['passenger', 'ship', 'vehicle'] },
      { code: 'U+26F4', emoji: '⛴', keywords: ['boat', 'ferry'] },
      { code: 'U+1F6E5', emoji: '🛥', keywords: ['boat', 'motorboat', 'vehicle'] },
      { code: 'U+1F6A2', emoji: '🚢', keywords: ['ship', 'vehicle'] },
      { code: 'U+2708', emoji: '✈', keywords: ['airplane', 'vehicle'] },
      { code: 'U+1F6E9', emoji: '🛩', keywords: ['airplane', 'vehicle'] },
      { code: 'U+1F6EB', emoji: '🛫', keywords: ['airplane', 'check-in', 'departure', 'departures', 'vehicle'] },
      { code: 'U+1F6EC', emoji: '🛬', keywords: ['airplane', 'arrivals', 'arriving', 'landing', 'vehicle'] },
      { code: 'U+1F4BA', emoji: '💺', keywords: ['chair', 'seat'] },
      { code: 'U+1F681', emoji: '🚁', keywords: ['helicopter', 'vehicle'] },
      { code: 'U+1F69F', emoji: '🚟', keywords: ['railway', 'suspension', 'vehicle'] },
      { code: 'U+1F6A0', emoji: '🚠', keywords: ['cable', 'gondola', 'mountain', 'vehicle'] },
      {
        code: 'U+1F6A1',
        emoji: '🚡',
        keywords: ['aerial', 'cable', 'car', 'gondola', 'ropeway', 'tramway', 'vehicle'],
      },
      { code: 'U+1F680', emoji: '🚀', keywords: ['rocket', 'space', 'vehicle'] },
      { code: 'U+1F6F0', emoji: '🛰', keywords: ['satellite', 'space', 'vehicle'] },
      { code: 'U+1F6CE', emoji: '🛎', keywords: ['bell', 'bellhop', 'hotel'] },
      { code: 'U+1F6AA', emoji: '🚪', keywords: ['door'] },
      { code: 'U+1F6CC', emoji: '🛌', keywords: ['hotel', 'sleep'] },
      { code: 'U+1F6CF', emoji: '🛏', keywords: ['bed', 'hotel', 'sleep'] },
      { code: 'U+1F6CB', emoji: '🛋', keywords: ['couch', 'hotel', 'lamp'] },
      { code: 'U+1F6BD', emoji: '🚽', keywords: ['toilet'] },
      { code: 'U+1F6BF', emoji: '🚿', keywords: ['shower', 'water'] },
      { code: 'U+1F6C0', emoji: '🛀', keywords: ['bath', 'bathtub'] },
      { code: 'U+1F6C1', emoji: '🛁', keywords: ['bath', 'bathtub'] },
      { code: 'U+231B', emoji: '⌛', keywords: ['hourglass', 'sand', 'timer'] },
      { code: 'U+23F3', emoji: '⏳', keywords: ['hourglass', 'sand', 'timer'] },
      { code: 'U+231A', emoji: '⌚', keywords: ['clock', 'watch'] },
      { code: 'U+23F0', emoji: '⏰', keywords: ['alarm', 'clock'] },
      { code: 'U+23F1', emoji: '⏱', keywords: ['clock', 'stopwatch'] },
      { code: 'U+23F2', emoji: '⏲', keywords: ['clock', 'timer'] },
      { code: 'U+1F570', emoji: '🕰', keywords: ['clock'] },
      { code: 'U+1F55B', emoji: '🕛', keywords: ['00', '12', '12:00', 'clock', 'o’clock', 'twelve'] },
      { code: 'U+1F567', emoji: '🕧', keywords: ['12', '12:30', '30', 'clock', 'thirty', 'twelve'] },
      { code: 'U+1F550', emoji: '🕐', keywords: ['00', '1', '1:00', 'clock', 'o’clock', 'one'] },
      { code: 'U+1F55C', emoji: '🕜', keywords: ['1', '1:30', '30', 'clock', 'one', 'thirty'] },
      { code: 'U+1F551', emoji: '🕑', keywords: ['00', '2', '2:00', 'clock', 'o’clock', 'two'] },
      { code: 'U+1F55D', emoji: '🕝', keywords: ['2', '2:30', '30', 'clock', 'thirty', 'two'] },
      { code: 'U+1F552', emoji: '🕒', keywords: ['00', '3', '3:00', 'clock', 'o’clock', 'three'] },
      { code: 'U+1F55E', emoji: '🕞', keywords: ['3', '3:30', '30', 'clock', 'thirty', 'three'] },
      { code: 'U+1F553', emoji: '🕓', keywords: ['00', '4', '4:00', 'clock', 'four', 'o’clock'] },
      { code: 'U+1F55F', emoji: '🕟', keywords: ['30', '4', '4:30', 'clock', 'four', 'thirty'] },
      { code: 'U+1F554', emoji: '🕔', keywords: ['00', '5', '5:00', 'clock', 'five', 'o’clock'] },
      { code: 'U+1F560', emoji: '🕠', keywords: ['30', '5', '5:30', 'clock', 'five', 'thirty'] },
      { code: 'U+1F555', emoji: '🕕', keywords: ['00', '6', '6:00', 'clock', 'o’clock', 'six'] },
      { code: 'U+1F561', emoji: '🕡', keywords: ['30', '6', '6:30', 'clock', 'six', 'thirty'] },
      { code: 'U+1F556', emoji: '🕖', keywords: ['00', '7', '7:00', 'clock', 'o’clock', 'seven'] },
      { code: 'U+1F562', emoji: '🕢', keywords: ['30', '7', '7:30', 'clock', 'seven', 'thirty'] },
      { code: 'U+1F557', emoji: '🕗', keywords: ['00', '8', '8:00', 'clock', 'eight', 'o’clock'] },
      { code: 'U+1F563', emoji: '🕣', keywords: ['30', '8', '8:30', 'clock', 'eight', 'thirty'] },
      { code: 'U+1F558', emoji: '🕘', keywords: ['00', '9', '9:00', 'clock', 'nine', 'o’clock'] },
      { code: 'U+1F564', emoji: '🕤', keywords: ['30', '9', '9:30', 'clock', 'nine', 'thirty'] },
      { code: 'U+1F559', emoji: '🕙', keywords: ['00', '10', '10:00', 'clock', 'o’clock', 'ten'] },
      { code: 'U+1F565', emoji: '🕥', keywords: ['10', '10:30', '30', 'clock', 'ten', 'thirty'] },
      { code: 'U+1F55A', emoji: '🕚', keywords: ['00', '11', '11:00', 'clock', 'eleven', 'o’clock'] },
      { code: 'U+1F566', emoji: '🕦', keywords: ['11', '11:30', '30', 'clock', 'eleven', 'thirty'] },
      { code: 'U+1F311', emoji: '🌑', keywords: ['dark', 'moon', 'space', 'weather'] },
      { code: 'U+1F312', emoji: '🌒', keywords: ['crescent', 'moon', 'space', 'waxing', 'weather'] },
      { code: 'U+1F313', emoji: '🌓', keywords: ['moon', 'quarter', 'space', 'weather'] },
      { code: 'U+1F314', emoji: '🌔', keywords: ['gibbous', 'moon', 'space', 'waxing', 'weather'] },
      { code: 'U+1F315', emoji: '🌕', keywords: ['full', 'moon', 'space', 'weather'] },
      { code: 'U+1F316', emoji: '🌖', keywords: ['gibbous', 'moon', 'space', 'waning', 'weather'] },
      { code: 'U+1F317', emoji: '🌗', keywords: ['moon', 'quarter', 'space', 'weather'] },
      { code: 'U+1F318', emoji: '🌘', keywords: ['crescent', 'moon', 'space', 'waning', 'weather'] },
      { code: 'U+1F319', emoji: '🌙', keywords: ['crescent', 'moon', 'space', 'weather'] },
      { code: 'U+1F31A', emoji: '🌚', keywords: ['face', 'moon', 'space', 'weather'] },
      { code: 'U+1F31B', emoji: '🌛', keywords: ['face', 'moon', 'quarter', 'space', 'weather'] },
      { code: 'U+1F31C', emoji: '🌜', keywords: ['face', 'moon', 'quarter', 'space', 'weather'] },
      { code: 'U+1F321', emoji: '🌡', keywords: ['thermometer', 'weather'] },
      { code: 'U+2600', emoji: '☀', keywords: ['bright', 'rays', 'space', 'sun', 'sunny', 'weather'] },
      { code: 'U+1F31D', emoji: '🌝', keywords: ['bright', 'face', 'full', 'moon', 'space', 'weather'] },
      { code: 'U+1F31E', emoji: '🌞', keywords: ['bright', 'face', 'space', 'sun', 'weather'] },
      { code: 'U+2B50', emoji: '⭐', keywords: ['star'] },
      { code: 'U+1F31F', emoji: '🌟', keywords: ['glittery', 'glow', 'shining', 'sparkle', 'star'] },
      { code: 'U+1F320', emoji: '🌠', keywords: ['activity', 'falling', 'shooting', 'space', 'star'] },
      { code: 'U+2601', emoji: '☁', keywords: ['cloud', 'weather'] },
      { code: 'U+26C5', emoji: '⛅', keywords: ['cloud', 'sun', 'weather'] },
      { code: 'U+26C8', emoji: '⛈', keywords: ['cloud', 'rain', 'thunder', 'weather'] },
      { code: 'U+1F324', emoji: '🌤', keywords: ['cloud', 'sun', 'weather'] },
      { code: 'U+1F325', emoji: '🌥', keywords: ['cloud', 'sun', 'weather'] },
      { code: 'U+1F326', emoji: '🌦', keywords: ['cloud', 'rain', 'sun', 'weather'] },
      { code: 'U+1F327', emoji: '🌧', keywords: ['cloud', 'rain', 'weather'] },
      { code: 'U+1F328', emoji: '🌨', keywords: ['cloud', 'cold', 'snow', 'weather'] },
      { code: 'U+1F329', emoji: '🌩', keywords: ['cloud', 'lightning', 'weather'] },
      { code: 'U+1F32A', emoji: '🌪', keywords: ['cloud', 'tornado', 'weather', 'whirlwind'] },
      { code: 'U+1F32B', emoji: '🌫', keywords: ['cloud', 'fog', 'weather'] },
      { code: 'U+1F32C', emoji: '🌬', keywords: ['blow', 'cloud', 'face', 'weather', 'wind'] },
      { code: 'U+1F300', emoji: '🌀', keywords: ['cyclone', 'dizzy', 'twister', 'typhoon', 'weather'] },
      { code: 'U+1F308', emoji: '🌈', keywords: ['rain', 'rainbow', 'weather'] },
      { code: 'U+1F302', emoji: '🌂', keywords: ['clothing', 'rain', 'umbrella', 'weather'] },
      { code: 'U+2602', emoji: '☂', keywords: ['clothing', 'rain', 'umbrella', 'weather'] },
      { code: 'U+2614', emoji: '☔', keywords: ['clothing', 'drop', 'rain', 'umbrella', 'weather'] },
      { code: 'U+26F1', emoji: '⛱', keywords: ['rain', 'sun', 'umbrella', 'weather'] },
      { code: 'U+26A1', emoji: '⚡', keywords: ['danger', 'electric', 'electricity', 'lightning', 'voltage', 'zap'] },
      { code: 'U+2744', emoji: '❄', keywords: ['cold', 'snow', 'snowflake', 'weather'] },
      { code: 'U+2603', emoji: '☃', keywords: ['cold', 'snow', 'snowman', 'weather'] },
      { code: 'U+26C4', emoji: '⛄', keywords: ['cold', 'snow', 'snowman', 'weather'] },
      { code: 'U+2604', emoji: '☄', keywords: ['comet', 'space'] },
      { code: 'U+1F525', emoji: '🔥', keywords: ['fire', 'flame', 'tool'] },
      { code: 'U+1F4A7', emoji: '💧', keywords: ['cold', 'comic', 'drop', 'sweat', 'weather'] },
      { code: 'U+1F30A', emoji: '🌊', keywords: ['ocean', 'water', 'wave', 'weather'] },
    ],
  },
  activities: {
    label: 'Activities',
    emojis: [
      {
        code: 'U+1F383',
        emoji: '🎃',
        keywords: ['activity', 'celebration', 'entertainment', 'halloween', 'jack', 'lantern'],
      },
      { code: 'U+1F384', emoji: '🎄', keywords: ['activity', 'celebration', 'christmas', 'entertainment', 'tree'] },
      { code: 'U+1F386', emoji: '🎆', keywords: ['activity', 'celebration', 'entertainment', 'fireworks'] },
      { code: 'U+1F387', emoji: '🎇', keywords: ['activity', 'celebration', 'entertainment', 'fireworks', 'sparkle'] },
      { code: 'U+2728', emoji: '✨', keywords: ['entertainment', 'sparkle', 'star'] },
      { code: 'U+1F388', emoji: '🎈', keywords: ['activity', 'balloon', 'celebration', 'entertainment'] },
      {
        code: 'U+1F389',
        emoji: '🎉',
        keywords: ['activity', 'celebration', 'entertainment', 'party', 'popper', 'tada'],
      },
      { code: 'U+1F38A', emoji: '🎊', keywords: ['activity', 'ball', 'celebration', 'confetti', 'entertainment'] },
      {
        code: 'U+1F38B',
        emoji: '🎋',
        keywords: ['activity', 'banner', 'celebration', 'entertainment', 'japanese', 'tree'],
      },
      { code: 'U+1F38D', emoji: '🎍', keywords: ['activity', 'bamboo', 'celebration', 'japanese', 'pine', 'plant'] },
      {
        code: 'U+1F38E',
        emoji: '🎎',
        keywords: ['activity', 'celebration', 'doll', 'entertainment', 'festival', 'japanese'],
      },
      {
        code: 'U+1F38F',
        emoji: '🎏',
        keywords: ['activity', 'carp', 'celebration', 'entertainment', 'flag', 'streamer'],
      },
      { code: 'U+1F390', emoji: '🎐', keywords: ['activity', 'bell', 'celebration', 'chime', 'entertainment', 'wind'] },
      { code: 'U+1F391', emoji: '🎑', keywords: ['activity', 'celebration', 'ceremony', 'entertainment', 'moon'] },
      { code: 'U+1F380', emoji: '🎀', keywords: ['celebration', 'ribbon'] },
      { code: 'U+1F381', emoji: '🎁', keywords: ['box', 'celebration', 'entertainment', 'gift', 'present', 'wrapped'] },
      { code: 'U+1F397', emoji: '🎗', keywords: ['celebration', 'reminder', 'ribbon'] },
      { code: 'U+1F39F', emoji: '🎟', keywords: ['admission', 'entertainment', 'ticket'] },
      { code: 'U+1F3AB', emoji: '🎫', keywords: ['activity', 'admission', 'entertainment', 'ticket'] },
      { code: 'U+1F396', emoji: '🎖', keywords: ['celebration', 'medal', 'military'] },
      { code: 'U+1F3C6', emoji: '🏆', keywords: ['prize', 'trophy'] },
      { code: 'U+1F3C5', emoji: '🏅', keywords: ['medal'] },
      { code: 'U+1F947', emoji: '🥇', keywords: ['first', 'gold', 'medal'] },
      { code: 'U+1F948', emoji: '🥈', keywords: ['medal', 'second', 'silver'] },
      { code: 'U+1F949', emoji: '🥉', keywords: ['bronze', 'medal', 'third'] },
      { code: 'U+26BD', emoji: '⚽', keywords: ['ball', 'soccer'] },
      { code: 'U+26BE', emoji: '⚾', keywords: ['ball', 'baseball'] },
      { code: 'U+1F3C0', emoji: '🏀', keywords: ['ball', 'basketball', 'hoop'] },
      { code: 'U+1F3D0', emoji: '🏐', keywords: ['ball', 'game', 'volleyball'] },
      { code: 'U+1F3C8', emoji: '🏈', keywords: ['american', 'ball', 'football'] },
      { code: 'U+1F3C9', emoji: '🏉', keywords: ['ball', 'football', 'rugby'] },
      { code: 'U+1F3BE', emoji: '🎾', keywords: ['ball', 'racquet', 'tennis'] },
      { code: 'U+1F3B1', emoji: '🎱', keywords: ['8', '8 ball', 'ball', 'billiard', 'eight', 'game'] },
      { code: 'U+1F3B3', emoji: '🎳', keywords: ['ball', 'bowling', 'game'] },
      { code: 'U+1F3CF', emoji: '🏏', keywords: ['ball', 'bat', 'cricket', 'game'] },
      { code: 'U+1F3D1', emoji: '🏑', keywords: ['ball', 'field', 'game', 'hockey', 'stick'] },
      { code: 'U+1F3D2', emoji: '🏒', keywords: ['game', 'hockey', 'ice', 'puck', 'stick'] },
      { code: 'U+1F3D3', emoji: '🏓', keywords: ['ball', 'bat', 'game', 'paddle', 'table tennis'] },
      { code: 'U+1F3F8', emoji: '🏸', keywords: ['badminton', 'birdie', 'game', 'racquet', 'shuttlecock'] },
      { code: 'U+1F94A', emoji: '🥊', keywords: ['boxing', 'glove'] },
      { code: 'U+1F94B', emoji: '🥋', keywords: ['judo', 'karate', 'martial arts', 'taekwondo', 'uniform'] },
      { code: 'U+26F3', emoji: '⛳', keywords: ['flag', 'golf', 'hole'] },
      { code: 'U+1F3CC', emoji: '🏌', keywords: ['ball', 'golf'] },
      { code: 'U+26F8', emoji: '⛸', keywords: ['ice', 'skate'] },
      { code: 'U+1F3A3', emoji: '🎣', keywords: ['entertainment', 'fish', 'pole'] },
      { code: 'U+1F3BD', emoji: '🎽', keywords: ['running', 'sash', 'shirt'] },
      { code: 'U+1F3BF', emoji: '🎿', keywords: ['ski', 'snow'] },
      { code: 'U+26F7', emoji: '⛷', keywords: ['ski', 'snow'] },
      { code: 'U+1F3C2', emoji: '🏂', keywords: ['ski', 'snow', 'snowboard'] },
      { code: 'U+1F3C4', emoji: '🏄', keywords: ['surfer', 'surfing'] },
      { code: 'U+1F3C7', emoji: '🏇', keywords: ['horse', 'jockey', 'racehorse', 'racing'] },
      { code: 'U+1F3CA', emoji: '🏊', keywords: ['swim', 'swimmer'] },
      { code: 'U+26F9', emoji: '⛹', keywords: ['ball'] },
      { code: 'U+1F3CB', emoji: '🏋', keywords: ['lifter', 'weight'] },
      { code: 'U+1F6B4', emoji: '🚴', keywords: ['bicycle', 'bicyclist', 'bike', 'cyclist'] },
      { code: 'U+1F6B5', emoji: '🚵', keywords: ['bicycle', 'bicyclist', 'bike', 'cyclist', 'mountain'] },
      { code: 'U+1F3CE', emoji: '🏎', keywords: ['car', 'racing'] },
      { code: 'U+1F3CD', emoji: '🏍', keywords: ['motorcycle', 'racing'] },
      { code: 'U+1F938', emoji: '🤸', keywords: ['cartwheel', 'gymnastics'] },
      { code: 'U+1F93C', emoji: '🤼', keywords: ['wrestle', 'wrestler'] },
      { code: 'U+1F93D', emoji: '🤽', keywords: ['polo', 'water'] },
      { code: 'U+1F93E', emoji: '🤾', keywords: ['ball', 'handball'] },
      { code: 'U+1F93A', emoji: '🤺', keywords: ['fencer', 'fencing', 'sword'] },
      { code: 'U+1F945', emoji: '🥅', keywords: ['goal', 'net'] },
      { code: 'U+1F939', emoji: '🤹', keywords: ['balance', 'juggle', 'multitask', 'skill'] },
      {
        code: 'U+1F3AF',
        emoji: '🎯',
        keywords: ['activity', 'bull', 'bullseye', 'dart', 'entertainment', 'eye', 'game', 'hit', 'target'],
      },
      { code: 'U+1F3AE', emoji: '🎮', keywords: ['controller', 'entertainment', 'game', 'video game'] },
      { code: 'U+1F579', emoji: '🕹', keywords: ['entertainment', 'game', 'joystick', 'video game'] },
      { code: 'U+1F3B2', emoji: '🎲', keywords: ['dice', 'die', 'entertainment', 'game'] },
      { code: 'U+2660', emoji: '♠', keywords: ['card', 'game', 'spade', 'suit'] },
      { code: 'U+2665', emoji: '♥', keywords: ['card', 'game', 'heart', 'hearts', 'suit'] },
      { code: 'U+2666', emoji: '♦', keywords: ['card', 'diamond', 'diamonds', 'game', 'suit'] },
      { code: 'U+2663', emoji: '♣', keywords: ['card', 'club', 'clubs', 'game', 'suit'] },
      { code: 'U+1F0CF', emoji: '🃏', keywords: ['card', 'entertainment', 'game', 'joker', 'playing'] },
      { code: 'U+1F004', emoji: '🀄', keywords: ['game', 'mahjong', 'red'] },
      {
        code: 'U+1F3B4',
        emoji: '🎴',
        keywords: ['activity', 'card', 'entertainment', 'flower', 'game', 'japanese', 'playing'],
      },
    ],
  },
  objects: {
    label: 'Objects',
    emojis: [
      { code: 'U+1F507', emoji: '🔇', keywords: ['mute', 'quiet', 'silent', 'speaker', 'volume'] },
      { code: 'U+1F508', emoji: '🔈', keywords: ['speaker', 'volume'] },
      { code: 'U+1F509', emoji: '🔉', keywords: ['low', 'speaker', 'volume', 'wave'] },
      { code: 'U+1F50A', emoji: '🔊', keywords: ['3', 'entertainment', 'high', 'loud', 'speaker', 'three', 'volume'] },
      { code: 'U+1F4E2', emoji: '📢', keywords: ['communication', 'loud', 'loudspeaker', 'public address'] },
      { code: 'U+1F4E3', emoji: '📣', keywords: ['cheering', 'communication', 'megaphone'] },
      { code: 'U+1F4EF', emoji: '📯', keywords: ['communication', 'entertainment', 'horn', 'post', 'postal'] },
      { code: 'U+1F514', emoji: '🔔', keywords: ['bell'] },
      {
        code: 'U+1F515',
        emoji: '🔕',
        keywords: ['bell', 'forbidden', 'mute', 'no', 'not', 'prohibited', 'quiet', 'silent'],
      },
      { code: 'U+1F3BC', emoji: '🎼', keywords: ['activity', 'entertainment', 'music', 'score'] },
      { code: 'U+1F3B5', emoji: '🎵', keywords: ['activity', 'entertainment', 'music', 'note'] },
      { code: 'U+1F3B6', emoji: '🎶', keywords: ['activity', 'entertainment', 'music', 'note', 'notes'] },
      { code: 'U+1F399', emoji: '🎙', keywords: ['mic', 'microphone', 'music', 'studio'] },
      { code: 'U+1F39A', emoji: '🎚', keywords: ['level', 'music', 'slider'] },
      { code: 'U+1F39B', emoji: '🎛', keywords: ['control', 'knobs', 'music'] },
      { code: 'U+1F3A4', emoji: '🎤', keywords: ['activity', 'entertainment', 'karaoke', 'mic', 'microphone'] },
      { code: 'U+1F3A7', emoji: '🎧', keywords: ['activity', 'earbud', 'entertainment', 'headphone'] },
      { code: 'U+1F4FB', emoji: '📻', keywords: ['entertainment', 'radio', 'video'] },
      {
        code: 'U+1F3B7',
        emoji: '🎷',
        keywords: ['activity', 'entertainment', 'instrument', 'music', 'sax', 'saxophone'],
      },
      { code: 'U+1F3B8', emoji: '🎸', keywords: ['activity', 'entertainment', 'guitar', 'instrument', 'music'] },
      {
        code: 'U+1F3B9',
        emoji: '🎹',
        keywords: ['activity', 'entertainment', 'instrument', 'keyboard', 'music', 'piano'],
      },
      { code: 'U+1F3BA', emoji: '🎺', keywords: ['activity', 'entertainment', 'instrument', 'music', 'trumpet'] },
      { code: 'U+1F3BB', emoji: '🎻', keywords: ['activity', 'entertainment', 'instrument', 'music', 'violin'] },
      { code: 'U+1F941', emoji: '🥁', keywords: ['drum', 'drumsticks', 'music'] },
      { code: 'U+1F4F1', emoji: '📱', keywords: ['cell', 'communication', 'mobile', 'phone', 'telephone'] },
      {
        code: 'U+1F4F2',
        emoji: '📲',
        keywords: ['arrow', 'call', 'cell', 'communication', 'mobile', 'phone', 'receive', 'telephone'],
      },
      { code: 'U+260E', emoji: '☎', keywords: ['phone', 'telephone'] },
      { code: 'U+1F4DE', emoji: '📞', keywords: ['communication', 'phone', 'receiver', 'telephone'] },
      { code: 'U+1F4DF', emoji: '📟', keywords: ['communication', 'pager'] },
      { code: 'U+1F4E0', emoji: '📠', keywords: ['communication', 'fax'] },
      { code: 'U+1F50B', emoji: '🔋', keywords: ['battery'] },
      { code: 'U+1F50C', emoji: '🔌', keywords: ['electric', 'electricity', 'plug'] },
      { code: 'U+1F4BB', emoji: '💻', keywords: ['computer', 'pc', 'personal'] },
      { code: 'U+1F5A5', emoji: '🖥', keywords: ['computer', 'desktop'] },
      { code: 'U+1F5A8', emoji: '🖨', keywords: ['computer', 'printer'] },
      { code: 'U+2328', emoji: '⌨', keywords: ['computer', 'keyboard'] },
      { code: 'U+1F5B1', emoji: '🖱', keywords: ['3', 'button', 'computer', 'mouse', 'three'] },
      { code: 'U+1F5B2', emoji: '🖲', keywords: ['computer', 'trackball'] },
      { code: 'U+1F4BD', emoji: '💽', keywords: ['computer', 'disk', 'entertainment', 'minidisk', 'optical'] },
      { code: 'U+1F4BE', emoji: '💾', keywords: ['computer', 'disk', 'floppy'] },
      { code: 'U+1F4BF', emoji: '💿', keywords: ['blu-ray', 'cd', 'computer', 'disk', 'dvd', 'optical'] },
      {
        code: 'U+1F4C0',
        emoji: '📀',
        keywords: ['blu-ray', 'cd', 'computer', 'disk', 'dvd', 'entertainment', 'optical'],
      },
      { code: 'U+1F3A5', emoji: '🎥', keywords: ['activity', 'camera', 'cinema', 'entertainment', 'movie'] },
      { code: 'U+1F39E', emoji: '🎞', keywords: ['cinema', 'entertainment', 'film', 'frames', 'movie'] },
      { code: 'U+1F4FD', emoji: '📽', keywords: ['cinema', 'entertainment', 'film', 'movie', 'projector', 'video'] },
      { code: 'U+1F3AC', emoji: '🎬', keywords: ['activity', 'clapper', 'entertainment', 'movie'] },
      { code: 'U+1F4FA', emoji: '📺', keywords: ['entertainment', 'television', 'tv', 'video'] },
      { code: 'U+1F4F7', emoji: '📷', keywords: ['camera', 'entertainment', 'video'] },
      { code: 'U+1F4F8', emoji: '📸', keywords: ['camera', 'flash', 'video'] },
      { code: 'U+1F4F9', emoji: '📹', keywords: ['camera', 'entertainment', 'video'] },
      { code: 'U+1F4FC', emoji: '📼', keywords: ['entertainment', 'tape', 'vhs', 'video', 'videocassette'] },
      { code: 'U+1F50D', emoji: '🔍', keywords: ['glass', 'magnifying', 'search', 'tool'] },
      { code: 'U+1F50E', emoji: '🔎', keywords: ['glass', 'magnifying', 'search', 'tool'] },
      { code: 'U+1F52C', emoji: '🔬', keywords: ['microscope', 'tool'] },
      { code: 'U+1F52D', emoji: '🔭', keywords: ['telescope', 'tool'] },
      { code: 'U+1F4E1', emoji: '📡', keywords: ['antenna', 'communication', 'dish', 'satellite'] },
      { code: 'U+1F56F', emoji: '🕯', keywords: ['candle', 'light'] },
      { code: 'U+1F4A1', emoji: '💡', keywords: ['bulb', 'comic', 'electric', 'idea', 'light'] },
      { code: 'U+1F526', emoji: '🔦', keywords: ['electric', 'flashlight', 'light', 'tool', 'torch'] },
      { code: 'U+1F3EE', emoji: '🏮', keywords: ['bar', 'japanese', 'lantern', 'light', 'red'] },
      { code: 'U+1F4D4', emoji: '📔', keywords: ['book', 'cover', 'decorated', 'notebook'] },
      { code: 'U+1F4D5', emoji: '📕', keywords: ['book', 'closed'] },
      { code: 'U+1F4D6', emoji: '📖', keywords: ['book', 'open'] },
      { code: 'U+1F4D7', emoji: '📗', keywords: ['book', 'green'] },
      { code: 'U+1F4D8', emoji: '📘', keywords: ['blue', 'book'] },
      { code: 'U+1F4D9', emoji: '📙', keywords: ['book', 'orange'] },
      { code: 'U+1F4DA', emoji: '📚', keywords: ['book', 'books'] },
      { code: 'U+1F4D3', emoji: '📓', keywords: ['notebook'] },
      { code: 'U+1F4D2', emoji: '📒', keywords: ['ledger', 'notebook'] },
      { code: 'U+1F4C3', emoji: '📃', keywords: ['curl', 'document', 'page'] },
      { code: 'U+1F4DC', emoji: '📜', keywords: ['paper', 'scroll'] },
      { code: 'U+1F4C4', emoji: '📄', keywords: ['document', 'page'] },
      { code: 'U+1F4F0', emoji: '📰', keywords: ['communication', 'news', 'newspaper', 'paper'] },
      { code: 'U+1F5DE', emoji: '🗞', keywords: ['news', 'newspaper', 'paper', 'rolled'] },
      { code: 'U+1F4D1', emoji: '📑', keywords: ['bookmark', 'mark', 'marker', 'tabs'] },
      { code: 'U+1F516', emoji: '🔖', keywords: ['bookmark', 'mark'] },
      { code: 'U+1F3F7', emoji: '🏷', keywords: ['label'] },
      { code: 'U+1F4B0', emoji: '💰', keywords: ['bag', 'dollar', 'money', 'moneybag'] },
      { code: 'U+1F4B4', emoji: '💴', keywords: ['bank', 'banknote', 'bill', 'currency', 'money', 'note', 'yen'] },
      { code: 'U+1F4B5', emoji: '💵', keywords: ['bank', 'banknote', 'bill', 'currency', 'dollar', 'money', 'note'] },
      { code: 'U+1F4B6', emoji: '💶', keywords: ['bank', 'banknote', 'bill', 'currency', 'euro', 'money', 'note'] },
      { code: 'U+1F4B7', emoji: '💷', keywords: ['bank', 'banknote', 'bill', 'currency', 'money', 'note', 'pound'] },
      {
        code: 'U+1F4B8',
        emoji: '💸',
        keywords: ['bank', 'banknote', 'bill', 'dollar', 'fly', 'money', 'note', 'wings'],
      },
      { code: 'U+1F4B3', emoji: '💳', keywords: ['bank', 'card', 'credit', 'money'] },
      {
        code: 'U+1F4B9',
        emoji: '💹',
        keywords: ['bank', 'chart', 'currency', 'graph', 'growth', 'market', 'money', 'rise', 'trend', 'upward', 'yen'],
      },
      { code: 'U+1F4B1', emoji: '💱', keywords: ['bank', 'currency', 'exchange', 'money'] },
      { code: 'U+1F4B2', emoji: '💲', keywords: ['currency', 'dollar', 'money'] },
      { code: 'U+2709', emoji: '✉', keywords: ['e-mail', 'email', 'envelope'] },
      { code: 'U+1F4E7', emoji: '📧', keywords: ['communication', 'e-mail', 'email', 'letter', 'mail'] },
      {
        code: 'U+1F4E8',
        emoji: '📨',
        keywords: ['communication', 'e-mail', 'email', 'envelope', 'incoming', 'letter', 'mail', 'receive'],
      },
      {
        code: 'U+1F4E9',
        emoji: '📩',
        keywords: [
          'arrow',
          'communication',
          'down',
          'e-mail',
          'email',
          'envelope',
          'letter',
          'mail',
          'outgoing',
          'sent',
        ],
      },
      { code: 'U+1F4E4', emoji: '📤', keywords: ['box', 'communication', 'letter', 'mail', 'outbox', 'sent', 'tray'] },
      {
        code: 'U+1F4E5',
        emoji: '📥',
        keywords: ['box', 'communication', 'inbox', 'letter', 'mail', 'receive', 'tray'],
      },
      { code: 'U+1F4E6', emoji: '📦', keywords: ['box', 'communication', 'package', 'parcel'] },
      { code: 'U+1F4EB', emoji: '📫', keywords: ['closed', 'communication', 'flag', 'mail', 'mailbox', 'postbox'] },
      {
        code: 'U+1F4EA',
        emoji: '📪',
        keywords: ['closed', 'communication', 'flag', 'lowered', 'mail', 'mailbox', 'postbox'],
      },
      { code: 'U+1F4EC', emoji: '📬', keywords: ['communication', 'flag', 'mail', 'mailbox', 'open', 'postbox'] },
      {
        code: 'U+1F4ED',
        emoji: '📭',
        keywords: ['communication', 'flag', 'lowered', 'mail', 'mailbox', 'open', 'postbox'],
      },
      { code: 'U+1F4EE', emoji: '📮', keywords: ['communication', 'mail', 'mailbox', 'postbox'] },
      { code: 'U+1F5F3', emoji: '🗳', keywords: ['ballot', 'box'] },
      { code: 'U+270F', emoji: '✏', keywords: ['pencil'] },
      { code: 'U+2712', emoji: '✒', keywords: ['nib', 'pen'] },
      { code: 'U+1F58B', emoji: '🖋', keywords: ['communication', 'fountain', 'pen'] },
      { code: 'U+1F58A', emoji: '🖊', keywords: ['ballpoint', 'communication', 'pen'] },
      { code: 'U+1F58C', emoji: '🖌', keywords: ['communication', 'paintbrush', 'painting'] },
      { code: 'U+1F58D', emoji: '🖍', keywords: ['communication', 'crayon'] },
      { code: 'U+1F4DD', emoji: '📝', keywords: ['communication', 'memo', 'pencil'] },
      { code: 'U+1F4BC', emoji: '💼', keywords: ['briefcase'] },
      { code: 'U+1F4C1', emoji: '📁', keywords: ['file', 'folder'] },
      { code: 'U+1F4C2', emoji: '📂', keywords: ['file', 'folder', 'open'] },
      { code: 'U+1F5C2', emoji: '🗂', keywords: ['card', 'dividers', 'index'] },
      { code: 'U+1F4C5', emoji: '📅', keywords: ['calendar', 'date'] },
      { code: 'U+1F4C6', emoji: '📆', keywords: ['calendar'] },
      { code: 'U+1F5D2', emoji: '🗒', keywords: ['note', 'pad', 'spiral'] },
      { code: 'U+1F5D3', emoji: '🗓', keywords: ['calendar', 'pad', 'spiral'] },
      { code: 'U+1F4C7', emoji: '📇', keywords: ['card', 'index', 'rolodex'] },
      { code: 'U+1F4C8', emoji: '📈', keywords: ['chart', 'graph', 'growth', 'trend', 'upward'] },
      { code: 'U+1F4C9', emoji: '📉', keywords: ['chart', 'down', 'graph', 'trend'] },
      { code: 'U+1F4CA', emoji: '📊', keywords: ['bar', 'chart', 'graph'] },
      { code: 'U+1F4CB', emoji: '📋', keywords: ['clipboard'] },
      { code: 'U+1F4CC', emoji: '📌', keywords: ['pin', 'pushpin'] },
      { code: 'U+1F4CD', emoji: '📍', keywords: ['pin', 'pushpin'] },
      { code: 'U+1F4CE', emoji: '📎', keywords: ['paperclip'] },
      { code: 'U+1F587', emoji: '🖇', keywords: ['communication', 'link', 'paperclip'] },
      { code: 'U+1F4CF', emoji: '📏', keywords: ['ruler', 'straight edge'] },
      { code: 'U+1F4D0', emoji: '📐', keywords: ['ruler', 'set', 'triangle'] },
      { code: 'U+2702', emoji: '✂', keywords: ['scissors', 'tool'] },
      { code: 'U+1F5C3', emoji: '🗃', keywords: ['box', 'card', 'file'] },
      { code: 'U+1F5C4', emoji: '🗄', keywords: ['cabinet', 'file'] },
      { code: 'U+1F5D1', emoji: '🗑', keywords: ['wastebasket'] },
      { code: 'U+1F512', emoji: '🔒', keywords: ['closed', 'lock'] },
      { code: 'U+1F513', emoji: '🔓', keywords: ['lock', 'open', 'unlock'] },
      { code: 'U+1F50F', emoji: '🔏', keywords: ['ink', 'lock', 'nib', 'pen', 'privacy'] },
      { code: 'U+1F510', emoji: '🔐', keywords: ['closed', 'key', 'lock', 'secure'] },
      { code: 'U+1F511', emoji: '🔑', keywords: ['key', 'lock', 'password'] },
      { code: 'U+1F5DD', emoji: '🗝', keywords: ['clue', 'key', 'lock', 'old'] },
      { code: 'U+1F528', emoji: '🔨', keywords: ['hammer', 'tool'] },
      { code: 'U+26CF', emoji: '⛏', keywords: ['mining', 'pick', 'tool'] },
      { code: 'U+2692', emoji: '⚒', keywords: ['hammer', 'pick', 'tool'] },
      { code: 'U+1F6E0', emoji: '🛠', keywords: ['hammer', 'tool', 'wrench'] },
      { code: 'U+1F5E1', emoji: '🗡', keywords: ['dagger', 'knife', 'weapon'] },
      { code: 'U+2694', emoji: '⚔', keywords: ['crossed', 'swords', 'weapon'] },
      { code: 'U+1F52B', emoji: '🔫', keywords: ['gun', 'handgun', 'pistol', 'revolver', 'tool', 'weapon'] },
      { code: 'U+1F3F9', emoji: '🏹', keywords: ['archer', 'arrow', 'bow', 'sagittarius', 'tool', 'weapon', 'zodiac'] },
      { code: 'U+1F6E1', emoji: '🛡', keywords: ['shield', 'weapon'] },
      { code: 'U+1F527', emoji: '🔧', keywords: ['tool', 'wrench'] },
      { code: 'U+1F529', emoji: '🔩', keywords: ['bolt', 'nut', 'tool'] },
      { code: 'U+2699', emoji: '⚙', keywords: ['gear', 'tool'] },
      { code: 'U+1F5DC', emoji: '🗜', keywords: ['compression', 'tool', 'vice'] },
      { code: 'U+2697', emoji: '⚗', keywords: ['alembic', 'chemistry', 'tool'] },
      { code: 'U+2696', emoji: '⚖', keywords: ['balance', 'justice', 'libra', 'scales', 'tool', 'weight', 'zodiac'] },
      { code: 'U+1F517', emoji: '🔗', keywords: ['link'] },
      { code: 'U+26D3', emoji: '⛓', keywords: ['chain'] },
      { code: 'U+1F489', emoji: '💉', keywords: ['doctor', 'medicine', 'needle', 'shot', 'sick', 'syringe', 'tool'] },
      { code: 'U+1F48A', emoji: '💊', keywords: ['doctor', 'medicine', 'pill', 'sick'] },
      { code: 'U+1F6AC', emoji: '🚬', keywords: ['activity', 'smoking'] },
      { code: 'U+26B0', emoji: '⚰', keywords: ['coffin', 'death'] },
      { code: 'U+26B1', emoji: '⚱', keywords: ['death', 'funeral', 'urn'] },
      { code: 'U+1F5FF', emoji: '🗿', keywords: ['face', 'moyai', 'statue'] },
      { code: 'U+1F6E2', emoji: '🛢', keywords: ['drum', 'oil'] },
      { code: 'U+1F52E', emoji: '🔮', keywords: ['ball', 'crystal', 'fairy tale', 'fantasy', 'fortune', 'tool'] },
      { code: 'U+1F6D2', emoji: '🛒', keywords: ['cart', 'shopping', 'trolley'] },
    ],
  },
  symbols: {
    label: 'Symbols',
    emojis: [
      { code: 'U+1F3E7', emoji: '🏧', keywords: ['atm', 'automated', 'bank', 'teller'] },
      { code: 'U+1F6AE', emoji: '🚮', keywords: ['litter', 'litterbox'] },
      { code: 'U+1F6B0', emoji: '🚰', keywords: ['drink', 'potable', 'water'] },
      { code: 'U+267F', emoji: '♿', keywords: ['access', 'wheelchair'] },
      { code: 'U+1F6B9', emoji: '🚹', keywords: ['lavatory', 'man', 'restroom', 'wc'] },
      { code: 'U+1F6BA', emoji: '🚺', keywords: ['lavatory', 'restroom', 'wc', 'woman'] },
      { code: 'U+1F6BB', emoji: '🚻', keywords: ['lavatory', 'restroom', 'wc'] },
      { code: 'U+1F6BC', emoji: '🚼', keywords: ['baby', 'changing'] },
      { code: 'U+1F6BE', emoji: '🚾', keywords: ['closet', 'lavatory', 'restroom', 'water', 'wc'] },
      { code: 'U+1F6C2', emoji: '🛂', keywords: ['control', 'passport'] },
      { code: 'U+1F6C3', emoji: '🛃', keywords: ['customs'] },
      { code: 'U+1F6C4', emoji: '🛄', keywords: ['baggage', 'claim'] },
      { code: 'U+1F6C5', emoji: '🛅', keywords: ['baggage', 'left luggage', 'locker', 'luggage'] },
      { code: 'U+26A0', emoji: '⚠', keywords: ['warning'] },
      { code: 'U+1F6B8', emoji: '🚸', keywords: ['child', 'crossing', 'pedestrian', 'traffic'] },
      { code: 'U+26D4', emoji: '⛔', keywords: ['entry', 'forbidden', 'no', 'not', 'prohibited', 'traffic'] },
      { code: 'U+1F6AB', emoji: '🚫', keywords: ['entry', 'forbidden', 'no', 'not', 'prohibited'] },
      {
        code: 'U+1F6B3',
        emoji: '🚳',
        keywords: ['bicycle', 'bike', 'forbidden', 'no', 'not', 'prohibited', 'vehicle'],
      },
      { code: 'U+1F6AD', emoji: '🚭', keywords: ['forbidden', 'no', 'not', 'prohibited', 'smoking'] },
      { code: 'U+1F6AF', emoji: '🚯', keywords: ['forbidden', 'litter', 'no', 'not', 'prohibited'] },
      { code: 'U+1F6B1', emoji: '🚱', keywords: ['drink', 'forbidden', 'no', 'not', 'potable', 'prohibited', 'water'] },
      { code: 'U+1F6B7', emoji: '🚷', keywords: ['forbidden', 'no', 'not', 'pedestrian', 'prohibited'] },
      {
        code: 'U+1F4F5',
        emoji: '📵',
        keywords: ['cell', 'communication', 'forbidden', 'mobile', 'no', 'not', 'phone', 'prohibited', 'telephone'],
      },
      {
        code: 'U+1F51E',
        emoji: '🔞',
        keywords: ['18', 'age restriction', 'eighteen', 'forbidden', 'no', 'not', 'prohibited', 'underage'],
      },
      { code: 'U+2622', emoji: '☢', keywords: ['radioactive'] },
      { code: 'U+2623', emoji: '☣', keywords: ['biohazard'] },
      { code: 'U+2B06', emoji: '⬆', keywords: ['arrow', 'cardinal', 'direction', 'north'] },
      { code: 'U+2197', emoji: '↗', keywords: ['arrow', 'direction', 'intercardinal', 'northeast'] },
      { code: 'U+27A1', emoji: '➡', keywords: ['arrow', 'cardinal', 'direction', 'east'] },
      { code: 'U+2198', emoji: '↘', keywords: ['arrow', 'direction', 'intercardinal', 'southeast'] },
      { code: 'U+2B07', emoji: '⬇', keywords: ['arrow', 'cardinal', 'direction', 'down', 'south'] },
      { code: 'U+2199', emoji: '↙', keywords: ['arrow', 'direction', 'intercardinal', 'southwest'] },
      { code: 'U+2B05', emoji: '⬅', keywords: ['arrow', 'cardinal', 'direction', 'west'] },
      { code: 'U+2196', emoji: '↖', keywords: ['arrow', 'direction', 'intercardinal', 'northwest'] },
      { code: 'U+2195', emoji: '↕', keywords: ['arrow'] },
      { code: 'U+2194', emoji: '↔', keywords: ['arrow'] },
      { code: 'U+21A9', emoji: '↩', keywords: ['arrow'] },
      { code: 'U+21AA', emoji: '↪', keywords: ['arrow'] },
      { code: 'U+2934', emoji: '⤴', keywords: ['arrow'] },
      { code: 'U+2935', emoji: '⤵', keywords: ['arrow', 'down'] },
      { code: 'U+1F503', emoji: '🔃', keywords: ['arrow', 'clockwise', 'reload'] },
      { code: 'U+1F504', emoji: '🔄', keywords: ['anticlockwise', 'arrow', 'counterclockwise', 'withershins'] },
      { code: 'U+1F519', emoji: '🔙', keywords: ['arrow', 'back'] },
      { code: 'U+1F51A', emoji: '🔚', keywords: ['arrow', 'end'] },
      { code: 'U+1F51B', emoji: '🔛', keywords: ['arrow', 'mark', 'on'] },
      { code: 'U+1F51C', emoji: '🔜', keywords: ['arrow', 'soon'] },
      { code: 'U+1F51D', emoji: '🔝', keywords: ['arrow', 'top', 'up'] },
      { code: 'U+1F6D0', emoji: '🛐', keywords: ['religion', 'worship'] },
      { code: 'U+269B', emoji: '⚛', keywords: ['atheist', 'atom'] },
      { code: 'U+1F549', emoji: '🕉', keywords: ['hindu', 'om', 'religion'] },
      { code: 'U+2721', emoji: '✡', keywords: ['david', 'jew', 'jewish', 'religion', 'star'] },
      { code: 'U+2638', emoji: '☸', keywords: ['buddhist', 'dharma', 'religion', 'wheel'] },
      { code: 'U+262F', emoji: '☯', keywords: ['religion', 'tao', 'taoist', 'yang', 'yin'] },
      { code: 'U+271D', emoji: '✝', keywords: ['christian', 'cross', 'religion'] },
      { code: 'U+2626', emoji: '☦', keywords: ['christian', 'cross', 'religion'] },
      { code: 'U+262A', emoji: '☪', keywords: ['islam', 'muslim', 'religion'] },
      { code: 'U+262E', emoji: '☮', keywords: ['peace'] },
      { code: 'U+1F54E', emoji: '🕎', keywords: ['candelabrum', 'candlestick', 'menorah', 'religion'] },
      { code: 'U+1F52F', emoji: '🔯', keywords: ['fortune', 'star'] },
      { code: 'U+267B', emoji: '♻', keywords: ['recycle'] },
      { code: 'U+1F4DB', emoji: '📛', keywords: ['badge', 'name'] },
      { code: 'U+269C', emoji: '⚜', keywords: ['fleur-de-lis'] },
      {
        code: 'U+1F530',
        emoji: '🔰',
        keywords: ['beginner', 'chevron', 'green', 'japanese', 'leaf', 'tool', 'yellow'],
      },
      { code: 'U+1F531', emoji: '🔱', keywords: ['anchor', 'emblem', 'ship', 'tool', 'trident'] },
      { code: 'U+2B55', emoji: '⭕', keywords: ['circle', 'o'] },
      { code: 'U+2705', emoji: '✅', keywords: ['check', 'mark'] },
      { code: 'U+2611', emoji: '☑', keywords: ['ballot', 'box', 'check'] },
      { code: 'U+2714', emoji: '✔', keywords: ['check', 'mark'] },
      { code: 'U+2716', emoji: '✖', keywords: ['cancel', 'multiplication', 'multiply', 'x'] },
      { code: 'U+274C', emoji: '❌', keywords: ['cancel', 'mark', 'multiplication', 'multiply', 'x'] },
      { code: 'U+274E', emoji: '❎', keywords: ['mark', 'square'] },
      { code: 'U+2795', emoji: '➕', keywords: ['math', 'plus'] },
      { code: 'U+2796', emoji: '➖', keywords: ['math', 'minus'] },
      { code: 'U+2797', emoji: '➗', keywords: ['division', 'math'] },
      { code: 'U+27B0', emoji: '➰', keywords: ['curl', 'loop'] },
      { code: 'U+27BF', emoji: '➿', keywords: ['curl', 'double', 'loop'] },
      { code: 'U+303D', emoji: '〽', keywords: ['mark', 'part'] },
      { code: 'U+2733', emoji: '✳', keywords: ['asterisk'] },
      { code: 'U+2734', emoji: '✴', keywords: ['star'] },
      { code: 'U+2747', emoji: '❇', keywords: ['sparkle'] },
      { code: 'U+203C', emoji: '‼', keywords: ['bangbang', 'exclamation', 'mark', 'punctuation'] },
      { code: 'U+2049', emoji: '⁉', keywords: ['exclamation', 'interrobang', 'mark', 'punctuation', 'question'] },
      { code: 'U+2753', emoji: '❓', keywords: ['mark', 'punctuation', 'question'] },
      { code: 'U+2754', emoji: '❔', keywords: ['mark', 'outlined', 'punctuation', 'question'] },
      { code: 'U+2755', emoji: '❕', keywords: ['exclamation', 'mark', 'outlined', 'punctuation'] },
      { code: 'U+2757', emoji: '❗', keywords: ['exclamation', 'mark', 'punctuation'] },
      { code: 'U+3030', emoji: '〰', keywords: ['dash', 'punctuation', 'wavy'] },
      { code: 'U+00A9', emoji: '©', keywords: ['copyright'] },
      { code: 'U+00AE', emoji: '®', keywords: ['registered'] },
      { code: 'U+2122', emoji: '™', keywords: ['mark', 'tm', 'trademark'] },
      { code: 'U+2648', emoji: '♈', keywords: ['aries', 'ram', 'zodiac'] },
      { code: 'U+2649', emoji: '♉', keywords: ['bull', 'ox', 'taurus', 'zodiac'] },
      { code: 'U+264A', emoji: '♊', keywords: ['gemini', 'twins', 'zodiac'] },
      { code: 'U+264B', emoji: '♋', keywords: ['cancer', 'crab', 'zodiac'] },
      { code: 'U+264C', emoji: '♌', keywords: ['leo', 'lion', 'zodiac'] },
      { code: 'U+264D', emoji: '♍', keywords: ['maiden', 'virgin', 'virgo', 'zodiac'] },
      { code: 'U+264E', emoji: '♎', keywords: ['balance', 'justice', 'libra', 'scales', 'zodiac'] },
      { code: 'U+264F', emoji: '♏', keywords: ['scorpio', 'scorpion', 'scorpius', 'zodiac'] },
      { code: 'U+2650', emoji: '♐', keywords: ['archer', 'sagittarius', 'zodiac'] },
      { code: 'U+2651', emoji: '♑', keywords: ['capricorn', 'goat', 'zodiac'] },
      { code: 'U+2652', emoji: '♒', keywords: ['aquarius', 'bearer', 'water', 'zodiac'] },
      { code: 'U+2653', emoji: '♓', keywords: ['fish', 'pisces', 'zodiac'] },
      { code: 'U+26CE', emoji: '⛎', keywords: ['bearer', 'ophiuchus', 'serpent', 'snake', 'zodiac'] },
      { code: 'U+1F500', emoji: '🔀', keywords: ['arrow', 'crossed'] },
      { code: 'U+1F501', emoji: '🔁', keywords: ['arrow', 'clockwise', 'repeat'] },
      { code: 'U+1F502', emoji: '🔂', keywords: ['arrow', 'clockwise', 'once'] },
      { code: 'U+25B6', emoji: '▶', keywords: ['arrow', 'play', 'right', 'triangle'] },
      { code: 'U+23E9', emoji: '⏩', keywords: ['arrow', 'double', 'fast', 'forward'] },
      { code: 'U+23ED', emoji: '⏭', keywords: ['arrow', 'next scene', 'next track', 'triangle'] },
      { code: 'U+23EF', emoji: '⏯', keywords: ['arrow', 'pause', 'play', 'right', 'triangle'] },
      { code: 'U+25C0', emoji: '◀', keywords: ['arrow', 'left', 'reverse', 'triangle'] },
      { code: 'U+23EA', emoji: '⏪', keywords: ['arrow', 'double', 'rewind'] },
      { code: 'U+23EE', emoji: '⏮', keywords: ['arrow', 'previous scene', 'previous track', 'triangle'] },
      { code: 'U+1F53C', emoji: '🔼', keywords: ['arrow', 'button', 'red'] },
      { code: 'U+23EB', emoji: '⏫', keywords: ['arrow', 'double'] },
      { code: 'U+1F53D', emoji: '🔽', keywords: ['arrow', 'button', 'down', 'red'] },
      { code: 'U+23EC', emoji: '⏬', keywords: ['arrow', 'double', 'down'] },
      { code: 'U+23F8', emoji: '⏸', keywords: ['bar', 'double', 'pause', 'vertical'] },
      { code: 'U+23F9', emoji: '⏹', keywords: ['square', 'stop'] },
      { code: 'U+23FA', emoji: '⏺', keywords: ['circle', 'record'] },
      { code: 'U+23CF', emoji: '⏏', keywords: ['eject'] },
      { code: 'U+1F3A6', emoji: '🎦', keywords: ['activity', 'camera', 'cinema', 'entertainment', 'film', 'movie'] },
      { code: 'U+1F505', emoji: '🔅', keywords: ['brightness', 'dim', 'low'] },
      { code: 'U+1F506', emoji: '🔆', keywords: ['bright', 'brightness'] },
      {
        code: 'U+1F4F6',
        emoji: '📶',
        keywords: ['antenna', 'bar', 'cell', 'communication', 'mobile', 'phone', 'signal', 'telephone'],
      },
      {
        code: 'U+1F4F3',
        emoji: '📳',
        keywords: ['cell', 'communication', 'mobile', 'mode', 'phone', 'telephone', 'vibration'],
      },
      { code: 'U+1F4F4', emoji: '📴', keywords: ['cell', 'communication', 'mobile', 'off', 'phone', 'telephone'] },
      { code: 'U+0023 U+FE0F U+20E3', emoji: '#️⃣', keywords: ['hash', 'keycap', 'pound'] },
      { code: 'U+002A U+FE0F U+20E3', emoji: '*️⃣', keywords: ['asterisk', 'keycap', 'star'] },
      { code: 'U+0030 U+FE0F U+20E3', emoji: '0️⃣', keywords: ['0', 'keycap', 'zero'] },
      { code: 'U+0031 U+FE0F U+20E3', emoji: '1️⃣', keywords: ['1', 'keycap', 'one'] },
      { code: 'U+0032 U+FE0F U+20E3', emoji: '2️⃣', keywords: ['2', 'keycap', 'two'] },
      { code: 'U+0033 U+FE0F U+20E3', emoji: '3️⃣', keywords: ['3', 'keycap', 'three'] },
      { code: 'U+0034 U+FE0F U+20E3', emoji: '4️⃣', keywords: ['4', 'four', 'keycap'] },
      { code: 'U+0035 U+FE0F U+20E3', emoji: '5️⃣', keywords: ['5', 'five', 'keycap'] },
      { code: 'U+0036 U+FE0F U+20E3', emoji: '6️⃣', keywords: ['6', 'keycap', 'six'] },
      { code: 'U+0037 U+FE0F U+20E3', emoji: '7️⃣', keywords: ['7', 'keycap', 'seven'] },
      { code: 'U+0038 U+FE0F U+20E3', emoji: '8️⃣', keywords: ['8', 'eight', 'keycap'] },
      { code: 'U+0039 U+FE0F U+20E3', emoji: '9️⃣', keywords: ['9', 'keycap', 'nine'] },
      { code: 'U+1F51F', emoji: '🔟', keywords: ['10', 'keycap', 'ten'] },
      { code: 'U+1F4AF', emoji: '💯', keywords: ['100', 'full', 'hundred', 'score'] },
      { code: 'U+1F520', emoji: '🔠', keywords: ['input', 'latin', 'letters', 'uppercase'] },
      { code: 'U+1F521', emoji: '🔡', keywords: ['abcd', 'input', 'latin', 'letters', 'lowercase'] },
      { code: 'U+1F522', emoji: '🔢', keywords: ['1234', 'input', 'numbers'] },
      { code: 'U+1F523', emoji: '🔣', keywords: ['input'] },
      { code: 'U+1F524', emoji: '🔤', keywords: ['abc', 'alphabet', 'input', 'latin', 'letters'] },
      { code: 'U+1F170', emoji: '🅰', keywords: ['a', 'blood'] },
      { code: 'U+1F18E', emoji: '🆎', keywords: ['ab', 'blood'] },
      { code: 'U+1F171', emoji: '🅱', keywords: ['b', 'blood'] },
      { code: 'U+1F191', emoji: '🆑', keywords: ['cl'] },
      { code: 'U+1F192', emoji: '🆒', keywords: ['cool'] },
      { code: 'U+1F193', emoji: '🆓', keywords: ['free'] },
      { code: 'U+2139', emoji: 'ℹ', keywords: ['i', 'information'] },
      { code: 'U+1F194', emoji: '🆔', keywords: ['id', 'identity'] },
      { code: 'U+24C2', emoji: 'Ⓜ', keywords: ['circle', 'm'] },
      { code: 'U+1F195', emoji: '🆕', keywords: ['new'] },
      { code: 'U+1F196', emoji: '🆖', keywords: ['ng'] },
      { code: 'U+1F17E', emoji: '🅾', keywords: ['blood', 'o'] },
      { code: 'U+1F197', emoji: '🆗', keywords: ['ok'] },
      { code: 'U+1F17F', emoji: '🅿', keywords: ['parking'] },
      { code: 'U+1F198', emoji: '🆘', keywords: ['help', 'sos'] },
      { code: 'U+1F199', emoji: '🆙', keywords: ['mark', 'up'] },
      { code: 'U+1F19A', emoji: '🆚', keywords: ['versus', 'vs'] },
      { code: 'U+1F201', emoji: '🈁', keywords: ['japanese'] },
      { code: 'U+1F202', emoji: '🈂', keywords: ['japanese'] },
      { code: 'U+1F237', emoji: '🈷', keywords: ['japanese'] },
      { code: 'U+1F236', emoji: '🈶', keywords: ['japanese'] },
      { code: 'U+1F22F', emoji: '🈯', keywords: ['japanese'] },
      { code: 'U+1F250', emoji: '🉐', keywords: ['japanese'] },
      { code: 'U+1F239', emoji: '🈹', keywords: ['japanese'] },
      { code: 'U+1F21A', emoji: '🈚', keywords: ['japanese'] },
      { code: 'U+1F232', emoji: '🈲', keywords: ['japanese'] },
      { code: 'U+1F251', emoji: '🉑', keywords: ['chinese'] },
      { code: 'U+1F238', emoji: '🈸', keywords: ['chinese'] },
      { code: 'U+1F234', emoji: '🈴', keywords: ['chinese'] },
      { code: 'U+1F233', emoji: '🈳', keywords: ['chinese'] },
      { code: 'U+3297', emoji: '㊗', keywords: ['chinese', 'congratulation', 'congratulations', 'ideograph'] },
      { code: 'U+3299', emoji: '㊙', keywords: ['chinese', 'ideograph', 'secret'] },
      { code: 'U+1F23A', emoji: '🈺', keywords: ['chinese'] },
      { code: 'U+1F235', emoji: '🈵', keywords: ['chinese'] },
      { code: 'U+25AA', emoji: '▪', keywords: ['geometric', 'square'] },
      { code: 'U+25AB', emoji: '▫', keywords: ['geometric', 'square'] },
      { code: 'U+25FB', emoji: '◻', keywords: ['geometric', 'square'] },
      { code: 'U+25FC', emoji: '◼', keywords: ['geometric', 'square'] },
      { code: 'U+25FD', emoji: '◽', keywords: ['geometric', 'square'] },
      { code: 'U+25FE', emoji: '◾', keywords: ['geometric', 'square'] },
      { code: 'U+2B1B', emoji: '⬛', keywords: ['geometric', 'square'] },
      { code: 'U+2B1C', emoji: '⬜', keywords: ['geometric', 'square'] },
      { code: 'U+1F536', emoji: '🔶', keywords: ['diamond', 'geometric', 'orange'] },
      { code: 'U+1F537', emoji: '🔷', keywords: ['blue', 'diamond', 'geometric'] },
      { code: 'U+1F538', emoji: '🔸', keywords: ['diamond', 'geometric', 'orange'] },
      { code: 'U+1F539', emoji: '🔹', keywords: ['blue', 'diamond', 'geometric'] },
      { code: 'U+1F53A', emoji: '🔺', keywords: ['geometric', 'red'] },
      { code: 'U+1F53B', emoji: '🔻', keywords: ['down', 'geometric', 'red'] },
      { code: 'U+1F4A0', emoji: '💠', keywords: ['comic', 'diamond', 'geometric', 'inside'] },
      { code: 'U+1F518', emoji: '🔘', keywords: ['button', 'geometric', 'radio'] },
      { code: 'U+1F532', emoji: '🔲', keywords: ['button', 'geometric', 'square'] },
      { code: 'U+1F533', emoji: '🔳', keywords: ['button', 'geometric', 'outlined', 'square'] },
      { code: 'U+26AA', emoji: '⚪', keywords: ['circle', 'geometric'] },
      { code: 'U+26AB', emoji: '⚫', keywords: ['circle', 'geometric'] },
      { code: 'U+1F534', emoji: '🔴', keywords: ['circle', 'geometric', 'red'] },
      { code: 'U+1F535', emoji: '🔵', keywords: ['blue', 'circle', 'geometric'] },
    ],
  },
  flags: {
    label: 'Flags',
    emojis: [
      { code: 'U+1F3C1', emoji: '🏁', keywords: ['checkered', 'chequered', 'flag', 'racing'] },
      { code: 'U+1F6A9', emoji: '🚩', keywords: ['flag', 'post'] },
      { code: 'U+1F38C', emoji: '🎌', keywords: ['activity', 'celebration', 'cross', 'crossed', 'flag', 'japanese'] },
      { code: 'U+1F3F4', emoji: '🏴', keywords: ['flag', 'waving'] },
      { code: 'U+1F3F3', emoji: '🏳', keywords: ['flag', 'waving'] },
      { code: 'U+1F1E6 U+1F1E8', emoji: '🇦🇨', keywords: ['ascension', 'flag', 'island'] },
      { code: 'U+1F1E6 U+1F1E9', emoji: '🇦🇩', keywords: ['andorra', 'flag'] },
      { code: 'U+1F1E6 U+1F1EA', emoji: '🇦🇪', keywords: ['emirates', 'flag', 'uae', 'united'] },
      { code: 'U+1F1E6 U+1F1EB', emoji: '🇦🇫', keywords: ['afghanistan', 'flag'] },
      { code: 'U+1F1E6 U+1F1EC', emoji: '🇦🇬', keywords: ['antigua', 'barbuda', 'flag'] },
      { code: 'U+1F1E6 U+1F1EE', emoji: '🇦🇮', keywords: ['anguilla', 'flag'] },
      { code: 'U+1F1E6 U+1F1F1', emoji: '🇦🇱', keywords: ['albania', 'flag'] },
      { code: 'U+1F1E6 U+1F1F2', emoji: '🇦🇲', keywords: ['armenia', 'flag'] },
      { code: 'U+1F1E6 U+1F1F4', emoji: '🇦🇴', keywords: ['angola', 'flag'] },
      { code: 'U+1F1E6 U+1F1F6', emoji: '🇦🇶', keywords: ['antarctica', 'flag'] },
      { code: 'U+1F1E6 U+1F1F7', emoji: '🇦🇷', keywords: ['argentina', 'flag'] },
      { code: 'U+1F1E6 U+1F1F8', emoji: '🇦🇸', keywords: ['american', 'flag', 'samoa'] },
      { code: 'U+1F1E6 U+1F1F9', emoji: '🇦🇹', keywords: ['austria', 'flag'] },
      { code: 'U+1F1E6 U+1F1FA', emoji: '🇦🇺', keywords: ['australia', 'flag'] },
      { code: 'U+1F1E6 U+1F1FC', emoji: '🇦🇼', keywords: ['aruba', 'flag'] },
      { code: 'U+1F1E6 U+1F1FD', emoji: '🇦🇽', keywords: ['åland', 'flag'] },
      { code: 'U+1F1E6 U+1F1FF', emoji: '🇦🇿', keywords: ['azerbaijan', 'flag'] },
      { code: 'U+1F1E7 U+1F1E6', emoji: '🇧🇦', keywords: ['bosnia', 'flag', 'herzegovina'] },
      { code: 'U+1F1E7 U+1F1E7', emoji: '🇧🇧', keywords: ['barbados', 'flag'] },
      { code: 'U+1F1E7 U+1F1E9', emoji: '🇧🇩', keywords: ['bangladesh', 'flag'] },
      { code: 'U+1F1E7 U+1F1EA', emoji: '🇧🇪', keywords: ['belgium', 'flag'] },
      { code: 'U+1F1E7 U+1F1EB', emoji: '🇧🇫', keywords: ['burkina faso', 'flag'] },
      { code: 'U+1F1E7 U+1F1EC', emoji: '🇧🇬', keywords: ['bulgaria', 'flag'] },
      { code: 'U+1F1E7 U+1F1ED', emoji: '🇧🇭', keywords: ['bahrain', 'flag'] },
      { code: 'U+1F1E7 U+1F1EE', emoji: '🇧🇮', keywords: ['burundi', 'flag'] },
      { code: 'U+1F1E7 U+1F1EF', emoji: '🇧🇯', keywords: ['benin', 'flag'] },
      { code: 'U+1F1E7 U+1F1F1', emoji: '🇧🇱', keywords: ['barthelemy', 'barthélemy', 'flag', 'saint'] },
      { code: 'U+1F1E7 U+1F1F2', emoji: '🇧🇲', keywords: ['bermuda', 'flag'] },
      { code: 'U+1F1E7 U+1F1F3', emoji: '🇧🇳', keywords: ['brunei', 'darussalam', 'flag'] },
      { code: 'U+1F1E7 U+1F1F4', emoji: '🇧🇴', keywords: ['bolivia', 'flag'] },
      {
        code: 'U+1F1E7 U+1F1F6',
        emoji: '🇧🇶',
        keywords: ['bonaire', 'caribbean', 'eustatius', 'flag', 'netherlands', 'saba', 'sint'],
      },
      { code: 'U+1F1E7 U+1F1F7', emoji: '🇧🇷', keywords: ['brazil', 'flag'] },
      { code: 'U+1F1E7 U+1F1F8', emoji: '🇧🇸', keywords: ['bahamas', 'flag'] },
      { code: 'U+1F1E7 U+1F1F9', emoji: '🇧🇹', keywords: ['bhutan', 'flag'] },
      { code: 'U+1F1E7 U+1F1FB', emoji: '🇧🇻', keywords: ['bouvet', 'flag', 'island'] },
      { code: 'U+1F1E7 U+1F1FC', emoji: '🇧🇼', keywords: ['botswana', 'flag'] },
      { code: 'U+1F1E7 U+1F1FE', emoji: '🇧🇾', keywords: ['belarus', 'flag'] },
      { code: 'U+1F1E7 U+1F1FF', emoji: '🇧🇿', keywords: ['belize', 'flag'] },
      { code: 'U+1F1E8 U+1F1E6', emoji: '🇨🇦', keywords: ['canada', 'flag'] },
      { code: 'U+1F1E8 U+1F1E8', emoji: '🇨🇨', keywords: ['cocos', 'flag', 'island', 'keeling'] },
      {
        code: 'U+1F1E8 U+1F1E9',
        emoji: '🇨🇩',
        keywords: ['congo', 'congo-kinshasa', 'democratic republic of congo', 'drc', 'flag', 'kinshasa', 'republic'],
      },
      { code: 'U+1F1E8 U+1F1EB', emoji: '🇨🇫', keywords: ['central african republic', 'flag', 'republic'] },
      {
        code: 'U+1F1E8 U+1F1EC',
        emoji: '🇨🇬',
        keywords: [
          'brazzaville',
          'congo',
          'congo republic',
          'congo-brazzaville',
          'flag',
          'republic',
          'republic of the congo',
        ],
      },
      { code: 'U+1F1E8 U+1F1ED', emoji: '🇨🇭', keywords: ['flag', 'switzerland'] },
      { code: 'U+1F1E8 U+1F1EE', emoji: '🇨🇮', keywords: ['cote ivoire', 'côte ivoire', 'flag', 'ivory coast'] },
      { code: 'U+1F1E8 U+1F1F0', emoji: '🇨🇰', keywords: ['cook', 'flag', 'island'] },
      { code: 'U+1F1E8 U+1F1F1', emoji: '🇨🇱', keywords: ['chile', 'flag'] },
      { code: 'U+1F1E8 U+1F1F2', emoji: '🇨🇲', keywords: ['cameroon', 'flag'] },
      { code: 'U+1F1E8 U+1F1F3', emoji: '🇨🇳', keywords: ['china', 'flag'] },
      { code: 'U+1F1E8 U+1F1F4', emoji: '🇨🇴', keywords: ['colombia', 'flag'] },
      { code: 'U+1F1E8 U+1F1F5', emoji: '🇨🇵', keywords: ['clipperton', 'flag', 'island'] },
      { code: 'U+1F1E8 U+1F1F7', emoji: '🇨🇷', keywords: ['costa rica', 'flag'] },
      { code: 'U+1F1E8 U+1F1FA', emoji: '🇨🇺', keywords: ['cuba', 'flag'] },
      { code: 'U+1F1E8 U+1F1FB', emoji: '🇨🇻', keywords: ['cabo', 'cape', 'flag', 'verde'] },
      { code: 'U+1F1E8 U+1F1FC', emoji: '🇨🇼', keywords: ['antilles', 'curacao', 'curaçao', 'flag'] },
      { code: 'U+1F1E8 U+1F1FD', emoji: '🇨🇽', keywords: ['christmas', 'flag', 'island'] },
      { code: 'U+1F1E8 U+1F1FE', emoji: '🇨🇾', keywords: ['cyprus', 'flag'] },
      { code: 'U+1F1E8 U+1F1FF', emoji: '🇨🇿', keywords: ['czech republic', 'flag'] },
      { code: 'U+1F1E9 U+1F1EA', emoji: '🇩🇪', keywords: ['flag', 'germany'] },
      { code: 'U+1F1E9 U+1F1EC', emoji: '🇩🇬', keywords: ['diego garcia', 'flag'] },
      { code: 'U+1F1E9 U+1F1EF', emoji: '🇩🇯', keywords: ['djibouti', 'flag'] },
      { code: 'U+1F1E9 U+1F1F0', emoji: '🇩🇰', keywords: ['denmark', 'flag'] },
      { code: 'U+1F1E9 U+1F1F2', emoji: '🇩🇲', keywords: ['dominica', 'flag'] },
      { code: 'U+1F1E9 U+1F1F4', emoji: '🇩🇴', keywords: ['dominican republic', 'flag'] },
      { code: 'U+1F1E9 U+1F1FF', emoji: '🇩🇿', keywords: ['algeria', 'flag'] },
      { code: 'U+1F1EA U+1F1E6', emoji: '🇪🇦', keywords: ['ceuta', 'flag', 'melilla'] },
      { code: 'U+1F1EA U+1F1E8', emoji: '🇪🇨', keywords: ['ecuador', 'flag'] },
      { code: 'U+1F1EA U+1F1EA', emoji: '🇪🇪', keywords: ['estonia', 'flag'] },
      { code: 'U+1F1EA U+1F1EC', emoji: '🇪🇬', keywords: ['egypt', 'flag'] },
      { code: 'U+1F1EA U+1F1ED', emoji: '🇪🇭', keywords: ['flag', 'sahara', 'west', 'western sahara'] },
      { code: 'U+1F1EA U+1F1F7', emoji: '🇪🇷', keywords: ['eritrea', 'flag'] },
      { code: 'U+1F1EA U+1F1F8', emoji: '🇪🇸', keywords: ['flag', 'spain'] },
      { code: 'U+1F1EA U+1F1F9', emoji: '🇪🇹', keywords: ['ethiopia', 'flag'] },
      { code: 'U+1F1EA U+1F1FA', emoji: '🇪🇺', keywords: ['european union', 'flag'] },
      { code: 'U+1F1EB U+1F1EE', emoji: '🇫🇮', keywords: ['finland', 'flag'] },
      { code: 'U+1F1EB U+1F1EF', emoji: '🇫🇯', keywords: ['fiji', 'flag'] },
      {
        code: 'U+1F1EB U+1F1F0',
        emoji: '🇫🇰',
        keywords: ['falkland', 'falklands', 'flag', 'island', 'islas', 'malvinas'],
      },
      { code: 'U+1F1EB U+1F1F2', emoji: '🇫🇲', keywords: ['flag', 'micronesia'] },
      { code: 'U+1F1EB U+1F1F4', emoji: '🇫🇴', keywords: ['faroe', 'flag', 'island'] },
      { code: 'U+1F1EB U+1F1F7', emoji: '🇫🇷', keywords: ['flag', 'france'] },
      { code: 'U+1F1EC U+1F1E6', emoji: '🇬🇦', keywords: ['flag', 'gabon'] },
      {
        code: 'U+1F1EC U+1F1E7',
        emoji: '🇬🇧',
        keywords: [
          'britain',
          'british',
          'cornwall',
          'england',
          'flag',
          'great britain',
          'ireland',
          'northern ireland',
          'scotland',
          'uk',
          'union jack',
          'united',
          'united kingdom',
          'wales',
        ],
      },
      { code: 'U+1F1EC U+1F1E9', emoji: '🇬🇩', keywords: ['flag', 'grenada'] },
      { code: 'U+1F1EC U+1F1EA', emoji: '🇬🇪', keywords: ['flag', 'georgia'] },
      { code: 'U+1F1EC U+1F1EB', emoji: '🇬🇫', keywords: ['flag', 'french', 'guiana'] },
      { code: 'U+1F1EC U+1F1EC', emoji: '🇬🇬', keywords: ['flag', 'guernsey'] },
      { code: 'U+1F1EC U+1F1ED', emoji: '🇬🇭', keywords: ['flag', 'ghana'] },
      { code: 'U+1F1EC U+1F1EE', emoji: '🇬🇮', keywords: ['flag', 'gibraltar'] },
      { code: 'U+1F1EC U+1F1F1', emoji: '🇬🇱', keywords: ['flag', 'greenland'] },
      { code: 'U+1F1EC U+1F1F2', emoji: '🇬🇲', keywords: ['flag', 'gambia'] },
      { code: 'U+1F1EC U+1F1F3', emoji: '🇬🇳', keywords: ['flag', 'guinea'] },
      { code: 'U+1F1EC U+1F1F5', emoji: '🇬🇵', keywords: ['flag', 'guadeloupe'] },
      { code: 'U+1F1EC U+1F1F6', emoji: '🇬🇶', keywords: ['equatorial guinea', 'flag', 'guinea'] },
      { code: 'U+1F1EC U+1F1F7', emoji: '🇬🇷', keywords: ['flag', 'greece'] },
      {
        code: 'U+1F1EC U+1F1F8',
        emoji: '🇬🇸',
        keywords: ['flag', 'georgia', 'island', 'south', 'south georgia', 'south sandwich'],
      },
      { code: 'U+1F1EC U+1F1F9', emoji: '🇬🇹', keywords: ['flag', 'guatemala'] },
      { code: 'U+1F1EC U+1F1FA', emoji: '🇬🇺', keywords: ['flag', 'guam'] },
      { code: 'U+1F1EC U+1F1FC', emoji: '🇬🇼', keywords: ['bissau', 'flag', 'guinea'] },
      { code: 'U+1F1EC U+1F1FE', emoji: '🇬🇾', keywords: ['flag', 'guyana'] },
      { code: 'U+1F1ED U+1F1F0', emoji: '🇭🇰', keywords: ['china', 'flag', 'hong kong'] },
      { code: 'U+1F1ED U+1F1F2', emoji: '🇭🇲', keywords: ['flag', 'heard', 'island', 'mcdonald'] },
      { code: 'U+1F1ED U+1F1F3', emoji: '🇭🇳', keywords: ['flag', 'honduras'] },
      { code: 'U+1F1ED U+1F1F7', emoji: '🇭🇷', keywords: ['croatia', 'flag'] },
      { code: 'U+1F1ED U+1F1F9', emoji: '🇭🇹', keywords: ['flag', 'haiti'] },
      { code: 'U+1F1ED U+1F1FA', emoji: '🇭🇺', keywords: ['flag', 'hungary'] },
      { code: 'U+1F1EE U+1F1E8', emoji: '🇮🇨', keywords: ['canary', 'flag', 'island'] },
      { code: 'U+1F1EE U+1F1E9', emoji: '🇮🇩', keywords: ['flag', 'indonesia'] },
      { code: 'U+1F1EE U+1F1EA', emoji: '🇮🇪', keywords: ['flag', 'ireland'] },
      { code: 'U+1F1EE U+1F1F1', emoji: '🇮🇱', keywords: ['flag', 'israel'] },
      { code: 'U+1F1EE U+1F1F2', emoji: '🇮🇲', keywords: ['flag', 'isle of man'] },
      { code: 'U+1F1EE U+1F1F3', emoji: '🇮🇳', keywords: ['flag', 'india'] },
      { code: 'U+1F1EE U+1F1F4', emoji: '🇮🇴', keywords: ['british', 'chagos', 'flag', 'indian ocean', 'island'] },
      { code: 'U+1F1EE U+1F1F6', emoji: '🇮🇶', keywords: ['flag', 'iraq'] },
      { code: 'U+1F1EE U+1F1F7', emoji: '🇮🇷', keywords: ['flag', 'iran'] },
      { code: 'U+1F1EE U+1F1F8', emoji: '🇮🇸', keywords: ['flag', 'iceland'] },
      { code: 'U+1F1EE U+1F1F9', emoji: '🇮🇹', keywords: ['flag', 'italy'] },
      { code: 'U+1F1EF U+1F1EA', emoji: '🇯🇪', keywords: ['flag', 'jersey'] },
      { code: 'U+1F1EF U+1F1F2', emoji: '🇯🇲', keywords: ['flag', 'jamaica'] },
      { code: 'U+1F1EF U+1F1F4', emoji: '🇯🇴', keywords: ['flag', 'jordan'] },
      { code: 'U+1F1EF U+1F1F5', emoji: '🇯🇵', keywords: ['flag', 'japan'] },
      { code: 'U+1F1F0 U+1F1EA', emoji: '🇰🇪', keywords: ['flag', 'kenya'] },
      { code: 'U+1F1F0 U+1F1EC', emoji: '🇰🇬', keywords: ['flag', 'kyrgyzstan'] },
      { code: 'U+1F1F0 U+1F1ED', emoji: '🇰🇭', keywords: ['cambodia', 'flag'] },
      { code: 'U+1F1F0 U+1F1EE', emoji: '🇰🇮', keywords: ['flag', 'kiribati'] },
      { code: 'U+1F1F0 U+1F1F2', emoji: '🇰🇲', keywords: ['comoros', 'flag'] },
      { code: 'U+1F1F0 U+1F1F3', emoji: '🇰🇳', keywords: ['flag', 'kitts', 'nevis', 'saint'] },
      { code: 'U+1F1F0 U+1F1F5', emoji: '🇰🇵', keywords: ['flag', 'korea', 'north', 'north korea'] },
      { code: 'U+1F1F0 U+1F1F7', emoji: '🇰🇷', keywords: ['flag', 'korea', 'south', 'south korea'] },
      { code: 'U+1F1F0 U+1F1FC', emoji: '🇰🇼', keywords: ['flag', 'kuwait'] },
      { code: 'U+1F1F0 U+1F1FE', emoji: '🇰🇾', keywords: ['cayman', 'flag', 'island'] },
      { code: 'U+1F1F0 U+1F1FF', emoji: '🇰🇿', keywords: ['flag', 'kazakhstan'] },
      { code: 'U+1F1F1 U+1F1E6', emoji: '🇱🇦', keywords: ['flag', 'laos'] },
      { code: 'U+1F1F1 U+1F1E7', emoji: '🇱🇧', keywords: ['flag', 'lebanon'] },
      { code: 'U+1F1F1 U+1F1E8', emoji: '🇱🇨', keywords: ['flag', 'lucia', 'saint'] },
      { code: 'U+1F1F1 U+1F1EE', emoji: '🇱🇮', keywords: ['flag', 'liechtenstein'] },
      { code: 'U+1F1F1 U+1F1F0', emoji: '🇱🇰', keywords: ['flag', 'sri lanka'] },
      { code: 'U+1F1F1 U+1F1F7', emoji: '🇱🇷', keywords: ['flag', 'liberia'] },
      { code: 'U+1F1F1 U+1F1F8', emoji: '🇱🇸', keywords: ['flag', 'lesotho'] },
      { code: 'U+1F1F1 U+1F1F9', emoji: '🇱🇹', keywords: ['flag', 'lithuania'] },
      { code: 'U+1F1F1 U+1F1FA', emoji: '🇱🇺', keywords: ['flag', 'luxembourg'] },
      { code: 'U+1F1F1 U+1F1FB', emoji: '🇱🇻', keywords: ['flag', 'latvia'] },
      { code: 'U+1F1F1 U+1F1FE', emoji: '🇱🇾', keywords: ['flag', 'libya'] },
      { code: 'U+1F1F2 U+1F1E6', emoji: '🇲🇦', keywords: ['flag', 'morocco'] },
      { code: 'U+1F1F2 U+1F1E8', emoji: '🇲🇨', keywords: ['flag', 'monaco'] },
      { code: 'U+1F1F2 U+1F1E9', emoji: '🇲🇩', keywords: ['flag', 'moldova'] },
      { code: 'U+1F1F2 U+1F1EA', emoji: '🇲🇪', keywords: ['flag', 'montenegro'] },
      { code: 'U+1F1F2 U+1F1EB', emoji: '🇲🇫', keywords: ['flag', 'french', 'martin', 'saint'] },
      { code: 'U+1F1F2 U+1F1EC', emoji: '🇲🇬', keywords: ['flag', 'madagascar'] },
      { code: 'U+1F1F2 U+1F1ED', emoji: '🇲🇭', keywords: ['flag', 'island', 'marshall'] },
      { code: 'U+1F1F2 U+1F1F0', emoji: '🇲🇰', keywords: ['flag', 'macedonia'] },
      { code: 'U+1F1F2 U+1F1F1', emoji: '🇲🇱', keywords: ['flag', 'mali'] },
      { code: 'U+1F1F2 U+1F1F2', emoji: '🇲🇲', keywords: ['burma', 'flag', 'myanmar'] },
      { code: 'U+1F1F2 U+1F1F3', emoji: '🇲🇳', keywords: ['flag', 'mongolia'] },
      { code: 'U+1F1F2 U+1F1F4', emoji: '🇲🇴', keywords: ['china', 'flag', 'macao', 'macau'] },
      { code: 'U+1F1F2 U+1F1F5', emoji: '🇲🇵', keywords: ['flag', 'island', 'mariana', 'north', 'northern mariana'] },
      { code: 'U+1F1F2 U+1F1F6', emoji: '🇲🇶', keywords: ['flag', 'martinique'] },
      { code: 'U+1F1F2 U+1F1F7', emoji: '🇲🇷', keywords: ['flag', 'mauritania'] },
      { code: 'U+1F1F2 U+1F1F8', emoji: '🇲🇸', keywords: ['flag', 'montserrat'] },
      { code: 'U+1F1F2 U+1F1F9', emoji: '🇲🇹', keywords: ['flag', 'malta'] },
      { code: 'U+1F1F2 U+1F1FA', emoji: '🇲🇺', keywords: ['flag', 'mauritius'] },
      { code: 'U+1F1F2 U+1F1FB', emoji: '🇲🇻', keywords: ['flag', 'maldives'] },
      { code: 'U+1F1F2 U+1F1FC', emoji: '🇲🇼', keywords: ['flag', 'malawi'] },
      { code: 'U+1F1F2 U+1F1FD', emoji: '🇲🇽', keywords: ['flag', 'mexico'] },
      { code: 'U+1F1F2 U+1F1FE', emoji: '🇲🇾', keywords: ['flag', 'malaysia'] },
      { code: 'U+1F1F2 U+1F1FF', emoji: '🇲🇿', keywords: ['flag', 'mozambique'] },
      { code: 'U+1F1F3 U+1F1E6', emoji: '🇳🇦', keywords: ['flag', 'namibia'] },
      { code: 'U+1F1F3 U+1F1E8', emoji: '🇳🇨', keywords: ['flag', 'new', 'new caledonia'] },
      { code: 'U+1F1F3 U+1F1EA', emoji: '🇳🇪', keywords: ['flag', 'niger'] },
      { code: 'U+1F1F3 U+1F1EB', emoji: '🇳🇫', keywords: ['flag', 'island', 'norfolk'] },
      { code: 'U+1F1F3 U+1F1EC', emoji: '🇳🇬', keywords: ['flag', 'nigeria'] },
      { code: 'U+1F1F3 U+1F1EE', emoji: '🇳🇮', keywords: ['flag', 'nicaragua'] },
      { code: 'U+1F1F3 U+1F1F1', emoji: '🇳🇱', keywords: ['flag', 'netherlands'] },
      { code: 'U+1F1F3 U+1F1F4', emoji: '🇳🇴', keywords: ['flag', 'norway'] },
      { code: 'U+1F1F3 U+1F1F5', emoji: '🇳🇵', keywords: ['flag', 'nepal'] },
      { code: 'U+1F1F3 U+1F1F7', emoji: '🇳🇷', keywords: ['flag', 'nauru'] },
      { code: 'U+1F1F3 U+1F1FA', emoji: '🇳🇺', keywords: ['flag', 'niue'] },
      { code: 'U+1F1F3 U+1F1FF', emoji: '🇳🇿', keywords: ['flag', 'new', 'new zealand'] },
      { code: 'U+1F1F4 U+1F1F2', emoji: '🇴🇲', keywords: ['flag', 'oman'] },
      { code: 'U+1F1F5 U+1F1E6', emoji: '🇵🇦', keywords: ['flag', 'panama'] },
      { code: 'U+1F1F5 U+1F1EA', emoji: '🇵🇪', keywords: ['flag', 'peru'] },
      { code: 'U+1F1F5 U+1F1EB', emoji: '🇵🇫', keywords: ['flag', 'french', 'polynesia'] },
      { code: 'U+1F1F5 U+1F1EC', emoji: '🇵🇬', keywords: ['flag', 'guinea', 'new', 'papua new guinea'] },
      { code: 'U+1F1F5 U+1F1ED', emoji: '🇵🇭', keywords: ['flag', 'philippines'] },
      { code: 'U+1F1F5 U+1F1F0', emoji: '🇵🇰', keywords: ['flag', 'pakistan'] },
      { code: 'U+1F1F5 U+1F1F1', emoji: '🇵🇱', keywords: ['flag', 'poland'] },
      { code: 'U+1F1F5 U+1F1F2', emoji: '🇵🇲', keywords: ['flag', 'miquelon', 'pierre', 'saint'] },
      { code: 'U+1F1F5 U+1F1F3', emoji: '🇵🇳', keywords: ['flag', 'island', 'pitcairn'] },
      { code: 'U+1F1F5 U+1F1F7', emoji: '🇵🇷', keywords: ['flag', 'puerto rico'] },
      { code: 'U+1F1F5 U+1F1F8', emoji: '🇵🇸', keywords: ['flag', 'palestine'] },
      { code: 'U+1F1F5 U+1F1F9', emoji: '🇵🇹', keywords: ['flag', 'portugal'] },
      { code: 'U+1F1F5 U+1F1FC', emoji: '🇵🇼', keywords: ['flag', 'palau'] },
      { code: 'U+1F1F5 U+1F1FE', emoji: '🇵🇾', keywords: ['flag', 'paraguay'] },
      { code: 'U+1F1F6 U+1F1E6', emoji: '🇶🇦', keywords: ['flag', 'qatar'] },
      { code: 'U+1F1F7 U+1F1EA', emoji: '🇷🇪', keywords: ['flag', 'reunion', 'réunion'] },
      { code: 'U+1F1F7 U+1F1F4', emoji: '🇷🇴', keywords: ['flag', 'romania'] },
      { code: 'U+1F1F7 U+1F1F8', emoji: '🇷🇸', keywords: ['flag', 'serbia'] },
      { code: 'U+1F1F7 U+1F1FA', emoji: '🇷🇺', keywords: ['flag', 'russia'] },
      { code: 'U+1F1F7 U+1F1FC', emoji: '🇷🇼', keywords: ['flag', 'rwanda'] },
      { code: 'U+1F1F8 U+1F1E6', emoji: '🇸🇦', keywords: ['flag', 'saudi arabia'] },
      { code: 'U+1F1F8 U+1F1E7', emoji: '🇸🇧', keywords: ['flag', 'island', 'solomon'] },
      { code: 'U+1F1F8 U+1F1E8', emoji: '🇸🇨', keywords: ['flag', 'seychelles'] },
      { code: 'U+1F1F8 U+1F1E9', emoji: '🇸🇩', keywords: ['flag', 'sudan'] },
      { code: 'U+1F1F8 U+1F1EA', emoji: '🇸🇪', keywords: ['flag', 'sweden'] },
      { code: 'U+1F1F8 U+1F1EC', emoji: '🇸🇬', keywords: ['flag', 'singapore'] },
      { code: 'U+1F1F8 U+1F1ED', emoji: '🇸🇭', keywords: ['flag', 'helena', 'saint'] },
      { code: 'U+1F1F8 U+1F1EE', emoji: '🇸🇮', keywords: ['flag', 'slovenia'] },
      { code: 'U+1F1F8 U+1F1EF', emoji: '🇸🇯', keywords: ['flag', 'jan mayen', 'svalbard'] },
      { code: 'U+1F1F8 U+1F1F0', emoji: '🇸🇰', keywords: ['flag', 'slovakia'] },
      { code: 'U+1F1F8 U+1F1F1', emoji: '🇸🇱', keywords: ['flag', 'sierra leone'] },
      { code: 'U+1F1F8 U+1F1F2', emoji: '🇸🇲', keywords: ['flag', 'san marino'] },
      { code: 'U+1F1F8 U+1F1F3', emoji: '🇸🇳', keywords: ['flag', 'senegal'] },
      { code: 'U+1F1F8 U+1F1F4', emoji: '🇸🇴', keywords: ['flag', 'somalia'] },
      { code: 'U+1F1F8 U+1F1F7', emoji: '🇸🇷', keywords: ['flag', 'suriname'] },
      { code: 'U+1F1F8 U+1F1F8', emoji: '🇸🇸', keywords: ['flag', 'south', 'south sudan', 'sudan'] },
      { code: 'U+1F1F8 U+1F1F9', emoji: '🇸🇹', keywords: ['flag', 'principe', 'príncipe', 'sao tome', 'são tomé'] },
      { code: 'U+1F1F8 U+1F1FB', emoji: '🇸🇻', keywords: ['el salvador', 'flag'] },
      { code: 'U+1F1F8 U+1F1FD', emoji: '🇸🇽', keywords: ['flag', 'maarten', 'sint'] },
      { code: 'U+1F1F8 U+1F1FE', emoji: '🇸🇾', keywords: ['flag', 'syria'] },
      { code: 'U+1F1F8 U+1F1FF', emoji: '🇸🇿', keywords: ['flag', 'swaziland'] },
      { code: 'U+1F1F9 U+1F1E6', emoji: '🇹🇦', keywords: ['flag', 'tristan da cunha'] },
      { code: 'U+1F1F9 U+1F1E8', emoji: '🇹🇨', keywords: ['caicos', 'flag', 'island', 'turks'] },
      { code: 'U+1F1F9 U+1F1E9', emoji: '🇹🇩', keywords: ['chad', 'flag'] },
      { code: 'U+1F1F9 U+1F1EB', emoji: '🇹🇫', keywords: ['antarctic', 'flag', 'french'] },
      { code: 'U+1F1F9 U+1F1EC', emoji: '🇹🇬', keywords: ['flag', 'togo'] },
      { code: 'U+1F1F9 U+1F1ED', emoji: '🇹🇭', keywords: ['flag', 'thailand'] },
      { code: 'U+1F1F9 U+1F1EF', emoji: '🇹🇯', keywords: ['flag', 'tajikistan'] },
      { code: 'U+1F1F9 U+1F1F0', emoji: '🇹🇰', keywords: ['flag', 'tokelau'] },
      { code: 'U+1F1F9 U+1F1F1', emoji: '🇹🇱', keywords: ['east', 'east timor', 'flag', 'timor-leste'] },
      { code: 'U+1F1F9 U+1F1F2', emoji: '🇹🇲', keywords: ['flag', 'turkmenistan'] },
      { code: 'U+1F1F9 U+1F1F3', emoji: '🇹🇳', keywords: ['flag', 'tunisia'] },
      { code: 'U+1F1F9 U+1F1F4', emoji: '🇹🇴', keywords: ['flag', 'tonga'] },
      { code: 'U+1F1F9 U+1F1F7', emoji: '🇹🇷', keywords: ['flag', 'turkey'] },
      { code: 'U+1F1F9 U+1F1F9', emoji: '🇹🇹', keywords: ['flag', 'tobago', 'trinidad'] },
      { code: 'U+1F1F9 U+1F1FB', emoji: '🇹🇻', keywords: ['flag', 'tuvalu'] },
      { code: 'U+1F1F9 U+1F1FC', emoji: '🇹🇼', keywords: ['china', 'flag', 'taiwan'] },
      { code: 'U+1F1F9 U+1F1FF', emoji: '🇹🇿', keywords: ['flag', 'tanzania'] },
      { code: 'U+1F1FA U+1F1E6', emoji: '🇺🇦', keywords: ['flag', 'ukraine'] },
      { code: 'U+1F1FA U+1F1EC', emoji: '🇺🇬', keywords: ['flag', 'uganda'] },
      {
        code: 'U+1F1FA U+1F1F2',
        emoji: '🇺🇲',
        keywords: ['america', 'flag', 'island', 'minor outlying', 'united', 'united states', 'us', 'usa'],
      },
      {
        code: 'U+1F1FA U+1F1F8',
        emoji: '🇺🇸',
        keywords: ['america', 'flag', 'stars and stripes', 'united', 'united states'],
      },
      { code: 'U+1F1FA U+1F1FE', emoji: '🇺🇾', keywords: ['flag', 'uruguay'] },
      { code: 'U+1F1FA U+1F1FF', emoji: '🇺🇿', keywords: ['flag', 'uzbekistan'] },
      { code: 'U+1F1FB U+1F1E6', emoji: '🇻🇦', keywords: ['flag', 'vatican'] },
      { code: 'U+1F1FB U+1F1E8', emoji: '🇻🇨', keywords: ['flag', 'grenadines', 'saint', 'vincent'] },
      { code: 'U+1F1FB U+1F1EA', emoji: '🇻🇪', keywords: ['flag', 'venezuela'] },
      { code: 'U+1F1FB U+1F1EC', emoji: '🇻🇬', keywords: ['british', 'flag', 'island', 'virgin'] },
      {
        code: 'U+1F1FB U+1F1EE',
        emoji: '🇻🇮',
        keywords: ['america', 'american', 'flag', 'island', 'united', 'united states', 'us', 'usa', 'virgin'],
      },
      {
        code: 'U+1F1FB U+1F1F3',
        emoji: '🇻🇳',
        keywords: ['flag', 'viet nam', 'vietnam'],
      },
      {
        code: 'U+1F1FB U+1F1FA',
        emoji: '🇻🇺',
        keywords: ['flag', 'vanuatu'],
      },
      {
        code: 'U+1F1FC U+1F1EB',
        emoji: '🇼🇫',
        keywords: ['flag', 'futuna', 'wallis'],
      },
      {
        code: 'U+1F1FC U+1F1F8',
        emoji: '🇼🇸',
        keywords: ['flag', 'samoa'],
      },
      { code: 'U+1F1FD U+1F1F0', emoji: '🇽🇰', keywords: ['flag', 'kosovo'] },
      { code: 'U+1F1FE U+1F1EA', emoji: '🇾🇪', keywords: ['flag', 'yemen'] },
      { code: 'U+1F1FE U+1F1F9', emoji: '🇾🇹', keywords: ['flag', 'mayotte'] },
      { code: 'U+1F1FF U+1F1E6', emoji: '🇿🇦', keywords: ['flag', 'south', 'south africa'] },
      { code: 'U+1F1FF U+1F1F2', emoji: '🇿🇲', keywords: ['flag', 'zambia'] },
      { code: 'U+1F1FF U+1F1FC', emoji: '🇿🇼', keywords: ['flag', 'zimbabwe'] },
    ],
  },
};
