import './style.scss';

import { FC, useMemo } from 'react';

import classNames from 'classnames';

import { MQFlexBoxProps } from '../../types';

const MQFlexBox: FC<MQFlexBoxProps> = ({
  children,
  className = '',
  space = 'sm',
  padding = true,
  wrap = 'nowrap',
  direction = 'row',
  justify,
  align,
  fill = false,
  compact = false,
  dataTestId = 'mq-grid-flex-box',
  ...props
}) => {
  const classes = useMemo(() => {
    const items = ['mq-grid-flex-box'];
    if (direction) {
      items.push(`mq-grid-flex-box__direction-${direction}`);
    }

    if (justify) {
      items.push(`mq-grid-flex-box__justify-${justify}`);
    }

    if (align) {
      items.push(`mq-grid-flex-box__align-${align}`);
    }

    if (wrap) {
      items.push(`mq-grid-flex-box__flex-${wrap}`);
    }

    return items;
  }, [direction, justify, align, wrap]);

  return (
    <div
      data-testid={dataTestId}
      className={classNames([...classes, className, space, { padding, fill, compact }])}
      {...props}
    >
      {children}
    </div>
  );
};

export default MQFlexBox;
