import './style.scss';

import { FC } from 'react';

import classNames from 'classnames';

import { MQFormTextProps } from '../../types';

const MQFormText: FC<MQFormTextProps> = ({ children, className = '', muted = true, ...props }) => (
  <p {...props} data-testid="mq-form-text" className={classNames('mq-form-text', { muted }, className)}>
    {children}
  </p>
);

export default MQFormText;
