import { lazy } from 'react';

import ROUTES from 'routes';

const UserDashboard = lazy(() => import('./UserDashboard'));

export const PreviewPages = [
  {
    route: ROUTES.previewDashboard.data,
    component: <UserDashboard />,
  },
];
