import './style.scss';

import { FC, cloneElement, useEffect, useState, ReactElement } from 'react';

import { MQAnimateAppearanceProps } from '../../types';

const MQAnimateStyles: FC<MQAnimateAppearanceProps> = ({
  children,
  dependencies,
  animation,
  delay = 300,
  doAfter,
  ...props
}) => {
  const [deps, setDeps] = useState(dependencies);
  const [child, setChild] = useState<ReactElement | null>(children);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDeps(dependencies);
      if (doAfter) {
        setChild(doAfter(children));
      }
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  });

  return (
    child &&
    cloneElement(child, {
      ...props,
      ...children.props,
      style: {
        ...children.props.style,
        animationName: dependencies !== deps ? animation : null,
        animationDuration: `${delay}ms`,
        transitionDuration: `${delay}ms`,
      },
    })
  );
};

export default MQAnimateStyles;
