import admin from './admin';
import cliqipedia from './cliqipedia';
import configure from './configure';
import enrollment from './enrollment';
import gdpr from './gdpr';
import helpdesk from './helpdesk';
import labs from './labs';
import linkedIn from './linkedIn';
import matches from './matches';
import mentorFlix from './mentorFlix';
import microsoftAuth from './microsoftAuth';
import people from './people';
import profilette from './profilette';
import program from './program';
import programWizard from './programWizard';
import reports from './reports';
import resourceLibrary from './resourceLibrary';
import slack from './slack';
import user from './user';
import verify from './verify';

const privateRoutes = {
  ...gdpr,
  ...verify,
  ...mentorFlix,
  ...helpdesk,
  ...enrollment,
  ...configure,
  ...user,
  ...admin,
  ...program,
  ...programWizard,
  ...profilette,
  ...linkedIn,
  ...resourceLibrary,
  ...reports,
  ...matches,
  ...people,
  ...cliqipedia,
  ...microsoftAuth,
  ...labs,
  ...slack,
};

export default privateRoutes;
