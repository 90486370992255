import { FC } from 'react';

import { FeatureTypeEnum, PermissionsEnum } from '@mentorcliq/storage';
import { FormattedMessage } from 'react-intl';

import ROUTES from 'routes';

import MQIcon from 'modules/MQIcon';
import MQSidebar from 'modules/MQSidebar';

import AppLink from 'router/AppLink';

import PermissionWrapper from 'components/PermissionWrapper';

const Resources: FC = () => (
  <MQSidebar.List>
    <MQSidebar.ListItem>
      <MQSidebar.Heading className="app-sidebar__heading">
        <FormattedMessage defaultMessage="Admin Resources" description="[Sidebar] Menu Item Name" />
      </MQSidebar.Heading>
    </MQSidebar.ListItem>
    <PermissionWrapper
      permissions={{ value: [PermissionsEnum.ManageContentHelpdeskData] }}
      features={{ value: [FeatureTypeEnum.ContentHelpdesk] }}
      strict
    >
      <MQSidebar.ListItem
        as={AppLink}
        className="app-sidebar__list-item"
        data-testid="sidebar-helpdesk-link"
        to={ROUTES.helpdesk.data.path}
        prefix={<MQIcon.Svg icon="cabinet-filing" />}
      >
        <FormattedMessage defaultMessage="Content Helpdesk" description="[Sidebar] Menu Item Name" />
      </MQSidebar.ListItem>
    </PermissionWrapper>
    <PermissionWrapper
      features={{ value: [FeatureTypeEnum.MentorFlix] }}
      permissions={{ value: [PermissionsEnum.ManageMentorFlixContent] }}
      strict
    >
      <MQSidebar.ListItem
        as={AppLink}
        className="app-sidebar__list-item"
        data-testid="sidebar-mentorFlix-link"
        to={ROUTES.mentorFlix.data.path}
        prefix={<MQIcon.Svg icon="film" />}
      >
        <FormattedMessage defaultMessage="MentorFlix" description="[Sidebar] Menu Item Name" />
      </MQSidebar.ListItem>
    </PermissionWrapper>
  </MQSidebar.List>
);

export default Resources;
