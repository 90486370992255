import './style.scss';

import { FC, useMemo } from 'react';

import { UserBasicInfoDto, ChatMessageDto, ChatRoomDto } from '@mentorcliq/storage';
import { MQBadge } from '@mentorcliq/ui';
import classNames from 'classnames';

import { LOCALE_DATE_FORMAT_WITH_MONTH, LOCALE_DATE_FORMAT_MONTH_DAY_ONLY } from 'definitions/configs';

import MQAnimate from 'modules/MQAnimate';
import MQAvatar from 'modules/MQAvatar';
import MQTypo from 'modules/MQTypo';

import AppFormattedDate from 'formatters/AppFormattedDate';
import AppFormattedList from 'formatters/AppFormattedList';
import AppFormattedNumber from 'formatters/AppFormattedNumber';

interface ChatRoomProps {
  room?: ChatRoomDto;
  members: UserBasicInfoDto[];
  messages: ChatMessageDto[];
}

const ChatRoom: FC<ChatRoomProps> = ({ members, room, messages }) => {
  const lastMessage = useMemo(() => {
    if (messages.length > 0) {
      return messages[messages.length - 1];
    }
  }, [messages]);

  const lastDate = useMemo(() => {
    if (lastMessage?.created) {
      const messageDate = new Date(lastMessage.created);
      const currentDate = new Date();
      const messageYear = messageDate.getFullYear();
      const currentYear = currentDate.getFullYear();
      if (currentYear === messageYear) {
        return (
          <AppFormattedDate
            date={messageDate}
            options={{
              format: LOCALE_DATE_FORMAT_MONTH_DAY_ONLY,
            }}
          />
        );
      }

      return (
        <AppFormattedDate
          date={messageDate}
          options={{
            format: LOCALE_DATE_FORMAT_WITH_MONTH,
          }}
        />
      );
    }
  }, [lastMessage]);

  return (
    <div className="app-chat-room">
      <div className="app-chat-room__icon">
        <MQAvatar.Group
          items={members.map((item) => ({
            imageUrl: item.profile.profilePictureFileHandle,
            title: item.realName,
          }))}
          max={2}
          size="xs"
          overlap
        />
      </div>
      <div className={classNames('app-chat-room__info', { unread: !!room?.unreadMessagesCount })}>
        <div className="app-chat-room__header">
          <div className="app-chat-room__members" data-testid="app-chat-room-members">
            <MQTypo.Text size="3x" bold nowrap>
              <AppFormattedList list={members.map((item) => item.realName)} />
            </MQTypo.Text>
          </div>
          {lastDate && (
            <div className="app-chat-room__date">
              <MQTypo.Text italic>{lastDate}</MQTypo.Text>
            </div>
          )}
        </div>
        <div className="app-chat-room__message">
          {lastMessage && (
            <div
              className="app-chat-room__message-text"
              data-testid="app-chat-room-message-text"
              dangerouslySetInnerHTML={{ __html: lastMessage.message }}
            />
          )}
          <div className="app-chat-room__unread">
            {!!room?.unreadMessagesCount && (
              <MQAnimate.Styles dependencies={room.unreadMessagesCount} animation="scale-up-center">
                <MQBadge.Info variant="warning" size="sm" circle>
                  <AppFormattedNumber number={room.unreadMessagesCount} />
                </MQBadge.Info>
              </MQAnimate.Styles>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatRoom;
