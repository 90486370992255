import './style.scss';

import { FC, ReactNode } from 'react';

interface MQFormatterProps {
  prefix?: ReactNode;
  suffix?: ReactNode;
  children: ReactNode;
}

const MQFormatter: FC<MQFormatterProps> = ({ prefix, suffix, children }) => (
  <>
    {!!prefix && <span className="mq-formatter__prefix">{prefix}</span>}
    {children}
    {!!suffix && <span className="mq-formatter__suffix">{suffix}</span>}
  </>
);

export default MQFormatter;
