import { EffectCallback, useEffect, useRef } from 'react';

export const useAppMount = (effect: EffectCallback) => {
  const ref = useRef(false);

  useEffect(() => {
    if (!ref.current) {
      ref.current = true;
      return effect();
    }
  }, [effect]);
};
