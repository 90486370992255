import { useMemo } from 'react';

import { getAuthUserSelector } from '@mentorcliq/storage';

import { useAppQuery } from 'hooks/useAppQuery';
import { useAppSelector } from 'hooks/useAppSelector';

export const useAppViewAs = () => {
  const { participantId, ownerId } = useAppQuery();
  const authUser = useAppSelector(({ auth }) => getAuthUserSelector(auth));

  const ownerUserId = useMemo(() => {
    if (ownerId) {
      return Number(ownerId);
    }
  }, [ownerId]);

  const viewingUserId = useMemo(() => {
    if (participantId) {
      return Number(participantId);
    }

    return authUser?.id ?? 0;
  }, [participantId, authUser?.id]);

  const nativeUserId = useMemo(() => authUser?.id ?? 0, [authUser?.id]);

  return useMemo(
    () => ({
      ownerUserId,
      viewingUserId,
      nativeUserId,
    }),
    [ownerUserId, viewingUserId, nativeUserId],
  );
};
