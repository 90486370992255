import { cloneElement, FC, isValidElement, ReactNode, useCallback, useEffect, useRef } from 'react';

interface MQScrollablePointerProps {
  dependencies?: unknown[];
  id?: string;
  children?: ReactNode;
  force?: boolean;
  behavior?: 'instant' | 'auto' | 'smooth';
}

const MQScrollablePointer: FC<MQScrollablePointerProps> = ({
  children,
  behavior = 'instant',
  force = false,
  dependencies = [],
  id,
}) => {
  const deps = useRef(JSON.stringify(dependencies));

  const scrollTo = useCallback(() => {
    if (id) {
      const pointer = document.getElementById(id);

      if (pointer) {
        pointer.scrollIntoView({
          behavior,
        });
      }
    }
  }, [id, behavior]);

  useEffect(() => {
    if (force) {
      scrollTo();
    }
  }, [force, scrollTo]);

  useEffect(() => {
    const newDeps = JSON.stringify(dependencies);
    if (deps.current !== newDeps) {
      deps.current = newDeps;

      scrollTo();
    }
  }, [dependencies, id, scrollTo]);

  if (isValidElement(children)) {
    return cloneElement(children, {
      ...children.props,
      id,
    });
  }

  return children;
};

export default MQScrollablePointer;
