import './style.scss';

import { FC } from 'react';

import classNames from 'classnames';

import MQLoader from 'modules/MQLoader';

import { MQButtonProps } from './types';

const MQButton: FC<MQButtonProps> = ({
  children,
  className,
  isLoading,
  variant = 'primary',
  startIcon,
  endIcon,
  primaryIcon,
  secondaryIcon,
  disabled,
  truncate = true,
  fullWidth = false,
  onClick,
  dataTestId,
  type = 'button',
  shape = 'round',
  size = 'md',
  ...props
}) => (
  <button
    aria-label="Action button"
    {...props}
    data-testid={dataTestId}
    onClick={(e) => {
      if (!isLoading && !disabled) {
        onClick?.(e);
      }
    }}
    className={classNames(
      'mq-button',
      { truncate },
      variant,
      shape,
      size,
      {
        disabled: isLoading || disabled,
        'full-width': fullWidth,
      },
      className,
    )}
    type={type}
    tabIndex={disabled ? -1 : 0}
    disabled={isLoading || disabled}
    >
      {primaryIcon && <span className="mq-button__primary-icon mq-button__meta">{primaryIcon}</span>}
      {secondaryIcon && <span className="mq-button__secondary-icon mq-button__meta">{secondaryIcon}</span>}
      {startIcon && !endIcon && <span className="mq-button__start-icon mq-button__meta">{startIcon}</span>}
      {children && <span className="mq-button__child">{children}</span>}
      {endIcon && <span className="mq-button__end-icon mq-button__meta">{endIcon}</span>}
      {isLoading && (
        <span className="mq-button__loading mq-button__meta">
          <MQLoader.Spinner data-testid="mq-button-loading" variant="secondary" size="sm" compact />
        </span>
      )}
    </button>
  );

export default MQButton;
