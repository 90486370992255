import { FC, useEffect, useMemo, useState } from 'react';

import {
  fetchProgramsBriefThunk,
  getAuthUserSelector,
  getProgramDraftsListSelector,
  getProgramDraftsListThunk,
  getProgramsBriefSelector,
  ProgramBriefResponseDto,
} from '@mentorcliq/storage';
import { FormattedMessage } from 'react-intl';

import ROUTES from 'routes';

import history from 'helpers/history';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppIntl } from 'hooks/useAppIntl';
import { useAppRouter } from 'hooks/useAppRouter';
import { useAppSelector } from 'hooks/useAppSelector';

import MQIcon from 'modules/MQIcon';
import MQSidebar from 'modules/MQSidebar';

import AppDynamicMessage from 'formatters/AppDynamicMessage';

import AppLink from 'router/AppLink';

type CategorizedProgramsProps = {
  id: number;
  category: string;
  programs: ProgramBriefResponseDto[];
};

const Programs: FC = () => {
  const intl = useAppIntl();

  const dispatch = useAppDispatch();
  const { location } = useAppRouter();

  const user = useAppSelector(({ auth }) => getAuthUserSelector(auth));
  const programDraftsList = useAppSelector(({ programDraftsList }) => getProgramDraftsListSelector(programDraftsList));
  const programsBriefList = useAppSelector(({ programsBrief }) => getProgramsBriefSelector(programsBrief));

  const [selectedKeys, setSelectedKey] = useState<string[]>([]);

  useEffect(() => {
    const unListen = history.mq.listen((newLocation) => {
      if (location.key !== newLocation.key) {
        setSelectedKey([]);
      }
    });

    return () => {
      unListen();
    };
  }, [location.key, setSelectedKey]);

  useEffect(() => {
    dispatch(fetchProgramsBriefThunk());
    dispatch(getProgramDraftsListThunk());
  }, [dispatch]);

  const activePrograms = useMemo(() => {
    const items = programsBriefList?.filter((item) => !item.isDemo && !item.isRetired);

    return items
      ?.reduce<CategorizedProgramsProps[]>((acc, program) => {
        const index = acc.findIndex((item) => item.id === program.programCategory.id);
        if (index > -1) {
          acc[index].programs.push(program);
        } else {
          acc.push({
            id: program.programCategory.id,
            category: program.programCategory.name,
            programs: [program],
          });
        }

        return acc;
      }, [])
      .sort((a, b) => a.category.localeCompare(b.category));
  }, [programsBriefList]);

  const demoPrograms = useMemo(() => programsBriefList?.filter((item) => item.isDemo), [programsBriefList]);

  const retiredPrograms = useMemo(() => programsBriefList?.filter((item) => item.isRetired), [programsBriefList]);

  return (
    <MQSidebar.ListItem as={AppLink} className="app-sidebar__list">
      <MQSidebar.List className="app-sidebar__list">
        {!!programsBriefList?.length && (
          <MQSidebar.ListItem as={AppLink}>
            <MQSidebar.List className="app-sidebar__list">
              <MQSidebar.ListItem as={AppLink}>
                <MQSidebar.Heading className="app-sidebar__heading">
                  <FormattedMessage defaultMessage="Programs" description="[Sidebar] Menu Item Name" />
                  <MQSidebar.Badge>{programsBriefList?.length + (programDraftsList?.length || 0)}</MQSidebar.Badge>
                </MQSidebar.Heading>
              </MQSidebar.ListItem>

              {activePrograms?.map((item, index) => (
                <MQSidebar.ListItem key={item.id}>
                  <MQSidebar.ListToggle
                    eventKey={`category-${index}`}
                    badge={item.programs.length}
                    title={<AppDynamicMessage defaultMessage={item.category} />}
                    activeEventKeys={selectedKeys}
                    setActiveEventKeys={(keys) => setSelectedKey(keys)}
                    className="app-sidebar__list-toggle"
                  >
                    <MQSidebar.List className="app-sidebar__list">
                      {item.programs.map((program) => (
                        <MQSidebar.ListItem
                          as={AppLink}
                          data-testid={`sidebar-program-${program.id}-link`}
                          key={program.id}
                          to={ROUTES.programDashboard.convert({
                            path: {
                              programId: program.id,
                            },
                          })}
                          overlay={<MQIcon.Svg icon="arrow-circle-right" />}
                          truncate
                        >
                          <AppDynamicMessage defaultMessage={program.name} />
                        </MQSidebar.ListItem>
                      ))}
                    </MQSidebar.List>
                  </MQSidebar.ListToggle>
                </MQSidebar.ListItem>
              ))}

              {!!demoPrograms?.length && (
                <MQSidebar.ListItem>
                  <MQSidebar.ListToggle
                    key="demo"
                    eventKey={`category-demo`}
                    badge={demoPrograms?.length}
                    title={intl.formatMessage({
                      defaultMessage: 'Demo',
                      description: '[Admin.Sidebar] label',
                    })}
                    activeEventKeys={selectedKeys}
                    setActiveEventKeys={(keys) => setSelectedKey(keys)}
                    className="app-sidebar__list-toggle"
                  >
                    <MQSidebar.List className="app-sidebar__list">
                      {demoPrograms?.map((program) => (
                        <MQSidebar.ListItem
                          as={AppLink}
                          data-testid={`sidebar-program-${[program.id]}-link`}
                          key={program.id}
                          to={ROUTES.programDashboard.convert({
                            path: {
                              programId: program.id,
                            },
                          })}
                          overlay={<MQIcon.Svg icon="arrow-circle-right" />}
                          truncate
                        >
                          <AppDynamicMessage defaultMessage={program.name} />
                        </MQSidebar.ListItem>
                      ))}
                    </MQSidebar.List>
                  </MQSidebar.ListToggle>
                </MQSidebar.ListItem>
              )}

              {!!retiredPrograms?.length && (
                <MQSidebar.ListItem>
                  <MQSidebar.ListToggle
                    key="retired"
                    eventKey={`category-retired`}
                    dataTestId="programs-category-retired"
                    badge={retiredPrograms?.length}
                    title={intl.formatMessage({
                      defaultMessage: 'Retired',
                      description: '[Admin.Sidebar] label',
                    })}
                    activeEventKeys={selectedKeys}
                    setActiveEventKeys={(keys) => setSelectedKey(keys)}
                    className="app-sidebar__list-toggle"
                  >
                    <MQSidebar.List className="app-sidebar__list">
                      {retiredPrograms?.map((program) => (
                        <MQSidebar.ListItem
                          as={AppLink}
                          data-testid={`sidebar-program-${[program.id]}-link`}
                          key={program.id}
                          to={ROUTES.programDashboard.convert({
                            path: {
                              programId: program.id,
                            },
                          })}
                          overlay={<MQIcon.Svg icon="arrow-circle-right" />}
                          truncate
                        >
                          <AppDynamicMessage defaultMessage={program.name} />
                        </MQSidebar.ListItem>
                      ))}
                    </MQSidebar.List>
                  </MQSidebar.ListToggle>
                </MQSidebar.ListItem>
              )}
            </MQSidebar.List>
          </MQSidebar.ListItem>
        )}

        {!!programDraftsList?.length && (
          <MQSidebar.ListItem>
            <MQSidebar.ListToggle
              key="draft-programs"
              eventKey="draft-programs"
              dataTestId="program-draft-programs"
              isDraft
              title={intl.formatMessage({
                defaultMessage: 'Draft Programs',
                description: '[Admin.Sidebar] label',
              })}
              badge={programDraftsList.length}
              activeEventKeys={selectedKeys}
              setActiveEventKeys={(keys) => setSelectedKey(keys)}
              className="app-sidebar__list-toggle"
            >
              <MQSidebar.List className="app-sidebar__list">
                {programDraftsList
                  ?.filter((draft) => draft.userId === user?.id)
                  ?.map((draft) => (
                    <MQSidebar.ListItem
                      as={AppLink}
                      data-testid={`sidebar-draft-${draft.id}-link`}
                      key={`draft-${draft.id}`}
                      to={
                        draft.programId
                          ? ROUTES.programWizardDraftEdit.convert({
                              path: {
                                programId: draft.programId,
                                draftId: draft.id,
                              },
                            })
                          : ROUTES.programWizardDraftPreview.convert({
                              path: {
                                draftId: draft.id,
                              },
                            })
                      }
                      overlay={<MQIcon.Svg icon="arrow-circle-right" />}
                      truncate
                    >
                      <FormattedMessage
                        defaultMessage="{programName} (owner)"
                        description="[Dashboard] Draft Program Name"
                        values={{
                          programName: draft.programName,
                        }}
                      />
                    </MQSidebar.ListItem>
                  ))}
                {programDraftsList
                  ?.filter((draft) => draft.userId !== user?.id)
                  ?.map((draft) => (
                    <MQSidebar.ListItem
                      as={AppLink}
                      data-testid={`sidebar-draft-${draft.id}-link`}
                      key={`draft-${draft.id}`}
                      to={
                        draft.programId
                          ? ROUTES.programWizardDraftEdit.convert({
                              path: {
                                programId: draft.programId,
                                draftId: draft.id,
                              },
                            })
                          : ROUTES.programWizardDraftPreview.convert({
                              path: {
                                draftId: draft.id,
                              },
                            })
                      }
                      overlay={<MQIcon.Svg icon="arrow-circle-right" />}
                      truncate
                    >
                      {draft.programName}
                    </MQSidebar.ListItem>
                  ))}
              </MQSidebar.List>
            </MQSidebar.ListToggle>
          </MQSidebar.ListItem>
        )}
      </MQSidebar.List>
    </MQSidebar.ListItem>
  );
};

export default Programs;
