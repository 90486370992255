import { FC, useEffect, useRef, useState } from 'react';

import { calculateAnimationResult, calculateCountDifference } from '../../helpers';
import { CountResultsDiff, MQAnimateCountProps } from '../../types';

const MQAnimateCount: FC<MQAnimateCountProps> = ({
  steps,
  minStepDiff,
  currentCount,
  stepTime = 50,
  formatCount,
  forceChange,
}) => {
  const [count, setCount] = useState(currentCount);
  const [diff, setDiff] = useState<CountResultsDiff | null>(null);
  const animationsInterval = useRef(0);

  useEffect(() => {
    if (forceChange) {
      setCount(currentCount);
    } else {
      setCount((prevCount) => {
        setDiff(null);
        const currentDiff = calculateCountDifference(prevCount, currentCount, steps, minStepDiff);
        if (currentDiff) {
          setDiff(currentDiff);
          return prevCount;
        } else {
          return currentCount;
        }
      });
    }
  }, [steps, minStepDiff, currentCount, forceChange]);

  useEffect(() => {
    window.clearInterval(animationsInterval.current);
    if (diff) {
      animationsInterval.current = window.setInterval(
        () =>
          setCount((prevState) => {
            const animationResult = calculateAnimationResult(prevState, diff);

            if (animationResult.finalStep) {
              setDiff(null);
            }

            return animationResult.result;
          }),
        stepTime,
      );
    }
  }, [diff, stepTime]);

  return <>{formatCount ? formatCount(count) : count}</>;
};

export default MQAnimateCount;
