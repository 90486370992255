import { FC, ReactNode } from 'react';

import { NavLink, NavLinkProps } from 'react-router-dom';

interface AppLinkProps extends NavLinkProps {
  children?: ReactNode;
}

const AppLink: FC<AppLinkProps> = ({ ...props }) => <NavLink {...props} />;

export default AppLink;
