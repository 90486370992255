import './style.scss';

import { FC } from 'react';

import classNames from 'classnames';

import { MQAvatarBadgeProps } from '../../types';

const MQAvatarBadge: FC<MQAvatarBadgeProps> = ({ inline = false, children, badge, className, dataTestId }) => (
  <div data-testid={dataTestId} className={classNames(className, 'mq-avatar-badge', { inline })}>
    <div className="mq-avatar-badge__image">{children}</div>
    {badge && <div className="mq-avatar-badge__label">{badge}</div>}
  </div>
);

export default MQAvatarBadge;
