import { FC, ReactNode, useEffect } from 'react';

import { brandingSlice, updatePlatformInformationPrivateThunk } from '@mentorcliq/storage';

import { PreviewMessageTypesEnum, PreviewSourceTypesEnum } from 'types/global';

import { useAppDispatch } from 'hooks/useAppDispatch';

interface PreviewWrapperProps {
  children?: ReactNode;
}

const PreviewWrapper: FC<PreviewWrapperProps> = ({ children }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (window.self !== window.top) {
      window.onmessage = (event) => {
        if (event.data?.source === PreviewSourceTypesEnum.MQ) {
          switch (event.data.type) {
            case PreviewMessageTypesEnum.SyncConfigs: {
              dispatch(brandingSlice.actions.preset(event.data.payload.configs));
              break;
            }
            case PreviewMessageTypesEnum.SetVPSPreviewData: {
              dispatch(
                updatePlatformInformationPrivateThunk({
                  vpsConfiguration: event.data.payload.vpsConfiguration,
                }),
              );
              break;
            }
          }
        }
      };
    }
  }, [dispatch]);

  return <>{children}</>;
};

export default PreviewWrapper;
