import { RouteTypes } from 'types/route';

import { createRoute } from 'helpers/routes';

export default {
  cliqipedia: createRoute({
    params: {
      layoutTypes: [],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted],
    },
    data: {
      path: `/cliqipedia`,
      exact: true,
    },
  }),
};
