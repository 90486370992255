import './style.scss';

import { FC, useContext } from 'react';

import classNames from 'classnames';

import { MQNavProviderProps } from '../../types';
import { MQNavbarContext } from '../MQNavbar';

const MQNavProvider: FC<MQNavProviderProps> = ({ className = '', borderless = false, children }) => {
  const { expanded } = useContext(MQNavbarContext);
  return <div className={classNames(['mq-nav nav', className, { borderless, expanded }])}>{children}</div>;
};

export default MQNavProvider;
