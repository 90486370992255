import './style.scss';

import { FC } from 'react';

import classNames from 'classnames';

import { MQSidebarListItemComponentProps } from '../../types';

const MQSidebarListItem: FC<MQSidebarListItemComponentProps> = ({
  className = '',
  to,
  exact = false,
  children,
  prefix,
  postfix,
  overlay,
  truncate = false,
  as: Component,
  instance,
  ...props
}) => (
  <li data-testid="mq-sidebar-list-item" className={classNames(['mq-sidebar__item', className, { truncate }])}>
    {to && Component ? (
      <Component
        data-testid="mq-sidebar-list-item-link"
        {...props}
        className="mq-sidebar__link"
        to={to}
        exact={exact}
        ref={instance}
        onClick={(e: MouseEvent) => {
          if (!to || to === '#') {
            e.preventDefault();
          }
        }}
      >
        {prefix && <span className="mq-sidebar__item-icon">{prefix}</span>}
        <span className="mq-sidebar__item-label">{children}</span>
        {postfix && <span className="mq-sidebar__item-icon">{postfix}</span>}
        {overlay && <span className="mq-sidebar__item-overlay">{overlay}</span>}
      </Component>
    ) : (
      children
    )}
  </li>
);

export default MQSidebarListItem;
