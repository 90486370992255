import './style.scss';

import { FC, useMemo } from 'react';

import { MQBadge } from '@mentorcliq/ui';
import classNames from 'classnames';

import MQLayout from 'modules/MQLayout';
import MQTooltip from 'modules/MQTooltip';
import MQTypo from 'modules/MQTypo';

import { MQAvatarGroupProps } from '../../types';
import MQAvatarBadge from '../MQAvatarBadge';
import MQAvatarImage from '../MQAvatarImage';
import MQAvatarInfo from '../MQAvatarInfo';

const MQAvatarGroup: FC<MQAvatarGroupProps> = ({
  title,
  overlap = false,
  max = 6,
  size = 'md',
  items,
  label,
  nowrap,
}) => {
  const avatars = useMemo(() => {
    let key = 0;
    return items.slice(0, items.length <= max ? max : max - 1).map((item) => ({
      item,
      key: ++key,
    }));
  }, [items, max]);

  return (
    <div className="mq-avatar-group">
      {title && (
        <MQLayout.Heading>
          <MQTypo.Text size="5x" bold>
            {title}
          </MQTypo.Text>
        </MQLayout.Heading>
      )}

      <div className={classNames('mq-avatar-group__wrapper', { overlap, nowrap })}>
        {avatars.map(({ item, key }) => (
          <MQTooltip key={key} placement="bottom" overlay={item.helpText ?? item.title}>
            <div className="mq-avatar-group__item">
              <MQAvatarBadge
                badge={
                  item.badge && (
                    <MQBadge.Info variant={item.disabled ? 'disabled' : 'primary'} size={size}>
                      {item.badge}
                    </MQBadge.Info>
                  )
                }
              >
                <MQAvatarImage title={item.title} imageUrl={item.imageUrl} size={size} />
              </MQAvatarBadge>
            </div>
          </MQTooltip>
        ))}
        {label ? (
          <MQAvatarInfo size={size}>{label}</MQAvatarInfo>
        ) : (
          items.length > max && (
            <MQTooltip
              placement="bottom"
              overlay={items
                .slice(max - 1, items.length)
                .map((el) => el.helpText ?? el.title)
                .join(', ')}
            >
              <div className="mq-avatar-group__item">
                <MQAvatarInfo size={size}>{`+${items.length - max + 1}`}</MQAvatarInfo>
              </div>
            </MQTooltip>
          )
        )}
      </div>
    </div>
  );
};

export default MQAvatarGroup;
