import './style.scss';

import { FC } from 'react';

import classNames from 'classnames';

import { MQLayoutProviderProps } from '../../types';

const MQLayoutProvider: FC<MQLayoutProviderProps> = ({ className = '', id, children }) => (
  <div data-testid="mq-layout" className={classNames(['mq-layout', className])} id={id}>
    {children}
  </div>
);

export default MQLayoutProvider;
