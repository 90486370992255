import { FC } from 'react';

import classNames from 'classnames';

import { MQCustomIconProps } from '../../types';

const MQCustomIcon: FC<MQCustomIconProps> = ({ className, svg, width, height, ...props }) => (
  <span
    data-testid="mq-custom-icon"
    style={{ width, height }}
    dangerouslySetInnerHTML={{ __html: svg }}
    className={classNames('mq-custom-icon', [className])}
    {...props}
  />
);

export default MQCustomIcon;
