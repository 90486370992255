import { FeatureTypeEnum, PermissionsEnum } from '@mentorcliq/storage';
import { NoInfer } from 'react-redux';

import { AccessMetaDto, AccessParamsDto, ProviderTypesEnum } from 'types/access';

export const checkIsAuthorized = (params: AccessParamsDto, meta: AccessMetaDto) => {
  if (Object.values(params).every((param) => !param.value?.length)) {
    return true;
  }

  const access: Record<string, boolean> = {};

  const checkAccessItem = <T = unknown>(all: T[], permission: NoInfer<T>) => all.some((item) => item === permission);

  if (params.permissions?.value.length) {
    const grantedRoles = Object.entries(meta.permissions)
      .filter(([permissions]) => meta.adminMode || !permissions.includes(PermissionsEnum.AccessDashboard))
      .map(([role]) => role);

    const grantedPermissions = Object.fromEntries(
      Object.entries(meta.permissions).filter(([key]) => grantedRoles.includes(key)),
    );

    const flatPermissions = Object.values(grantedPermissions).flat();

    if (params.permissions.strict) {
      access.permissions = params.permissions.value.every((permission) => checkAccessItem(flatPermissions, permission));
    } else {
      access.permissions = params.permissions.value.some((permission) => checkAccessItem(flatPermissions, permission));
    }
  }

  if (params.features?.value.length) {
    const enabledFeatures = meta.features.filter(({ enabled }) => enabled);
    const checkFeatures = (feature: FeatureTypeEnum) => enabledFeatures.some((item) => item.name === feature);

    if (params.features?.strict) {
      access.features = params.features.value.every((feature) => checkFeatures(feature));
    } else {
      access.features = params.features.value.some((feature) => checkFeatures(feature));
    }
  }

  if (params.providers?.value.length) {
    const userProviders = {
      [ProviderTypesEnum.VpsEnabled]: meta.vpsEnabled,
      [ProviderTypesEnum.ProfilePictureEnabled]: meta.profilePictureEnabled,
      [ProviderTypesEnum.LabsEnabled]: meta.labsEnabled,
      [ProviderTypesEnum.AccountEditEnabled]: meta.accountEditEnabled,
    };

    if (params.providers?.strict) {
      access.providers = params.providers.value.every((el) => userProviders[el]);
    } else {
      access.providers = params.providers.value.some((el) => userProviders[el]);
    }
  }

  if (params.programs?.value.length) {
    if (params.programs?.strict) {
      access.programs = !!params.programs?.value.every((programId) => meta.programs.includes(programId));
    } else {
      access.programs = !!params.programs?.value.some((programId) => meta.programs.includes(programId));
    }
  }

  if (params.strict) {
    return Object.values(access).every(Boolean);
  }

  return Object.values(access).some(Boolean);
};
