import { lazy } from 'react';

import CUSTOM_ROUTES from '__custom/mq-advocateaurorahealth/routes';

import { APP_ENV_CONFIGS } from 'definitions/configs';

const CustomerBoundary = () => {
  if (APP_ENV_CONFIGS.projectId === 'mq-multidev' || APP_ENV_CONFIGS.projectId === 'localhost') {
    return {
      GraduationYearSelect: lazy(() => import('__custom/mq-americamentors/components/GraduationYearSelect')),
      GraduationYearLabel: lazy(() => import('__custom/mq-americamentors/components/GraduationYearLabel')),
      CustomQuickcliQsReport: lazy(() => import('__custom/mq-advocateaurorahealth/components/CustomQuickcliQsReports')),
      CustomReportsCard: lazy(() => import('__custom/mq-advocateaurorahealth/components/CustomReportsCard')),
      CustomCookiesPolicy: lazy(() => import('__custom/mq-deloitte-me/components/CustomCookiesPolicy')),
      AdvancedFilter: lazy(() => import('__custom/mq-team-site/components/CustomAdvancedFilters')),
      ReleaseInfoBanner: lazy(() => import('__custom/mq-team-site/components/ReleaseInfoBanner')),

      routes: { ...CUSTOM_ROUTES },
    };
  } else if (APP_ENV_CONFIGS.projectId === 'mq-americamentors') {
    return {
      GraduationYearSelect: lazy(() => import('__custom/mq-americamentors/components/GraduationYearSelect')),
      GraduationYearLabel: lazy(() => import('__custom/mq-americamentors/components/GraduationYearLabel')),
    };
  } else if (APP_ENV_CONFIGS.projectId === 'mq-advocateaurorahealth') {
    return {
      CustomQuickcliQsReport: lazy(() => import('__custom/mq-advocateaurorahealth/components/CustomQuickcliQsReports')),
      CustomReportsCard: lazy(() => import('__custom/mq-advocateaurorahealth/components/CustomReportsCard')),
      routes: { ...CUSTOM_ROUTES },
    };
  } else if (APP_ENV_CONFIGS.projectId === 'mq-deloitte-me') {
    return {
      CustomCookiesPolicy: lazy(() => import('__custom/mq-deloitte-me/components/CustomCookiesPolicy')),
    };
  } else if (APP_ENV_CONFIGS.projectId === 'mq-nielseniq') {
    return {
      AdvancedFilter: lazy(() => import('__custom/mq-nielseniq/components/CustomAdvancedFilters')),
    };
  } else if (
    APP_ENV_CONFIGS.projectId &&
    ['mq-team-site', 'mq-demo-base', 'mq-spring-react-internal', 'mq-spring-react-qa'].includes(
      APP_ENV_CONFIGS.projectId,
    )
  ) {
    return {
      AdvancedFilter: lazy(() => import('__custom/mq-team-site/components/CustomAdvancedFilters')),
      ReleaseInfoBanner: lazy(() => import('__custom/mq-team-site/components/ReleaseInfoBanner')),
    };
  }
  return { routes: {} };
};

export default CustomerBoundary;
