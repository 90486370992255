import ROUTES from 'routes';

const SUPPORT_ARTICLE_PREFIX = 'https://mentorcliq.zendesk.com';

export const SUPPORT_ARTICLES_CONFIGS = {
  supervisorApproval: `${SUPPORT_ARTICLE_PREFIX}/hc/en-us/articles/11338174981901`,
  ssoIntegration: `${SUPPORT_ARTICLE_PREFIX}/hc/en-us/articles/9975865824013-Single-Sign-On-SSO-Integration`,
  ssoTesting: `${SUPPORT_ARTICLE_PREFIX}/hc/en-us/articles/9975865824013`,
  learningLabOverView: `${SUPPORT_ARTICLE_PREFIX}/hc/en-us/articles/11460083925389-MentorcliQ-Reports-Overview-`,
  collaborationTools: `${SUPPORT_ARTICLE_PREFIX}/hc/en-us/articles/11015882508813-Scheduling-Time-with-My-Mentoring-Partner`,
  matchingOverView: `${SUPPORT_ARTICLE_PREFIX}/hc/en-us/articles/12099237165709-MentorcliQ-Matching-Overview-`,
  publishingMatches: `${SUPPORT_ARTICLE_PREFIX}/hc/en-us/articles/11103696136077-Publishing-Matches`,
  relationshipClosure: `${SUPPORT_ARTICLE_PREFIX}/hc/en-us/articles/11116533722637-Unmatching-and-Closing-Relationships`,
  intelligentMatching: `${SUPPORT_ARTICLE_PREFIX}/hc/en-us/articles/11225654849677`,
  viewParticipants: `${SUPPORT_ARTICLE_PREFIX}/hc/en-us/articles/11237731687821`,
  mentorCliQReportsOverview: `${SUPPORT_ARTICLE_PREFIX}/hc/en-us/articles/11460083925389-MentorcliQ-Reports-Overview-`,
  groupMatch: `${SUPPORT_ARTICLE_PREFIX}/hc/en-us/articles/9940325721101-Group-Matching`,
  adminMatching: `${SUPPORT_ARTICLE_PREFIX}/hc/en-us/articles/12092114316813-Admin-Matching`,
  manageInvitations: `${SUPPORT_ARTICLE_PREFIX}/hc/en-us/articles/9940667534221-Managing-and-Removing-Pending-Invitations`,
  sendCommunicationsImmediately: `${SUPPORT_ARTICLE_PREFIX}/hc/en-us/articles/10573051160077-Sending-a-Communication-Immediately`,
  manageParticipants: `${SUPPORT_ARTICLE_PREFIX}/hc/en-us/articles/9505074800525-Manage-Participants`,
  chatWithMatch: `${SUPPORT_ARTICLE_PREFIX}/hc/en-us/articles/11014384893453-Chatting-with-My-Mentoring-Partner-MentorcliQ-Chat-`,
  advancedFilters: `${SUPPORT_ARTICLE_PREFIX}/hc/en-us/articles/27957220595341-Using-Advanced-Filters-in-Communications`,
};

export const SUPPORT_KEYWORDS_CONFIGS = {
  addons: {
    keywords: 'Add-ons',
    locations: [ROUTES.addonsConfiguration.data.path],
  },
  support: {
    keywords: 'Support',
    locations: [ROUTES.support.data.path],
  },
  vps: {
    keywords: 'VPS',
    locations: [ROUTES.profileVps.data.path, ROUTES.peopleEditProfileVps.data.path, ROUTES.verifyVps.data.path],
  },
  privacy: {
    keywords: 'Privacy',
    locations: [ROUTES.privacy.data.path, ROUTES.acceptGdpr.data.path, ROUTES.gdprConsentView.data.path],
  },
  branding: {
    keywords: 'Branding',
    locations: [ROUTES.brandingConfiguration.data.path],
  },
  calendar: {
    keywords: 'Calendar',
    locations: [ROUTES.calendarConfiguration.data.path],
  },
  platformConfiguration: {
    keywords: 'Configuration',
    locations: [ROUTES.platformConfiguration.data.path],
  },
  platformInfo: {
    keywords: 'Info',
    locations: [ROUTES.platformInfoConfiguration.data.path],
  },
  manageResources: {
    keywords: 'Manage Resources',
    locations: [ROUTES.managePlatformResources.data.path],
  },
  profileDisplay: {
    keywords: 'Profile Display',
    locations: [ROUTES.profileDisplay.data.path],
  },
  profileFields: {
    keywords: 'Profile Fields',
    locations: [ROUTES.profileFields.data.path],
  },
  contentHelpDesk: {
    keywords: 'Content Helpdesk',
    locations: [ROUTES.helpdesk.data.path],
  },
  matchPreferences: {
    keywords: 'Match Preferences',
    locations: [ROUTES.editPreferences.data.path],
  },
  requestMatch: {
    keywords: 'Request Match',
    locations: [ROUTES.editRequestMatch.data.path],
  },
  suggestMatch: {
    keywords: 'Suggest Match',
    locations: [ROUTES.editSuggestMatch.data.path],
  },
  inclusionLab: {
    keywords: 'Inclusion Lab',
    locations: [ROUTES.inclusionLab.data.path],
  },
  inclusionLabMasterClass: {
    keywords: 'Inclusion Lab Master Class',
    locations: [ROUTES.inclusionLabMasterClass.data.path],
  },
  learningLab: {
    keywords: 'Learning Lab',
    locations: [ROUTES.labsList.data.path],
  },
  leadershipLab: {
    keywords: 'Leadership Lab',
    locations: [ROUTES.leadershipLab.data.path],
  },
  leadershipLabMasterClass: {
    keywords: 'Leadership Lab Master Class',
    locations: [ROUTES.leadershipLabMasterClass.data.path],
  },
  leadershipLabQuickStart: {
    keywords: 'Leadership Lab Quick Start',
    locations: [ROUTES.leadershipLabQuickStart.data.path],
  },
  mentorLab: {
    keywords: 'Mentor Lab',
    locations: [ROUTES.mentorLab.data.path],
  },
  mentorLabQuickStart: {
    keywords: 'Mentor Lab Quick Start',
    locations: [ROUTES.mentorLabQuickStart.data.path],
  },
  people: {
    keywords: 'people',
    locations: [ROUTES.peopleList.data.path],
  },
  programDashBoard: {
    keywords: 'Program Dashboard',
    locations: [ROUTES.programDashboard.data.path],
  },
  manageParticipants: {
    keywords: 'Manage Participants',
    locations: [ROUTES.manageParticipants.data.path],
  },
  sendCommunications: {
    keywords: 'Send Communications',
    locations: [ROUTES.sendCommunication.data.path],
  },
  manageInvitations: {
    keywords: 'Manage Invitations',
    locations: [ROUTES.manageInvitations.data.path],
  },
  invitationsInvite: {
    keywords: 'Invite {role}',
    resolve: ({ role = 'Participant' }) => `Invite ${role}`,
    locations: [],
  },
  manageMatches: {
    keywords: 'Manage Matches',
    locations: [ROUTES.manageMatches.data.path],
  },
  detailedReports: {
    keywords: 'Detailed Reports',
    locations: [ROUTES.detailedReportsDashboard.data.path],
  },
  programDataExport: {
    keywords: 'Export Data',
    locations: [ROUTES.platformReports.data.path],
  },
  programReports: {
    keywords: 'Program Reports',
    locations: [ROUTES.programReports.data.path],
  },
  quickQliQManualSend: {
    keywords: 'Send QuickcliQs',
    locations: [ROUTES.programSettingsQuickcliQsManualSend.data.path],
  },
  designNewProgram: {
    keywords: 'Design new program',
    locations: [ROUTES.programWizardDraftCreate.data.path, ROUTES.programWizardWelcome.data.path],
  },
  configureProgram: {
    keywords: 'Configure Program',
    locations: [ROUTES.programWizardDraftEdit.data.path],
  },
  addProgram: {
    keywords: 'Add Program',
    locations: [ROUTES.programWizardDraftPreview.data.path],
  },
  programTemplates: {
    keywords: 'Program Templates',
    locations: [ROUTES.programWizardTemplates.data.path],
  },
  programWizardWelcome: {
    keywords: 'Design New Program',
    locations: [],
  },
  mentorFlixReports: {
    keywords: 'MentorFlix Reports',
    locations: [ROUTES.mentorFlixReport.data.path],
  },
  exportData: {
    keywords: 'Export data',
    locations: [ROUTES.programExportData.data.path],
  },
  resourceLibrary: {
    keywords: 'Resource Library',
    locations: [ROUTES.resourceLibrary.data.path],
  },
  myCliQ: {
    keywords: 'My CliQ',
    locations: [ROUTES.myCliQ.data.path],
  },
  editProfile: {
    keywords: 'Edit Profile',
    locations: [ROUTES.profileEdit.data.path],
  },
  editProfilePicture: {
    keywords: 'Profile',
    locations: [ROUTES.profilePicture.data.path],
  },
  openPrograms: {
    keywords: 'Open Programs',
    locations: [ROUTES.programs.data.path],
  },
  timeLine: {
    keywords: 'Timeline',
    locations: [ROUTES.timeline.data.path],
  },
  createProfile: {
    keywords: 'Profile',
    locations: [ROUTES.verifyProfile.data.path],
  },
  platformWelcome: {
    keywords: 'Welcome',
    locations: [ROUTES.verifyWelcome.data.path],
  },
  intelligentMatching: {
    keywords: 'Intelligent Matching',
    locations: [ROUTES.intelligentMatching.data.path],
  },
  developingOthersReport: {
    keywords: 'Developing Others Report',
    locations: [ROUTES.developmentReportsOthers.data.path],
  },
  individualDevelopmentReport: {
    keywords: 'Individual Development Report',
    locations: [ROUTES.developmentReportsPersonal.data.path],
  },
  meeting: {
    keywords: 'Schedule Meeting',
    locations: [ROUTES.meeting.data.path],
  },
  adminDashboard: {
    keywords: 'Admin Dashboard',
    locations: [ROUTES.adminDashboard.data.path],
  },
  mentorFlix: {
    keywords: 'MentorFlix',
    locations: [ROUTES.mentorFlix.data.path],
  },
  contentHelpdeskReport: {
    keywords: 'Content Helpdesk Report',
    locations: [ROUTES.helpdeskReport.data.path],
  },
  resetStatuses: {
    keywords: 'Reset Participant Statuses',
    locations: [ROUTES.resetParticipantStatuses.data.path],
  },
  resourceLibraryReport: {
    keywords: 'Resource Library Report',
    locations: [ROUTES.resourceLibraryReport.data.path],
  },
  viewParticipants: {
    keywords: 'View Participants',
    locations: [ROUTES.viewParticipants.data.path],
  },
  smartMatch: {
    keywords: 'Smart Match Participants',
    locations: [ROUTES.smartMatch.data.path],
  },
  groupMatch: {
    keywords: 'Group Match Participants',
    locations: [ROUTES.groupMatch.data.path],
  },
  conclude: {
    keywords: 'Leave Relationship',
    locations: [ROUTES.conclude.data.path],
  },
  quickcliqSubmission: {
    keywords: 'QuickcliQ Submission',
    locations: [ROUTES.quickcliqSubmission.data.path],
  },
  surveySubmission: {
    keywords: 'Survey Submission',
    locations: [ROUTES.quickcliqSubmission.data.path],
  },
  approvals: {
    keywords: 'Approval',
    locations: [ROUTES.approvals.data.path],
  },
  goals: {
    keywords: 'Program Goals',
    locations: [ROUTES.goalsList.data.path],
  },
  goalEdit: {
    keywords: 'Edit Program Goal',
    locations: [ROUTES.editGoal.data.path],
  },
  goalAdd: {
    keywords: 'Add Program Goal',
    locations: [ROUTES.addGoal.data.path],
  },
  milestones: {
    keywords: 'Program Milestones',
    locations: [ROUTES.milestones.data.path],
  },
  mentorLabMasterClass: {
    keywords: 'Mentor Lab Master Class',
    locations: [ROUTES.mentorLabMasterClass.data.path],
  },
  inclusionLabQuickStart: {
    keywords: 'Inclusion Lab Quick Start',
    locations: [ROUTES.inclusionLabQuickStart.data.path],
  },
  sso: {
    keywords: 'SSO',
    locations: [ROUTES.ssoConfigurationLogging.data.path, ROUTES.ssoConfigurationSetup.data.path],
  },
  boostedCalculator: {
    keywords: 'ROI Calculator (Boost Version)',
    locations: [],
  },
  freeCalculator: {
    keywords: 'ROI Calculator',
    locations: [],
  },
  roiTrackingReport: {
    keywords: 'ROI Tracking Report',
    locations: [ROUTES.roiTrackingReport.data.path],
  },
  roiProfileFieldSummary: {
    keywords: 'Profile Field Summary',
    locations: [ROUTES.reportsROICalculatorProfileFieldSummary.data.path],
  },
};
