import './style.scss';

import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { FileDestinationEnum, uploadChatFileThunk } from '@mentorcliq/storage';
import * as yup from 'yup';

import { APP_KEYBOARD_EVENT_KEYS } from 'definitions/configs';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppErrors, useAppFormik } from 'hooks/useAppFormik';
import { useAppIntl } from 'hooks/useAppIntl';

import MQButton from 'modules/MQButton';
import MQForm from 'modules/MQForm';
import MQIcon from 'modules/MQIcon';

import AppFilePreview from 'components/AppFilePreview';
import AppFileUpload from 'components/AppFileUpload';

import { ChatMessageDataProps } from '../../types';
import ChatEmojis from '../ChatEmojis';

interface ChatToolbarProps {
  matchId: number;
  sendMessage: (message: ChatMessageDataProps) => void;
}

const CHAT_MESSAGE_MAX_LENGTH = 300;

const ChatToolbar: FC<ChatToolbarProps> = ({ sendMessage, matchId }) => {
  const intl = useAppIntl();
  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [showEmojis, setShowEmojis] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [errors, setErrors] = useAppErrors({
    attachment: null,
  });

  const formik = useAppFormik<ChatMessageDataProps>({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      message: yup.string().max(CHAT_MESSAGE_MAX_LENGTH, ({ value }) =>
        intl.formatMessage(
          {
            defaultMessage: `Must be less than {max} characters ({length} / {max})`,
            id: 'chat.message.max.validation',
            description: '[Chat] message length validation',
          },
          {
            length: value.length,
            max: CHAT_MESSAGE_MAX_LENGTH,
          },
        ),
      ),
    }),
    initialValues: {
      message: '',
      attachment: null,
    },
    onSubmit: (values, { resetForm }) => {
      if (values.message || values.attachment) {
        sendMessage({
          attachment: values.attachment,
          message: values.message.replaceAll('<br/>', '\n'),
        });
        resetForm({
          values: {
            message: '',
            attachment: null,
          },
          errors: {},
        });
      }
      setShowEmojis(false);
    },
  });

  const { values, setValues } = formik;

  const appendAfterCursor = useCallback(
    (content: string) => {
      if (inputRef.current) {
        const textarea = inputRef.current;
        const cursorPos = textarea.selectionStart;
        const newMessage = values.message.substring(0, cursorPos) + content + values.message.substring(cursorPos);
        setValues((prevState) => ({
          ...prevState,
          message: newMessage,
        }));
        const newCursorPos = cursorPos + content.length;
        textarea.focus();
        textarea.setSelectionRange(newCursorPos, newCursorPos, 'forward');
      }
    },
    [setValues, values.message],
  );

  const handleUpload = async (file: File) => {
    setUploading(true);

    const formData = new FormData();
    formData.append('file', file, file.name);

    const response = await dispatch(
      uploadChatFileThunk({
        matchId,
        data: formData,
      }),
    );

    if (uploadChatFileThunk.fulfilled.match(response)) {
      await setValues((prevState) => ({
        ...prevState,
        attachment: response.payload,
      }));
    }

    setUploading(false);
  };

  useEffect(() => {
    if (inputRef.current) {
      if (!values.message.length) {
        inputRef.current.style.height = 'auto';
      } else {
        inputRef.current.style.height = 'auto';
        inputRef.current.style.height = `${inputRef.current.scrollHeight + 1}px`;

        if (inputRef.current.scrollHeight > inputRef.current.offsetHeight) {
          inputRef.current.style.overflow = 'auto';
        } else {
          inputRef.current.style.overflow = 'hidden';
        }
      }
    }
  }, [values.message.length]);

  return (
    <div className="app-chat-toolbar">
      <MQForm onSubmit={formik.handleSubmit} className="app-chat-toolbar__form">
        {!!values.attachment && (
          <div className="app-chat-toolbar__attachment">
            <AppFilePreview
              uuid={values.attachment.uuid}
              onRemove={() => {
                setErrors({
                  message: errors.message,
                });
                setValues((prev) => ({
                  ...prev,
                  attachmentId: null,
                }));
              }}
              height={100}
              showInfo
              cover
              allowDeleteFileFromStorage
            />
          </div>
        )}
        <MQForm.Group>
          <div className="app-chat-toolbar__buttons">
            <div className="app-chat-toolbar__tools">
              <AppFileUpload
                triggers={['drop']}
                onUpload={(files) => handleUpload(files[0])}
                type={FileDestinationEnum.Chat}
                onReject={({ message }) => {
                  setErrors((prevState) => ({
                    ...prevState,
                    attachment: message,
                  }));
                }}
                onBlur={() => {
                  setErrors((prevState) => ({
                    ...prevState,
                    attachment: null,
                  }));
                }}
              >
                <MQForm.Textarea
                  instance={inputRef}
                  placeholder={intl.formatMessage({
                    id: 'chat.message.input.placeholder',
                    defaultMessage: 'Write a message...',
                    description: '[Chat] Message Input placeholder',
                  })}
                  onKeyDown={(e) => {
                    if (APP_KEYBOARD_EVENT_KEYS.enter.includes(e.key) && !e.shiftKey) {
                      e.preventDefault();
                      return formik.submitForm();
                    }
                  }}
                  onChange={(e) => {
                    setValues((prevState) => ({
                      ...prevState,
                      message: e.currentTarget.value,
                    }));
                  }}
                  resize="none"
                  rows={1}
                  className="app-chat-toolbar__input"
                  dataTestId="chat-toolbar-write-a-message"
                  value={values.message}
                  autoFocus
                />
              </AppFileUpload>
              <div className="app-chat-toolbar__helpers">
                <MQButton
                  dataTestId="toggle-chat-emojis"
                  type="button"
                  shape="square"
                  variant="sub-action--tertiary"
                  startIcon={<MQIcon.Svg icon="face-smile" size="lg" />}
                  onClick={() => {
                    setShowEmojis(!showEmojis);
                  }}
                />
                <AppFileUpload
                  onUpload={(files) => handleUpload(files[0])}
                  type={FileDestinationEnum.Chat}
                  onReject={({ message }) => {
                    setErrors((prevState) => ({
                      ...prevState,
                      attachment: message,
                    }));
                  }}
                  onBlur={() => {
                    setErrors((prevState) => ({
                      ...prevState,
                      attachment: null,
                    }));
                  }}
                >
                  <MQButton
                    dataTestId="toggle-chat-file"
                    type="button"
                    variant="sub-action--tertiary"
                    shape="square"
                    isLoading={uploading}
                    startIcon={<MQIcon.Svg icon="paperclip-light" size="lg" />}
                  />
                </AppFileUpload>
              </div>
            </div>
            <MQButton
              dataTestId="send-message"
              startIcon={<MQIcon.Svg icon="send" size="lg" />}
              variant="sub-action--tertiary"
              shape="square"
              type="submit"
            />
          </div>
          <MQForm.Feedback type="invalid" touched={formik.touched.message}>
            {formik.errors.message}
          </MQForm.Feedback>
          <MQForm.Feedback type="invalid">{errors.attachment}</MQForm.Feedback>
        </MQForm.Group>
        {showEmojis && (
          <div className="app-chat-toolbar__emojis">
            <ChatEmojis
              onSelect={(data) => {
                appendAfterCursor(data.emoji);
              }}
            />
          </div>
        )}
      </MQForm>
    </div>
  );
};

export default ChatToolbar;
