import './style.scss';

import { FC } from 'react';

import classNames from 'classnames';

import { MQRowProps } from '../../types';

const MQRow: FC<MQRowProps> = ({ compact = false, className = '', dataTestId = '', centered = false, ...props }) => (
  <div
    data-testid={dataTestId || 'mq-grid-row'}
    className={classNames(['mq-grid-row', 'row', className, { compact, centered }])}
    {...props}
  />
);

export default MQRow;
