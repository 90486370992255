import { LayoutTypesEnum, RouteTypes } from 'types/route';

import { createRoute } from 'helpers/routes';

export default {
  enrollment: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
    },
    data: {
      path: `/enroll`,
    },
  }),
  enrollWizard: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
    },
    data: {
      path: '/enroll/wizard',
    },
  }),
  enrollWizardOverview: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
    },
    data: {
      path: '/enroll/wizard/overview',
      exact: true,
    },
  }),
  enrollWizardWelcome: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
    },
    data: {
      path: '/enroll/wizard/welcome/:programId',
      exact: true,
    },
  }),
  enrollWizardProgram: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified],
    },
    data: {
      path: '/enroll/wizard/program',
      exact: true,
    },
  }),
  editPreferences: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
    },
    data: {
      path: '/enroll/preferences/:programId/:roleId/:enrollmentId',
      exact: true,
    },
  }),
  editRequestMatch: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
    },
    data: {
      path: `/enroll/request-match/:enrollmentId`,
      exact: true,
    },
  }),
  editSuggestMatch: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
    },
    data: {
      path: `/enroll/suggest-match/:enrollmentId`,
      exact: true,
    },
  }),
};
