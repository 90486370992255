import './style.scss';

import { FC, useRef } from 'react';

import classNames from 'classnames';

import MQDefaultImage from './assets/images/defaultImage.png';
import { MQImageProps } from './types';

const MQImage: FC<MQImageProps> = ({
  className = '',
  src,
  defaultImage,
  contain = false,
  cover = false,
  width = 150,
  height = 'auto',
  style,
  thumbnail = false,
  fluid = false,
  rounded = false,
  roundedCircle = false,
  stretched = false,
  alt = '',
  dataTestId = 'mq-image',
  ...props
}) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const handleOnError = () => {
    if (imageRef.current) {
      imageRef.current.classList.add('mq-image--error');
      imageRef.current.src = defaultImage || MQDefaultImage;
    }
  };

  return (
    <img
      data-testid={dataTestId}
      ref={imageRef}
      decoding="async"
      className={classNames('mq-image', className, {
        thumbnail,
        fluid,
        rounded,
        stretched,
        roundedCircle,
        cover,
        contain,
      })}
      width={width}
      height={height}
      alt={alt || ''}
      onError={handleOnError}
      style={style}
      src={src || defaultImage || MQDefaultImage}
      {...props}
    />
  );
};

export default MQImage;
