import './style.scss';

import { Children, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import classNames from 'classnames';

import { MQSliderProviderProps } from '../../types';
import MQSliderItem from '../MQSliderItem';
import MQSliderSwiper from '../MQSliderSwiper';

export const ArrowKeys = {
  ArrowRight: 'ArrowRight',
  ArrowLeft: 'ArrowLeft',
};

const MQSliderProvider: FC<MQSliderProviderProps> = ({
  className = '',
  direction = 'horizontal',
  animation = 'slide',
  navigation,
  data,
  breakPoints = [
    {
      size: 1200,
      count: 3,
    },
    {
      size: 500,
      count: 3,
    },
    {
      size: 0,
      count: 3,
    },
  ],
  gap = 0,
  sliding = true,
  activeSlide = 0,
  showButton = false,
  showDots = false,
  slidesPerView = 5,
  slidesPerSlide = 1,
  autoAdjustHeight = true,
  onSlideChange,
  renderItem,
  children,
}) => {
  const [active, setActive] = useState(activeSlide);
  const [view, setView] = useState(slidesPerView);
  const [pos, setPos] = useState({ x: 0, y: 0 });
  const ref = useRef<HTMLDivElement>(null);

  const count = useMemo(() => data?.length || Children.count(children), [children, data?.length]);
  const grow = useMemo(() => 100 / Math.min(count, view), [view, count]);

  useEffect(() => {
    setActive(activeSlide);
  }, [activeSlide]);

  useEffect(() => {
    function calculateSlidesToShow(windowWidth: number): number {
      let slidesToShow = 1;
      Object.entries(breakPoints).forEach(([breakpoint, config]) => {
        if (windowWidth >= parseInt(breakpoint)) {
          slidesToShow = config.count;
        }
      });

      return slidesToShow;
    }

    const handleResize = () => {
      const windowWidth = window.innerWidth;
      setView(calculateSlidesToShow(windowWidth));
    };

    const windowWidth = window.innerWidth;
    setView(calculateSlidesToShow(windowWidth));

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [breakPoints]);

  const animate = useCallback(
    (index: number) => {
      switch (animation) {
        case 'scale':
          return {
            padding: gap,
            flex: active === index ? 2 : 1,
          };
        case 'slide':
        default:
          return {
            padding: gap,
            flex: `0 0 ${grow}%`,
          };
      }
    },
    [animation, gap, active, grow],
  );

  useEffect(() => {
    const handleResize = () => {
      if (ref.current?.clientWidth) {
        const width = ref.current.clientWidth;
        const ordered = breakPoints.sort((a, b) => b.size - a.size);
        const point = ordered?.find((item) => item.size < width);

        if (point) {
          setView(point.count);
        }
      }
    };

    const observer = new ResizeObserver(handleResize);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [breakPoints, autoAdjustHeight]);

  const handleSlideTo = (slide: number, event?: string) => {
    const newActiveSlide = Math.max(Math.min(slide, count - slidesPerSlide), 0);
    setActive(newActiveSlide);

    onSlideChange?.(newActiveSlide, slide, event);
  };

  const items = useMemo(() => {
    let key = 0;
    return (data || Children.toArray(children)).map((item) => ({
      item,
      key: ++key,
    }));
  }, [data, children]);

  const translate = useMemo(
    () =>
      Math.min(
        Math.max((count - slidesPerSlide) * grow - count * (view - Math.floor(view)) * grow, 0),
        Math.min(active, count - slidesPerSlide) * grow,
      ),
    [count, slidesPerSlide, grow, view, active],
  );

  const diff = useMemo(() => {
    if (ref.current) {
      return {
        x: (pos.x / ref.current?.clientWidth) * 100,
        y: (pos.y / ref.current?.clientWidth) * 100,
      };
    }

    return {
      x: 0,
      y: 0,
    };
  }, [pos]);

  return (
    <div>
      <div
        ref={ref}
        className={classNames('mq-slider', className, direction, { animate: !diff?.x && !diff?.y })}
        onKeyDown={(event) => {
          if (event.key === ArrowKeys.ArrowRight) {
            handleSlideTo(active + 1, event.key);
          } else if (event.key === ArrowKeys.ArrowLeft) {
            handleSlideTo(active - 1, event.key);
          }
        }}
      >
        {showButton && (
          <button
            onClick={() => {
              handleSlideTo(active - slidesPerSlide);
            }}
            disabled={active === 0 && !navigation?.firstEl}
            className="mq-slider__button prev"
            aria-label="Sidebar Prev Button"
          >
            {(count - active <= slidesPerSlide && navigation?.firstEl) || navigation?.prevEl || '<'}
          </button>
        )}
        <div className="mq-slider__wrapper" tabIndex={-1}>
          <MQSliderSwiper
            onSwipe={(pos) => {
              setPos(pos);
            }}
            onSwipeEnd={(pos) => {
              if (ref.current) {
                setPos({
                  x: 0,
                  y: 0,
                });

                if (pos.x) {
                  handleSlideTo(active - Math.round(((pos.x / ref.current.clientWidth) * 100) / grow));
                } else if (pos.y) {
                  handleSlideTo(active - Math.round(((pos.y / ref.current.clientHeight) * 100) / grow));
                }
              }
            }}
            direction={direction === 'horizontal' ? 'x' : 'y'}
          >
            {!!items?.length && (
              <div
                className="mq-slider__inner"
                style={{
                  padding: gap,
                  transform: sliding
                    ? direction === 'horizontal'
                      ? `translateX(-${translate - diff.x}%)`
                      : `translateY(-${translate - diff.y}%)`
                    : '',
                }}
              >
                {items?.map(({ item, key }, index) => {
                  const styles = animate(index);
                  return (
                    <div key={key} className="mq-slider__item" style={styles}>
                      <MQSliderItem
                        active={index === active}
                        slideIndex={index}
                        onFocus={(slide) => {
                          handleSlideTo(slide, 'tab');
                        }}
                      >
                        {renderItem?.(item, index) || item}
                      </MQSliderItem>
                    </div>
                  );
                })}
              </div>
            )}
          </MQSliderSwiper>
        </div>
        {showButton && (
          <button
            onClick={() => handleSlideTo(active + slidesPerSlide)}
            disabled={active + view >= count && !navigation?.lastEl}
            className="mq-slider__button next"
            aria-label="Sidebar Next Button"
          >
            {(active + view >= count && navigation?.lastEl) || navigation?.nextEl || '>'}
          </button>
        )}
      </div>
      {showDots && (
        <div className="mq-slider__dots">
          {items.map((_, index) => (
            <span
              key={`${index + active}`}
              className={classNames('mq-slider__dot', { active: index === active })}
              onClick={() => handleSlideTo(index)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default MQSliderProvider;
