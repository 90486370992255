import './style.scss';

import { FC, ReactNode } from 'react';

import classNames from 'classnames';

type MQSliderItemProps = {
  children?: ReactNode;
  slideIndex: number;
  active?: boolean;
  disabled?: boolean;
  onClick?: (index: number) => void;
  onFocus?: (index: number) => void;
  width?: number;
  className?: string;
};

const MQSliderItem: FC<MQSliderItemProps> = ({
  children,
  active = false,
  disabled = false,
  slideIndex,
  onClick,
  onFocus,
  width,
  className,
}) => (
  <div
    style={{ width: width }}
    data-index={slideIndex}
    className={classNames('mq-slider-item', { disabled }, { active }, className)}
    onClick={() => !disabled && !active && onClick?.(slideIndex)}
    onFocus={() => !disabled && onFocus?.(slideIndex)}
  >
    {children}
  </div>
);

export default MQSliderItem;
