import './style.scss';

import { FC, HTMLAttributes } from 'react';

import classNames from 'classnames';

const MQFormSection: FC<HTMLAttributes<HTMLDivElement>> = ({ className = '', ...props }) => (
  <div {...props} data-testid="mq-form-section" className={classNames('mq-form-section', className)} />
);

export default MQFormSection;
