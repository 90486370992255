import { FC, ReactNode, useEffect, useMemo } from 'react';

import { getBrandingConfigsSelector, getBrandingLogoConfigsSelector } from '@mentorcliq/storage';

import { generateCSSVariableStyles, transformToCSSVariables } from 'helpers/branding';

import { useAppSelector } from 'hooks/useAppSelector';

interface BrandingWrapperProps {
  tagId?: string;
  children?: ReactNode;
}

const BrandingWrapper: FC<BrandingWrapperProps> = ({ tagId = 'mq-styles', children }) => {
  const brandingConfigs = useAppSelector(({ branding }) => getBrandingConfigsSelector(branding));
  const brandingLogoConfigs = useAppSelector(({ branding }) => getBrandingLogoConfigsSelector(branding));

  useEffect(() => {
    if (brandingLogoConfigs) {
      document.getElementById('favicon')?.setAttribute('href', brandingLogoConfigs.favicon.fileHandle);
    }
  }, [brandingLogoConfigs]);

  const styles = useMemo(() => {
    if (brandingConfigs?.colors) {
      return generateCSSVariableStyles([
        ...transformToCSSVariables(brandingConfigs.colors.adminPanel, { prefix: 'admin-panel' }),
        ...transformToCSSVariables(brandingConfigs.colors.languageOption, { prefix: 'language-option' }),
        ...transformToCSSVariables(brandingConfigs.colors.userMenu, { prefix: 'user-menu' }),
        ...transformToCSSVariables(brandingConfigs.colors.topNavigation, { prefix: 'top-navigation' }),
        ...transformToCSSVariables(
          {
            rollover: `${brandingConfigs.colors.adminPanel.rollover}0d`,
            scrollbar: `${brandingConfigs.colors.adminPanel.scrollbar}80`,
          },
          { prefix: 'admin-panel' },
        ),
      ]);
    }
  }, [brandingConfigs?.colors]);

  return (
    <>
      {styles && <style id={tagId}>{styles}</style>}
      {children}
    </>
  );
};

export default BrandingWrapper;
