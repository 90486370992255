import { default as Caption } from './MQCaption';
import { default as Formatter } from './MQFormatter';
import { default as Paragraph } from './MQParagraph';
import { default as Text } from './MQText';
import { default as Title } from './MQTitle';

const MQTypo = {
  Title,
  Text,
  Formatter,
  Paragraph,
  Caption,
};

export default MQTypo;
