import './style.scss';

import { FC, ReactNode } from 'react';

import classNames from 'classnames';

export interface MQCardNavProps {
  className?: string;
  children?: ReactNode;
  dataTestId?: string;
}

const MQCardNav: FC<MQCardNavProps> = ({ className = '', dataTestId = 'mq-card-nav', ...props }) => (
  <div data-testid={dataTestId} className={classNames(['mq-card-nav', className])} {...props} />
);

export default MQCardNav;
