import { FC, ReactNode, useMemo } from 'react';

import {
  getProfileCurrentStateThunk,
  getProfilePreviousStateThunk,
  ProfileStateEnum,
  setProfileNextStateThunk,
} from '@mentorcliq/storage';

import ROUTES from 'routes';

import { AppVerifyContext, AppVerifyContextParams } from 'definitions/context';

import { useAppAuthState } from 'hooks/useAppAuthState';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppRouter } from 'hooks/useAppRouter';

import AppRedirect from 'router/AppRedirect';

import EnrollProvider from 'providers/EnrollProvider';

interface VerifyProviderProps {
  children?: ReactNode;
}

const VERIFY_PATHS = {
  [ProfileStateEnum.Gdpr]: ROUTES.acceptGdpr.data.path,
  [ProfileStateEnum.Initial]: ROUTES.verifyWelcome.data.path,
  [ProfileStateEnum.Qhr_Privacy]: ROUTES.verifyPerson.data.path,
  [ProfileStateEnum.ProfileInformation]: ROUTES.verifyProfile.data.path,
  [ProfileStateEnum.ProfilePicture]: ROUTES.verifyProfilePicture.data.path,
  [ProfileStateEnum.Vps]: ROUTES.verifyVps.data.path,
  [ProfileStateEnum.ProfileCompleted]: ROUTES.verifyComplete.data.path,
};

const VerifyProvider: FC<VerifyProviderProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const authState = useAppAuthState();
  const router = useAppRouter();

  const prev = async () => {
    if (authState.user?.id) {
      await dispatch(
        getProfilePreviousStateThunk({
          userId: authState.user.id,
        }),
      );
    }
  };

  const next = async () => {
    if (authState.user?.id) {
      await dispatch(
        setProfileNextStateThunk({
          userId: authState.user.id,
        }),
      );
    }
  };

  const save = async () => {
    if (authState.user?.id) {
      await dispatch(
        getProfileCurrentStateThunk({
          userId: authState.user.id,
        }),
      );
    }
  };

  const redirect = useMemo(() => {
    const routerPath = router.route.data.path;

    const redirectConfig = {
      path: routerPath,
    };

    if (router.requirements.gdpr && authState?.gdpr.required) {
      redirectConfig.path = ROUTES.acceptGdpr.data.path;
    } else if (authState.registration.state) {
      const verifyPaths = new Set(Object.values(VERIFY_PATHS));
      const redirectPath = VERIFY_PATHS[authState.registration.state];

      if ((router.requirements.profile && authState.registration.verifying) || verifyPaths.has(routerPath)) {
        redirectConfig.path = redirectPath;
      }
    }

    if (redirectConfig.path !== routerPath) {
      return redirectConfig.path;
    }
  }, [
    router.requirements.gdpr,
    router.requirements.profile,
    router.route.data.path,
    authState.registration.state,
    authState.registration.verifying,
    authState?.gdpr.required,
  ]);

  const processing = useMemo(
    () => Object.values(VERIFY_PATHS).includes(router.route.data.path),
    [router.route.data.path],
  );

  const content = useMemo(() => {
    if (redirect) {
      return <AppRedirect path="*" to={redirect} />;
    }

    if (processing) {
      return children;
    }

    return <EnrollProvider>{children}</EnrollProvider>;
  }, [redirect, processing, children]);

  return (
    <VerifyContextProvider
      user={authState.user}
      state={authState.registration.state}
      loading={authState.registration.loading}
      actions={{
        prev,
        save,
        next,
      }}
    >
      {content}
    </VerifyContextProvider>
  );
};

interface VerifyContextProviderProps extends AppVerifyContextParams {
  children?: ReactNode;
}

const VerifyContextProvider: FC<VerifyContextProviderProps> = ({ actions, state, user, loading, children }) => {
  const value = useMemo(
    () => ({
      state,
      user,
      loading,
      actions,
    }),
    [actions, loading, state, user],
  );

  return <AppVerifyContext.Provider value={value}>{children}</AppVerifyContext.Provider>;
};

export default VerifyProvider;
