import { useMemo } from 'react';

import { matchPath } from 'react-router-dom';

import ROUTES from 'routes';

import { useAppRouter } from 'hooks/useAppRouter';

export const useAppParams = () => {
  const { location } = useAppRouter();

  return useMemo(
    () =>
      Object.values(ROUTES).reduce<Record<string, string>>((acc, item) => {
        const match = matchPath(location.pathname, {
          path: item.data.path,
          exact: true,
        });

        return {
          ...acc,
          ...match?.params,
        };
      }, {}),
    [location.pathname],
  );
};
