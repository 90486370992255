import './style.scss';

import { FC, HTMLAttributes } from 'react';

import classNames from 'classnames';

interface MQFormInputGroupProviderProps extends HTMLAttributes<HTMLElement> {
  stick?: 'top' | 'bottom' | 'auto';
  compact?: boolean;
}

const MQFormInputGroupProvider: FC<MQFormInputGroupProviderProps> = ({
  className = '',
  compact = false,
  stick = 'auto',
  ...props
}) => (
  <div
    data-testid="mq-form-input-group"
    className={classNames('mq-form-input-group', className, stick, { compact })}
    {...props}
  />
);

export default MQFormInputGroupProvider;
