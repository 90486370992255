import { FeatureTypeEnum } from '@mentorcliq/storage';

import { LayoutSizeTypes, LayoutTypesEnum, RouteTypes } from 'types/route';

import { createRoute } from 'helpers/routes';

export default {
  matches: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
    },
    data: {
      path: `/matches`,
    },
  }),
  chat: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.md,
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
      access: {
        features: {
          value: [FeatureTypeEnum.Chat],
        },
      },
    },
    data: {
      path: `/matches/chat`,
    },
  }),
  meeting: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
      access: {
        features: { value: [FeatureTypeEnum.Calendar] },
      },
    },
    data: {
      path: `/matches/meeting/match/:matchId/organizer/:enrollmentId`,
      exact: true,
    },
  }),
  goals: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
    },
    data: {
      path: `/matches/goals/:programId/:enrollmentId/:matchId`,
    },
  }),
  goalsList: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
    },
    data: {
      path: `/matches/goals/:programId/:enrollmentId/:matchId`,
      exact: true,
    },
  }),
  addGoal: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
    },
    data: {
      path: `/matches/goals/:programId/:enrollmentId/:matchId/add`,
      exact: true,
    },
  }),
  editGoal: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
    },
    data: {
      path: `/matches/goals/:programId/:enrollmentId/:matchId/:id/update`,
      exact: true,
    },
  }),
  milestones: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
    },
    data: {
      path: `/matches/milestones/:programId/:enrollmentId/:matchId`,
    },
  }),
  conclude: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
    },
    data: {
      path: `/matches/leave/:matchId/program/:programId/enrollment/:enrollmentId/role/:roleId`,
      exact: true,
    },
  }),
};
