import './style.scss';

import { FC, useContext } from 'react';

import classNames from 'classnames';

import { MQDropdownItemProps } from '../../types';
import { MQNavbarContext } from '../MQNavbar';

const MQDropdownItem: FC<MQDropdownItemProps> = ({
  className = '',
  children,
  startIcon,
  endIcon,
  truncate = false,
  onClick,
  ...props
}) => {
  const { setCollapsed } = useContext(MQNavbarContext);

  const clickHandler = () => {
    setCollapsed && setCollapsed(false);
    onClick?.();
  };

  return (
    <div
      data-testid="mq-dropdown-item"
      {...props}
      className={classNames(['mq-dropdown__item', className, { truncate }])}
      onClick={clickHandler}
    >
      {startIcon && <span className="mq-dropdown__item-icon">{startIcon}</span>}
      {children && <span className="mq-dropdown__item-label">{children}</span>}
      {endIcon && <span className="mq-dropdown__item-icon">{endIcon}</span>}
    </div>
  );
};

MQDropdownItem.displayName = 'MQDropdownItem';

export default MQDropdownItem;
