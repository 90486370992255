import { LayoutTypesEnum, RouteTypes } from 'types/route';

import { createRoute } from 'helpers/routes';

export default {
  acceptMatchRequest: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Common],
    },
    data: {
      path: `/match/:token/accept`,
      exact: true,
    },
  }),
  declineMatchRequest: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Common],
    },
    data: {
      path: `/match/:token/decline`,
      exact: true,
    },
  }),
  matchRequestExpired: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Common],
    },
    data: {
      path: `/match/expired`,
      exact: true,
    },
  }),
  matchRequestDeclined: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Common],
    },
    data: {
      path: `/match/declined`,
      exact: true,
    },
  }),
  matchRequestConfirmed: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Common],
    },
    data: {
      path: `/match/confirmed`,
    },
  }),
  nudgeLeaveRelationship: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Common],
    },
    data: {
      path: `/match/leave/:token`,
      exact: true,
    },
  }),
  matchComparison: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Common],
    },
    data: {
      path: `/match/:token/compatibility`,
      exact: true,
    },
  }),
};
