import { PermissionsEnum } from '@mentorcliq/storage';

import { LayoutSizeTypes, LayoutTypesEnum, RouteTypes } from 'types/route';

import { createRoute } from 'helpers/routes';

export default {
  programWizard: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AddAProgram],
        },
      },
    },
    data: {
      path: `/program-wizard`,
    },
  }),
  programWizardDraft: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AddAProgram],
        },
      },
    },
    data: {
      path: `/program-wizard/draft`,
    },
  }),
  programWizardDraftEdit: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard, PermissionsEnum.AddAProgram],
          strict: false,
        },
      },
    },
    data: {
      path: `/program-wizard/draft/edit/:draftId/:programId`,
    },
  }),
  programWizardDraftPreview: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AddAProgram],
        },
      },
    },
    data: {
      path: `/program-wizard/draft/preview-draft/:draftId`,
    },
  }),
  programWizardDraftCreate: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AddAProgram],
        },
      },
    },
    data: {
      path: `/program-wizard/draft/create/:draftId`,
    },
  }),
  programWizardWelcome: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AddAProgram],
        },
      },
    },
    data: {
      path: `/program-wizard/welcome`,
      exact: true,
    },
  }),
  programWizardTemplates: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AddAProgram],
        },
      },
    },
    data: {
      path: `/program-wizard/templates`,
      exact: true,
    },
  }),
};
