import './style.scss';

import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import classNames from 'classnames';

export interface MQVirtualizedItemProps<T> {
  item: T;
  index: number;
  key: string | number;
}

interface MQVirtualizedDataProps<T> {
  before: number;
  after: number;
  visible: MQVirtualizedItemProps<T>[];
}

export interface MQVirtualizedProps<T> {
  className?: string;
  items: T[];
  children: (data: MQVirtualizedDataProps<T>) => ReactNode;
  size?: number;
  last?: number;
  inset?: boolean;
  disabled?: boolean;
  proportion?: number;
  itemHeight?: number;
  maxHeight?: number | `${number}%` | `${number}vh`;
}

const MQVirtualized = <T = unknown,>({
  items,
  children,
  itemHeight = 30,
  size = 100,
  className = '',
  maxHeight = '100%',
  disabled = false,
  inset = false,
  last = -1,
}: MQVirtualizedProps<T>) => {
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const beforeRef = useRef<HTMLDivElement | null>(null);
  const afterRef = useRef<HTMLDivElement | null>(null);

  const [offset, setOffset] = useState(() => {
    const visible = Math.min(size, items.length);
    const end = last >= 0 ? Math.min(last, items.length) : visible;
    const start = Math.max(end - visible, 0);
    return {
      start,
      end,
      size: visible,
      length: items.length,
    };
  });

  useEffect(() => {
    if (last >= 0) {
      setOffset((prevState) => {
        const end = last + 1;
        return {
          ...prevState,
          end,
          start: Math.max(end - prevState.size, 0),
          length: end,
        };
      });
      afterRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    } else {
      setOffset((prevState) => {
        const end = Math.min(Math.max(prevState.end, size), items.length);
        const start = Math.max(end - size, 0);

        return {
          ...prevState,
          size,
          start,
          end,
          length: items.length,
        };
      });
    }
  }, [last, items.length, size]);

  const handleScrollTop = useCallback(() => {
    setOffset((prevState) => {
      const start = Math.max(0, prevState.start - prevState.size / 2);

      return {
        ...prevState,
        start,
        end: Math.min(start + prevState.size, items.length),
      };
    });
  }, [items.length]);

  const handleScrollBottom = useCallback(() => {
    setOffset((prevState) => {
      const end = Math.min(items.length, prevState.end + prevState.size / 2);

      return {
        ...prevState,
        start: Math.max(end - prevState.size, 0),
        end,
      };
    });
  }, [items.length]);

  const virtualized = useMemo(() => {
    let key = 0;
    return {
      before: offset.start,
      visible: items.slice(offset.start, offset.end).map((item, index) => ({
        item,
        index: offset.start + index,
        key: ++key,
      })),
      after: items.length - offset.end,
    };
  }, [offset.start, offset.end, items]);

  const content = useMemo(() => children(virtualized), [children, virtualized]);

  const virtual = useMemo(() => items.length > size, [items.length, size]);

  if (virtual) {
    return (
      <div
        style={{ maxHeight }}
        ref={scrollRef}
        className={classNames('mq-virtualized', className, { disabled, inset })}
        onScroll={(e) => {
          const before = beforeRef.current?.offsetHeight ?? 1;
          const after = afterRef.current?.offsetHeight ?? 1;
          if (e.currentTarget.scrollTop <= before) {
            handleScrollTop();
            e.currentTarget.scrollTop += before;
          } else if (e.currentTarget.scrollTop >= e.currentTarget.scrollHeight - e.currentTarget.clientHeight - after) {
            handleScrollBottom();
            e.currentTarget.scrollTop -= after;
          }
        }}
      >
        <div
          className="mq-virtualized__before"
          ref={beforeRef}
          style={{ height: disabled ? 0 : Math.min(offset.size / 2, offset.start) * itemHeight }}
        />
        {content}
        <div
          className="mq-virtualized__after"
          ref={afterRef}
          style={{ height: disabled ? 0 : Math.min(offset.size / 2, items.length - offset.end) * itemHeight }}
        />
      </div>
    );
  }

  return <>{content}</>;
};

export default MQVirtualized;
