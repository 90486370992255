import './style.scss';

import { FC } from 'react';

import classNames from 'classnames';

import { MQFormNavItemProps } from '../../types';

const MQFormNavItem: FC<MQFormNavItemProps> = ({
  dataTestId = 'mq-form-nav-item',
  align = 'end',
  nowrap = true,
  ...props
}) => (
  <div {...props} data-testid={dataTestId} className={classNames('mq-form-nav-item', `align-${align}`, { nowrap })} />
);

export default MQFormNavItem;
