import './style.scss';

import { FC } from 'react';

import classNames from 'classnames';

import { MQFormTextareaProps } from '../../types';

const MQFormTextarea: FC<MQFormTextareaProps> = ({
  isValid,
  isInvalid,
  resize,
  dataTestId,
  className,
  ariaLabel = 'Multi Line String Field',
  instance,
  ...props
}) => (
  <textarea
    {...props}
    ref={instance}
    data-testid={`mq-form-textarea-${dataTestId || props.name}`}
    aria-label={ariaLabel}
    className={classNames(
      'mq-form-textarea',
      { ['is-valid']: isValid, ['is-invalid']: isInvalid },
      resize && `resize-${resize}`,
      className,
    )}
  />
);

export default MQFormTextarea;
