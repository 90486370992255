import './style.scss';

import { FC, useState } from 'react';

import classNames from 'classnames';
import ReactPlayer from 'react-player/lazy';

import { MQVideoProps } from './types';

const MQVideo: FC<MQVideoProps> = (props) => {
  const [show, setShow] = useState(false);

  return (
    <div className={classNames(['mq-video', { visible: show }])}>
      <ReactPlayer data-testid="mq-video-player" {...props} onReady={() => setShow(true)} />
    </div>
  );
};

export default MQVideo;
