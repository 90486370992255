import { lazy } from 'react';

import ROUTES from 'routes';

const CreateAccount = lazy(() => import('./CreateAccount'));
const CreatePassword = lazy(() => import('./CreatePassword'));
const ExternalLogin = lazy(() => import('./ExternalLogin'));
const Login = lazy(() => import('./Login'));
const QuickcliqSubmission = lazy(() => import('./QuickcliqSubmission'));
const RequestPassword = lazy(() => import('./RequestPassword'));
const ResetPassword = lazy(() => import('./ResetPassword'));
const SurveySubmission = lazy(() => import('./SurveySubmission'));
const Verification = lazy(() => import('./Verification'));

export const PublicPages = [
  {
    route: ROUTES.externalLogin.data,
    component: <ExternalLogin />,
  },
  {
    route: ROUTES.createPassword.data,
    component: <CreatePassword />,
  },
  {
    route: ROUTES.resetPassword.data,
    component: <ResetPassword />,
  },
  {
    route: ROUTES.createAccount.data,
    component: <CreateAccount />,
  },
  {
    route: ROUTES.verification.data,
    component: <Verification />,
  },
  {
    route: ROUTES.login.data,
    component: <Login admin={false} />,
  },
  {
    route: ROUTES.adminLogin.data,
    component: <Login admin={true} />,
  },
  {
    route: ROUTES.requestPassword.data,
    component: <RequestPassword />,
  },
  {
    route: ROUTES.quickcliqSubmission.data,
    component: <QuickcliqSubmission />,
  },
  {
    route: ROUTES.surveySubmission.data,
    component: <SurveySubmission />,
  },
];
