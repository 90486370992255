import './style.scss';

import { FC } from 'react';

import { MQ_AVATAR_SIZES } from '@mentorcliq/ui';
import classNames from 'classnames';

import { MQAvatarInfoProps } from '../../types';

const MQAvatarInfo: FC<MQAvatarInfoProps> = ({
  children,
  size = 'md',
  className,
  variant = 'secondary',
  borderless = false,
  disabled,
  ...props
}) => (
  <div
    {...props}
    className={classNames(className, size, variant, { borderless }, { disabled }, 'mq-avatar-info')}
    style={{
      width: MQ_AVATAR_SIZES[size].width,
      height: MQ_AVATAR_SIZES[size].height,
    }}
  >
    {children}
  </div>
);

export default MQAvatarInfo;
