import { useContext, useMemo } from 'react';

import { AppSocketContext } from 'definitions/context';

export const useAppSocket = () => {
  const { unread, send, read, toggleChat, toggleRoom, chatConfigs, chatRooms } = useContext(AppSocketContext);

  return useMemo(
    () => ({
      send,
      read,
      toggleRoom,
      toggleChat,
      chatConfigs,
      chatRooms,
      unread,
      ready: !!chatRooms && !!Object.keys(chatRooms).length,
    }),
    [unread, send, read, toggleChat, toggleRoom, chatConfigs, chatRooms],
  );
};
