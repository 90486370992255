import { FC, ReactNode } from 'react';

import { RouteProps, matchPath } from 'react-router-dom';

import ROUTES from 'routes';

import AppBridge from 'router/AppBridge';
import AppRedirect from 'router/AppRedirect';
import AppRoute from 'router/AppRoute';

interface RouteProviderProps extends RouteProps {
  path?: string;
  children?: ReactNode;
}

const RouteProvider: FC<RouteProviderProps> = ({ path = '/', children, ...rest }) => (
  <AppRoute
    path={path}
    {...rest}
    render={({ location }) => {
      const route = Object.values(ROUTES).find(
        (item) =>
          !!matchPath(location.pathname, {
            path: item.data.path,
            exact: true,
          }),
      );

      if (!route) {
        return <AppRedirect path="*" to={ROUTES.statusPage404.data.path} />;
      }

      if (route.redirectTo) {
        return <AppBridge>{children}</AppBridge>;
      }

      return children;
    }}
  />
);

export default RouteProvider;
