import './style.scss';

import { FC } from 'react';

import classNames from 'classnames';

import MQDefaultImage from './assets/images/defaultImage.png';

export interface MQCardImageProps {
  defaultImage?: string;
  src?: string;
  alt?: string;
  className?: string;
  dataTestId?: string;
  width?: string | number;
  height?: string | number;
}

const MQCardImage: FC<MQCardImageProps> = ({
  className = '',
  dataTestId = 'mq-card-image',
  defaultImage,
  src,
  alt = '',
  width = '100%',
  height = 'auto',
}) => (
  <img
    decoding="async"
    className={classNames(['mq-card__image', className])}
    data-testid={dataTestId}
    src={src || defaultImage || MQDefaultImage}
    alt={alt || ''}
    width={width}
    height={height}
  />
);

export default MQCardImage;
