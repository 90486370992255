import { FeatureTypeEnum } from '@mentorcliq/storage';

import { LayoutTypesEnum, RouteTypes } from 'types/route';

import { createRoute } from 'helpers/routes';

export default {
  support: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      types: [RouteTypes.Common],
      access: {
        features: {
          value: [FeatureTypeEnum.Support],
        },
      },
    },
    data: {
      path: `/support`,
      exact: true,
    },
  }),
};
