import { useEffect, useMemo, useState } from 'react';

import { isEqual } from 'lodash';

export interface ProviderProps {
  id: string;
  fc: () => Promise<void>;
  deps: unknown[];
  active: boolean;
}

export const useAppProvider = (items: ProviderProps[]) => {
  const [providers, setProviders] = useState({
    pending: false,
    data: items.map((item) => ({
      ...item,
      fulfilled: false,
    })),
  });

  useEffect(() => {
    setProviders((prev) => ({
      ...prev,
      data: prev.data.map((item) => {
        const event = items.find(({ id }) => id === item.id);
        if (event) {
          if (!isEqual(event.fc, item.fc) && !isEqual(event.deps, item.deps)) {
            return {
              ...event,
              fulfilled: false,
            };
          }

          return {
            ...item,
            ...event,
          };
        }

        return item;
      }),
    }));
  }, [items]);

  useEffect(() => {
    if (!providers.pending) {
      const promises = providers.data.filter(({ fulfilled, active }) => !fulfilled && active);
      if (promises.length) {
        setProviders((prev) => ({
          ...prev,
          pending: true,
        }));

        Promise.all(promises.map((item) => item.fc().then(() => item.id))).then((list) => {
          setProviders((prev) => ({
            pending: false,
            data: prev.data.map((item) => {
              if (list.includes(item.id)) {
                return {
                  ...item,
                  fulfilled: true,
                };
              }

              return item;
            }),
          }));
        });
      }
    }
  }, [providers]);

  const consistent = useMemo(() => {
    const prev = providers.data.map((item) => ({
      id: item.id,
      fc: item.fc,
      deps: item.deps,
    }));

    const next = items.map((item) => ({
      id: item.id,
      fc: item.fc,
      deps: item.deps,
    }));

    return isEqual(prev, next);
  }, [items, providers]);

  const finished = useMemo(
    () => !providers.data.some(({ fulfilled, active }) => !fulfilled && active) && !providers.pending,
    [providers.data, providers.pending],
  );

  return useMemo(
    () => ({
      ready: consistent && finished,
    }),
    [consistent, finished],
  );
};
