import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

export const useAppUpdate = (effect: EffectCallback, ...deps: DependencyList) => {
  const ref = useRef(false);

  useEffect(() => {
    if (ref.current) {
      return effect();
    } else {
      ref.current = true;
    }
  }, [effect, deps]);
};
