import CustomerBoundary from 'components/CustomerBoundary';

import commonRoutes from './common';
import previewRoutes from './preview';
import privateRoutes from './private';
import publicRoutes from './public';
import redirectRoutes from './redirect';

const customer = CustomerBoundary();

// IMPORTANT:: DO NOT CHANGE ORDERING
const ROUTES = Object.assign(
  {
    ...privateRoutes,
    ...publicRoutes,
    ...commonRoutes,
    ...previewRoutes,
  },
  {
    ...redirectRoutes,
    ...customer.routes,
  },
);

export default ROUTES;
