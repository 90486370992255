import { default as Badge } from './components/MQCardBadge';
import { default as Body } from './components/MQCardBody';
import { default as Footer } from './components/MQCardFooter';
import { default as Header } from './components/MQCardHeader';
import { default as Image } from './components/MQCardImage';
import { default as Link } from './components/MQCardLink';
import { default as Nav } from './components/MQCardNav';
import { default as Overlay } from './components/MQCardOverlay';
import { default as Provider } from './components/MQCardProvider';
import { default as Section } from './components/MQCardSection';
import { default as SubTitle } from './components/MQCardSubTitle';
import { default as Text } from './components/MQCardText';
import { default as Title } from './components/MQCardTitle';

const MQCard = Object.assign(Provider, {
  Nav,
  Link,
  Title,
  SubTitle,
  Text,
  Body,
  Badge,
  Header,
  Footer,
  Image,
  Overlay,
  Section,
});

export default MQCard;
