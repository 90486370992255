import './style.scss';

import { FC } from 'react';

import classNames from 'classnames';

import { MQLayoutSectionProps } from '../../types';

const MQLayoutSection: FC<MQLayoutSectionProps> = ({
  type = 'content',
  className,
  children,
  dataTestId = 'mq-layout-section',
  ...props
}) => (
  <div data-testid={dataTestId} className={classNames(['mq-layout-section', type, className])} {...props}>
    {children}
  </div>
);

export default MQLayoutSection;
