import { default as Dots } from './components/MQLoaderDots';
import { default as Skeleton } from './components/MQLoaderSkeleton';
import { default as Spinner } from './components/MQLoaderSpinner';

const MQLoader = {
  Dots,
  Skeleton,
  Spinner,
};
export default MQLoader;
