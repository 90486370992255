import './style.scss';

import { FC } from 'react';

import classNames from 'classnames';

import { MQModalTitleProps } from '../../types';

const MQModalTitle: FC<MQModalTitleProps> = ({ className = '', bold = true, ...props }) => (
  <p
    data-testid="mq-modal-title"
    aria-label="Modal Title"
    className={classNames(['mq-modal__title', { bold }, className])}
    {...props}
  />
);

export default MQModalTitle;
