import { FC, useMemo, useState, useEffect } from 'react';

import classNames from 'classnames';
import { createPortal } from 'react-dom';

import { generateOverlayAbsoluteData, generateOverlayRelativeData, getEventTarget } from '../../helpers';
import { MQOverLayWrapperProps } from '../../types';

const MQOverLayWrapper: FC<MQOverLayWrapperProps> = ({
  children,
  rootClose = true,
  centralize = false,
  className = '',
  state,
  setState,
  hideOnScroll,
  placement,
  triggers = [],
  rootId = '',
  rect = {},
  ...props
}) => {
  const [overlayRef, setOverlayRef] = useState<HTMLDivElement | null>(null);
  const [nodeWidth, setNodeWidth] = useState<null | number>(null);
  const [nodeHeight, setNodeHeight] = useState<null | number>(null);
  const overlayRoot = useMemo(
    () => document.getElementById(rootId) || state.target?.parentElement,
    [state?.target, rootId],
  );

  useEffect(() => {
    const handleScroll = (e: Event) => {
      if (e.target) {
        const target = getEventTarget(e.target);
        if (target) {
          if (!overlayRef?.contains(target)) {
            setState(null);
          }
        }
      }
    };

    if (hideOnScroll) {
      if (overlayRef) {
        document.addEventListener('scroll', handleScroll, true);
      } else {
        document.removeEventListener('scroll', handleScroll, true);
      }
    }

    return () => {
      document.removeEventListener('scroll', handleScroll, true);
    };
  }, [hideOnScroll, overlayRef, setState]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (event.target) {
        const target = getEventTarget(event.target);
        if (target) {
          if (!overlayRef?.contains(target) && !state.target.contains(target)) {
            setState(null);
          }
        }
      }
    };

    if (rootClose) {
      if (overlayRef) {
        document.addEventListener('mousedown', handleClickOutside, true);
      } else {
        document.removeEventListener('mousedown', handleClickOutside, true);
      }
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, [overlayRef, setState, state.target, rootClose]);

  const overlayData = useMemo(() => {
    const currentRect = state.target.getBoundingClientRect();
    const overlayRect = rootId ? currentRect : { ...currentRect, ...rect };

    if (centralize) {
      return generateOverlayRelativeData(overlayRect, {
        placement,
        width: nodeWidth || 0,
        height: nodeHeight || 0,
        triggerWidth: overlayRect?.width || 0,
        triggerHeight: overlayRect?.height || 0,
      });
    } else {
      return generateOverlayAbsoluteData(overlayRect, {
        placement,
        width: nodeWidth || 0,
        height: nodeHeight || 0,
        triggerWidth: overlayRect?.width || 0,
        triggerHeight: overlayRect?.height || 0,
      });
    }
  }, [state.target, rect, centralize, placement, nodeWidth, nodeHeight, rootId]);

  if (overlayData) {
    if (overlayRoot) {
      return createPortal(
        <div
          {...props}
          className={classNames('mq-overlay-wrapper', className)}
          data-placement={overlayData.placement}
          style={{
            position: 'fixed',
            left: overlayData.left,
            right: overlayData.right,
            top: overlayData.top,
            bottom: overlayData.bottom,
            transform: `translate(${overlayData.translateX || 0}px, ${overlayData.translateY || 0}px)`,
            zIndex: 5,
          }}
          ref={(node) => {
            setOverlayRef(node);
            if (node) {
              const rect = node.getBoundingClientRect();
              if (rect.width) {
                setNodeWidth(rect.width);
              }
              if (rect.height) {
                setNodeHeight(rect.height);
              }
            }
          }}
          onMouseOver={() => {
            if (state && triggers?.includes('hover')) {
              setState(state);
            }
          }}
          onMouseOut={() => {
            if (triggers?.includes('hover')) {
              setState(null);
            }
          }}
          onBlur={(e) => {
            if (triggers?.includes('blur')) {
              if (e.relatedTarget && !overlayRef?.contains(e.relatedTarget)) {
                setState(null);
              }
            }
          }}
        >
          {children(overlayData)}
        </div>,
        overlayRoot,
      );
    }
  }

  return null;
};

export default MQOverLayWrapper;
