import { ReactComponent as MQAddressCardAltSvg } from '../assets/address-card-light.svg';
import { ReactComponent as MQAlignJustifyLightSvg } from '../assets/align-justify-light.svg';
import { ReactComponent as MQAlignLeftSvg } from '../assets/align-left-regular.svg';
import { ReactComponent as MQAngleDownSolidSvg } from '../assets/angle-down-solid.svg';
import { ReactComponent as MQAngleLeftSolidSvg } from '../assets/angle-left-solid.svg';
import { ReactComponent as MQAngleRightSolidSvg } from '../assets/angle-right-solid.svg';
import { ReactComponent as MQAngleUpSolidSvg } from '../assets/angle-up-solid.svg';
import { ReactComponent as MQAnglesDoubleLeftSolidSvg } from '../assets/angles-left-solid.svg';
import { ReactComponent as MQAnglesDoubleRightSolidSvg } from '../assets/angles-right-solid.svg';
import { ReactComponent as MQSortNumericDownSolidSvg } from '../assets/arrow-down-1-9-solid.svg';
import { ReactComponent as MQSortNumericAltDownSolidSvg } from '../assets/arrow-down-9-1-solid.svg';
import { ReactComponent as MQSortAlphaDownSolidSvg } from '../assets/arrow-down-a-z-solid.svg';
import { ReactComponent as MQArrowDownSolidSvg } from '../assets/arrow-down-solid.svg';
import { ReactComponent as MQSortAlphaAltDownSolidSvg } from '../assets/arrow-down-z-a-solid.svg';
import { ReactComponent as MQArrowLeftSolidSvg } from '../assets/arrow-left-solid.svg';
import { ReactComponent as MQExchangeAltSvg } from '../assets/arrow-right-arrow-left-light.svg';
import { ReactComponent as MQArrowRightSolidSvg } from '../assets/arrow-right-solid.svg';
import { ReactComponent as MQArrowRightToBracketSvg } from '../assets/arrow-right-to-bracket-regular.svg';
import { ReactComponent as MQSortUpDownSolidSvg } from '../assets/arrow-up-arrow-down-solid.svg';
import { ReactComponent as MQArrowUpRightFromSquareSolidSvg } from '../assets/arrow-up-right-from-square-solid.svg';
import { ReactComponent as MQArrowsUpDownSolidSvg } from '../assets/arrows-up-down-solid.svg';
import { ReactComponent as MQAtomAltSvg } from '../assets/atom-regular.svg';
import { ReactComponent as MQBanSolidSvg } from '../assets/ban-solid.svg';
import { ReactComponent as MQBarsSvg } from '../assets/bars-light.svg';
import { ReactComponent as MQBellConciergeAltSvg } from '../assets/bell-concierge-regular.svg';
import { ReactComponent as MQBookOpenAltSvg } from '../assets/book-open-regular.svg';
import { ReactComponent as MQBookUserSolidSvg } from '../assets/book-user-solid.svg';
import { ReactComponent as MQBooksAltSvg } from '../assets/books-light.svg';
import { ReactComponent as MQBoxArchiveLightSvg } from '../assets/box-archive-light.svg';
import { ReactComponent as MQBullhornSvg } from '../assets/bullhorn-regular.svg';
import { ReactComponent as MQBullseyeArrowDuoToneSvg } from '../assets/bullseye-arrow-duotone.svg';
import { ReactComponent as MQBullseyePointerDuoToneSvg } from '../assets/bullseye-pointer-duotone.svg';
import { ReactComponent as MQBullseyePointerAltSvg } from '../assets/bullseye-pointer-light.svg';
import { ReactComponent as MQBullseyeSolidSvg } from '../assets/bullseye-solid.svg';
import { ReactComponent as MQCabinetFilingSvg } from '../assets/cabinet-filing-regular.svg';
import { ReactComponent as MQCalculatorSolidSvg } from '../assets/calculator-solid.svg';
import { ReactComponent as MQCalendarCheckAltSvg } from '../assets/calendar-check-regular.svg';
import { ReactComponent as MQCalendarDayAltSvg } from '../assets/calendar-day-regular.svg';
import { ReactComponent as MQCalendarAltDuoToneSvg } from '../assets/calendar-days-duotone.svg';
import { ReactComponent as MQCalendarDaysLightSvg } from '../assets/calendar-days-light.svg';
import { ReactComponent as MQCalendarAltSvg } from '../assets/calendar-regular.svg';
import { ReactComponent as MQCaretDownSolidSvg } from '../assets/caret-down-solid.svg';
import { ReactComponent as MQChalkboardUserAltSvg } from '../assets/chalkboard-user-light.svg';
import { ReactComponent as MQChartLineSolidSvg } from '../assets/chart-line-solid.svg';
import { ReactComponent as MQChartLineUpSvg } from '../assets/chart-line-up-regular.svg';
import { ReactComponent as MQChartMixedSvg } from '../assets/chart-mixed-regular.svg';
import { ReactComponent as MQChartNetworkSvg } from '../assets/chart-network-light.svg';
import { ReactComponent as MQChartPieSvg } from '../assets/chart-pie-regular.svg';
import { ReactComponent as MQChartUserAltSvg } from '../assets/chart-user-light.svg';
import { ReactComponent as MQCheckSolidSvg } from '../assets/check-solid.svg';
import { ReactComponent as MQCheckToSlotAltSvg } from '../assets/check-to-slot-regular.svg';
import { ReactComponent as MQChevronDownSolidSvg } from '../assets/chevron-down-solid.svg';
import { ReactComponent as MQChevronLeftSolidSvg } from '../assets/chevron-left-solid.svg';
import { ReactComponent as MQChevronRightSolidSvg } from '../assets/chevron-right-solid.svg';
import { ReactComponent as MQChevronUpSolidSvg } from '../assets/chevron-up-solid.svg';
import { ReactComponent as MQChevronsRightSolidSvg } from '../assets/chevrons-right-solid.svg';
import { ReactComponent as MQArrowCircleDownAltSvg } from '../assets/circle-arrow-down-regular.svg';
import { ReactComponent as MQArrowCircleRightSolidSvg } from '../assets/circle-arrow-right-solid.svg';
import { ReactComponent as MQArrowCircleUpSolidSvg } from '../assets/circle-arrow-up-solid.svg';
import { ReactComponent as MQCircleCheckAltSvg } from '../assets/circle-check-light.svg';
import { ReactComponent as MQCheckCircleAltSvg } from '../assets/circle-check-regular.svg';
import { ReactComponent as MQCheckCircleSolidSvg } from '../assets/circle-check-solid.svg';
import { ReactComponent as MQChevronCircleDownSvg } from '../assets/circle-chevron-down-regular.svg';
import { ReactComponent as MQCircleChevronLeftDuoToneSvg } from '../assets/circle-chevron-left-duotone.svg';
import { ReactComponent as MQCircleChevronRightDuoToneSvg } from '../assets/circle-chevron-right-duotone.svg';
import { ReactComponent as MQChevronCircleUpSvg } from '../assets/circle-chevron-up-regular.svg';
import { ReactComponent as MQDotCircleAltSvg } from '../assets/circle-dot-regular.svg';
import { ReactComponent as MQExclamationCircleAltSvg } from '../assets/circle-exclamation-regular.svg';
import { ReactComponent as MQExclamationCircleSolidSvg } from '../assets/circle-exclamation-solid.svg';
import { ReactComponent as MQInfoCircleDuoToneSvg } from '../assets/circle-info-duotone.svg';
import { ReactComponent as MQInfoCircleAltSvg } from '../assets/circle-info-regular.svg';
import { ReactComponent as MQInfoCircleSvg } from '../assets/circle-info-solid.svg';
import { ReactComponent as MQMinusCircleAltSvg } from '../assets/circle-minus-regular.svg';
import { ReactComponent as MQPlayCircleLightSvg } from '../assets/circle-play-light.svg';
import { ReactComponent as MQPlayCircleAltSvg } from '../assets/circle-play-regular.svg';
import { ReactComponent as MQPlayCircleSolidSvg } from '../assets/circle-play-solid.svg';
import { ReactComponent as MQCirclePlusLightSvg } from '../assets/circle-plus-light.svg';
import { ReactComponent as MQPlusCircleAltSvg } from '../assets/circle-plus-regular.svg';
import { ReactComponent as MQPlusCircleSolidSvg } from '../assets/circle-plus-solid.svg';
import { ReactComponent as MQQuestionCircleSolidSvg } from '../assets/circle-question-solid.svg';
import { ReactComponent as MQCircleAltSvg } from '../assets/circle-regular.svg';
import { ReactComponent as MQCircleRightSolidSvg } from '../assets/circle-right-solid.svg';
import { ReactComponent as MQCircleSolidSvg } from '../assets/circle-solid.svg';
import { ReactComponent as MQUserCircleDuoToneSvg } from '../assets/circle-user-duotone.svg';
import { ReactComponent as MQUserCircleRegularSvg } from '../assets/circle-user-regular.svg';
import { ReactComponent as MQTimesCircleLightSvg } from '../assets/circle-xmark-light.svg';
import { ReactComponent as MQTimesCircleSolidSvg } from '../assets/circle-xmark-solid.svg';
import { ReactComponent as MQClipboardListCheckSvg } from '../assets/clipboard-list-check-light.svg';
import { ReactComponent as MQClockAltSvg } from '../assets/clock-regular.svg';
import { ReactComponent as MQClockRotateLeftSolidSvg } from '../assets/clock-rotate-left-solid.svg';
import { ReactComponent as MQCloudArrowUpRegularSvg } from '../assets/cloud-arrow-up-regular.svg';
import { ReactComponent as MQCloudArrowUpSolidSvg } from '../assets/cloud-arrow-up-solid.svg';
import { ReactComponent as MQCommentsSolidSvg } from '../assets/comments-solid.svg';
import { ReactComponent as MQMouseSolidSvg } from '../assets/computer-mouse-solid.svg';
import { ReactComponent as MQCookieBiteSvg } from '../assets/cookie-bite-regular.svg';
import { ReactComponent as MQCopyLightSvg } from '../assets/copy-light.svg';
import { ReactComponent as MQCopySolidSvg } from '../assets/copy-solid.svg';
import { ReactComponent as MQCopyrightAltSvg } from '../assets/copyright-regular.svg';
import { ReactComponent as MQDataBaseSvg } from '../assets/database-regular.svg';
import { ReactComponent as MQDesktopSvg } from '../assets/desktop-regular.svg';
import { ReactComponent as MQDownloadSvg } from '../assets/download-regular.svg';
import { ReactComponent as MQDrawCircleSvg } from '../assets/draw-circle-light.svg';
import { ReactComponent as MQEllipsisVerticalSolidSvg } from '../assets/ellipsis-vertical-solid.svg';
import { ReactComponent as MQEnvelopeOpenTextDuoToneSvg } from '../assets/envelope-open-text-duotone.svg';
import { ReactComponent as MQEnvelopeOpenTextAltSvg } from '../assets/envelope-open-text-regular.svg';
import { ReactComponent as MQEnvelopeSolidSvg } from '../assets/envelope-solid.svg';
import { ReactComponent as MQEnvelopesBulkSolidSvg } from '../assets/envelopes-bulk-solid.svg';
import { ReactComponent as MQExpandSolidSvg } from '../assets/expand-solid.svg';
import { ReactComponent as MQEyeLightSvg } from '../assets/eye-light.svg';
import { ReactComponent as MQEyeSlashSolidSvg } from '../assets/eye-slash-solid.svg';
import { ReactComponent as MQEyeSolidSvg } from '../assets/eye-solid.svg';
import { ReactComponent as MQFaceSmileSvg } from '../assets/face-smile-regular.svg';
import { ReactComponent as MQFileChartColumnSvg } from '../assets/file-chart-column-regular.svg';
import { ReactComponent as MQFileCirclePlusLightSvg } from '../assets/file-circle-plus-light.svg';
import { ReactComponent as MQFileCirclePlusSolidSvg } from '../assets/file-circle-plus-solid.svg';
import { ReactComponent as MQFileExportSvg } from '../assets/file-export-regular.svg';
import { ReactComponent as MQFileImportAltSvg } from '../assets/file-import-regular.svg';
import { ReactComponent as MQFileImportSolidSvg } from '../assets/file-import-solid.svg';
import { ReactComponent as MQFileAltDuoToneSvg } from '../assets/file-lines-duotone.svg';
import { ReactComponent as MQFileLinesSolidSvg } from '../assets/file-lines-solid.svg';
import { ReactComponent as MQFileMagnifyingGlassAltSvg } from '../assets/file-magnifying-glass-regular.svg';
import { ReactComponent as MQFilePdfAltSvg } from '../assets/file-pdf-regular.svg';
import { ReactComponent as MQFileEditDuoToneSvg } from '../assets/file-pen-duotone.svg';
import { ReactComponent as MQFilePenLightSvg } from '../assets/file-pen-light.svg';
import { ReactComponent as MQFileEditSolidSvg } from '../assets/file-pen-solid.svg';
import { ReactComponent as MQFilePowerpointAltSvg } from '../assets/file-powerpoint-regular.svg';
import { ReactComponent as MQFileSolidSvg } from '../assets/file-solid.svg';
import { ReactComponent as MQFileVideoAltSvg } from '../assets/file-video-regular.svg';
import { ReactComponent as MQFileWordAltSvg } from '../assets/file-word-regular.svg';
import { ReactComponent as MQFilmSvg } from '../assets/film-regular.svg';
import { ReactComponent as MQFilterCircleDollarAltSvg } from '../assets/filter-circle-dollar-regular.svg';
import { ReactComponent as MQFilterSvg } from '../assets/filter-regular.svg';
import { ReactComponent as MQFiltersSvg } from '../assets/filters-regular.svg';
import { ReactComponent as MQPennantDuoToneSvg } from '../assets/flag-pennant-duotone.svg';
import { ReactComponent as MQPennantAltSvg } from '../assets/flag-pennant-light.svg';
import { ReactComponent as MQSaveSolidSvg } from '../assets/floppy-disk-solid.svg';
import { ReactComponent as MQGearAltSvg } from '../assets/gear-regular.svg';
import { ReactComponent as MQGearsSvg } from '../assets/gears-regular.svg';
import { ReactComponent as MQGlobeLightSvg } from '../assets/globe-light.svg';
import { ReactComponent as MQGoogleBrandSvg } from '../assets/google.svg';
import { ReactComponent as MQGridSolidSvg } from '../assets/grid-solid.svg';
import { ReactComponent as MQHandHoldingHeartAltSvg } from '../assets/hand-holding-heart-light.svg';
import { ReactComponent as MQHandshakeAngleSvg } from '../assets/handshake-angle-light.svg';
import { ReactComponent as MQHandShakeSolidSvg } from '../assets/handshake-solid.svg';
import { ReactComponent as MQHeadSideBrainAltSvg } from '../assets/head-side-brain-regular.svg';
import { ReactComponent as MQHeadSideHeartSolidSvg } from '../assets/head-side-heart-solid.svg';
import { ReactComponent as MQHistorySvg } from '../assets/history-regular.svg';
import { ReactComponent as MQHourGlassEndAltSvg } from '../assets/hourglass-end-regular.svg';
import { ReactComponent as MQHourGlassHalfAltSvg } from '../assets/hourglass-half-regular.svg';
import { ReactComponent as MQHouseSvg } from '../assets/house-regular.svg';
import { ReactComponent as MQImageAltSvg } from '../assets/image-regular.svg';
import { ReactComponent as MQLanguageSolidSvg } from '../assets/language-solid.svg';
import { ReactComponent as MQLongArrowAltLeftSolidSvg } from '../assets/left-long-solid.svg';
import { ReactComponent as MQLeftSolidSvg } from '../assets/left-solid.svg';
import { ReactComponent as MQLifeRingSvg } from '../assets/life-ring-regular.svg';
import { ReactComponent as MQLinkSolidSvg } from '../assets/link-solid.svg';
import { ReactComponent as MQListCheckSolidSvg } from '../assets/list-check-solid.svg';
import { ReactComponent as MQListUlSolidSvg } from '../assets/list-ul-solid.svg';
import { ReactComponent as MQLocationDotAltSvg } from '../assets/location-dot-regular.svg';
import { ReactComponent as MQLockSolidSvg } from '../assets/lock-solid.svg';
import { ReactComponent as MQSearchPlusLightSvg } from '../assets/magnifying-glass-plus-light.svg';
import { ReactComponent as MQSearchSvg } from '../assets/magnifying-glass-regular.svg';
import { ReactComponent as MQMapLocationAltSvg } from '../assets/map-location-light.svg';
import { ReactComponent as MQMapSolidSvg } from '../assets/map-solid.svg';
import { ReactComponent as MQMessageLinesLightSvg } from '../assets/message-lines-light.svg';
import { ReactComponent as MQMessageSolidSvg } from '../assets/message-solid.svg';
import { ReactComponent as MQMessagesDuoToneSvg } from '../assets/messages-duotone.svg';
import { ReactComponent as MQMessagesAltSvg } from '../assets/messages-regular.svg';
import { ReactComponent as MQMicrophoneAltSvg } from '../assets/microphone-regular.svg';
import { ReactComponent as MQMicroscopeAltSvg } from '../assets/microscope-regular.svg';
import { ReactComponent as MQMicrosoftBrandSvg } from '../assets/microsoft.svg';
import { ReactComponent as MQMinusSolidSvg } from '../assets/minus-solid.svg';
import { ReactComponent as MQMobileSvg } from '../assets/mobile-regular.svg';
import { ReactComponent as MQPaletteSvg } from '../assets/palette-regular.svg';
import { ReactComponent as MQPaperPlaneAltSvg } from '../assets/paper-plane-light.svg';
import { ReactComponent as MQPaperPlaneSolidSvg } from '../assets/paper-plane-solid.svg';
import { ReactComponent as MQSendSolidSvg } from '../assets/paper-plane-top-solid.svg';
import { ReactComponent as MQPaperclipLightSvg } from '../assets/paperclip-light.svg';
import { ReactComponent as MQEditSquareSvg } from '../assets/pen-to-square-regular.svg';
import { ReactComponent as MQPencilAltLightSvg } from '../assets/pencil-light.svg';
import { ReactComponent as MQPencilAltSolidSvg } from '../assets/pencil-solid.svg';
import { ReactComponent as MQPeopleArrowsSolidSvg } from '../assets/people-arrows-solid.svg';
import { ReactComponent as MQPeopleCarryBoxAltSvg } from '../assets/people-carry-box-light.svg';
import { ReactComponent as MQPeopleGroupSolidSvg } from '../assets/people-group-solid.svg';
import { ReactComponent as MQPhotoFilmSvg } from '../assets/photo-film-regular.svg';
import { ReactComponent as MQPlusSolidSvg } from '../assets/plus-solid.svg';
import { ReactComponent as MQPolPeopleDuoToneSvg } from '../assets/poll-people-duotone.svg';
import { ReactComponent as MQPollPeopleAltSvg } from '../assets/poll-people-regular.svg';
import { ReactComponent as MQPuzzlePieceSvg } from '../assets/puzzle-piece-regular.svg';
import { ReactComponent as MQRectangleHistoryCircleUserSolidSvg } from '../assets/rectangle-history-circle-user-solid.svg';
import { ReactComponent as MQHistoryAltSvg } from '../assets/rectangle-history-regular.svg';
import { ReactComponent as MQListAltSvg } from '../assets/rectangle-list-light.svg';
import { ReactComponent as MQRectangleListAltSvg } from '../assets/rectangle-list-regular.svg';
import { ReactComponent as MQSignOutSolidSvg } from '../assets/right-from-bracket-solid.svg';
import { ReactComponent as MQRocketLaunchSvg } from '../assets/rocket-launch-regular.svg';
import { ReactComponent as MQRocketAltSvg } from '../assets/rocket-regular.svg';
import { ReactComponent as MQRocketSolidSvg } from '../assets/rocket-solid.svg';
import { ReactComponent as MQUndoAltLightSvg } from '../assets/rotate-left-light.svg';
import { ReactComponent as MQUndoAltSolidSvg } from '../assets/rotate-left-solid.svg';
import { ReactComponent as MQRotateSolidSvg } from '../assets/rotate-solid.svg';
import { ReactComponent as MQBalanceScaleSolidSvg } from '../assets/scale-balanced-solid.svg';
import { ReactComponent as MQScreenUsersSvg } from '../assets/screen-users-solid.svg';
import { ReactComponent as MQShieldSvg } from '../assets/shield-regular.svg';
import { ReactComponent as MQRandomSolidSvg } from '../assets/shuffle-solid.svg';
import { ReactComponent as MQStreamSolidSvg } from '../assets/signal-stream-solid.svg';
import { ReactComponent as MQSkypeBrandSvg } from '../assets/skype.svg';
import { ReactComponent as MQSlackBrandSvg } from '../assets/slack.svg';
import { ReactComponent as MQSlidersAltSvg } from '../assets/sliders-light.svg';
import { ReactComponent as MQSlidersHSvg } from '../assets/sliders-regular.svg';
import { ReactComponent as MQSortDownSolidSvg } from '../assets/sort-down-solid.svg';
import { ReactComponent as MQSortSolidSvg } from '../assets/sort-solid.svg';
import { ReactComponent as MQSortUpSolidSvg } from '../assets/sort-up-solid.svg';
import { ReactComponent as MQCheckSquareLightSvg } from '../assets/square-check-light.svg';
import { ReactComponent as MQCheckSquareAltSvg } from '../assets/square-check-regular.svg';
import { ReactComponent as MQCheckSquareSolidSvg } from '../assets/square-check-solid.svg';
import { ReactComponent as MQPollHDuoToneSvg } from '../assets/square-poll-horizontal-duotone.svg';
import { ReactComponent as MQPollHLightSvg } from '../assets/square-poll-horizontal-light.svg';
import { ReactComponent as MQSquareAltSvg } from '../assets/square-regular.svg';
import { ReactComponent as MQTimesSquareSvg } from '../assets/square-xmark-regular.svg';
import { ReactComponent as MQStarLightSvg } from '../assets/star-light.svg';
import { ReactComponent as MQStarSolidSvg } from '../assets/star-solid.svg';
import { ReactComponent as MQStarsSvg } from '../assets/stars.svg';
import { ReactComponent as MQStopWatchAltSvg } from '../assets/stopwatch-light.svg';
import { ReactComponent as MQTableColumnsAltSvg } from '../assets/table-columns-regular.svg';
import { ReactComponent as MQTableAltSvg } from '../assets/table-regular.svg';
import { ReactComponent as MQTagAltSvg } from '../assets/tag-regular.svg';
import { ReactComponent as MQTargetCommunicationsGraphicSvg } from '../assets/targeted-communications-graphic.svg';
import { ReactComponent as MQThumbsUpAltSvg } from '../assets/thumbs-up-regular.svg';
import { ReactComponent as MQTimesSolidSvg } from '../assets/times-solid.svg';
import { ReactComponent as MQTrashAltSvg } from '../assets/trash-regular.svg';
import { ReactComponent as MQExclamationTriangleAltSvg } from '../assets/triangle-exclamation-regular.svg';
import { ReactComponent as MQTriangleExclamationSolidSvg } from '../assets/triangle-exclamation-solid.svg';
import { ReactComponent as MQUniversalAccessAltSvg } from '../assets/universal-access-regular.svg';
import { ReactComponent as MQUpDownLeftRightSolidSvg } from '../assets/up-down-left-right-solid.svg';
import { ReactComponent as MQExternalLinkSolidSvg } from '../assets/up-right-from-square-solid.svg';
import { ReactComponent as MQUploadSolidSvg } from '../assets/upload-solid.svg';
import { ReactComponent as MQUserClockAltSvg } from '../assets/user-clock-light.svg';
import { ReactComponent as MQUserClockSolidSvg } from '../assets/user-clock-solid.svg';
import { ReactComponent as MQUserDuoToneSvg } from '../assets/user-duotone.svg';
import { ReactComponent as MQUserCogAltSvg } from '../assets/user-gear-regular.svg';
import { ReactComponent as MQUserGroupDuoToneSvg } from '../assets/user-group-duotone.svg';
import { ReactComponent as MQUserFriendsSvg } from '../assets/user-group-light.svg';
import { ReactComponent as MQUserGroupSvg } from '../assets/user-group-regular.svg';
import { ReactComponent as MQUserHeadSetSvg } from '../assets/user-headset-regular.svg';
import { ReactComponent as MQUserLightSvg } from '../assets/user-light.svg';
import { ReactComponent as MQUserLockDuoToneSvg } from '../assets/user-lock-duotone.svg';
import { ReactComponent as MQUserPlusSolidSvg } from '../assets/user-plus-solid.svg';
import { ReactComponent as MQUserShieldSolidSvg } from '../assets/user-shield-solid.svg';
import { ReactComponent as MQUserSlashSolidSvg } from '../assets/user-slash-solid.svg';
import { ReactComponent as MQUserSolidSvg } from '../assets/user-solid.svg';
import { ReactComponent as MQUserXmarkAltSvg } from '../assets/user-xmark-regular.svg';
import { ReactComponent as MQUserXMarkSolidSvg } from '../assets/user-xmark-solid.svg';
import { ReactComponent as MQUsersDuoToneSvg } from '../assets/users-duotone.svg';
import { ReactComponent as MQUsersCogsDuoToneSvg } from '../assets/users-gear-duotone.svg';
import { ReactComponent as MQUsersCogLightSvg } from '../assets/users-gear-light.svg';
import { ReactComponent as MQUsersCogSolidSvg } from '../assets/users-gear-solid.svg';
import { ReactComponent as MQUsersLightSvg } from '../assets/users-light.svg';
import { ReactComponent as MQUsersSolidSvg } from '../assets/users-solid.svg';
import { ReactComponent as MQVideoPlusAltSvg } from '../assets/video-plus-regular.svg';
import { ReactComponent as MQVideoAltSvg } from '../assets/video-regular.svg';
import { ReactComponent as MQVideoSolidSvg } from '../assets/video-solid.svg';
import { ReactComponent as MQMagicDuoToneSvg } from '../assets/wand-magic-sparkles-duotone.svg';
import { ReactComponent as MQMagicSvg } from '../assets/wand-magic-sparkles-regular.svg';
import { ReactComponent as MQWeightHangingAltSvg } from '../assets/weight-hanging-regular.svg';
import { ReactComponent as MQWindowAltSvg } from '../assets/window-flip-regular.svg';
import { ReactComponent as MQTimesLightSvg } from '../assets/xmark-light.svg';

export const MQ_BRAND_ICONS = {
  [`slack`]: MQSlackBrandSvg,
  [`microsoft`]: MQMicrosoftBrandSvg,
  [`skype`]: MQSkypeBrandSvg,
  [`google`]: MQGoogleBrandSvg,
};

export const MQ_SOLID_ICONS = {
  [`screen-users`]: MQScreenUsersSvg,
  [`globe-light`]: MQGlobeLightSvg,
  [`draw-circle`]: MQDrawCircleSvg,
  [`filter`]: MQFilterSvg,
  [`filters`]: MQFiltersSvg,
  [`file-export`]: MQFileExportSvg,
  [`photo-film`]: MQPhotoFilmSvg,
  [`film`]: MQFilmSvg,
  [`cabinet-filing`]: MQCabinetFilingSvg,
  [`bars`]: MQBarsSvg,
  [`search`]: MQSearchSvg,
  [`download`]: MQDownloadSvg,
  [`info-circle`]: MQInfoCircleSvg,
  [`info-circle-alt`]: MQInfoCircleAltSvg,
  [`face-smile`]: MQFaceSmileSvg,
  [`life-ring`]: MQLifeRingSvg,
  [`house`]: MQHouseSvg,
  [`chart-line-up`]: MQChartLineUpSvg,
  [`user-group`]: MQUserGroupSvg,
  [`sliders-h`]: MQSlidersHSvg,
  [`puzzle-piece`]: MQPuzzlePieceSvg,
  [`palette`]: MQPaletteSvg,
  [`cogs`]: MQGearsSvg,
  [`shield-blank`]: MQShieldSvg,
  [`align-left`]: MQAlignLeftSvg,
  [`user-circle`]: MQUserCircleRegularSvg,
  [`database`]: MQDataBaseSvg,
  [`sign-in`]: MQArrowRightToBracketSvg,
  [`user-headset`]: MQUserHeadSetSvg,
  [`rocket-launch`]: MQRocketLaunchSvg,
  [`bullhorn`]: MQBullhornSvg,
  [`analytics`]: MQChartMixedSvg,
  [`chart-pie`]: MQChartPieSvg,
  [`file-chart-column`]: MQFileChartColumnSvg,
  [`star-light`]: MQStarLightSvg,
  [`times-circle-light`]: MQTimesCircleLightSvg,
  [`times-alt`]: MQTimesSolidSvg,
  [`calendar-days-light`]: MQCalendarDaysLightSvg,
  [`users-light`]: MQUsersLightSvg,
  [`clipboard-list-check`]: MQClipboardListCheckSvg,
  [`play-circle-light`]: MQPlayCircleLightSvg,
  [`search-plus-light`]: MQSearchPlusLightSvg,
  [`list-alt`]: MQListAltSvg,
  [`user-clock-alt`]: MQUserClockAltSvg,
  [`pennant-alt`]: MQPennantAltSvg,
  [`bullseye-pointer-alt`]: MQBullseyePointerAltSvg,
  [`exchange-alt`]: MQExchangeAltSvg,
  [`circle-check-light`]: MQCircleCheckAltSvg,
  [`paper-plane-alt`]: MQPaperPlaneAltSvg,
  [`address-card-alt`]: MQAddressCardAltSvg,
  [`pencil-alt-light`]: MQPencilAltLightSvg,
  [`circle-plus-light`]: MQCirclePlusLightSvg,
  [`user-friends`]: MQUserFriendsSvg,
  [`chart-network`]: MQChartNetworkSvg,
  [`books-alt`]: MQBooksAltSvg,
  [`handshake-angle-alt`]: MQHandshakeAngleSvg,
  [`map-location-alt`]: MQMapLocationAltSvg,
  [`people-carry-box-alt`]: MQPeopleCarryBoxAltSvg,
  [`people-group`]: MQPeopleGroupSolidSvg,
  [`hand-holding-heart-alt`]: MQHandHoldingHeartAltSvg,
  [`sliders-alt`]: MQSlidersAltSvg,
  [`chart-user-alt`]: MQChartUserAltSvg,
  [`chalkboard-user-alt`]: MQChalkboardUserAltSvg,
  [`undo-alt-light`]: MQUndoAltLightSvg,
  [`box-archive-light`]: MQBoxArchiveLightSvg,
  [`users-cog-light`]: MQUsersCogLightSvg,
  [`paperclip-light`]: MQPaperclipLightSvg,
  [`poll-h-light`]: MQPollHLightSvg,
  [`message-lines-light`]: MQMessageLinesLightSvg,
  [`check-square-light`]: MQCheckSquareLightSvg,
  [`edit-light`]: MQPencilAltLightSvg,
  [`eye-light`]: MQEyeLightSvg,
  [`user-light`]: MQUserLightSvg,
  [`magic`]: MQMagicSvg,
  [`file-circle-plus-light`]: MQFileCirclePlusLightSvg,
  [`file-pen-light`]: MQFilePenLightSvg,
  [`align-justify-light`]: MQAlignJustifyLightSvg,
  [`info-circle-d`]: MQInfoCircleDuoToneSvg,
  [`messages-d`]: MQMessagesDuoToneSvg,
  [`calendar-alt-d`]: MQCalendarAltDuoToneSvg,
  [`envelope-open-text-d`]: MQEnvelopeOpenTextDuoToneSvg,
  [`bullseye-arrow-d`]: MQBullseyeArrowDuoToneSvg,
  [`bullseye-pointer-d`]: MQBullseyePointerDuoToneSvg,
  [`magic-d`]: MQMagicDuoToneSvg,
  [`users-cogs-d`]: MQUsersCogsDuoToneSvg,
  [`pennant-d`]: MQPennantDuoToneSvg,
  [`user-circle-d`]: MQUserCircleDuoToneSvg,
  [`circle-chevron-left-d`]: MQCircleChevronLeftDuoToneSvg,
  [`circle-chevron-right-d`]: MQCircleChevronRightDuoToneSvg,
  [`file-edit-d`]: MQFileEditDuoToneSvg,
  [`user-d`]: MQUserDuoToneSvg,
  [`users-d`]: MQUsersDuoToneSvg,
  [`user-lock-d`]: MQUserLockDuoToneSvg,
  [`poll-h-d`]: MQPollHDuoToneSvg,
  [`user-group-d`]: MQUserGroupDuoToneSvg,
  [`poll-people-d`]: MQPolPeopleDuoToneSvg,
  [`file-alt-d`]: MQFileAltDuoToneSvg,
  [`edit-square`]: MQEditSquareSvg,
  [`times-square`]: MQTimesSquareSvg,
  [`upload`]: MQUploadSolidSvg,
  [`star`]: MQStarSolidSvg,
  [`times-circle`]: MQTimesCircleSolidSvg,
  [`arrow-circle-right`]: MQArrowCircleRightSolidSvg,
  [`long-arrow-alt-left`]: MQLongArrowAltLeftSolidSvg,
  [`undo-alt`]: MQUndoAltSolidSvg,
  [`check`]: MQCheckSolidSvg,
  [`arrow-up-right-from-square`]: MQArrowUpRightFromSquareSolidSvg,
  [`left`]: MQLeftSolidSvg,
  [`minus`]: MQMinusSolidSvg,
  [`plus`]: MQPlusSolidSvg,
  [`rotate`]: MQRotateSolidSvg,
  [`arrows-v`]: MQArrowsUpDownSolidSvg,
  [`arrow-right`]: MQArrowRightSolidSvg,
  [`check-square`]: MQCheckSquareSolidSvg,
  [`up-down-left-right`]: MQUpDownLeftRightSolidSvg,
  [`eye-slash`]: MQEyeSlashSolidSvg,
  [`stream`]: MQStreamSolidSvg,
  [`sort`]: MQSortSolidSvg,
  [`sort-up`]: MQSortUpSolidSvg,
  [`sort-down`]: MQSortDownSolidSvg,
  [`sign-out`]: MQSignOutSolidSvg,
  [`copy`]: MQCopySolidSvg,
  [`angle-up`]: MQAngleUpSolidSvg,
  [`angle-down`]: MQAngleDownSolidSvg,
  [`file-edit`]: MQFileEditSolidSvg,
  [`pencil-alt`]: MQPencilAltSolidSvg,
  [`user-xmark`]: MQUserXMarkSolidSvg,
  [`xmark-light`]: MQTimesLightSvg,
  [`circle-right`]: MQCircleRightSolidSvg,
  [`check-circle`]: MQCheckCircleSolidSvg,
  [`triangle-exclamation`]: MQTriangleExclamationSolidSvg,
  [`link`]: MQLinkSolidSvg,
  [`file`]: MQFileSolidSvg,
  [`caret-down`]: MQCaretDownSolidSvg,
  [`ellipsis-vertical`]: MQEllipsisVerticalSolidSvg,
  [`cloud-arrow-up`]: MQCloudArrowUpSolidSvg,
  [`plus-circle`]: MQPlusCircleSolidSvg,
  [`user-slash`]: MQUserSlashSolidSvg,
  [`user-clock`]: MQUserClockSolidSvg,
  [`play-circle`]: MQPlayCircleSolidSvg,
  [`rectangle-history-circle-user`]: MQRectangleHistoryCircleUserSolidSvg,
  [`user`]: MQUserSolidSvg,
  [`arrow-left`]: MQArrowLeftSolidSvg,
  [`chevron-up`]: MQChevronUpSolidSvg,
  [`chevron-down`]: MQChevronDownSolidSvg,
  [`sort-up-down`]: MQSortUpDownSolidSvg,
  [`arrow-down`]: MQArrowDownSolidSvg,
  [`mouse`]: MQMouseSolidSvg,
  [`question-circle`]: MQQuestionCircleSolidSvg,
  [`book-user`]: MQBookUserSolidSvg,
  [`message`]: MQMessageSolidSvg,
  [`send`]: MQSendSolidSvg,
  [`file-lines`]: MQFileLinesSolidSvg,
  [`random`]: MQRandomSolidSvg,
  [`user-shield`]: MQUserShieldSolidSvg,
  [`video`]: MQVideoSolidSvg,
  [`handshake`]: MQHandShakeSolidSvg,
  [`comments`]: MQCommentsSolidSvg,
  [`map`]: MQMapSolidSvg,
  [`list-check`]: MQListCheckSolidSvg,
  [`chart-line`]: MQChartLineSolidSvg,
  [`head-side-heart`]: MQHeadSideHeartSolidSvg,
  [`external-link`]: MQExternalLinkSolidSvg,
  [`ban`]: MQBanSolidSvg,
  [`list-ul`]: MQListUlSolidSvg,
  [`grid`]: MQGridSolidSvg,
  [`sort-alpha-down`]: MQSortAlphaDownSolidSvg,
  [`sort-numeric-down`]: MQSortNumericDownSolidSvg,
  [`sort-alpha-down-alt`]: MQSortAlphaAltDownSolidSvg,
  [`sort-numeric-down-alt`]: MQSortNumericAltDownSolidSvg,
  [`calculator`]: MQCalculatorSolidSvg,
  [`language`]: MQLanguageSolidSvg,
  [`lock`]: MQLockSolidSvg,
  [`envelope`]: MQEnvelopeSolidSvg,
  [`chevrons-right`]: MQChevronsRightSolidSvg,
  [`exclamation-circle`]: MQExclamationCircleSolidSvg,
  [`save`]: MQSaveSolidSvg,
  [`angle-left`]: MQAngleLeftSolidSvg,
  [`angle-right`]: MQAngleRightSolidSvg,
  [`angle-double-left`]: MQAnglesDoubleLeftSolidSvg,
  [`angle-double-right`]: MQAnglesDoubleRightSolidSvg,
  [`chevron-right`]: MQChevronRightSolidSvg,
  [`chevron-left`]: MQChevronLeftSolidSvg,
  [`rocket`]: MQRocketSolidSvg,
  [`circle`]: MQCircleSolidSvg,
  [`users`]: MQUsersSolidSvg,
  [`user-plus`]: MQUserPlusSolidSvg,
  [`eye`]: MQEyeSolidSvg,
  [`bullseye`]: MQBullseyeSolidSvg,
  [`users-cog`]: MQUsersCogSolidSvg,
  [`file-circle-plus`]: MQFileCirclePlusSolidSvg,
  [`file-import`]: MQFileImportSolidSvg,
  [`clock-rotate-left`]: MQClockRotateLeftSolidSvg,
  [`envelopes-bulk`]: MQEnvelopesBulkSolidSvg,
  [`balance-scale`]: MQBalanceScaleSolidSvg,
  [`arrow-circle-up`]: MQArrowCircleUpSolidSvg,
  [`people-arrows`]: MQPeopleArrowsSolidSvg,
  [`desktop`]: MQDesktopSvg,
  [`mobile`]: MQMobileSvg,
  [`paper-plane`]: MQPaperPlaneSolidSvg,
  [`minus-circle-alt`]: MQMinusCircleAltSvg,
  [`plus-circle-alt`]: MQPlusCircleAltSvg,
  [`calendar-day`]: MQCalendarDayAltSvg,
  [`poll-people`]: MQPollPeopleAltSvg,
  [`check-circle-alt`]: MQCheckCircleAltSvg,
  [`clock`]: MQClockAltSvg,
  [`video-alt`]: MQVideoAltSvg,
  [`head-side-brain`]: MQHeadSideBrainAltSvg,
  [`exclamation-triangle-alt`]: MQExclamationTriangleAltSvg,
  [`exclamation-circle-alt`]: MQExclamationCircleAltSvg,
  [`bell-concierge-alt`]: MQBellConciergeAltSvg,
  [`image`]: MQImageAltSvg,
  [`expand`]: MQExpandSolidSvg,
  [`weight-hanging-alt`]: MQWeightHangingAltSvg,
  [`window-alt`]: MQWindowAltSvg,
  [`calendar-alt`]: MQCalendarAltSvg,
  [`calendar-check-alt`]: MQCalendarCheckAltSvg,
  [`trash-alt`]: MQTrashAltSvg,
  [`user-cog-alt`]: MQUserCogAltSvg,
  [`universal-access-alt`]: MQUniversalAccessAltSvg,
  [`gear-alt`]: MQGearAltSvg,
  [`video-plus-alt`]: MQVideoPlusAltSvg,
  [`rocket-alt`]: MQRocketAltSvg,
  [`atom-alt`]: MQAtomAltSvg,
  [`stopwatch-alt`]: MQStopWatchAltSvg,
  [`hourglass-end`]: MQHourGlassEndAltSvg,
  [`dot-circle-alt`]: MQDotCircleAltSvg,
  [`circle-alt`]: MQCircleAltSvg,
  [`square-alt`]: MQSquareAltSvg,
  [`check-square-alt`]: MQCheckSquareAltSvg,
  [`envelope-open-text-alt`]: MQEnvelopeOpenTextAltSvg,
  [`filter-circle-dollar-alt`]: MQFilterCircleDollarAltSvg,
  [`table-alt`]: MQTableAltSvg,
  [`copyright-alt`]: MQCopyrightAltSvg,
  [`table-columns-alt`]: MQTableColumnsAltSvg,
  [`thumbs-up-alt`]: MQThumbsUpAltSvg,
  [`user-xmark-alt`]: MQUserXmarkAltSvg,
  [`location-dot-alt`]: MQLocationDotAltSvg,
  [`tag-alt`]: MQTagAltSvg,
  [`file-magnifying-glass-alt`]: MQFileMagnifyingGlassAltSvg,
  [`check-to-slot-alt`]: MQCheckToSlotAltSvg,
  [`history-alt`]: MQHistoryAltSvg,
  [`file-import-alt`]: MQFileImportAltSvg,
  [`hourglass-half-alt`]: MQHourGlassHalfAltSvg,
  [`microphone-alt`]: MQMicrophoneAltSvg,
  [`microscope-alt`]: MQMicroscopeAltSvg,
  [`rectangle-list-alt`]: MQRectangleListAltSvg,
  [`play-circle-alt`]: MQPlayCircleAltSvg,
  [`arrow-circle-down-alt`]: MQArrowCircleDownAltSvg,
  [`file-pdf-alt`]: MQFilePdfAltSvg,
  [`file-powerpoint-alt`]: MQFilePowerpointAltSvg,
  [`file-video-alt`]: MQFileVideoAltSvg,
  [`file-word-alt`]: MQFileWordAltSvg,
  [`messages-alt`]: MQMessagesAltSvg,
  [`book-open-alt`]: MQBookOpenAltSvg,
  [`chevron-circle-up`]: MQChevronCircleUpSvg,
  [`chevron-circle-down`]: MQChevronCircleDownSvg,
  [`cloud-arrow-up-alt`]: MQCloudArrowUpRegularSvg,
  [`times-light`]: MQTimesLightSvg,
  [`history`]: MQHistorySvg,
  [`cookie-bite`]: MQCookieBiteSvg,
  [`target-communications-graphic`]: MQTargetCommunicationsGraphicSvg,
  [`stars`]: MQStarsSvg,
};

export const MQ_LIGHT_ICONS = {
  [`copy`]: MQCopyLightSvg,
};

export const MQ_ICONS = {
  solid: MQ_SOLID_ICONS,
  brand: MQ_BRAND_ICONS,
  light: MQ_LIGHT_ICONS,
};
