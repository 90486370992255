import './style.scss';

import { FC, useMemo } from 'react';

import classNames from 'classnames';

import { MQ_ICONS } from '../../definitions';
import { MQSvgIconProps } from '../../types';

const MQSvgIcon: FC<MQSvgIconProps> = ({ className, variant, size = '1x', icon, ...props }) => {
  const SvgIcon = useMemo(() => {
    if (typeof icon === 'object') {
      switch (icon.prefix) {
        case 'solid':
          return MQ_ICONS.solid[icon.name];
        case 'brand':
          return MQ_ICONS.brand[icon.name];
        case 'light':
          return MQ_ICONS.light[icon.name];
      }
    }

    return MQ_ICONS.solid[icon];
  }, [icon]);

  return (
    <span data-testid="mq-svg-icon" className={classNames('mq-svg-icon', `mq-svg-size-${size}`, variant, [className])}>
      <SvgIcon alt="" aria-hidden="true" {...props} />
    </span>
  );
};

export default MQSvgIcon;
