import { FC, useState } from 'react';

import { MQOverlayProps, MQOverlayStateProps } from '../../types';

const MQOverlayProvider: FC<MQOverlayProps> = ({ children, onToggle }) => {
  const [state, setState] = useState<MQOverlayStateProps | null>(null);
  return (
    <>
      {children(state, (data) => {
        setState(data);
        onToggle?.(!!data?.target);
      })}
    </>
  );
};

export default MQOverlayProvider;
